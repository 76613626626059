import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeModule } from './../../../../modules/employee';
@Component({
  selector: 'employee',
  templateUrl: './createEmployee.component.html',
  styleUrls: ['./createEmployee.component.css']
})
export class CreateEmployeeComponent {

  public empFields = EmployeeModule;
  constructor() {
  }

  ngOnInit() {
    console.log('this.empFields', this.empFields);
  }


  submitted(e: any) {
console.log(e);
  }
}
