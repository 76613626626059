import { Component, OnInit  } from '@angular/core';

@Component({
    selector: 'app-test-information',
    templateUrl: './test.component.html',
    styles: ['./test.component.css'],
})

export class TestInformationComponent implements OnInit {
    public addPageShow = false;
    public viewPageShow = true;
    constructor() { }

    public routeObj = [];
    ngOnInit(): void {
    }
    currentPage(e: string) {
      if (e == 'addRoutePage') {
        this.addPageShow = true;
        this.viewPageShow = false;
      } else {
        this.addPageShow = false;
        this.viewPageShow = true;
      }
    }
    routeGetData(e: any) {
      this.routeObj.push(e);
    }
}
