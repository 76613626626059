import { Component, OnInit, ViewChild, Input, Output } from '@angular/core';
import { userModule } from './../../../../modules/user';
import { DialogActionService } from './../../../../Services/dialog-actions.service';
import { ApiService } from 'src/app/Services/api.service';
import { SnackBarService } from 'src/app/Services/snackbar.service';
import { SharedService } from 'src/app/Services/shared.service';
@Component({
  selector: 'user-view',
  templateUrl: './viewUser.component.html',
  styleUrls: ['./viewUser.component.css']
})
export class ViewUserComponent {
  public userFields = userModule;
  constructor(
    private dialogService: DialogActionService,
    private api: ApiService,
    private snackBar: SnackBarService,
    private sharedService: SharedService
  ) {
  }
  public userInfo: any = {};
  public values: any = [];
  public formInfo: any = {
    editForm: false,
    viewForm: false,
  };

  cols = [
    { key: 'firstName', display: 'Name' },
    // { key: "lastName", display: "Last Name" },
    { key: 'email', display: 'Email' },
    { key: 'contactNumber', display: 'Contact Number' },
    { key: 'address', display: 'Address' },
    { key: 'city', display: 'City' },
    { key: 'state', display: 'State' },
    { key: 'country', display: 'Country' },

    {
      key: 'action',
      display: 'Action',
      config: {
        isAction: true,
        actions: [
          { display: 'view', color: 'primary', icon: 'View' },
          { display: 'delete', color: 'warn', icon: 'Delete' },
          { display: 'update', color: 'primary' }
        ]
      }
    }
  ];
  public userDetails: any;
  // public userDetails: any = [
  //   {
  //     firstName: "Raja", lastName: "E",
  //     emailID: "rajae@groupten.com",
  //     phoneNumber: 9159185218, address: "Solinganallur",
  //     city: "chennai",
  //     country: "IND",
  //     state: "TN"
  //   },
  //   {
  //     firstName: "Ragu", lastName: "R",
  //     emailID: "ragu@groupten.com",
  //     phoneNumber: 9159184323, address: "Pallikaranai",
  //     city: "chennai",
  //     country: "IND",
  //     state: "TN"
  //   },
  //   {
  //     firstName: "Arun", lastName: "A",
  //     emailID: "arun@groupten.com",
  //     phoneNumber: 9159185218, address: "Medavakkam",
  //     city: "chennai",
  //     country: "IND",
  //     state: "TN"
  //   },
  //   {
  //     firstName: "Mohan", lastName: "Dass",
  //     emailID: "mohan@groupten.com",
  //     phoneNumber: 9159185218, address: "Velachery",
  //     city: "chennai",
  //     country: "IND",
  //     state: "TN"
  //   }

  // ];
  ngOnInit() {
    this.loadData();
  }

  loadData() {
    const obj = {
      orgID: '605471600e7dae2fdce593d8'
    };
    this.api.viewUser(obj).then((res: any) => {
      if (res.status == 'success') {
        this.userDetails = res.data;
        console.log('this.userDetails', this.userDetails);
      } else {
        const errorMsg = (typeof res.message == 'string') ? res.message : res.message.join(',');
        const config = {
          message: '<p class=\'p-0 m-0\'> <span > <i class=\'ms-Icon ms-Icon--InfoSolid text-primary h4 m-0 p-0 icon\' aria-hidden=\'true\'> </i> </span> ' + errorMsg + ' </p>',
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 5000
        };
        const snackBarRef: any = this.snackBar.openSnackBar(config);
      }
    });
  }

  onActionHandler(e: any) {
    const data = e.data;

    this.userFields.filter((item: any) => {
      if (item.type == 'date') {
        item.value = this.sharedService.TMSToDate(data[item.name], '-', '');
      } else {
        item.value = data[item.name];
      }
    });
    if (e.action && e.action.display.toLowerCase() == 'update') {

      this.formInfo.editForm = true;
    } else if (e.action && e.action.display.toLowerCase() == 'delete') {
      console.log('openDeleteProductModal');
      this.openDeleteProductModal();
    } else if (e.action && e.action.display.toLowerCase() == 'view') {

      this.userInfo = data;
      this.formInfo.viewForm = true;
    }
  }
  openDeleteProductModal() {
    const productId = 'prod01';
    let config = {};
    config = {
      name: 'deleteProduct',
      title: 'Are you sure you want to delete this product?',
      description: 'If you continue, the product with ID ' + productId + ' will be deleted.',
      actionButtonText: 'Delete',
      productId
    };
    const res = this.dialogService.popup(config);
    // const modalDialog = this.dialog.open(DialogComponent, dialogConfig);
  }
  formSubmit(e: any) {
    console.log(e);
    if (e == 0) {
      this.formInfo.editForm = false;
      this.formInfo.viewForm = false;
    } else {
      console.log(e);
    }
  }
}
