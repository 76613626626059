import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilteredEmployees } from  "./../../../store/employees/filters/reducer";

import * as formFields from "./employee.fields.json";
@Component({
    selector: 'g10-employee-filter',
    templateUrl: './employees.component.html',
    styleUrls: ['./employees.component.css']
})

export class EmployeeFilterComponent {
    @Input() _popup: boolean;
    @Output() employeeList = new EventEmitter();
    public allFields: any = formFields['default'];
    public User_type: any;
    formData: any = FormGroup;
    public availableFilters: any = [
        // "empRefID", "name", "dob", "gender", "mobile", "email", "address", "city", "state", "branch", "shift",
        // "transportType", "locationName"
    ].sort();
    public selectedFilterOptions = [];
    public selectedFields = [];
    constructor(
        private fb: FormBuilder,
        private storage: StorageService,
        private api: ApiService
    ) { }
    public shiftList: any = {};
    public orgInfo = this.storage.get('userInfo');
    public vendorInfo = this.storage.get('vendorID_orgID');
    ngOnInit() {
         
        this.api.getEmployeeFilter({orgID: this.orgInfo.orgID,projection:{
            _id: 0,
            empRefID: 1, name: 1, dob: 1, gender: 1, mobile: 0, email: 1,
            address: 1, city: 1, state: 1, branch:1, shift: 1, transportType: 1, locationName: 1
          }}).then((res:any)=>{
             if(res.status =='success'){
                this.availableFilters = res.filterKeys;
                console.log(this.availableFilters);
                this.init();
             }
        })
    //   
    }

    async init(){
         
        let dynamicFields = {
            availableFields: ['']
        }
        this.availableFilters.map((item) => {
            if (dynamicFields[item] == undefined) dynamicFields[item] = [''];
        });
        this.formData = this.fb.group(dynamicFields);
        this.User_type = this.storage.get('userType');
        this.getBranches();
    }

    selectedBranch(e:any){

        if(e.value){
            this.allFields['shift']['options'] = this.shiftList[e.value];
        }
            console.log(this.shiftList, this.allFields);
    }

    getBranches() {
        let obj = {
            orgID: this.orgInfo.orgID,
            vendorID: this.vendorInfo.vendorID
        }
        this.api.listBranches(obj).then((res: any) => {
            if (res.status == 'success') {
                let branch = [];
                console.log('List ', res.data);
                res.data.map((item, i) => {                   

                    branch.push({ name: item.branchName, value: item.branchID });
                    if (res.data.length - 1 == i) {
                        this.allFields['branch']['options'] = branch;
                        this.getShifts();
                    }
                });

            }
        });
    }

    getShifts() {
        let obj = {
            orgID: this.orgInfo.orgID
        }
        this.api.ListShift(obj).then((res: any) => {
            if (res.status == 'success') {
                res.data.map((item, i) => {
                    if(this.shiftList[item.branch] == undefined) this.shiftList[item.branch]=[];
                    this.shiftList[item.branch].push({ name: item.shiftName, value: item.shiftID });
                    
                });

            }
        });

    }

    addValidation(fields) {
        let val = (fields == 'email' ? [Validators.email] : (fields == 'mobile' ? [Validators.minLength(10), Validators.maxLength(12)] : []));
        this.formData.controls[fields].setValidators(val);
        this.formData.controls[fields].updateValueAndValidity();
    }
    removeValidation(fields) {
        this.formData.controls[fields].setValidators(null);
        this.formData.controls[fields].updateValueAndValidity();
    }
    getFieldInfo(data) {
        let value = data.value;
        let isChecked = data.selected;
        if (isChecked) {
            this.selectedFilterOptions.push(this.allFields[value]);
            this.addValidation(value);
        } else {
            this.selectedFilterOptions.filter((item, i) => {
                if (item.name == value) this.selectedFilterOptions.splice(i, 1);
            });
            this.removeValidation(value);
        }
    }
    getFilterObj() {
        return new Promise((res, rej) => {
            let dateRead = (str) => {
                const date = new Date(str),
                    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
                    day = ('0' + date.getDate()).slice(-2);

                return [date.getFullYear(), mnth, day].join('-');
            };
            let values = this.formData.value;
            let obj = {};
            this.formData.value.availableFields.map((fieldName, i) => {

                if (fieldName == 'dob') {
                    obj[fieldName] = dateRead(values[fieldName]);
                } else {
                    obj[fieldName] = values[fieldName];
                }

                if (this.formData.value.availableFields.length - 1 == i) {
                    res(obj);
                }

            });
        })
    }
    async onSubmit() {

        if (this.formData.valid) {

            let obj = await this.getFilterObj();

            this.api.filterEmployees(obj).then((res: any) => {

                  if(res.status == 'success'){
                       this.employeeList.emit(res.data);
                  }
            });

        }
    }
}