import { Component, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
    selector: 'g10-ms-multiSelect',
    template: `
    <mat-form-field appearance="outline">
	<mat-select (openedChange)="openedChange($event)" placeholder="field.lable" name="sss" [formControl]="selectFormControl" ngDefaultControl multiple>
		<mat-select-trigger>
			{{selectFormControl.value ? selectFormControl.value[0] : ''}}
			<span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">
        (+{{selectFormControl.value.length - 1}} {{selectFormControl.value?.length === 2 ? 'other' : 'others'}})
      </span>
    </mat-select-trigger>
    <div class="select-container">
    <mat-optgroup >
  <mat-form-field style="width:100%;" >
    <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput [formControl]="searchTextboxControl">
    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
    <mat-icon >close</mat-icon>
  </button>
         </mat-form-field>
    </mat-optgroup>
    <mat-optgroup *ngIf="(filteredOptions | async).length == 0">
      <div>No results found!</div>
    </mat-optgroup>
 <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
</div>
</mat-select>
</mat-form-field>
<!--div *ngIf="selectFormControl.value">{{ 'You have choosen ' + selectFormControl.value }}</div-->

`,
styles: [`.additional-selection {
    opacity: 0.75;
    font-size: 0.75em;
  }
  .select-container{
    margin-top:10px;
  }
  `]


})

export class MultiSelectComponent {

    @ViewChild('search', {static: true}) searchTextBox: ElementRef;

    selectFormControl = new FormControl();
    searchTextboxControl = new FormControl();
    selectedValues = [];
    data: string[] = [
      'A1',
      'A2',
      'A3',
      'B1',
      'B2',
      'B3',
      'C1',
      'C2',
      'C3'
    ];

    filteredOptions: Observable<any[]>;

    ngOnInit() {
      /**
       * Set filter event based on value changes
       */
      this.filteredOptions = this.searchTextboxControl.valueChanges
        .pipe(
          startWith<string>(''),
          map(name => this._filter(name))
        );
    }

    /**
     * Used to filter data based on search input
     */
    private _filter(name: string): String[] {
      const filterValue = name.toLowerCase();
      // Set selected values to retain the selected checkbox state
      this.setSelectedValues();
      this.selectFormControl.patchValue(this.selectedValues);
      const filteredList = this.data.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
      return filteredList;
    }

  /**
   * Remove from selected values based on uncheck
   */
    selectionChange(event) {
      if (event.isUserInput && event.source.selected == false) {
        const index = this.selectedValues.indexOf(event.source.value);
        this.selectedValues.splice(index, 1);
      }
    }

    openedChange(e) {
      // Set search textbox value as empty while opening selectbox
      this.searchTextboxControl.patchValue('');
      // Focus to search textbox while clicking on selectbox
      if (e == true) {
        this.searchTextBox.nativeElement.focus();
      }
    }

    /**
     * Clearing search textbox value
     */
    clearSearch(event) {
      event.stopPropagation();
      this.searchTextboxControl.patchValue('');
    }

    /**
     * Set selected values to retain the state
     */
    setSelectedValues() {
      console.log('selectFormControl', this.selectFormControl.value);
      if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
        this.selectFormControl.value.forEach((e) => {
          if (this.selectedValues.indexOf(e) == -1) {
            this.selectedValues.push(e);
          }
        });
      }
    }

}
