import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'g10-ms-singleSelect',
    template: `<mat-form-field appearance="outline">
    <mat-label>Input</mat-label>
    <input #search matInput [matAutocomplete]="auto" [formControl]="customerFilterControl">
    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
    <mat-icon >close</mat-icon>
  </button>
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option *ngFor="let option of (filteredOptions | async)" [value]="option">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>`

})

export class SingleSelectComponent {
    objectOptions = [
        { name: 'Angular' },
        { name: 'Angular Material' },
        { name: 'React' },
        { name: 'vue' }
        ];
        filteredOptions: any;
      customerFilterControl = new FormControl();

    ngOnInit() {
        this.filteredOptions = this.customerFilterControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );

        // console.log(this.htmlCode);
      }

      _filter(value: string) {
        const filterValue = value;
        return this.objectOptions.filter(option => option.name.toLowerCase().includes(filterValue)); // how do I filter values here
      }

      displayFn(subject) {
        return subject ? subject.name : undefined;
      }
      clearSearch(event) {
        event.stopPropagation();
        this.customerFilterControl.patchValue('');
      }

}
