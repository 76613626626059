import { Component, OnInit } from '@angular/core';
import { AnnouncementModel } from 'src/app/modules/announcement.model';

@Component({
  selector: 'app-add-announcement',
  templateUrl: './add-announcement.component.html',
  styleUrls: ['./add-announcement.component.css']
})
export class AddAnnouncementComponent implements OnInit {
annoncmentData = AnnouncementModel;
  constructor() { }

  ngOnInit(): void {
  }
  submitted(e) {
    console.log(e.value);
  }

}
