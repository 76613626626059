import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { SocketIOService } from 'src/app/Services/socket.io.service';
declare const mapUtilityMapBox;

// import  '../../../assets/js/cus-elem.js'

export interface Section {
  name: string;
  updated: Date;
}

@Component({
  selector: 'app-custom-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private socket: SocketIOService) { }
  links = ['Live Tracking', 'Notifications', 'Trips', 'Alerts', 'Vehicles', 'Passangers'];
  activeLink = this.links[0];
  background: ThemePalette = 'primary';
  value: '';
  showFiller = false;

  folders: Section[] = [
    {
      name: 'Photos',
      updated: new Date('1/1/16'),
    },
    {
      name: 'Recipes',
      updated: new Date('1/17/16'),
    },
    {
      name: 'Work',
      updated: new Date('1/28/16'),
    }
  ];
  notes: Section[] = [
    {
      name: 'Vacation Itinerary',
      updated: new Date('2/20/16'),
    },
    {
      name: 'Kitchen Remodel',
      updated: new Date('1/18/16'),
    }
  ];

  ngOnInit(): void {
    console.log('start  socket io');
    // this.socket.getMessages();
  }

  toggleBackground() {
    this.background = this.background ? 'accent' : 'primary';
  }

  addLink() {
    this.links.push(`Link ${this.links.length + 1}`);
  }
}
