export const complaintModule = [
    {
        type: 'text',
        name: 'Title',
        label: 'Title',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'dropdown',
        name: 'requestTo',
        label: 'Request To',
        value: '',
        required: true,
        col: 'col-md-4',
        options: [
            { value: 'emp-1', name: 'emp-1'},
            { value: 'emp-2', name: 'emp-2'},
            { value: 'emp-3', name: 'emp-3'},
          ]
      },
      {
        type: 'dropdown',
        name: 'priority',
        label: 'Priority',
        value: '',
        required: true,
        col: 'col-md-4',
        options: [
            { value: 'high', name: 'High'},
            { value: 'medium', name: 'Medium'},
            { value: 'low', name: 'Low'},
          ]
      },
      // {
      //   type: 'text',
      //   name: 'reason',
      //   label: 'Reason',
      //   value: '',
      //   required: true,
      //   col:'col-md-8'
      // },
      // {
      //   type: 'date',
      //   name: 'date',
      //   label: 'Today’s Date',
      //   value: '',
      //   required: true,
      //   col:'col-md-4'
      // },
      // {
      //   type: 'text',
      //   name: 'accusedName',
      //   label: 'Name of the Accused',
      //   value: '',
      //   required: true,
      //   col:'col-md-4'
      // },
      // {
      //   type: 'dropdown',
      //   name: 'accusedDepartment',
      //   label: 'Department',
      //   value: '',
      //   required: true,
      //   col:'col-md-4',
      //   options: [
      //       { value: 'Admin', name: 'Admin'},
      //       { value: 'Dept1', name: 'Dept1'},
      //       { value: 'Dept2', name: 'Dept2'},
      //     ]
      // },
      // {
      //   type: 'text',
      //   name: 'relationship',
      //   label: 'Relationship of the Accused to the Complainant',
      //   value: '',
      //   required: true,
      //   col:'col-md-4',
      // },
      // {
      //   type: 'number',
      //   name: 'accusedPhoneNumber',
      //   label: 'Phone Number',
      //   value: '',
      //   required: true,
      //   col:'col-md-4'
      // },
      // {
      //   type: 'text',
      //   name: 'accusedEmail',
      //   label: 'E-mail',
      //   value: '',
      //   required: true,
      //   col:'col-md-4',
      //   textType: 'email'
      // },
      // {
      //   type: 'date',
      //   name: 'dateOfIncident',
      //   label: 'Date of Incident',
      //   value: '',
      //   required: true,
      //   col:'col-md-4'
      // },
      // {
      //   type: 'text',
      //   name: 'eventOccur',
      //   label: 'Where did the specific event occur?',
      //   value: '',
      //   required: true,
      //   col:'col-md-4',
      //   multiline: true
      // },
      {
        type: 'text',
        name: 'message',
        label: 'Message',
        value: '',
        required: true,
        col: 'col-md-12',
        multiline: true
      }
];
