import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MustMatch } from './mustmatch/must-match.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  newPassword = ''
  confrimPassword = ''
  isShowEyesPsswrd: boolean;
  inputTypeNew: string = 'password';
  isShowEyesCnfrimPass: any;
  inputTypeConfrim: string = 'password';
  registerForm:FormGroup
  submitted: boolean = false;
  constructor(public formBuilder:FormBuilder,
    public router:Router) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
  }, {
      validator: MustMatch('password', 'confirmPassword')
  });
  }
  // formLoad(){
  //   this.registerForm = this.fb.group({
  //     password: ['', [Validators.required, Validators.minLength(6)]],
  //     confirmPassword: ['', Validators.required]
  // }, {
  //     validator: MustMatch('password', 'confirmPassword')
  // });
  // }
  viewPassword(val) {
    console.log(val);
    if (val == 'new') {
      this.isShowEyesPsswrd = !this.isShowEyesPsswrd;
      if (this.isShowEyesPsswrd) { this.inputTypeNew = 'text'; } else { this.inputTypeNew = 'password'; }
    }
    if (val == 'confrim') {

      this.isShowEyesCnfrimPass = !this.isShowEyesCnfrimPass;
      if (this.isShowEyesCnfrimPass) { this.inputTypeConfrim = 'text'; } else { this.inputTypeConfrim = 'password'; }
      console.log(this.isShowEyesCnfrimPass, this.inputTypeConfrim);
    }
  }
  get f() { return this.registerForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))
this.router.navigate(['organization/transport-manager/login'])
}
   enterToPassWord(e) { }
}
