import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'view-route',
  templateUrl: './view-route.component.html',
  styleUrls: ['./view-route.component.css']
})
export class ViewRouteComponent implements OnInit {

  @Input() routeData;
  @Output() currentState = new EventEmitter();

  public popupData;
  public isEmptyList;
  constructor() { }

  ngOnInit(): void {
    this.isEmptyList = Object.keys(this.routeData).length == 0;
    console.log(this.isEmptyList);
  }
  viewState() {
    console.log(this.currentState);
    this.currentState.emit('addRoutePage');
  }
  openPopup(index: number) {
    this.popupData = index;
    const y = document.getElementById('popupModal');
    if (y.style.display === 'none') {
      y.style.display = 'block';
    } else {
      y.style.display = 'none';
    }
  }
  closePopup() {
    const y = document.getElementById('popupModal');
    if (y.style.display === 'block') {
      y.style.display = 'none';
    } else {
      y.style.display = 'block';
    }
  }
}

