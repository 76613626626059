import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { SharedService } from 'src/app/Services/shared.service';



@Component({
    selector: 'g10-ms-bar-chart',
    templateUrl: './barchart.component.html',
    styleUrls: ['./barchart.component.css']
})
export class BarChartComponent {
    @Input() barChartData: ChartDataSets[];
    @Input() labels: Label[];
    @Input() chartType: ChartType;
    @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;


    public barChartOptions: ChartOptions = {
        responsive: true,
        plugins: {
            datalabels: {
              color: '#ffffff',
              font: {
                weight: 'bold',
                size: 8,
              }
            }

          },
    };

    public barChartLegend = true;
    public barChartPlugins = [];
    public barChartColors: Color[] = [
        { backgroundColor: '#6264a7' },
        { backgroundColor: '#e84393' },
        { backgroundColor: '#ff7675' },
        { backgroundColor: '#00b894' },
        { backgroundColor: '#fdcb6e' },
        { backgroundColor: '#82589F' },
        { backgroundColor: '#e17055' },
        { backgroundColor: '#182C61' },
        { backgroundColor: '#6D214F' },
        { backgroundColor: '#B33771' },
        { backgroundColor: '#55E6C1' },
        { backgroundColor: '#6c5ce7' },
        { backgroundColor: '#0984e3' },
        { backgroundColor: '#fd79a8' },
    ];
    constructor(private shared: SharedService) {

    }

    generateColors() {
        for (let i = 0; this.barChartData.length > i; i++) {
            this.barChartColors.push({ backgroundColor: this.shared.generateColors() });
            // console.log({ backgroundColor: this.shared.generateColors() })
        }

        console.log('barChartData', this.barChartData);
    }

    ngOnInit() {
        // console.log(this.barChartData)

        if (this.chart) {
            this.chart.chart;
            // console.log(this.chart)
        }
        this.generateColors();

    }



    // events
    public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
        // console.log(event, active);
    }

    public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
        // console.log(event, active);
    }



}
