import { Component, Input, Output } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { EventEmitter } from 'events';


@Component({
    selector: 'forms-button',
    template: `
    <button mat-flat-button color="{{field.color}}" (click)="customButton($event)">{{field.displatText}}</button>

     `
})

export class ButtonComponents {
    @Output() clickValues = new EventEmitter();
    @Input() field: any = {};
    @Input() form: FormGroup;


    customButton(e: any) {
        this.clickValues.emit(e);
    }

}

// <div [formGroup]="form">
//     <mat-form-field appearance="outline" *ngIf="!field.multiline">
//       <mat-label>{{field.label}} {{field.required ? '*':''}}</mat-label>
//       <input matInput [attr.type]="field.type" [id]="field.name" [name]="field.name" [formControlName]="field.name" [placeholder]="field.placeholder">

//       <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>
//     </mat-form-field>


//       <mat-form-field appearance="outline"  *ngIf="field.multiline">
//       <mat-label>{{field.label}}</mat-label>
//       <textarea style="height: 30px;" [class.is-invalid]="isDirty && !isValid" [formControlName]="field.name" [id]="field.name"
//       rows="9" [placeholder]="field.placeholder" matInput></textarea>
//     </mat-form-field>
//     </div>
