import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password,
@Component({
    selector: 'radio',
    template: `
<div [formGroup]="form">
      <label class="form-check-label" for="inlineRadio1">{{field.label}} {{field.required ? '*':''}}</label>
      &nbsp;&nbsp;
      <div class="form-check form-check-inline" *ngFor="let op of field && field.options">
      <label class="form-check-label" for="inlineRadio1">
      <input class="radio-btn" type="radio" [name]="field.name" [formControlName]="field.name" [value]="op.value" (click)="update($event)">
        {{op.name}} </label>
     </div>
     <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>
      </div>

    `
    // <label > {{field.label}} </label>
})
export class RadioComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }

    constructor() { }
    update(e: any) {
        setTimeout(() => {
            const field: any = this.form.controls[this.field.name];
            if (field.value != '') {
                field.value = field.value;
                field.status = 'VALID';
            } else {
                field.value = '';
                field.status = 'INVALID';
            }
        }, 1000);

    }
}
