import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password,
@Component({
  selector: 'password',
  template: `
      <div [formGroup]="form">
      <mat-form-field appearance="outline" *ngIf="!field.multiline">
      <mat-label>{{field.label}} {{field.required ? '*':''}}</mat-label>
      <input matInput [attr.type]="field.type" [id]="field.name" [name]="field.name" [formControlName]="field.name"  [placeholder]="field.placeholder">

      <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>
    </mat-form-field>
    </div>


    `
})
export class PasswordComponent {
  @Input() field: any = {};
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }
  constructor() { }
}
