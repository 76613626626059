import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
// import { EventEmitter } from 'events';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {
  panelOpenState = false;
  @Input() isOnlyIcon: any;
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  @ViewChild('sidenav') sidenav: MatSidenav;
  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  sideNav = [];
  // masterSideNav = []
  transportManagerNav: [];
  showSubSubMenu = false;
  constructor(public storage: StorageService,
              public router: Router) {

  }
  public masterSideNav: any = [
    {
      lable: 'Dashboard',
      icon: 'ViewDashboard',
      path: '/master/dashboard'
    },
    // {
    //   lable: 'create-org',
    //   icon: 'ExploreContent',
    //   path: '/master/create-org'
    // },
    {
      lable: 'View Organization',
      icon: 'Org',
      path: '/master/view-org'
    }];
  public trasnsportMangerNav = [
    {
      lable: 'Dashboard',
      icon: 'ViewDashboard',
      path: '/organization/transport-manager/dashboard'
    },
    {
      lable: 'Vendor',
      icon: 'Car',
      path: '/organization/transport-manager/information/vendor'
    },
    {
      lable: 'Employee',
      icon: 'UserSync',
      path: '/organization/transport-manager/information/employee'
    },
    {
      lable: 'Request',
      icon: 'BackToWindow',
      path: '/organization/transport-manager/request'
    },
    {
      lable: 'Reports',
      icon: 'CRMReport',
      path: '/organization/transport-manager/reports'
    },
    {
      lable: 'Support-desk',
      icon: 'SurveyQuestions',
      path: '/organization/transport-manager/support-desk'
    },
    // {
    //   lable: 'Employee',
    //   icon: 'ManagerSelfService',
    //   path: '/organization/transport-manager/information/employee'
    // }
  ];
  public vendorNav = [
    {
      lable: 'Dashboard',
      icon: 'ViewDashboard',
      path: '/organization/vendor/dashboard'
    },

  ];
  ngOnInit() {
    // console.log(this.router.url.split('/')[1])
    this.sideNavSetup();
  }

  sideNavSetup() {
    const url = this.router.url.split('/');
    if (url && url[1] === 'master') {
      this.sideNav = this.masterSideNav;
    }
    if (url && url[2] === 'transport-manager') {
      this.sideNav = this.trasnsportMangerNav;
    }
    if (url && url[2] === 'vendor') {
      this.sideNav = this.vendorNav;
    }
  }
  // mouseenter() {
  //   if (!this.isExpanded) {
  //     this.isShowing = true;
  //   }
  // }
  toggleSideBar() {
    this.showSubSubMenu = !this.showSubSubMenu;
    let isOpen = false;
    this.toggleSideBarForMe.emit(isOpen = !isOpen);
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }
  // mouseleave() {
  //   if (!this.isExpanded) {
  //     this.isShowing = false;
  //   }
  // }
}
