import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import {CardOptions} from '../../Interfaces/card';

@Component({
  selector: 'g10-ms-card',
  templateUrl: './card.component.html',
  styleUrls: [ './card.component.css' ]
})
export class CardComponent  {
   @Input() card: CardOptions;
   @ViewChild('ref', { static: true }) input: ElementRef;
   header = true;
  ngOnInit() {

  }
}
