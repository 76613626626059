import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';
// import { MsalService } from '../services/msal.service';
import { HttpHeaders } from '@angular/common/http';
import { from as fromPromise, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { SweetAlert } from './sweetalet.service';
import { Router } from '@angular/router';
import { LoaderService } from './loader.service';




@Injectable({
  providedIn: 'root'
})
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private storage: StorageService,
    public swal: SweetAlert,
    public router: Router,
    public loader: LoaderService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const path = request.url.split('/');
    if (path[path.length - 1] !== 'statusUpdate') {  this.loader.show(); }
    return fromPromise(this.handleAccess(request, next)).pipe(
      tap(result => {
        this.loader.hide();
        const sts: any = result;
        // console.log('result', sts)
        if (sts.body.status == 'failure' && sts.body.message == 'Token expired.') {
         this.swal.swalFunction('error', sts.body.status, sts.body.message, 2000);
         this.storage.setData({userType: 'transport_manager', token: ''});
         this.router.navigate(['/homepage']);
         this.storage.removeAll();
        }
      }, error => {
        console.log('error', error);
      }
      )
    );
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler):
    Promise<HttpEvent<any>> {
    const token = this.storage.get('token');
    let changedRequest = request;
    // HttpHeader object immutable - copy values
    const headerSettings: { [name: string]: string | string[]; } = {};

    for (const key of request.headers.keys()) {
      headerSettings[key] = request.headers.getAll(key);
    }
    if (token) {
      headerSettings.Token = token;
    }
    headerSettings['Content-Type'] = 'application/json';
    headerSettings['Access-Control-Allow-Origin'] = '*';
    headerSettings['Access-Control-Allow-Credentials'] = 'true';
    headerSettings['Access-Control-Allow-Methods'] = 'GET,HEAD,OPTIONS,POST,PUT';
    headerSettings['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers';

    const newHeader = new HttpHeaders(headerSettings);

    changedRequest = request.clone({
      headers: newHeader
    });

    return next.handle(changedRequest).toPromise();


  }



}
