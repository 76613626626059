import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

declare const Swal;
@Component({
  selector: 'g10-single-view-rostering',
  templateUrl: './single-view-rostering.component.html',
  styleUrls: ['./single-view-rostering.component.css']
})

export class SingleViewRoasteringComponent implements OnInit {
  @Input() routeID;
  @Output() currentPage = new EventEmitter();
  @Output() editObj= new EventEmitter();
  public singleViewData=[];
  public shiftObj;
  public branchInformation=[];

  public userData;
  public displayStartTime;
  public singleViewObj;
  public enableView:boolean=false;
  constructor(
    public api: ApiService,
    public storage: StorageService,
    public swal: SweetAlert,
  ) {
    this.userData = this.storage.get('userInfo')
   }

  ngOnInit(): void {
    this.getBranchList();
    this.getListShift();
    this.getRouteData();
  }
  /* API's*/
  getBranchList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.branchInformation = res.data;
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getListShift() {
    let obj = {
      orgID: this.userData.orgID
    }
    this.api.ListShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.shiftObj = res.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  /* get route Object*/
  getRouteData() {
    let obj = {
      orgID: this.userData.orgID,
    };
    this.api.transportMangerRouteList(obj).then((res: any) => {
      if (res.status == 'success') {
        let data=res.data;
        data.filter((e)=>{
          if(e.routeID==this.routeID){
            this.singleViewObj=e;
            this.singleViewData=[];
            this.singleViewData.push(e);
            this.displayTime();
            this.singleViewCount();
          }
        })
      } else {
        console.log('else', res);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  getShiftName(id){
    let shift=[];
    this.shiftObj.filter((e)=>{
      if(e.shiftID==id){
        shift.push(e);
      }
    });
    return shift[0].shiftName;
  }
  /* time display format*/
  displayTime(){
    if(this.singleViewObj && this.singleViewObj.startTime){
      let time=this.singleViewObj.startTime;
      this.displayStartTime=time.hour+':'+time.minute+' '+time.meriden;
    }
  }
  /* single view data counts*/
  singleViewCount(){
    this.singleViewData[0]['shiftName']=this.singleViewData[0].routeShift?this.getShiftName(this.singleViewData[0].routeShift):'',
    this.singleViewData[0]['branch']=this.singleViewData[0].branch?this.getBranchName(this.singleViewData[0].branch):'',
    this.singleViewData[0]['employeesCount'] = this.singleViewData[0].employeeInfo && this.singleViewData[0].employeeInfo.length?this.singleViewData[0].employeeInfo.length:0;
    this.singleViewData[0]['cabsCount'] = this.singleViewData[0].cabInfo && this.singleViewData[0].cabInfo.length?this.singleViewData[0].cabInfo.length:0;
    let employeeInfo = this.singleViewData[0].employeeInfo?this.singleViewData[0].employeeInfo:'';
    //this.singleViewData[0]['startTime']=this.singleViewData[0].startTime? this.singleViewData[0].startTime.hour+':'+this.singleViewData[0].startTime.minute+' '+this.singleViewData[0].startTime.meriden:'';
    if(employeeInfo){
       let maleCount;
       let femaleCount;
       maleCount = employeeInfo.filter((e) => e.gender == 'Male');
       femaleCount = employeeInfo.filter((e) => e.gender == 'Female');
       maleCount=maleCount.length;
       femaleCount=femaleCount.length;
       this.singleViewData[0]['employeeFemale'] = femaleCount;
       this.singleViewData[0]['employeeMale'] = maleCount;
    }
    this.enableView=true;
  }
  /* Get Branch Name*/
  getBranchName(id){
    let name='';
    this.branchInformation.filter((e)=>{
    if(e.branchID==id){
      name=e.branchName;
    }  
    });
    return name;
  }
  /* Back to view page*/
  goBack(){
    this.currentPage.emit('viewRoutePage');
  }
  /* Edit object send*/
  sendEditObj(){
    this.currentPage.emit('editPage');
    this.editObj.emit(this.singleViewData[0].routeID);
  }
  // / Delete Route /
  deleteRoute(element){
    let deleteItem=element;
     Swal.fire({
      title: 'Are you sure?',
      text: "You want be able to delete this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('delete click')
        this.deleteRouteData(deleteItem)
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        )
      }
    })
  }
  deleteRouteData(id){
    let obj={
      orgID: this.userData.orgID,
      routeID: id
    }
    this.api.transportMangerRouteDelete(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
        this.currentPage.emit('viewRoutePage');
      } else {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err)
    })
  }
}
