import { CreateOrgComponent } from './organization/create-org/createorg.component';
import { CreateUserComponent } from './user/create/createUser.component';
import { ViewUserComponent } from './user/view/viewUser.component';
import { EditUserComponent } from './user/editUser.component';
import { CreateEmployeeComponent } from './employee/create/createEmployee.component';
import { ViewEmployeeComponent } from './employee/view/viewEmployee.component';
import { EditEmployeeComponent } from './employee/editEmployee.component';
import { ViewOrgComponent } from './organization/view-org/vieworg.component';
import { EditOrgComponent } from './organization/edit-org/editorganization.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { MasterLoginComponent } from './master-login/master-login.component';
import { GetActivateLinkComponent } from './get-activate-link/get-activate-link.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { SingleUserViewComponent } from './user/view.component';
import { CreatePrimaryAdminComponent } from './organization/settings/create-primary-admin/create-primary-admin.component';
import { ServicesComponent } from './organization/settings/services/services.component';
import { AlertTypesComponent } from './organization/settings/alert-types/alert-types.component';
import { SingleOrgViewComponent } from './organization/view-org/single-view-org.component';


export const MasterAdminDeclarations = [
    CreateOrgComponent,
    CreateUserComponent,
    ViewUserComponent,
    EditUserComponent,
    CreateEmployeeComponent,
    ViewEmployeeComponent,
    EditEmployeeComponent,
    ViewOrgComponent,
    MasterLoginComponent,
    MasterDashboardComponent,
    GetActivateLinkComponent,
    UpdatePasswordComponent,
    EditOrgComponent,
    SingleUserViewComponent,
    CreatePrimaryAdminComponent,
    ServicesComponent,
    AlertTypesComponent,
    SingleOrgViewComponent,

];
