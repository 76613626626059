
export const AlertData = [
    {
        angle: '180',
        batteryDisconnect: '1',
        distance: 0,
        gpsDateTime: 1620758728000,
        internalBattery: '04.140', // 12.025566007839128, 79.15346168307198
        location: { lat: 12.025566007839128, lng: 79.15346168307198 },
        mainBattertPower: '13.864',
        nearLocationFull: '0.40 km from Dwarka, New Delhi, South West Delhi, Delhi, India',
        nearLocationShort: '0.40 km from Dwarka, New Delhi, South West Delhi, Delhi, India',
        notifications: [],
        powerOnReset: '0',
        powerSensor1: '1',
        powerSensor2: '0',
        rtcDateTime: false,
        serverDateTime: 1620758728000,
        speed: 27,
        switch: 'N',
        vehRegNo: 'Tn-32-AE-2132',
        userID: 123,
        time:'Yesterday 01:12 am'
    },
    {
        angle: '180',
        batteryDisconnect: '1',
        distance: 0,
        gpsDateTime: 1620758068000,
        internalBattery: '04.140', // 12.031861967333668, 79.1712286354877
        location: { lat: 12.031861967333668, lng: 79.1712286354877 },
        mainBattertPower: '13.864',
        nearLocationFull: '0.40 km from Dwarka, New Delhi, South West Delhi, Delhi, India',
        nearLocationShort: '0.40 km from Dwarka, New Delhi, South West Delhi, Delhi, India',
        notifications: [],
        powerOnReset: '0',
        powerSensor1: '1',
        powerSensor2: '0',
        rtcDateTime: false,
        serverDateTime: 1620758068000,
        speed: 27,
        switch: 'N',
        vehRegNo: 'Tn-32-EW-2145',
        userID: 1234,
      
    },
    {
        angle: '180',
        batteryDisconnect: '1',
        distance: 0,
        gpsDateTime: 1620759328000,
        internalBattery: '04.140', // 12.021116774252109, 79.17037032860772
        location: { lat: 12.021116774252109, lng: 79.17037032860772 },
        mainBattertPower: '13.864',
        nearLocationFull: '0.40 km from Dwarka, New Delhi, South West Delhi, Delhi, India',
        nearLocationShort: '0.40 km from Dwarka, New Delhi, South West Delhi, Delhi, India',
        notifications: [],
        powerOnReset: '0',
        powerSensor1: '1',
        powerSensor2: '0',
        rtcDateTime: false,
        serverDateTime: 1620759328000,
        speed: 27,
        switch: 'N',
        vehRegNo: 'Tn-45-NR-4352',
        userID: 12345,
      
    }
];

export const UserData = {
    123: {
        userID: 123,
        name: 'Raja',
        mobileNo: 9788033234,
        empid: 'abc123',
        address: 'no-21,cross road ,chennai',
        location: { lat: 12.025566007839128, lng: 79.15346168307198 },
        profilePhoto: 'man.png',
        time:'Yesterday'
    },
    1234: {
        userID: 1234,
        name: 'mohan',
        mobileNo: 9788033234,
        empid: 'abc1234',
        address: 'no-21,cross road ,chennai',
        location: { lat: 12.031861967333668, lng: 79.1712286354877 },
        profilePhoto: 'profile.png',
        time:'02/07/2021'
    },
    12345: {
        userID: 12345,
        name: 'ragu',
        mobileNo: 9788033234,
        empid: 'abc12345',
        address: 'no-21,cross road ,chennai',
        location: { lat: 12.021116774252109, lng: 79.17037032860772 },
        profilePhoto: 'user4.jpeg',
        time:'12:44 am'
    }
};

export const vehicleData = {
    'Tn-32-AE-2132': {
        chassisNo: 'asd234324dsfwr24',
        regiNo: 'Tn-32-AE-2132',
        engineNo: 'ae2342332dsdfds',
        expiryDate: 1620518400000,
        imei: '898988737373722',
        make: 'Honda',
        model: '2021',
        orgID: '605471600e7dae2fdce593d8',
        purchasedDate: 1620000000000,
        rcExpiryDate: 1620777600000,
        rcNo: 'AEI234232ER',
        location: { lat: 12.027934288849709, lng: 79.14242556588502 },
    },
    'Tn-32-EW-2145': {
        chassisNo: 'asd234324dsfwr25',
        regiNo: 'Tn-32-EW-2145',
        engineNo: 'ae2342332dsdfds',
        expiryDate: 1620518400000,
        imei: '898988737373722',
        make: 'Honda',
        model: '2021',
        orgID: '605471600e7dae2fdce593d8',
        purchasedDate: 1620000000000,
        rcExpiryDate: 1620777600000,
        rcNo: 'AEI234232ER',
        location: { lat: 12.016433390162518, lng: 79.15289690978285 },
    },
    'Tn-45-NR-4352': {
        chassisNo: 'asd234324dsfwr24',
        regiNo: 'Tn-45-NR-4352',
        engineNo: 'ae2342332dsdfds',
        expiryDate: 1620518400000,
        imei: '898988737373722',
        make: 'Honda',
        model: '2021',
        orgID: '605471600e7dae2fdce593d8',
        purchasedDate: 1620000000000,
        rcExpiryDate: 1620777600000,
        rcNo: 'AEI234232ER',
        location: { lat: 12.023778911528428, lng: 79.15422728547506 },
    }
};

export const DriverData = {
    'Sathish': {
        mobile: '8978494899',
        licence: 'TN4300000034323',
        licenceUrl: 'path',
    },
    'Karthi': {
        mobile: '8978534899',
        licence: 'TN4300000034323',
        licenceUrl: 'path',
    },
    'Kamal': {
        mobile: '89234294899',
        licence: 'TN4300000034323',
        licenceUrl: 'path',
    }
}
export const VendorData = {
    "vendor-1": {
        mobile: '875784938',
        VehicleCnt: 45,
        driverCnt: 34
    },
    "vendor-2": {
        mobile: '8757343938',
        VehicleCnt: 56,
        driverCnt: 14
    },
    "vendor-3": {
        mobile: '875435938',
        VehicleCnt: 64,
        driverCnt: 34
    },
}
