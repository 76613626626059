import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
  selector: 'g10-view-rostering',
  templateUrl: './view-rostering.component.html',
  styleUrls: ['./view-rostering.component.css']
})
export class ViewRoasteringComponent implements OnInit {
  @Output() currentPage = new EventEmitter();
  @Output() singleViewObj = new EventEmitter();
  public routeObj=[];
  public tableObj=[];
  public shiftObj=[];
  public branchInformation=[];
  public userData;
  public dataSource: any;
  public cols = [
    { key: 'name', display: 'Route Name' },
    { key: 'branch', display: 'Branch' },
    { key: 'shift', display: 'Shift' },
    { key: 'startTime', display: 'Start Time' },
    { key: 'pickupPoint', display: 'Pickup Points' },
    { key: 'employees', display: 'Employees' },
    { key: 'cabs', display: 'Cabs' },
  ];
  get keys() {
    return this.cols.map(({ key }) => key);
  }
  constructor(public api: ApiService,
              public storage: StorageService, ) {
    this.userData = this.storage.get('userInfo');
  }

  ngOnInit(): void {
    this.getBranchList();
    this.getListShift();
    this.getRouteData();
    //this.tableData();
  }
  getBranchList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.branchInformation = res.data;
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  /* Route api data*/
  getRouteData() {
    const obj = {
      orgID: this.userData.orgID,
    };
    this.api.transportMangerRouteList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.routeObj=res.data;
        this.tableData();
      } else {
        console.log('else', res);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  /* shift data*/
  getListShift() {
    let obj = {
      orgID: this.userData.orgID
    }
    this.api.ListShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.shiftObj = res.data;
        console.log(this.shiftObj);
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getShiftName(id){
    let shift=[];
    this.shiftObj.filter((e)=>{
      if(e.shiftID==id){
        shift.push(e);
      }
    });
    return shift[0].shiftName;
  }
  /* Table data */
  tableData(){
    if(this.routeObj.length>0){
      this.tableObj = [];
      this.routeObj.filter((e) => {
        let list = {
          name: e.routeName?e.routeName:'',
          shift: e.routeShift?this.getShiftName(e.routeShift):'',
          employees: e.employeeInfo && e.employeeInfo.length !=0 ?e.employeeInfo.length:'',
          cabs: e.cabInfo && e.cabInfo.length!=0 ? e.cabInfo.length :'',
          branch: e.branch?this.getBranchName(e.branch):'',
          pickupPoint: 1,
          routeID: e.routeID,
          startTime: e.startTime ? e.startTime.hour + ':' + e.startTime.minute + ' ' + e.startTime.meriden : '',
          // startTime:e.startTime,
        };
        this.tableObj.push(list);
      });
      this.dataSource = new MatTableDataSource(this.tableObj);
    }
  }
  getBranchName(id){
    let name='';
    this.branchInformation.filter((e)=>{
    if(e.branchID==id){
      name=e.branchName;
    }  
    });
    return name;
  }
  /* check table data */
  isNumber(o): boolean {
    return !isNaN(o - 0) && o !== null && o !== '' && o !== false;
  }
  singleViewData(e) {
    this.currentPage.emit('singleViewPage');
    this.singleViewObj.emit(e.routeID);
  }
}
