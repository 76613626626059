import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AuthenticationComponent } from "./authentication.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';

@NgModule({
    declarations:[AuthenticationComponent, ResetPasswordComponent],
    imports:[CommonModule,RouterModule,FormsModule,ReactiveFormsModule],
    exports:[],
    providers:[],
    
})

export class AuthenticationModule {

}