import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogConfirmService {

  constructor() { }

  alertLogout(modalData: any) {
    alert('User with ID ' + modalData.userId + ' has logged out.');
  }
}
