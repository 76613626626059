import { AddDriverComponent } from './add-driver/add-driver.component';
import { ViewDriverComponent } from './view-driver/view-driver.component';
import { SingleViewDriverComponent } from './view-driver/single-view-driver.component';
import { EditDriverComponent } from './edit-driver/edit-driver.component';
import { CreateDriverComponent } from './create-driver/create-driver.component';
import { EditdriverComponent } from './editdriver/editdriver.component';

export const DriverModuleDeclaration = [
    AddDriverComponent,
    ViewDriverComponent,
    EditDriverComponent ,
    SingleViewDriverComponent,
    CreateDriverComponent,
    EditdriverComponent,

];
