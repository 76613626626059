import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/Services/shared.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { MapService } from 'src/app/Services/map.service';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { resolveForwardRef } from '@angular/compiler/src/util';
import { RouteService } from 'src/app/Services/route.service';

@Component({
  selector: 'g10-add-rostering',
  templateUrl: './add-rostering.component.html',
  styleUrls: ['./add-rostering.component.css']
})
export class AddRoasteringComponent implements OnInit {
  @Output() currentPage = new EventEmitter();
  public shiftObj;
  public employeeObj;
  public routeObj;
  public vehicleObj;
  tableForm:FormGroup;
  moreInfo:FormGroup;
  public tableView:boolean=true;
  vehicleData: any;
  activeSelection = {};
  activeIndex = {};

  public routeForm=this.fb.group({
    routeName:['',[Validators.required,Validators.pattern('[A-Z|a-z|0-9]{0,30}')]],
    branch:['',[Validators.required]],
    routeShift:['',[Validators.required]],
    routeType:['',[Validators.required]],
    cabInfo:['',[Validators.required]],
    employeeInfo:['',[Validators.required]], 
  });
  public fieldOptions = [{
    routeType: ['temporary', 'regular'],
    routeShift: [],
    branch: [],
  }];

  public tableObj = [];
  public tableCols = [];
  public tableDataType = '';
  public empCols = [
    { key: "select", display: "" },
    { key: "empRefID", display: "Employee Id" },
    { key: "name", display: "Name" },
    { key: "location", display: "Area" },
    //{ key: "action", display: "Action" },
  ];
  public cabCols = [
    { key: "select", display: "" },
    { key: 'vehRegNo', display: "Cab Reg " },
    { key: "vehicleType", display: "Cab Type" },
    { key: 'model', display: "Model" },
    { key: 'capacity', display: "Seat Capacity " },
    //{ key: "action", display: "Action" },
  ];

  public userData;
  public routeID;

  public startTime = { hour: 0, minute: 0, meriden: 'AM', format: 12, val: 'startData' };
  public endTime = { hour: 0, minute: 0, meriden: 'AM', format: 12, val: 'startData' };
  public modifiedStartTime;
  public modifiedEndTime;
  public minStartDate: Date = new Date(Date.now());
  public minEndDate: Date;

  public selectedShift;
  public shiftwiseRoute;
  public shiftwiseEmployee;
  public employeeBtnClicked: boolean = false;
  public cabBtnClicked: boolean = false;

  public selectedEmployee = [];
  public selectedCabs = [];
  public selectedEmpCount;
  public selectedCabCount;

  public moreInfoView: boolean = false;
  public vehCumulativeView: any;
  public finalRouteObj = [];
  
  public branchInformation=[];

  constructor(
    public api: ApiService,
    public swal: SweetAlert,
    private fb: FormBuilder,
    public storage: StorageService,
    public routeGenerate: RouteService,
    private datePipe: DatePipe,) {
    this.userData = this.storage.get('userInfo')
  }
  ngOnInit(){
    this.getBranchList();
    this.getRouteData();
    this.getEmployeeData();
    this.getListShift();
    this.getVehicleList();
    this.tableForm=this.fb.group({
      tableInfo:this.fb.group({
        cabsInfo:['',[Validators.required]],
        employeeInfo:['',[Validators.required]],
      }),

    });
    this.moreInfo = this.fb.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
    })
  }
  
  /* API's*/
  getBranchList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.branchInformation = res.data;
        this.addBranchOptions();
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  getRouteData() {
    let obj = {
      orgID: this.userData.orgID,
    };
    this.api.transportMangerRouteList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.routeObj=res.data;
      } else {
        console.log('else', res);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  getEmployeeData() {
    let obj = {
      orgID: this.userData.orgID,
    }
    this.api.transportManagerEmployeeList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.employeeObj = res.data;
        console.log(this.employeeObj);
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getListShift() {
    let obj = {
      orgID: this.userData.orgID
    }
    this.api.ListShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.shiftObj = res.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getVehicleList(){
    let obj = {
      orgID: this.userData.orgID,
    }
    this.api.transportManagerVehicleList(obj).then((res:any)=>{
      if (res.status == 'success') {
      this.vehicleObj=res.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }  
  addBranchOptions(){
    if(this.branchInformation && this.branchInformation.length>0){
      this.branchInformation.filter((e)=>{
        let option={name:e.branchName,value:e.branchID};
        this.fieldOptions[0].branch.push(option);
      });
    }
  }
  getShiftTime(e){

  }
  getEmployeeTable(){
    this.tableView=false;
    let companyTransport=[];
    console.log('employee obj',this.employeeObj);
    this.shiftwiseEmployee = this.employeeObj.filter((item) => item.shift == this.selectedShift);
    companyTransport = this.employeeObj.filter((item) => item.shift == this.selectedShift && item.transportType == 'company');
    this.shiftwiseRoute = [];
    this.routeObj.filter((route) => {
      if (route.routeShift == this.selectedShift) {
        if (route.employeeInfo) {
          route.employeeInfo.filter((singleEmp) => {
            if (this.shiftwiseRoute.indexOf(singleEmp) == -1) {
              this.shiftwiseRoute.push(singleEmp);
            }
          })
        }
      }
    });
    console.log('shiftwise employee',this.shiftwiseEmployee);
    console.log('shiftwise route employee',this.shiftwiseRoute);
      let final=this.shiftwiseEmployee;
      this.shiftwiseRoute.filter((item)=>{
        this.shiftwiseEmployee.filter((element,index)=>{
          if(item.employeeID==element.employeeID){
            final.splice(index,1);
          }
        })
      })
      this.tableObj = final;
      this.tableCols = this.empCols;
      this.checkTableError(this.tableDataType,'employeeTable');
      this.tableDataType = 'employeeTable';
      this.moreInfoView=false;
      this.tableView=true;
  }
  checkTableError(previousTableType, currentTableType) {
    console.log(previousTableType,currentTableType);
    if (previousTableType != '') {
      if (previousTableType != currentTableType) {
        if (previousTableType == 'employeeTable') {
          if (this.selectedEmployee.length == 0) {
            this.employeeBtnClicked = true;
          }
        }
        if (previousTableType == 'cabsTable') {
          if (this.selectedCabs.length == 0) {
            this.cabBtnClicked = true;
          }
        }
      }
    }
  }
  getCabTable() {
    this.tableView=false;
    let filteredCabsList = [];
    this.routeObj.filter((item) => {
      if (item.cabInfo) {
        item.cabInfo.filter((cab) => {
          if (filteredCabsList.indexOf(cab) == -1) {
            filteredCabsList.push(cab);
          }
        })
      }
    })
    let finalObj=this.vehicleObj;
      filteredCabsList.filter((item)=>{
        this.vehicleObj.filter((element,index)=>{
          if(item.vehicleID==element.vehicleID){
            finalObj.splice(index,1);
          }
        })
      })
      this.tableCols = this.cabCols;
      this.checkTableError(this.tableDataType,'cabsTable');
      this.tableDataType = 'cabsTable';
      this.tableObj = finalObj;
      console.log(this.tableObj);
      console.log('filtered cabs',filteredCabsList);
      this.moreInfoView=false;
      this.tableView=true;
    }
  fieldChange(field,type,e:any){
    let value='';
    if(type=='date'){
      let timeField=new Date(this.tableForm.value.moreInfo[field]);
      let oneDay= 60*60*24*1000;
      if(field=='startDate'){
        this.minEndDate=new Date(timeField.getTime()+oneDay);
      }
      console.log(timeField.getDate()+1);
    };
    if (type == 'tableBtn') {
      if (this.routeForm.controls.routeShift.errors == null && this.routeForm.controls.branch.errors == null) {
        if (field == 'employees') {

          this.getEmployeeTable();
        }
        else if (field == 'cabs') {
          this.getCabTable();
        }
      }
      if (this.routeForm.controls.branch.errors == null) {
        if (this.routeForm.controls.routeShift.errors != null) {
          this.routeForm.controls.routeShift.markAsTouched();
        }
      }
      else if (this.routeForm.controls.branch.errors != null) {
        this.routeForm.controls.branch.markAsTouched();
      }
    }
    if (type != 'dropdown') {
      if (field != 'employeeInfo' && field != 'cabInfo') {
        value = e.target.value;
      }
      if (field == 'employeeInfo' || field == 'cabInfo') {
        value = e;
      }
    }
    else if(type=='dropdown'){
      value=e.value;
      if(field=='branch'&&e.value!=undefined){
        this.tableView=false;
        /* clear table data*/
        let filteredShift=this.shiftObj.filter((item)=>item.branch==e.value);
        let filteredShiftData=[];
        filteredShift.filter((item)=>{
          filteredShiftData.push(
          {shiftName:item.shiftName,
          shiftID:item.shiftID,
          }
          )});
        this.fieldOptions[0].routeShift=filteredShiftData;
      }
      if(field=='routeShift'){
        this.tableView=false;
        console.log(e);
        if(this.routeForm.controls.branch.errors==null){
          if(e.value!=undefined){
            this.selectedShift=e.value;
            let filteredShift=this.shiftObj.filter((item)=>item.shiftID==e.value);
            this.startTime=filteredShift[0].startTime;
            console.log('event Value',e.value);
            console.log(filteredShift[0].startTime);
            let changedHour;
            let changedMeriden = this.startTime.meriden;
            let assignedTime = Number(this.startTime.hour);

            if (assignedTime == 12) {
              changedHour = 11;
              if (this.startTime.meriden == 'AM') {
                changedMeriden = 'PM';
              }
              else if (this.startTime.meriden == 'PM') {
                changedMeriden = 'AM';
              }
            }
            else if (assignedTime == 1) {
              changedHour = 12;
            }
            else {
              changedHour = assignedTime - 1;
            }
            let changedTime = { hour: changedHour, minute: this.startTime.minute, meriden: changedMeriden, format: 12, val: 'startData' };
            this.startTime = changedTime;
            this.onChangeHour(this.startTime, 'start-time');
          }
        }
        else if (this.routeForm.controls.branch.errors != null) {
          this.routeForm.controls.branch.markAsTouched();
        }
      }
    }
    if (this.routeID == undefined) {
      this.createRoute(field,value);
    }
    else if(this.routeID){
      if(field=='employeeInfo'||field=='cabInfo'){
        if(e && e.length>0){
          this.updateRoute(field,value);
        }
      }
      else if(field!='employees' && field!='cabs'){
        this.updateRoute(field,value);
      }
    }
  }
  onChangeHour(event, val) {
    if (val == 'start-time') 
    { 
      this.startTime = event;
      this.updateRoute('startTime',this.startTime);
      this.modifiedStartTime= event.hour + ':' + event.minute + ' ' + event.meriden;
      if(Number(event.minute)<10){
        event.minute=event.minute.substring(1);
      }
      this.startTime = event;
    }
    if (val == 'end-time') this.endTime = event
    this.modifiedEndTime = event.hour + ':' + event.minute + ' ' + event.meriden;
  }
  createRoute(name,e:any){
    let obj={
      orgID:this.userData.orgID,
    }
    obj[name] = e;
    this.api.transportMangerRouteCreate(obj).then((res: any) => {
      if (res.status == 'success') {
        this.routeID = res.uniqueID;
      } else {
        this.swal.swalFunction('error', res.status, res.message, 2000)
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  updateRoute(name, e: any) {
    console.log(this.routeID);
    let obj = {
      orgID: this.userData.orgID,
      uniqID: this.routeID,
    };
    obj[name] = e;
    console.log(obj);
    this.api.transportMangerRouteUpdate(obj).then((res: any) => {
      if (res.status == 'success') {
        console.log(e);
        console.log('updated');
      } else {
        this.swal.swalFunction('error', res.status, res.message, 2000)
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  selectedTableData(e) {
    console.log(e);
    console.log(e.data.length);
    if(e.type=='employeeTable'){
      this.routeForm.controls.employeeInfo.patchValue(e.data);
      this.selectedEmployee=e.data;
      this.selectedEmpCount=e.data.length;
      if(e.data.length==0){
        this.employeeBtnClicked=true;
      }
      this.fieldChange('employeeInfo','text',e.data);
    }
    else if(e.type=='cabsTable'){
      console.log(this.routeForm.controls);
      this.routeForm.controls.cabInfo.patchValue(e.data);
      this.selectedCabs=e.data;
      this.selectedCabCount=e.data.length;
      if(e.data.length==0){
        this.cabBtnClicked=true;
      }
      this.fieldChange('cabInfo','text',e.data);
  }
}
  routeDetail() {
    this.tableView=false;
    this.moreInfoView = true;
    this.tableForm.addControl('moreInfo', this.moreInfo);
  }
  async finalRouteList() {
    if (this.routeForm.valid) {
      console.log('final route Object', this.finalRouteObj);
      this.vehCumulativeView = await this.routeGenerate.routeCreate(this.finalRouteObj);
      console.log('vehicle view', this.vehCumulativeView);
      this.tableView = false;
      this.moreInfoView = false;
    }
  }
  submitForm() {
    console.log(this.routeForm.value);
    if (this.routeForm.valid) {
      this.finalRouteObj = [];
      this.routeForm.value.routeName = this.routeForm.value.routeName.replace(/ +/g, ' ').trim();
      //this.routeForm.value.routeDate=this.datePipe.transform(this.routeForm.value.routeDate, 'dd-MM-yyyy');
      let obj = {
        ...this.routeForm.value,
        startTime: this.modifiedStartTime,
        //endTime:this.modifiedEndTime,
      }
      console.log("submit",obj);
      this.finalRouteObj.push(obj);
      this.finalRouteList();
      console.log(this.tableForm);
      if (this.tableForm.value.moreInfo && this.tableForm.valid) {
        console.log(this.tableForm.value);
      }
      this.swal.swalFunction('success', 'success', 'Route created successfully...', 2000)
      //this.routeData.emit(obj);
      this.tableView = false;
      //this.currentPage.emit("viewRoutePage"); 
      //this.routeForm.reset();
    }
  }
  cancelForm() {
    this.routeForm.reset();
    this.currentPage.emit('viewRoutePage');
  }

}