import { BranchRouting } from './branch/branch.routing';
import { DriverRouting } from './driver/driver.routing';
import { EmployeeBillingRouting } from './employeebilling/employeebilling.routing';
import { EmployeeDetailsRouting } from './employeedetails/employeedetails.routing';
import { InformationComponent } from './information.component';
import { PaymentHistoryRouting } from './paymenthistory/paymenthistory.routing';
import { AddShiftComponent } from './shift/add-shift/add-shift.component';
import { EditShiftComponent } from './shift/edit-shift/edit-shift.component';
import { ListShiftComponent } from './shift/list-shift/list-shift.component';
import { SingleViewShiftComponent } from './shift/list-shift/single-view-shift';
import { TestRouting } from './test/test.routing';
import { VehicleRouting } from './vehicle/vehicle.routing';
import { VendorRouting } from './vendor/vendor.routing';


export const InformationRouting = [
    // { path: '', redirectTo: 'vehicle', pathMatch: 'full' },
    { path: '', component: InformationComponent},
    { path: 'vehicle', children: VehicleRouting },
    { path: 'test', children: TestRouting },
    { path: 'vendor', children: VendorRouting },
    { path: 'driver', children: DriverRouting },
    { path: 'payment-History', children: PaymentHistoryRouting },
    { path: 'employee', children: EmployeeDetailsRouting },
    { path: 'employee-billing', children: EmployeeBillingRouting },
    { path: 'list-shift', component: ListShiftComponent },
    { path: 'add-shift', component: AddShiftComponent },
    { path: 'edit-shift', component: EditShiftComponent },
    { path: 'view-shift', component: SingleViewShiftComponent },
    { path: 'branch', children:BranchRouting },
];
