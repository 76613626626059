import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Services/storage.service';
import { ApiService } from 'src/app/Services/api.service';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
declare const popup: any, snackbar: any;

@Component({
  selector: 'app-get-activate-link',
  templateUrl: './get-activate-link.component.html',
  styleUrls: ['./get-activate-link.component.css']
})
export class GetActivateLinkComponent implements OnInit {
  isHideButtonActive = true;
  username: string;
  constructor(private router: Router,
              public storage: StorageService,
              public api: ApiService,
              public dialogService: DialogActionService, ) {

  }
  ngOnInit(): void {
    // this.username = this.storage.get('adEmail')
    // this.username = 'mohandass@groupten.com'
  }
  getLink(): void {
    this.openLogoutModal('success');
    console.log(this.username);
    if (this.username) {

      const obj = {
        email: this.username
      };
      this.api.getlink(obj)
        .then((res) => {
          this.openLogoutModal(res);
          // snackbar(`<span class="text-warning"> Get Link </span>`)
          console.log(res);
        }).catch((err) => {
          console.log(err);
        });
    }
  }
  openLogoutModal(value?) {
    const userId = 'user01';
    const config = {
      name: 'ok',
      title: 'Successfull send mail',
      description: 'Please check you Mail Id',
      actionButtonText: 'ok',
      userId
    };
    this.dialogService.popup(config);
    // const modalDialog = this.dialog.open(DialogComponent, dialogConfig);
  }
}

