import { Injectable } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})

export class CommonServices {

    constructor(private fb: FormBuilder) { }

    vehicleToObject(vehicleList: any, selectedVehicle: any) {
        return new Promise(async (res, rej) => {
            try {
                let processVehicle = {};
                if (selectedVehicle == 'ALL') {
                    const vehList = await this.getObjKeyValue(vehicleList, 'name', 'regenc');
                    processVehicle = vehList;
                } else {
                    const vehicle = vehicleList.filter((item) => {
                        //  console.log(item, selectedVehicle, '$$$$', item.regenc)
                        if (item.regenc == selectedVehicle) { return item; }
                    });
                    processVehicle = await this.getObjKeyValue(vehicle, 'name', 'regenc');

                }
                res(processVehicle);
            } catch (error) {
                console.log('vehicleToObject -- Error', error);
            }
        });
    }

    pad(num) {
        return num > 9 ? num : '0' + num;
    }
    subString(str, start, end) {
        return str.substring(start, end);
    }
    strReplace(string, search, replace) {
        return string.split(search).join(replace);
    }
    timestampToDate(timestamp, type = 'YMD', splited = '/') {
        const strl = (timestamp + '').length;
        const tms = strl > 10 ? timestamp : timestamp * 1000;
        const d = new Date(tms);
        let temp;
        // console.log('timestamp',timestamp)
        const h = this.pad(d.getHours());
        const m = this.pad(d.getMinutes());
        const s = this.pad(d.getSeconds());
        if (type == 'YMD') {
            temp =
                d.getFullYear() +
                splited +
                this.pad(d.getMonth() + 1) +
                splited +
                this.pad(d.getDate());
        } else if (type == 'MDY') {
            temp =
                this.pad(d.getMonth() + 1) +
                splited +
                this.pad(d.getDate()) +
                splited +
                d.getFullYear();
 } else if (type == 'DMY') {
            temp =
                this.pad(d.getDate()) +
                splited +
                this.pad(d.getMonth() + 1) +
                splited +
                d.getFullYear();
 } else if (type == 'YMD H:M:S') {
            temp =
                this.pad(d.getDate()) +
                splited +
                this.pad(d.getMonth() + 1) +
                splited +
                d.getFullYear() +
                ' ' +
                h +
                ':' +
                m +
                ':' +
                s;
 } else if (type == 'DMY H:M:S') {
            temp =
                this.pad(d.getDate()) +
                splited +
                this.pad(d.getMonth() + 1) +
                splited +
                d.getFullYear() +
                ' ' +
                h +
                ':' +
                m +
                ':' +
                s;
 }

        return temp;
    }
    chunkArray(data, chunksize) {
        return new Promise(function(res, rej) {
            const chunks = [];
            if (data.length > chunksize) {
                data.map(function(item) {
                    if (!chunks.length || chunks[chunks.length - 1].length == chunksize) {
                        chunks.push([]);
                    }

                    chunks[chunks.length - 1].push(item);
                });
            } else {
                chunks.push(data);
            }

            res(chunks);
        });
    }

    chunkObject(object, size) {
        return new Promise(function(res, rej) {
            try {
                let temp = {};
                const chunks = [];
                let chunkSize = size;
                const amount = chunkSize;

                for (let i = 0; i < Object.keys(object).length; i++) {
                    if (i == chunkSize) {
                        chunks.push(temp);
                        chunkSize += amount;
                        temp = {};
                    }

                    temp[Object.keys(object)[i]] = object[Object.keys(object)[i]];

                    if (i == Object.keys(object).length - 1) {
                        chunks.push(temp);
                    }
                }
                res(chunks);
            } catch (error) {
                console.log('object chunk ', error);
            }
        });
    }
    sleep(num) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(true);
            }, Number(num));
        });
    }
    getObjSingleValue(obj, key) {
        return new Promise((res, rej) => {
            const temp = [];
            for (const i of obj) {
                temp.push(i[key]);
            }
            res(temp);
        });
    }
    getObjKeyValue(obj, key, value) {
        return new Promise((res, rej) => {
            const temp = {};
            for (const i of obj) {
                if (temp[i[key]] == undefined) { temp[i[key]] = {}; }
                temp[i[key]] = i[value];
                // temp.push(i[key]);
            }
            res(temp);
        });
    }
    filterObj(obj, array) {
        return new Promise((res, rej) => {
            try {
                const data = obj.filter((item) => {
                    const chk = array.indexOf(item.regenc);
                    if (chk == -1) { return item; }
                });
                res(data);
            } catch (e) {
                rej();
            }
        });
    }

    formToDateValidation(from: string, to: string) {
        return (group: FormGroup): { [key: string]: any } => {
            const f = new Date(group.controls[from].value).getTime();
            const t = new Date(group.controls[to].value).getTime();
            let retValue = {};
            if (f >= t) {
                retValue = {
                    dates: 'Date from should be less than Date to'
                };
            }
            return retValue;
        };

    }

    ts2str(currentts, startTs) {
        // 3660 -> 00:00 - 01:00 diff btn time ts in hour
        let h = Math.floor((currentts - startTs) / 3660),
            m = Math.floor((currentts - (startTs + (3600 * h))) / 60),
            s = (currentts - (startTs + (3600 * h))) % 60;
        if (m >= 60) { h++; m = m - 60; }
        return this.pad(h) + ':' + this.pad(m) + ':' + s;
    }
    timeDiff(startTime, endTime) {
        const milliseconds = (endTime * 1000) - (startTime * 1000);
        let day: any, hour: any, minute: any, seconds: any;
        seconds = Math.floor(milliseconds / 1000);
        minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hour = Math.floor(minute / 60);
        minute = minute % 60;
        day = Math.floor(hour / 24);
        hour = hour % 24;
        // console.log( 'day',day,'hour',hour,'minute',minute,'seconds',seconds)

        //  return hour +' hour ' + minute + ' minute ' + seconds + ' seconds';
        const minut: any = ('' + minute).length == 1 ? '0' + minute : minute;
        return {
            timeString: hour + ' hour ' + minute + ' minute ' + seconds + ' seconds',
            hhMM: parseFloat(hour + '.' + minut),
            hh: hour,
            mm: ('' + minute).length == 1 ? '0' + minute : minute
        };
    }
}
