export const VendorModule = [

    {
        type: 'text',
        name: 'vendorName',
        label: 'Vendor Name',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'email',
        label: 'Email ID',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'number',
        name: 'mobile',
        label: 'Mobile Number',
        value: '',
        required: true,
        col: 'col-md-4',
        textType: 'mobile'
    },
    {
        type: 'number',
        name: 'landline',
        label: 'Landline',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'zone',
        label: 'Zone',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'address',
        label: 'Address',
        value: '',
        required: true,
        col: 'col-md-4',
        multiline: true
    },
    {
        type: 'text',
        name: 'pincode',
        label: 'Pincode',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'domain',
        label: 'Domain',
        value: '',
        required: true,
        col: 'col-md-4'
    }
];
