import { Component } from '@angular/core';

@Component({
    selector: 'app-payment-history',
    template: `<mat-toolbar class="mb-4">
    <mat-toolbar-row>
      <span  *ngIf="!isShowIcon">Payments History</span>
      <span *ngIf="isShowIcon"> Add Payments </span>
      <span class="example-spacer"></span>
      <button mat-icon-button *ngIf="isShowIcon" (click)="isShowIcon = !isShowIcon" class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <g10-ms-icon class="mr-2 text-accent" [icon]="'ViewListTree'" [mat]="ture"></g10-ms-icon>
  </button>
  <button mat-icon-button *ngIf="!isShowIcon"  (click)="isShowIcon = !isShowIcon" class="example-icon favorite-icon"  aria-label="Example icon-button with heart icon">
            <g10-ms-icon class="mr-2 text-accent" [icon]="'Add'" [mat]="ture"></g10-ms-icon>
        </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <app-addpayments *ngIf="isShowIcon" ></app-addpayments>
  <app-viewpaymenthistory *ngIf="!isShowIcon" ></app-viewpaymenthistory>

  `,
    styles: []
})

export class PaymetHistoryComponent {
    isShowIcon = false;
    constructor() {

    }
    ngOnInit() {

    }
}
