import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {
  dataModel: any;
  constructor() { }

  ngOnInit(): void {
  }
  onClickEventReceived(e: any) {

  }
}
