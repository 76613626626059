import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
declare const flatColor;
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public _subject = new Subject();

  private dataSource = new BehaviorSubject({});
  data = this.dataSource.asObservable();

  constructor() {
    console.log(this._subject);
   }

  updatedDataSelection(data) {
    this.dataSource.next(data);
  }

  generateColors() {
    const x = new flatColor();
    const colorcode = 'rgb(' + x.r + ',' + x.g + ',' + x.b + ')';
    return colorcode;
  }
  pad(num) {
    return num > 9 ? num : '0' + num;
  }
  TMSToDate(timestamp, splited = '/', type= 'hh:mm:ss') {
    const strl = (timestamp + '').length;
    const tms = strl > 10 ? timestamp : timestamp * 1000;
    const d = new Date(tms);
    let temp;
    const h = this.pad(d.getHours());
    const m = this.pad(d.getMinutes());
    const s = this.pad(d.getSeconds());
    if (type == 'hh:mm:ss') {
    temp = this.pad(d.getDate()) +
      splited +
      this.pad(d.getMonth() + 1) +
      splited +
      d.getFullYear() + ' ' + h + ':' + m + ':' + s;
    } else if (type == 'hh:mm') {
      temp = this.pad(d.getDate()) +
      splited +
      this.pad(d.getMonth() + 1) +
      splited +
      d.getFullYear() + ' ' + h + ':' + m;
    } else {
      temp = this.pad(d.getDate()) +
      splited +
      this.pad(d.getMonth() + 1) +
      splited +
      d.getFullYear();
    }
    return temp;
  }
}

