import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'g10-ms-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent {
  @Output('onAction') emitter = new EventEmitter();
  @Input('data') dataSource = [];
  @Input('cols') tableCols = [];
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  // We will need this getter to exctract keys from tableCols
  get keys() {
    return this.tableCols.map(({ key }) => key);
  }
  isNumber(o): boolean {
    return ! isNaN (o - 0) && o !== null && o !== '' && o !== false;
  }
  // this function will return a value from column configuration
  // depending on the value that element holds
  showBooleanValue(elt, column) {
    return column.config.values[`${elt[column.key]}`];
  }


}
