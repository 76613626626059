import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { OrgVehilceInfo } from './interface';

export class Store<T> {
    private state$: BehaviorSubject<OrgVehilceInfo[]>;
    protected constructor(initialState: OrgVehilceInfo[] = []) {
        this.state$ = new BehaviorSubject(initialState);
    }
    getValue(): OrgVehilceInfo[] {
        return this.state$.getValue();
    }
    getState(): Observable<OrgVehilceInfo[]> {
        return this.state$.asObservable();
    }
    setState(item: OrgVehilceInfo[]): void {
        this.state$.next(item);
    }
}

@Injectable({
    providedIn: 'root'
})

export class OrgVehicleReducer extends Store<OrgVehilceInfo[]> {
    addVehicleInfo(newItem: OrgVehilceInfo): void {
        const data: any = this.getValue();

        const orgID: any = Object.keys(newItem);
        if (data && data[orgID] && data[orgID].filter(itm => itm.orgID == newItem.orgID).length > 0) {
            data[orgID] = newItem[orgID];
            this.setState(data);
        } else {
            this.setState([...this.getValue(), newItem]);

        }

    }
    get(): OrgVehilceInfo[] {
        const temp = this.getState();
        console.log('#######  ', temp);
        return this.getValue();

    }
    listen(): Observable<OrgVehilceInfo[]> {
        return this.getState();
    }
}
