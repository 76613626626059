import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { SingleViewEmployeeComponent } from './viewemployees/single-view-employee.component';
import { ViewemployeesComponent } from './viewemployees/viewemployees.component';

export const EmployeeDetailsDeclatation = [
CreateEmployeeComponent,
ViewemployeesComponent,
SingleViewEmployeeComponent,
EditEmployeeComponent
];
