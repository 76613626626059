import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { FileUploadService } from 'src/app/Services/file-upload.service';
import { MapService } from 'src/app/Services/map.service';
import { SharedService } from 'src/app/Services/shared.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
declare const mapUtilityMapBox;


@Component({
  selector: 'app-editvehicle',
  templateUrl: './editvehicle.component.html',
  styleUrls: ['./editvehicle.component.css']
})
export class EditvehicleComponent implements OnInit {
  editVehicelForm: FormGroup;
  orgID: string;
  vendorID: string;
  vehicleID: string;
  EditFormData: any;
  public mapObj: any;


  isShowSimno = false;
  isShowVendorName = false;
  strDate: any;
  latlong: any;
  purchDate: any;
  rcStrDate: any;
  roadTaxStatus: any;
  public selectedValueClose = false;
  public address;
  public vehicleloc;
  public locationSuggestions: any;
  public vehicleLocation;
  public searchInput: any = new Subject<string>();
  public destroy$: any = new Subject();
  public myMap: any = this.map.retMap();
  imageData: any;

  constructor(
    public api: ApiService,
    public router: Router,
    public route: ActivatedRoute,
    public swal: SweetAlert,
    public sub: SharedService,
    public fb: FormBuilder,
    private map: MapService,
    public file:FileUploadService
  ) {
    this.route.paramMap.subscribe(params => {
      console.log(params.get('orgID'), params.get('vehicleID'), params.get('vendorID'));
      this.orgID = params.get('orgID');
      this.vendorID = params.get('vendorID');
      this.vehicleID = params.get('vehicleID');
    });
  }

  ngOnInit(): void {
    this.loadMap();
    this.myMap.mymap.onMapClick((e: any) => {
      this.locationSuggestions = [];
      this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
    });

    this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
      if (value.val.target.value != '') {
        const val = { key: 'vehicleLocation', val: value.val.target.value };
        this.getGeoCode(val);
        console.log(val);
      } else if (value.val.target.value.trim() == '') {
        this.emptySuggestions();
      }
      console.log(value.val.target.value);
      console.log(value.val);
    });
    this.editFormLoad();
    this.getVehicleData();
  }
  editFormLoad() {
    this.editVehicelForm = new FormGroup({
      vehRegNo: new FormControl('', [Validators.required, Validators.minLength(5)]),
      imei: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{14}')]),
      make: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      capacity: new FormControl('', [Validators.required]),
      vehicleType: new FormControl('', [Validators.required]),
      chassisNo: new FormControl('', [Validators.required, Validators.minLength(17)]),
      engineNo: new FormControl('', [Validators.required, Validators.minLength(17)]),
      purchasedDate: new FormControl('', [Validators.required]),
      routePermitCost: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(7)]),
      startDate: new FormControl('', [Validators.required]),
      expiryDate: new FormControl('', [Validators.required]),
      rcNo: new FormControl('', [Validators.required]),
      rcStartDate: new FormControl('', [Validators.required]),
      rcExpiryDate: new FormControl('', [Validators.required]),
      rcfile: new FormControl('', [Validators.required]),
      vehicleInfo: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      boxType: new FormControl('', [Validators.required]),
      vendorName: new FormControl(''),
      simNo: new FormControl(''),
      roadTaxStatus: new FormControl('', [Validators.required]),
    });

  }
  public checkError = (controlName: string, errorName: string) => {
    return this.editVehicelForm.controls[controlName].hasError(errorName);
  }
  getVehicleData() {
    const obj = {
      orgID: this.orgID,
      vendorID: this.vendorID,
      vehicleID: this.vehicleID
    };
    this.api.transportManagerVehicleList(obj).then((res: any) => {
      if (res.status == 'success') {
        console.log(res.data);
        const obj = res.data[0];
        this.EditFormData = res.data[0];
        const data = res.data[0];
        // this.editVehicelForm.patchValue(data)
        this.setValueEditVehicleForm(data);
        // console.log(obj, this.editVehicelForm.value)
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  setValueEditVehicleForm(data) {
    console.log(data);
    this.editVehicelForm.patchValue({
      vehRegNo: data.vehRegNo,
      imei: data.imei,
      make: data.make,
      model: data.model,
      capacity: data.capacity,
      vehicleType: data.vehicleType,
      chassisNo: data.chassisNo,
      engineNo: data.engineNo,
      purchasedDate: new Date(data.purchasedDate),
      routePermitCost: data.routePermitCost,
      startDate: new Date(data.startDate),
      expiryDate: new Date(data.expiryDate),
      rcNo: data.rcNo,
      rcStartDate: new Date(data.rcStartDate),
      rcExpiryDate: new Date(data.rcExpiryDate),
      vehicleInfo: data.vehicleInfo,
      location: data.location.name,
      boxType: data.boxType,
      vendorName: data.vendorName,
      simNo: data.simNo,
      roadTaxStatus: data.roadTaxStatus.toString(),
      rcfile: data.rcfile,
    });
    this.onChangeBoxType(data.boxType);
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }
  onChangeBoxType(e) {
    console.log(e);
    this.isShowVendorName = false;
    this.isShowSimno = false;
    if (e == 'others') { this.isShowVendorName = true; }
    if (e == 'group10') { this.isShowSimno = true; }
  }
  openLocation() {
    document.getElementById('vehicleLocationId').click();
  }
  onSubmit() {
    const obj = {
      orgID: this.orgID,
      vendorID:  this.vendorID,
      vehicleID: this.vehicleID,
      // ...this.editVehicelForm.value
      vehRegNo: this.editVehicelForm.value.vehRegNo,
      imei: this.editVehicelForm.value.imei,
      make: this.editVehicelForm.value.make,
      model: this.editVehicelForm.value.model,
      capacity: this.editVehicelForm.value.capacity,
      vehicleType: this.editVehicelForm.value.vehicleType,
      chassisNo: this.editVehicelForm.value.chassisNo,
      engineNo: this.editVehicelForm.value.engineNo,
      purchasedDate: new Date(this.editVehicelForm.value.purchasedDate),
      routePermitCost: this.editVehicelForm.value.routePermitCost,
      startDate: new Date(this.editVehicelForm.value.startDate),
      expiryDate: new Date(this.editVehicelForm.value.expiryDate),
      rcNo: this.editVehicelForm.value.rcNo,
      rcStartDate: new Date(this.editVehicelForm.value.rcStartDate),
      rcExpiryDate: new Date(this.editVehicelForm.value.rcExpiryDate),
      vehicleInfo: this.editVehicelForm.value.vehicleInfo,
      location: this.editVehicelForm.value.location.name,
      boxType: this.editVehicelForm.value.boxType,
      vendorName: this.editVehicelForm.value.vendorName,
      simNo: this.editVehicelForm.value.simNo,
      roadTaxStatus: this.editVehicelForm.value.roadTaxStatus,
      rcfile: this.editVehicelForm.value.rcfile,
    };
    // console.log(obj.location,this.editVehicelForm.controls.location.touched, this.address);
    if (this.editVehicelForm.value.roadTaxStatus) {
      obj.roadTaxStatus = this.editVehicelForm.value.roadTaxStatus == 'true' ? true : false;
    }
    if(this.editVehicelForm.controls.location.touched ){
      obj.location = {
        lat: this.address.center[1],
        lng: this.address.center[0],
        name: this.address.name,
      };
    }
    obj.rcfile = this.imageData
    console.log(obj);
    this.api.transportManagerVehicleUpdate(obj).then((res: any) => {
      console.log(res.status);
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 20000);
      }
      if (res.status == 'failure') {
        this.swal.swalFunction('error', res.status, res.message, 20000);
      }
    }).catch((err) => {
      console.log(err);
    });
    // if (formFields.title) findQuery['title'] = formFields.title;
  }
  getDirtyValues(form: any) {
    const dirtyValues = {};

    Object.keys(form.controls)
      .forEach(key => {
        const currentControl = form.controls[key];

        if (currentControl.dirty) {
          if (currentControl.controls) {
            dirtyValues[key] = this.getDirtyValues(currentControl);
          } else {
            dirtyValues[key] = currentControl.value;
          }
        }
      });

    return dirtyValues;
  }
  initMap(markers: any, type: string) {

    try {
      const mapBoxKey = 'pk.eyJ1Ijoib3dlbmxhbWIiLCJhIjoiY2lleWljcnF4MDBiOXQ0bHR0anRvamtucSJ9.t3YnHHqvQZ8Y0MTCNy0NNw';
      const parent = this;
      if (mapBoxKey) {
        const MyMap1 = new mapUtilityMapBox();
        MyMap1.setup('markerExampleMapBox1', {
          key: mapBoxKey
        },
          function(map) {
            // if (type == 'add') {
            parent.mapObj = map;
            map.clusters = [
              {
                name: 'dashboardMap',  // unique id
                maxZoom: 10, // max zoom on cluster click event
                radius: 20,
                color: 'rgba(255, 0, 0, 0.2)', // '#be9694'  // for opacity use rgb
                textColor: 'green' // count color
              }
            ];

            map.addMarkers(markers);
            setTimeout(() => {
              map.clusterSynch();
            }, 500);

            map.onMapZoom(function(z) {
              map.clusterSynch();
              setTimeout(() => { map.marker(markers); }, 200);
            });

            map.focus({
              lat: parseFloat(markers[0].lat),
              lng: parseFloat(markers[0].lng),
              zoom: 10
            });

            map.onClick({
              type: 'marker',
            }, (e: any) => {
              map.focus({
                lat: e._lngLat.lat,
                lng: e._lngLat.lng,
                zoom: 18
              });
            });
            // } else {
            //   console.log(markers)
            //   map.marker(markers);
            //   setTimeout(() => {
            //     map.clusterSynch();
            //   }, 500);
            // }
          });
      }
    } catch (error) {
      console.log(error);
    }
  }
  loadMap(marker: any = []) {
    this.map.loadMap('pickuppoint', marker);
  }
  getGeoCode(value) {
    const key = value.key;
    if (key == 'vehicleLocation') {
      this.locationSuggestions[key] = [];
    }
    this.myMap.mymap.geocoder(value.val, (res) => {
      try {
        const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
        console.log('Loading GEO code ', key);
        if (key == 'mapclick') {
          this.locationSuggestions = {};
          this.address = result[0];
          // this.vehicleloc = this.address.name;
          this.vehicleLocation = this.address;
          // this.selectedValueClose = true;
          console.log(result, result[0].name);

        } else {
          this.locationSuggestions[key] = result;
          console.log('Else Part', this.locationSuggestions);
        }
      } catch (er) { console.log(er); }
    });
  }
  selectedValue(value) {
    this.selectedValueClose = true;
    this.locationSuggestions = {};
    this.address = value;
    this.vehicleloc = this.address.name;
    this.vehicleLocation = value;
  }
  emptySuggestions() {
    this.locationSuggestions = {};
    this.selectedValueClose = false;
    this.vehicleLocation = null;
  }
  close() {
    this.editVehicelForm.patchValue({
      location: this.address.name
    });
    // let sendData = null;
    // if (this.vehicleLocation != null && this.vehicleLocation.name) {
    //   empLocation.value = this.vehicleLocation.name;
    //   sendData = this.vehicleLocation.name;
    // }
    // else {
    //   empLocation.value = null;
    //   sendData = null;
    // }
  }

  async handleUpload(event) {
    // const file = event.target.files[0];
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = () => {
    //   console.log(reader.result);
      // this.imageData = reader.result
       this.imageData  = await this.file.fileHandeleBase64(event)
    // }
  }
}
