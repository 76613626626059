import { Input } from "@angular/core";
import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
    selector: 'text_Area',
    template: `
    <div [formGroup]="form">
    <mat-form-field appearance="outline">
    <mat-label>{{field.label}} {{field.required ? '*':''}}</mat-label>
    <textarea style="height: 30px;" [class.is-invalid]="isDirty && !isValid" [formControlName]="field.name" [id]="field.name"
        rows="9" [placeholder]="field.placeholder" matInput></textarea>
    </mat-form-field>
    </div>
    `,
    styles: []
})
export class TextareaComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
}


// <mat-form-field appearance="outline">
//                                     <mat-label>{{field.label}}</mat-label>
//                                     <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" placeholder="{{field.placeholder}}"></textarea>
//                                     <mat-hint>{{field.hint}}</mat-hint>
//                                 </mat-form-field>