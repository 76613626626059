import { ApiService } from './api.service';
import { CustomValidator } from './custom.validator';
import { DialogActionService } from './dialog-actions.service';
import { DialogDeleteService } from './dialog-delete.service';
import { CustomHttpInterceptor } from './intercept.service';
import { LoaderService } from './loader.service';
import { SharedService } from './shared.service';
import { SnackBarService } from './snackbar.service';
import { StorageService } from './storage.service';
import { SweetAlert } from './sweetalet.service';
import { CommonServices } from './common.service';
import { MapService } from './map.service';
import { KDTreeService } from './kdtree.service';
import { FileUploadService } from './file-upload.service';
// import { RouteService } from "./route.service";


export const ServiceDeclaration = [
    ApiService,
    StorageService,
    CustomValidator,
    DialogActionService,
    SharedService,
    SnackBarService,
    SweetAlert,
    DialogDeleteService,
    DialogActionService,
    CustomHttpInterceptor,
    LoaderService,
    CommonServices,
    MapService,
    KDTreeService,
    FileUploadService
    // RouteService


];
