import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';


@Injectable({
    providedIn: 'root'
})
export class ExcelExportService {

    constructor() { }



    async generateExcel(xlData: any, fileName: string) {


        const sheets: any = Object.keys(xlData);
        const workbook = new Workbook();
        sheets.forEach((sheetName: any, i: number) => {
            const sheetInfo = xlData[sheetName];
            const title = sheetInfo.title;
            const data = sheetInfo.data;
            const header = sheetInfo.header;
            const footer = sheetInfo.footer ? sheetInfo.footer : '';

            // Create workbook and worksheet
            const worksheet = workbook.addWorksheet(sheetName);

            // Add Row and formatting
            const titleRow = worksheet.addRow([title.title]);
            titleRow.font = title.font;
            worksheet.addRow([]);

            if (title.subTitleRow) { worksheet.addRow([title.subTitleRow]); }
            if (title.mergeCells) { worksheet.mergeCells(title.mergeCells); }

            // Blank Row
            worksheet.addRow([]);
            // Add Header Row

            const headerRow = worksheet.addRow(header.headers);
            // Cell Style : Fill and Border
            headerRow.eachCell((cell, number) => {
                if (header.fill) { cell.fill = header.fill; }
                if (header.border) { cell.border = header.border; }
            });
            // Add Data and Conditional Formatting
            data.data.forEach(d => {
                const row = worksheet.addRow(d);
            });

            if (data.columWith) { data.columWith.forEach(col => {
                worksheet.getColumn(col.colNo).width = col.width;
            });
            }

            worksheet.addRow([]);

            // Footer Row
            if (footer != '') {
                const footerRow = worksheet.addRow([footer.text]);
                footerRow.getCell(1).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFCCFFE5' }
                };
                footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
                // Merge Cells
                worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
            }

            if (sheets.length - 1 == i) {
                // Generate Excel File with given name
                workbook.xlsx.writeBuffer().then((data: any) => {
                    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    fs.saveAs(blob, fileName);
                });
            }


        });


    }
}
