export const AnnouncementModel = [
    {
        type: 'text',
        name: 'Title',
        label: 'Title',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'dropdown',
        name: 'requestTo',
        label: 'Request To',
        value: '',
        required: true,
        col: 'col-md-4',
        multiselect: true,
        options: [
            { value: 'emp-1', name: 'emp-1'},
            { value: 'emp-2', name: 'emp-2'},
            { value: 'emp-3', name: 'emp-3'},
          ]
      },
      {
        type: 'text',
        name: 'message',
        label: 'Message',
        value: '',
        required: true,
        col: 'col-md-4',
        multiline: true,
      },
];
