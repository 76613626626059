export const SosReportModel = [
  {
    type: 'dropdown',
    name: 'Select',
    label: 'Select Vehicle',
    value: '',
    required: true,
    col: 'col-12',
    options: [
      { value: 'Admin', name: 'Admin' },
      { value: 'Dept1', name: 'Dept1' },
      { value: 'Dept2', name: 'Dept2' },
    ]
  },
   {
    type: 'date',
    name: 'date',
    label: 'Start Date',
    value: '',
    required: true,
    col: 'col-12'
  },
   {
    type: 'date',
    name: 'date',
    label: 'End Date',
    value: '',
    required: true,
    col: 'col-12'
  },

  {
    type: 'text',
    name: 'domain',
    label: 'Domain',
    value: '',
    required: true,
    col: 'col-12'
  }
];
