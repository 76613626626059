import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
    selector: 'view-user',
    template: ` `,
    styles: [`
/* The Modal (background) */
.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

  `]
})
/*
 <div align="center">
      <span class="btn btn-sm btn-dark" (click)="modalClose()" *ngIf="__close">{{__close}}</span>
    </div>
 */
export class SingleUserViewComponent {

    constructor() { }
    @Input() values: string;
    @Input() close: string;
    @Input() align: string;
    @Output() emit = new EventEmitter();

    public modalDisplayStyle = 'block';
    ngOnInit() {
        this.loadModal();
    }
    submitted(e: any) {
    }
    loadModal() {
        // Get the <span> element that closes the modal
        const span: any = document.getElementsByClassName('close')[0];
        // When the user clicks on <span> (x), close the modal
        span.onclick = function() {
            // modal.style.display = 'none';
            this.modalDisplayStyle = 'none';
        };
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = (event) => {
            if (this.modalDisplayStyle === 'none') {
                this.modalDisplayStyle = 'none';
            }
        };
    }

    modalClose() {
        const modal = document.getElementById('openModal');
        // modal.style.display = 'none';

        this.modalDisplayStyle = 'none';
        this.emit.emit('0');
    }
}


// <!-- The Modal -->
// <div id="openModal" class="modal" [ngStyle]="{display: modalDisplayStyle}">

//   <!-- Modal content -->
//   <div class="modal-content">
//     <div align="right">
//       <span (click)="modalClose()" class="close">&times;</span>
//     </div>
//     <p align="{{align}}" *ngIf="values" >
//        View User
//        <mat-card> {{values|json}}
//        </mat-card>
//     </p>

//   </div>

// </div>
