import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from './../Services/api.service';
import { Router } from '@angular/router';
import { StorageService } from '../Services/storage.service';


export interface PROFILE {
    contactNumber: number;
    designation: string;
    name: string;
    orgID: string;
    primary: number;
    status: number;
    userEmail: string;
    userID: string;
}


export class STORE<T> {
    protected state$: BehaviorSubject<PROFILE>;
    constructor(initialValue: PROFILE | null) {
        this.state$ = new BehaviorSubject(initialValue);
    }
    set setState($value: PROFILE) {
        this.state$.next($value);
    }
    get getValue() {
        return this.state$.getValue();
    }
    get getState() {
        return this.state$.asObservable();
    }

}

@Injectable({
    providedIn: 'root'
})

export class ProfileState extends STORE<PROFILE> {
    isApi = false;
    userData: any;
    constructor(
        public api: ApiService,
        public router: Router,
        public storage: StorageService
    ) {
        super(null);
        // this.fetchData();

    }

    private fetchData() {
        console.log(this.isApi);
        if (this.isApi) { return; }
        this.isApi = true;
        const url = (this.router.url.split('/')[2]);
        console.log(url);
        if (url == 'vendor') {
        this.api.GetCurUserVendor({})
        .then((res: any) => {
            console.log(res.data[0], 'profile state');
            if (res.status == 'success') {
                const result = res.data[0];
                this.userData = res.data[0];
                this.storage.setData({userData: this.userData});
                this.setState = result;
                console.log(this.setState);
            }
            if (res.message == 'Token expired.') { this.router.navigate(['login']); }
            this.isApi = false;
        })
        .catch((err) => { console.log(err); this.isApi = false; console.log(this.isApi); });
        }
        if (url == 'transport-manager') {
        this.api.GetCurUserOrg({})
            .then((res: any) => {
                console.log(res.data[0], 'profile state');
                if (res.status == 'success') {
                    const result = res.data[0];
                    this.userData = res.data[0];
                    this.storage.setData({userData: this.userData});
                    this.setState = result;
                    console.log(this.setState);
                }
                if (res.message == 'Token expired.') { this.router.navigate(['login']); }
                this.isApi = false;
            })
            .catch((err) => { console.log(err); this.isApi = false; console.log(this.isApi); });
    }
}
    get get() {
        const data = this.getValue;
        if (data == null) { this.fetchData(); }
        return data;
    }
    get listen() {
        return this.getState;
    }

}


/*
import { PROFILE,ProfileState } from './../'
export class Dashboard{
    profileInformation: PROFILE ={
        name: '',
        city:''
    };
  constructor(
      profile: ProfileState
  ){

  }
  getProfile(){
      if(profile.get==null){
          this.profile.listen.subcribe((value)=>{
             //   this.profileInformation = value;
             this.profileInformation = this.profile.get;
          })
      }else{
          this.profileInformation = this.profile.get;
      }
  }
}

*/

