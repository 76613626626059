import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Router } from '@angular/router';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { FileUploadService } from 'src/app/Services/file-upload.service';
import { MapService } from 'src/app/Services/map.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
// import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material/material-moment-adapter';

// export const MY_FORMATS = {
//   parse: {
//     dateInput: 'MM/YYYY',
//   },
//   display: {
//     dateInput: 'MM/YYYY',
//     monthYearLabel: 'MMM YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'MMMM YYYY',
//   },
// };

declare const mapUtilityMapBox;

@Component({
  selector: 'app-create-vehicle',
  templateUrl: './create-vehicle.component.html',
  styleUrls: ['./create-vehicle.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    // {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class CreateVehicleComponent implements OnInit {
  date = new FormControl(moment());

  addVehicelForm: FormGroup;
  submitted = false;
  isShowSimno = false;
  isShowVendorName = false;
  public addShopFormGroup: FormGroup;


  strDate: any;
  latlong: any;
  purchDate: any;
  rcStrDate: any;

  public selectedValueClose = false;
  public address;
  public vehicleloc;
  public locationSuggestions: any;
  public vehicleLocation;
  public searchInput: any = new Subject<string>();
  public destroy$: any = new Subject();

  public mapObj: any;
  public myMap: any = this.map.retMap();
  minFromDate = new Date();
  maxToDate = new Date().setDate(2);
  VendorInfos: any;
  imageData: any;
  constructor(
    public fb: FormBuilder,
    public api: ApiService,
    public swal: SweetAlert,
    public storage: StorageService,
    public router: Router,
    private map: MapService,
    public file: FileUploadService
  ) {
    this.VendorInfos = this.storage.get('vendorID_orgID');
  }

  ngOnInit(): void {
    // this.addFormLoad()
    this.loadMap();
    this.myMap.mymap.onMapClick((e: any) => {
      this.locationSuggestions = [];
      this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
    });
    this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
      if (value.val.target.value != '') {
        const val = { key: 'vehicleLocation', val: value.val.target.value };
        this.getGeoCode(val);
        console.log(val);
      } else if (value.val.target.value.trim() == '') {
        this.emptySuggestions();
      }
      console.log(value.val.target.value);
      console.log(value.val);
    });
    this.addFormLoad();
  }

  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    // console.log(new Date(this.date.value._d))
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
    console.log(new Date(this.date.value._d))
  }
  loadMap(marker: any = []) {
    this.map.loadMap('pickuppoint', marker);
  }
  getGeoCode(value) {
    const key = value.key;
    if (key == 'vehicleLocation') {
      this.locationSuggestions[key] = [];
    }
    this.myMap.mymap.geocoder(value.val, (res) => {
      try {
        const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
        console.log('Loading GEO code ', key);
        if (key == 'mapclick') {
          this.locationSuggestions = {};
          this.address = result[0];
          // this.vehicleloc = this.address.name;
          this.vehicleLocation = this.address;
          // this.selectedValueClose = true;
          console.log(result, result[0].name);

        } else {
          this.locationSuggestions[key] = result;
          console.log('Else Part', this.locationSuggestions);
        }
      } catch (er) { console.log(er); }
    });
  }
  selectedValue(value) {
    this.selectedValueClose = true;
    this.locationSuggestions = {};
    this.address = value;
    this.vehicleloc = this.address.name;
    this.vehicleLocation = value;
  }
  emptySuggestions() {
    this.locationSuggestions = {};
    this.selectedValueClose = false;
    this.vehicleLocation = null;
  }
  close() {
    this.addVehicelForm.patchValue({
      location: this.address.name
    });
    // let sendData = null;
    // if (this.vehicleLocation != null && this.vehicleLocation.name) {
    //   empLocation.value = this.vehicleLocation.name;
    //   sendData = this.vehicleLocation.name;
    // }
    // else {
    //   empLocation.value = null;
    //   sendData = null;
    // }
  }
  addFormLoad() {
    this.addVehicelForm = new FormGroup({
      vehRegNo: new FormControl('', [Validators.required, Validators.minLength(5)]),
      // ,Validators.pattern('^[A-Z]{2}\s[0-9]{1,2}\s[A-Z]{1,2}\s[0-9]{1,4}$' )
      // shopAddress: new FormControl('', [Validators.required, Validators.maxLength(15)]),
      imei: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{14}')]),
      make: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      capacity: new FormControl('', [Validators.required]),
      vehicleType: new FormControl('', [Validators.required]),
      chassisNo: new FormControl('', [Validators.required, Validators.minLength(17)]),
      engineNo: new FormControl('', [Validators.required, Validators.minLength(17)]),
      purchasedDate: new FormControl('', [Validators.required]),
      routePermitCost: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(7)]),
      startDate: new FormControl('', [Validators.required]),
      expiryDate: new FormControl('', [Validators.required]),
      rcNo: new FormControl('', [Validators.required]),
      rcStartDate: new FormControl('', [Validators.required]),
      rcExpiryDate: new FormControl('', [Validators.required]),
      rcfile: new FormControl('', [Validators.required]),
      vehicleInfo: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      boxType: new FormControl('', [Validators.required]),
      vendorName: new FormControl(''),
      simNo: new FormControl(''),
      roadTaxStatus: new FormControl('', [Validators.required]),
    });

  }
  public checkError = (controlName: string, errorName: string) => {
    return this.addVehicelForm.controls[controlName].hasError(errorName);
  }
  private getChangedProperties(): string[] {
    let changedProperties = [];
  
    Object.keys(this.addVehicelForm.controls).forEach((name) => {
      const currentControl = this.addVehicelForm.controls[name];
  
      if (currentControl.dirty) {
        changedProperties.push(name);
      }
    });
  
    return changedProperties;
  }
  onSubmit() {
    // this.submitted = true
    console.log(this.getChangedProperties())
    if (!this.addVehicelForm.valid) { return; }
    console.log(this.addVehicelForm.value);
    const obj = {
      orgID: this.storage.get('userInfo').orgID,
      vendorID: this.VendorInfos.vendorID,
      ...this.addVehicelForm.value
    };
    delete obj.location;
    obj.roadTaxStatus = this.addVehicelForm.value.roadTaxStatus == 'true' ? true : false;

    obj.location = {
      lat: this.address.center[1],
      lng: this.address.center[0],
      name: this.address.name,
    };
    obj.rcfile = this.imageData
    console.log(obj, this.address);
    this.api.transportManagerVehicleCreate(obj).then((res: any) => {
      if (res.status == 'success') {

        this.swal.swalFunction(res.status, res.status, res.message, 2000);
        this.router.navigate(['/organization/transport-manager/information/vehicle/view-vehicle'])
      }
      if (res.status == 'failure') {
        this.swal.swalFunction('error', res.status, res.message, 3000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }
  onChangeBoxType(e) {
    console.log(e.value);
    this.isShowVendorName = false;
    this.isShowSimno = false;
    if (e.value == 'others') { this.isShowVendorName = true; }
    if (e.value == 'group10') { this.isShowSimno = true; }
  }
  openLocation() {
    document.getElementById('vehicleLocationId').click();
  }
  initMap(markers: any, type: string) {

    try {
      const mapBoxKey = 'pk.eyJ1Ijoib3dlbmxhbWIiLCJhIjoiY2lleWljcnF4MDBiOXQ0bHR0anRvamtucSJ9.t3YnHHqvQZ8Y0MTCNy0NNw';
      const parent = this;
      if (mapBoxKey) {
        const MyMap1 = new mapUtilityMapBox();
        MyMap1.setup('markerExampleMapBox1', {
          key: mapBoxKey
        },
          function (map) {
            // if (type == 'add') {
            parent.mapObj = map;
            map.clusters = [
              {
                name: 'dashboardMap',  // unique id
                maxZoom: 10, // max zoom on cluster click event
                radius: 20,
                color: 'rgba(255, 0, 0, 0.2)', // '#be9694'  // for opacity use rgb
                textColor: 'green' // count color
              }
            ];

            map.addMarkers(markers);
            setTimeout(() => {
              map.clusterSynch();
            }, 500);

            map.onMapZoom(function (z) {
              map.clusterSynch();
              setTimeout(() => { map.marker(markers); }, 200);
            });

            map.focus({
              lat: parseFloat(markers[0].lat),
              lng: parseFloat(markers[0].lng),
              zoom: 10
            });

            map.onClick({
              type: 'marker',
            }, (e: any) => {
              map.focus({
                lat: e._lngLat.lat,
                lng: e._lngLat.lng,
                zoom: 18
              });
            });
            // } else {
            //   console.log(markers)
            //   map.marker(markers);
            //   setTimeout(() => {
            //     map.clusterSynch();
            //   }, 500);
            // }
          });
      }
    } catch (error) {
      console.log(error);
    }
  }
  // file upload--------------
  async handleUpload(event) {
    console.log(event.target.files[0])
    this.imageData = await this.file.fileHandeleBase64(event)
    console.log(this.imageData)
  }

  //  handleUpload(event) {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     console.log(reader.result);
  //     this.imageData = reader.result
  //   };
  // }
  // ------------------
}
