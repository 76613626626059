import { VendorComponent } from './transportmanger/information/vendor/vendor.component';
import { TransportmangerComponent } from './transportmanger/transportmanger.component';
import { TransportMangerAdminPage } from './transportmanger/transportmangeradminpage.declaration';
import { VendorDeclaration } from './vendor/vendor.declaration';

export const OrganizationDecaration = [
    TransportMangerAdminPage,
    VendorDeclaration,
    TransportmangerComponent,
    VendorComponent
];
