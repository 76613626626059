import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

let storedData;

@Component({
  selector: 'app-alert-types',
  templateUrl: './alert-types.component.html',
  styleUrls: ['./alert-types.component.css']
})
export class AlertTypesComponent implements OnInit {
  @Output() dataTransferToService: EventEmitter<any> = new EventEmitter();
  @Input() alert: any;
  access: any;
  selectAll = false;
  finalObj = {};
  constructor() {

  }
  ngOnInit() {
    console.log(storedData);
    if (storedData) {
       this.access = storedData;
    } else {
      this.access = [
        { name: 'Push Notification', control: 'push', checked: false },
        { name: 'SMS Notification', control: 'sms', checked: false },
        { name: 'Email Notification', control: 'email', checked: false },
        { name: 'Call Alert', control: 'call', checked: false }
      ];
    }
    console.log(this.alert);
    // this.alertChecked()
  }
  //   alertChecked(){
  // if(this.alert){
  //   for(let i in this.access){
  //     console.log(this.access[i]['control'])
  //     this.access[i]['control']
  //   }

  // }
  //   }
  updateCheck() {
    console.log(this.selectAll);
    // if (this.selectAll === true) {
    //   this.access.map((item) => {
    //     item.checked = true;
    //   });

    // } else {
    this.access.map((item) => {
      item.checked = false;
    });
    // }
  }


  // public organization;
  // public alertSettings;
  // constructor(
  //   public api: ApiService,
  //   public router: Router,
  //   public snackBar:SnackBarService) {

  // }

  // ngOnInit() {
  //   console.log(this.organization)

  //   this.alertSettings =alertSettings;
  //   // console.log(this.submitted)
  // }

  // submitted(e: any) {
  //   console.log(e);
  //   this.api.createOrg(e)
  //     .then((res: any) => {
  //       console.log('Success', res);
  //       this.triggerSnackbar()
  //       this.router.navigate(['/master/view-org'])
  //     })
  //     .catch((error: any) => {
  //       console.log('Error', error);
  //     });
  // }


  // triggerSnackbar() {
  //   let config = {
  //   message: "<p class='p-0 m-0'> <span > <i class='ms-Icon ms-Icon--InfoSolid text-primary h4 m-0 p-0 icon' aria-hidden='true'> </i> </span> Created Succesfully! </p>",
  //   horizontalPosition: 'right',
  //   verticalPosition: 'bottom',
  //   duration: 5000
  //   }
  //   let snackBarRef: any = this.snackBar.openSnackBar(config)
  //   }
  onClickEventReceived(event) {
    storedData = this.access;
    // console.log(this.access)
    for (let i = 0; this.access.length > i; i++) {
      this.finalObj[this.access[i].control] = this.access[i].checked;
    }
    console.log('dataTransferToService', this.finalObj);
    this.dataTransferToService.emit(this.finalObj);
  }
}

