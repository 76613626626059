import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import indiaStates from '../../../../../../../assets/js/india.state.list.json';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { StorageService } from 'src/app/Services/storage.service';
import { ApiService } from 'src/app/Services/api.service';
import { Router } from '@angular/router';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { type } from 'os';

export interface User {
  name: string;
}
declare const Compressor;
@Component({
  selector: 'app-create-driver',
  templateUrl: './create-driver.component.html',
  styleUrls: ['./create-driver.component.css']
})
export class CreateDriverComponent implements OnInit {
  public addDriverForm: FormGroup;
  myControl = new FormControl();
  public stateSelect = indiaStates.IND;
  options: User[] = [];
  public stateData = [];
  filteredOptions: Observable<User[]>;
  public userType: string;
  public userData: any;
  public imageField;
  public licenceImage;
  public profileImage;
  public profilePicClicked:boolean=false;
  public licenceImageClicked:boolean=false;
  public tempProfilePic: any;
  public tempLicencePic:any;
  constructor(
    public api: ApiService,
    public storage: StorageService,
    public router: Router,
    public swal: SweetAlert,
    private loader: LoaderService,
    ) {
    this.userType = this.storage.get('userType');
    this.userData = this.storage.get('vendorID_orgID');
   }
   public finalCheck:boolean=false;

  ngOnInit(): void {
    if (this.userType != 'transport_manager') {
      this.router.navigate(['/organization/transport-manager/login']);
    }
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.options.slice())
    );
    this.getStateOptions();
    this.addFormLoad();
  }
   getStateOptions() {
    this.stateSelect.forEach(element => {
      //console.log(element)
      this.options.push({ name: element })
    });
    console.log(this.options);
  }
  addFormLoad(){
    this.addDriverForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      licenceNo:new FormControl('', [Validators.required,Validators.pattern('(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}')]),
      licenceExpiry:new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{9}')]),
      emergencyContact: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{9}')]),
      bloodGroup: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl(''),
      country: new FormControl({ value: 'India', disabled: true }),
      pincode: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{5}')]),
      profilePic: new FormControl(''),
      licenceUpload: new FormControl('', [Validators.required]),
    });
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.addDriverForm.controls[controlName].hasError(errorName);
  }
  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }
  displayFn(user: User): string {
    this.stateData = [];
    this.stateData.push(user);
    //this.addDriverForm.value.patchValue({state:user});
    return user && user.name ? user.name : '';
  }
  /*clear image file*/
  clkFun(field) {
    this.addDriverForm.controls[field].patchValue('');
    if(field=='profilePic'){
      this.profileImage='';
    }
    else if(field=='licenceUpload'){
      this.licenceImage='';
    }
  }
  fileUpload(e:any,field:any){
    // console.log('field <<<--->>>', field);
    this.clkFun(field);
    this.handleUpload(e,field);

  }
 
  handleUpload(e:any,type:string) {
  const files = e.target.files[0];
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const sizeOfFile = files.size / 1024 / 1024;
    if (sizeOfFile <= 10) {
        if (sizeOfFile <= 1) {
          const reader = new FileReader();
          reader.onload = ((e)=>{
            if(type=='profilePic'){
              this.profileImage=e.target && e.target.result ? e.target.result :'';
            }
            else if(type=='licenceUpload'){
              this.licenceImage=e.target && e.target.result ? e.target.result :'';
            }
            // this.addDriverForm.value[type]= e.target && e.target.result ? e.target.result :'';
          })//this._handleReaderLoaded.bind(this);
          reader.readAsDataURL(file);
        } else {
          this.loader.show();
          const parent = this;
          new Compressor(file, {
            quality: Number((1 / sizeOfFile).toPrecision(2).slice(0, -1)),
            success(result) {
              const reader = new FileReader();
              reader.onload = ((e)=>{
                if(type=='profilePic'){
                  this.profileImage=e.target && e.target.result ? e.target.result :'';
                }
                else if(type=='licenceUpload'){
                  this.licenceImage=e.target && e.target.result ? e.target.result :'';
                }
              }) //parent._handleReaderLoaded.bind(parent);
              reader.readAsDataURL(result);
              parent.loader.hide();
            },
            error(err) {
              console.log(err);
              parent.loader.hide();
            }
          }
          );
      }
    } else {
      //this.fileError = true;
      //const f: any = this.form;
      //f.errors = true; 
      //console.log(this.addDriverForm.controls[field].value);
      alert('File size should be less than 1 MB.'); 
      // if(this.imageField=='profilePic'){
      //   this.profileImage='';
      //   this.addDriverForm.value['profilePic'] = '';
      //   this.profilePicClicked=true;
      // }
      // if(this.imageField=='licenceUpload'){
      //   this.licenceImage='';
      //   this.addDriverForm.value['licenceUpload'] = '';
      //   this.licenceImageClicked=true;
      // }
    }
  }

  onSubmit() {
    this.finalCheck=true;
    this.licenceImageClicked=true;
    if (this.addDriverForm.valid) {
        const obj = {
            orgID: this.userData.orgID,
            vendorID: this.userData.vendorID,
            country:'India',
            ...this.addDriverForm.value
        };
        if(this.licenceImage && this.licenceImage !='') obj['licenceUpload'] = this.licenceImage
        if(this.profileImage && this.profileImage !='') obj['profilePic'] = this.profileImage
        // obj['profilePic']=this.profileImage;
        // obj['licenceUpload']=this.licenceImage;
        this.api.transportManagerDriverCreate(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                this.router.navigate(['/organization/transport-manager/information/driver/view-driver', { orgID: obj.orgID, vendorID: obj.vendorID }]);
            } else {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    } else {
        console.log('error');
    }
}
}
