import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { driverModule } from '../../../../../../modules/information/driver';
declare const Swal;

@Component({
  selector: 'view-driver',
  templateUrl: './view-driver.component.html',
  styles: ['./view-driver.component.css']
})


export class ViewDriverComponent {
  constructor(public router: Router, public api: ApiService,
              private location: Location,
              public storage: StorageService,
              public swal: SweetAlert,
              public route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      this.orgID = params.get('orgID');
      this.vendorID = params.get('vendorID');
    });
    this.userType = this.storage.get('userType');
    this.userData = this.storage.get('vendorViewData');
  }
  public driverInfo: any = driverModule;
  public driverInformation: any;
  driverData: any = [];
  userType: string;
  userData: any;
  orgID: string;
  vendorID: string;
  public values: any = [];
  public editForm: boolean;
  public cols = [
    { key: '#', display: '#' },
    { key: 'name', display: 'Driver Name' },
    { key: 'licenceNo', display: 'Licence No' },
    { key: 'mobile', display: 'Mobile No' },
    {
      key: 'dob', display: 'DOB',
      config: {
        isDate: true,
        format: 'dd MMM YYYY'
      }
    },
    { key: 'city', display: 'City' },
    {
      key: 'action',
      display: 'Action',
      config: {
        isAction: true,
        actions: [
          { display: 'view', color: 'primary', icon: 'View' },
          { display: 'delete', color: 'warn', icon: 'Delete' },
          { display: 'update', color: 'primary', icon: 'EditContact' }
        ]
      }
    }
  ];
  ngOnInit() {
    if (this.userType != 'transport_manager') {
      this.router.navigate(['/organization/transport-manager/login']);
    }
    this.getListDriver();
  }

  submitted(e: any) {
    console.log(e);
  }

  onActionHandler(e: any) {
    const data = e.data;
    if (e.action.display == 'update') {
      console.log(e.data, 'edit');
      this.router.navigate(['/organization/transport-manager/information/driver/edit-driver', { orgID: e.data.orgID, driverID: e.data.driverID, vendorID: e.data.vendorID }]);
      // for (let f of this.driverInfo) {
      //   let name = f['name'];
      //   let value = '';
      //   if (f.type == 'dropdown') {
      //     let val = data[name].split(',');
      //     let temp = [], str = [];
      //     val.map(item => { temp.push({ value: val, name: val }); str.push(item) });
      //     f.selected = temp;
      //     f['value'] = str.join(',');
      //   } else if (f.type == 'checkbox') {
      //     let spStr = data[name].split(',')
      //     for (let op of f.options) {
      //       if (spStr.indexOf(op.value) !== -1) op.selected = true;
      //     }
      //   } else {
      //     if (name == 'email') value = 'emailID';
      //     else value = name;
      //     f['value'] = data[value];
      //   }
      // }
      // this.editForm = true;
    } else if (e.action.display.toLowerCase() == 'delete') {
      console.log(e);
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want be able to delete this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('delete click');
          this.deleteDriverData(e);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        }
      });
    } else if (e.action.display.toLowerCase() == 'view') {
      this.router.navigate(['/organization/transport-manager/information/driver/single-view-driver', { orgID: e.data.orgID, driverID: e.data.driverID, vendorID: e.data.vendorID }]);
    }
  }
  deleteDriverData(e?) {
    const obj = {
      orgID: e.data.orgID,
      vendorID: e.data.vendorID,
      driverID: e.data.driverID,
    };
    this.api.transportManagerDriverDelete(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
        this.getListDriver();
      } else {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }


  submitForm(e: any) {
    if (e == 0) { this.editForm = false; } else {
      console.log(e);
    }
  }


  getListDriver() {
    const obj = {
      orgID: this.orgID == null ? this.storage.get('vendorsIdOrgId').orgID : this.orgID,
      vendorID: this.vendorID == null ? this.storage.get('vendorsIdOrgId').vendorID : this.vendorID,
    };
    this.api.transportManagerDriverList(obj).then((res: any) => {
      if (res.status == 'success') {

        this.driverData = res.data;
        // let data
        // for(let i=0;i< res.data.length;i++){
        //   console.log( res.data[i].mobile.substr(7,10))
        //   data = res.data
        //   if(res.data[i].mobile){
        //      data[i].mobile.substr(7,10)
        //      this.driverData.push(data)
        //   }

        // }
        console.log(this.driverData.length);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  searchValue(e) {
    console.log(e);
  }
}
