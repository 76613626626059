import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

import { Subject } from 'rxjs';
declare const Swal;

@Component({
    selector: 'view-request',
    templateUrl: './view-request.component.html',
    styleUrls: ['./view-request.component.css']
})

export class ViewRequestComponent implements OnInit {

    constructor(
        private storage: StorageService,
        private api: ApiService,
        private router: Router,
        private swal: SweetAlert
    ) {

    }
    requestData: any;
    searchedValue = '';
    statusValue: any;
    public searchInput: any = new Subject<string>();
    custRequestData: any;
    reqList: any = ['on_Board_request', 'location_request', 'route_change', 'time_change', 'shift_change', 'leave_request'];
    requestValue = [
        { value: 'custom_request', viewValue: 'Custom request' },
        { value: 'on_Board_request', viewValue: 'On board request' },
        { value: 'location_request', viewValue: 'Location request' },
        { value: 'route_change', viewValue: 'Route change' },
        { value: 'time_change', viewValue: 'Time change' },
        { value: 'shift_change', viewValue: 'Shift change' },
        { value: 'leave_request', viewValue: 'Leave request' }
    ];
    toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

    isShowCusReq = false;
    isShowRequst = false;
    public dataForOption;
    ngOnInit() {
        this.getListRequest();
        this.getCustListRequest();
    }
    getListRequest(val?) {
        let obj = {
            orgID: this.storage.get('userInfo').orgID,
        };
        if (val) {
            obj['requestType'] = val
        }
        this.api.ListRequest(obj).then((res: any) => {
            // this.api.ListCustomRequest(obj).then((res: any) => {
            console.log(res);
            this.requestData = res.data;
            this.isShowRequst = true;

        }).catch((err) => {
            console.log(err);
        });
    }
    getCustListRequest() {
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
        };
        // this.api.ListRequest(obj).then((res: any) => {
        this.api.ListCustomRequest(obj).then((res: any) => {
            console.log(res);
            this.custRequestData = res.data;
            this.isShowCusReq = true;

        }).catch((err) => {
            console.log(err);
        });
    }
    action(data, type) {
        console.log(data, type);
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: data.requestID,
            status: type == 'accept' ? 'active' : 'inactive'
        };
        // if(type == 'waiting'){obj['status'] = 'waiting'}
        this.api.UpdateRequest(obj).then((res: any) => {
            console.log('RES ', res);
            this.getListRequest();
        });

    }
    requestAction(data, customValue?) {
        console.log(data, customValue);
        if (customValue == 'custom_request') {
            this.router.navigate(['/organization/transport-manager/request/single-custom-requrst-view', { cusmID: data.requestID }]);
        } else {
            this.router.navigate(['/organization/transport-manager/request/single-view-request', { id: data.requestID }]);
        }
    }
    deleteRequestData(reqID) {
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: reqID
        };
        this.api.DeleteRequest(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                this.getListRequest();
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    upDateStatus(data, name?) {
        if (name == 'custom-report') {
            if (data.status == '0') { data.status = '1'; } else if (data.status == '1') { data.status = '0'; }
            console.log(data);
            const obj = {
                orgID: this.storage.get('userInfo').orgID,
                ...data
            };
            this.api.UpdateCustomRequest(obj).then((res: any) => {
                console.log(res);
                this.getCustListRequest();
            }).catch((err) => {
                console.log(err);
            });
        } else {
            if (data.status == '0') { data.status = '1'; } else if (data.status == '1') { data.status = '0'; }
            const obj = {
                orgID: this.storage.get('userInfo').orgID,
                ...data
            };
            this.api.UpdateRequest(obj).then((res: any) => {
                if (res.status == 'success') {
                    this.swal.swalFunction(res.status, res.status, res.message, 2000);
                    this.getListRequest();
                    this.getCustListRequest();
                }
                if (res == 'failure') {
                    this.swal.swalFunction('error', res.status, res.message, 2000);
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }
    requstTypeValue(e) {
        console.log(e.length, e.length > 0, e);
        this.isShowCusReq = false;
        this.isShowRequst = false;
        if (e.length > 0) {
            if (e.length == 1 && e.includes('custom_request')) {
                this.getCustListRequest();
                return;
            } else if (!e.includes('custom_request')) {
                let fnVal = e.toString()
                this.getListRequest(fnVal);
            } else {
                let arr = e.includes('custom_request')
                if (arr) {
                    let rmv = e.indexOf('custom_request')
                    this.getCustListRequest();
                    e.splice(rmv, 1)
                    console.log(rmv, e)
                    let fnVal = e.toString()
                    this.getListRequest(fnVal);
                }
                if (e.includes('custom_request')) { this.getCustListRequest(); }
            }
        } else {
            this.getListRequest();
            this.getCustListRequest();
        }
    }
}
