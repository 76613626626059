import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/Services/api.service';
import { SharedService } from 'src/app/Services/shared.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
  selector: 'g10-ms-dynamic-form-builder',
  template: `

    <form *ngIf="enableForm" (ngSubmit)="Submit(this.form.value)" [formGroup]="form" class="form-horizontal row  px-3">
      <div *ngFor="let field of fields" [class]="field.col"  [ngClass]="{'col-md-3 col-12':field && !field.col}" >
          <field-builder [field]="field" [form]="form" [formSubmit]="formSubmit" [_edit]="__edit"></field-builder>
      </div>
      <div class="float-right vw-100 text-right px-3">
    <!--  <button type="button" mat-raised-button   class="btn btn-danger mr-2" color="warn" (click)="reset()">Reset</button> -->
    <button type="button" mat-raised-button *ngIf="__iscancel" class="btn btn-light mr-2" color="basic" goBack >Cancel</button>

    <button type="button" mat-raised-button color="info" *ngIf="__isDraft" class="btn btn-primary mr-2" (click)="draft()">Draft</button>
    <!--  <button type="submit" mat-raised-button [disabled]="!form.valid" color="primary" class="btn btn-primary mr-2">Save</button>  -->
      <button type="submit" mat-raised-button color="primary" *ngIf="__isSubmit" class="btn btn-primary mr-2 pr-4 pl-4 ">Save</button>
    </div>
      </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit {
  @Output() values = new EventEmitter();
  @Output() draftValues = new EventEmitter();
  @Input() fields: any[] = [];
  @Input() __edit: boolean;
  @Input() __isDraft: boolean;
  @Input() __iscancel = true;
  @Input() __isSubmit = true;
  @Input() type: string;
  form: FormGroup;
  public enableForm = true;
  public formSubmit: boolean;
  constructor(
    private sub: SharedService,
    /* public storage: StorageService,
    public api: ApiService, */
  ) {
    this.sub._subject.subscribe((e: any) => {
      if (e.type == 'employeeBranch') {
        console.log('employee branch', e.data);
      }
      console.log(e);
      if (e.type == 'vehicleBoxType') {
        console.log('checking', e);
        if (e.data == 'groupten') {
          this.form.removeControl('vendorName');
          this.fields = this.fields.filter(e => e.name !== 'vendorName');
          if (this.form.controls.simNo == undefined) {
            this.fields.push({
              type: 'text',
              name: 'simNo',
              label: 'Sim No',
              value: '',
              required: true,
              col: 'col-md-4'
            });
            this.form.addControl('simNo', new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]));
            console.log(this.form);
          }
        } else if (e.data == 'others') {
          this.form.removeControl('simNo');
          this.fields = this.fields.filter(e => e.name !== 'simNo');
          if (this.form.controls.vendorName == undefined) {
          this.fields.push({
            type: 'text',
            name: 'vendorName',
            label: 'Vendor Name',
            value: '',
            required: true,
            col: 'col-md-4'
          }, );
          this.form.addControl('vendorName', new FormControl('', [Validators.required]));
          }
      } else {
        this.form.removeControl('simNo');
        this.form.removeControl('vendorName');
        this.fields = this.fields.filter(e => e.name !== 'simNo');
        this.fields = this.fields.filter(e => e.name !== 'vendorName');
        console.log(this.fields);

      }
        console.log(e);
      }
    });
  }

  ngOnInit() {
    // console.log('asjkdfhadsfh',this.__iscancel,this.__isSubmit)
    // this.reset()
    // <div class="float-right vw-100 text-right px-3">
    // <button type="button" mat-raised-button  class="btn btn-danger mr-2" color="warn" (click)="reset()">Reset</button>

    // <button type="button" mat-raised-button color="info" *ngIf="__isDraft" class="btn btn-primary mr-2" (click)="draft()">Draft</button>
    //   <button type="submit" mat-raised-button [disabled]="!form.valid" color="primary" class="btn btn-primary mr-2">Save</button>
    // </div>
    // this.getListShift();

    const fieldsCtrls = {};

    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    for (const f of this.fields) {
      // if(f.name == 'branch'){
        // this.getListShift();
        /* console.log(this.shiftInformation);
        console.log(f); */
       /*  let getBranch=[];
        this.shiftInformation.filter((e)=>{
          if(getBranch.indexOf(e.branch)==-1){
            getBranch.push(e.branch);
          }
        });
        console.log(getBranch); */
        // f.options=getBranch;
      // }
      if (this.type == 'add') { f.value = ''; }
      // console.log((f.col.match(/(\d+)/)[0]))
      if (f.type == 'dropdown') {
        console.log('dropdown ttt');
        fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required);

      } else if (f.type != 'checkbox') {
        if (f.name == 'email' || f.textType == 'email') { fieldsCtrls[f.name] = new FormControl(f.value || '', [Validators.required, Validators.email]); } else if (f.inType == 'url') { fieldsCtrls[f.name] = new FormControl(f.value || '', [Validators.required, Validators.pattern(reg)]); } else if (f.name == 'pincode') { fieldsCtrls[f.name] = new FormControl(f.value || '', [Validators.required, Validators.pattern('[0-9]{6}')]); } else if (f.textType == 'mobile' || f.name == 'simNo') { fieldsCtrls[f.name] = new FormControl(f.value || '', [Validators.required, Validators.pattern('[0-9]{10}')]); } else if (f.textType == 'imei') { fieldsCtrls[f.name] = new FormControl(f.value || '', [Validators.required, Validators.pattern('[0-9]{15,16}')]); } else if (f.name == 'vehRegNo') { fieldsCtrls[f.name] = new FormControl(f.value || '', [Validators.required, Validators.pattern('[A-Z|a-z]{2}[0-9]{1,2}[A-Z|a-z]{0,2}[0-9]{4}')]); } else { fieldsCtrls[f.name] = new FormControl(f.value || '', Validators.required); }
      } else {

        const opts = {};
        for (const opt of f.options) {
          console.log('opt', opt.value);
          opts[opt.name] = new FormControl(opt.value);
        }
        fieldsCtrls[f.name] = new FormGroup(opts);
      }
    }
    this.form = new FormGroup(fieldsCtrls);
    console.log('this.form  ', this.form.value);
    if (this.type == 'add') {
      this.form.reset();
      }
  }


  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      console.log(this.form.get(name).valid);

      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  /* shift list api call */
  /* public shiftInformation;
  public userData = this.storage.get('userInfo');
  getListShift() {
    let obj = {
      orgID: this.userData.orgID
    }
    this.api.ListShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.shiftInformation = res.data;
        console.log(this.shiftInformation);
      }
    }).catch((err) => {
      console.log(err)
    })
  } */

  Submit() {
    this.formSubmit = true;
    this.values.emit(this.form);
  }
  draft() {
    this.draftValues.emit(this.form.value);
  }
  reset() {
    this.form.reset();
  }
}
