import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './Layouts/admin/admin.component';

// import { LoginComponent } from './Pages/login/login.component';
import { NotFoundComponent } from './Pages/404/notfound.component';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS, HttpClientModule, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { StorageService } from './Services/storage.service';
import { DocumentationComponent } from './Pages/documentation/documentation.component';
import { MasterRouting } from './Pages/master/master.routing';
// import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { HomepageComponent } from './Pages/homepage/homepage.component';
import { OrganizationRouting } from './Pages/organization/organization.routing';
import { AuthenticationComponent } from './authentication/authentication.component';

declare const window;

const routes: Routes = [
  { path: '', redirectTo: 'homepage', pathMatch: 'full' },
  { path: 'homepage', component: HomepageComponent },
  {path:'organization/transport-manager/reset-password/:param',component:AuthenticationComponent},
  { path: 'docs', component: DocumentationComponent },
  {
    path: '', component: AdminComponent,
    children: [
      { path: 'organization', children: OrganizationRouting },
      { path: 'master', children: MasterRouting },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: 'dashboard', component: DashboardComponent },
    ]
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }

];




@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {

  constructor(
    private storage: StorageService,
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers:any = {
      // 'Content-Type': 'application/json; charset=utf-8',
    };
    // headers = headers.set('Access-Control-Allow-Origin','*')
    //  headers['Access-Control-Allow-Headers'] = "X-Requested-With,content-type,Token";
    //  headers['Access-Control-Allow-Origin'] = "*";
    //  headers['Access-Control-Allow-Credentials'] = "true";
    headers['content-Type'] = 'application/json';

    if (this.storage.get('token')) {
      headers.Token = this.storage.get('token');
    }


    request = request.clone({
      setHeaders: headers
      // setHeaders: {
      //     Authorization: `Bearer ${this.storage.get('token')}`
      // }
    });


    // return next.handle(request);


    const started = Date.now();
    return next.handle(request).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const elapsed = Date.now() - started;
          // console.log(event.body)
          const body = event.body;
          if (body.status == 'failure') {
            if (body.message == 108) {
              alert('Token expired');
              // Swal.fire({
              //   icon: 'error',
              //   title: 'Login',
              //   text: 'Login Expired',
              // })
              localStorage.clear();
              window.setTimeout(function() { window.location = 'https://google.com'; }, 3000);
            }
          }
          // console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
        }
      }, error => {
        console.error('NICE ERROR', error);
        // this.loader.hide()

      })
    );
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule
  ],
  exports: [RouterModule],
  providers: [

    { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
  ]
})
export class AppRoutingModule { }
