export const ticketModule = [
    {
        type: 'text',
        name: 'title',
        label: 'Title',
        value: '',
        required: true,
        col: 'col-md-4',
      },
      {
        type: 'dropdown',
        name: 'category',
        label: 'Category',
        value: '',
        required: true,
        col: 'col-md-4',
        options: [
          { value: '', name: 'Select'},
          { value: 'cate1', name: 'Cate 1'},
          { value: 'cate2', name: 'Cate 2'},
          { value: 'cate3', name: 'Cate 3'},
        ]
      },
      {
        type: 'dropdown',
        name: 'status',
        label: 'Status',
        value: '',
        required: true,
        col: 'col-md-4',
        options: [
          { value: 'Active', name: 'Active'},
          { value: 'Inactive', name: 'Inactive'}
        ]
      },
      {
        type: 'text',
        name: 'description',
        label: 'Description',
        value: '',
        required: true,
        multiline: true,
        col: 'col-12'
      },
];
