import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { keys } from 'src/assets/fontawesome/js/v4-shims';
import { Observable, pipe, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
// import { FilterPipe } from 'src/app/pipe/filter.pipe';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  providers: [ ]
})
export class ReportsComponent implements OnInit {
  searchedValue = '';
  reportName = [];
  searchText: string;
  public searchInput: any = new Subject<string>();

  public reportModule = [
    {
      name: 'SOS Report',
      icon: 'assets/images/reports/sos.png',
      path: `sos-reports`
    },
    {
      name: 'Over Speed Report',
      icon: 'assets/images/reports/over-speed.png',
      path: 'overspeed-reports'
    },

    {
      name: 'Movement  Report',
      icon: 'assets/images/reports/movement.png',
      path: `moment-reports`
    },
    {
      name: 'Halt Report',
      icon: 'assets/images/reports/halt.png',
      path: `halt-reports`
    },
    {
      name: 'Productivity Report on Trip',
      icon: 'assets/images/reports/productivity.png',
      path: 'productivity-reports'
    }, {
      name: 'Vehicle Arriving Time',
      icon: 'assets/images/reports/vehicle-arrivel.png'
    },
    {
      name: 'Employee Boarding History',
      icon: 'assets/images/reports/employee-boarding-history.png'
    }, {
      name: 'Seat Occupancy Report',
      icon: 'assets/images/reports/seet.png'
    }, {
      name: 'Route Violation',
      icon: 'assets/images/reports/violation.png'
    }, {
      name: 'Geofence Violation',
      icon: 'assets/images/reports/geofence.png'
    }, {
      name: 'GPS Failure',
      icon: 'assets/images/reports/gps-failure.png'
    },
  ];
  constructor(public router: Router) {
    // this.searchInput.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
    //   this.reportModule.filter((item: any) => {
    //     if (item.name.toLowerCase().includes(value.toLowerCase())) {
    //       this.reportModule.push(item);
    //     }
    //   });
    // });
  }

  ngOnInit(): void {
    this.getReportName();


  }
  selectReportData(data) {
    // console.log(this.reportName.includes(data.name), data)
    if (this.reportName.includes(data.name)) {

      this.router.navigate([`/organization/transport-manager/reports/${data.path}`]);


    }

  }
  getReportName() {
    this.reportModule.forEach(element => {
      this.reportName.push(element.name);
    });
  }


  findFilter() {
    for (let i = 0; i < this.reportModule.length; i++) {
      console.log(this.reportModule[i].name);

    }
    console.log(this.searchedValue);
  }

}
