import { Component, OnInit } from '@angular/core';
import { VehicleModule } from 'src/app/modules/information/vehicle';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  public VehicleInfo = VehicleModule;

  constructor(

  ) { }

  ngOnInit(): void {
  }

}
