import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from './Services/loader.service';
import { NgxSpinnerService } from 'ngx-spinner';

export const DD_MM_YYYY_Format = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format },
  ]
})
export class AppComponent {
  title = 'dashboard';
  collection = [];
  name = new FormControl();
  constructor(private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              public loader: LoaderService, ) {
      this.loader.loaderState.subscribe((res: any) => {
        if (res.show) {
          this.spinner.show();
        } else {
          this.spinner.hide();

        }
      });
  }
  ngOnInit() {

  }
}
