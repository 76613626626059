import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeaveRequest, LocationRequest, OnBoardRequest, RouteRequest, SiftRequest, TimeRequest } from 'src/app/modules/request/allrequest';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
declare const Swal;
@Component({
    selector: 'app-sigle-view-request',
    templateUrl: './single-view-request.html',
    styles: [`
    .imgSet{
        background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/upwk58854696-wikimedia-image.jpg?w=1300&dpr=1&fit=default&crop=default&q=80&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=edc8c9eaea7958ed402548b4e03a7e7a");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 89%;

        z-index: 2;
    },
       .custom-style{
            padding: 5px;
        },
        .midCard{
            position: absolute;
            top: 74px;
            width: 600px;
            right: 400px;
        }
        ul {
            list-style-type: none;
        }
    `]
})

export class SingleViewRequestComponent {
    requestID: string;
    viewRequestData: any = [];
    public leaveReuest = LeaveRequest
    public onBoardReuest = OnBoardRequest
    public loactionRequest = LocationRequest
    public timeRequest = TimeRequest
    public shiftRequest = SiftRequest
    public routeChangeRequest = RouteRequest
    formTypes: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private api: ApiService,
        private storage: StorageService,
        public swal: SweetAlert
    ) {
        this.route.paramMap.subscribe(paramas => {
            this.requestID = paramas.get('id');
        });
    }
    ngOnInit() {
        this.getListRequest();
    }
    upDateStatus(data) {
        if (data.status == '0') { data.status = 1; } else if (data.status == '1') { data.status = 0; }
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: data.requestID,
            status: data.status
        };
        console.log(obj);
        this.api.UpdateRequest(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                this.getListRequest();
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    getListRequest() {
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: this.requestID
        };
        this.api.ListRequest(obj).then((res: any) => {
            console.log(res);
            this.viewRequestData = res.data[0];
            this.formLoadData(this.viewRequestData.requestType)
        }).catch((err) => {
            console.log(err);
        });
    }
    formLoadData(reqType) {
        console.log(reqType)
        if (reqType == 'on_Board_request') this.formTypes = this.onBoardReuest
        if (reqType == 'location_request') this.formTypes = this.loactionRequest
        if (reqType == 'route_change') this.formTypes = this.routeChangeRequest
        if (reqType == 'time_change') this.formTypes = this.timeRequest
        if (reqType == 'shift_change') this.formTypes = this.shiftRequest
        if (reqType == 'leave_request') this.formTypes = this.leaveReuest
    }
    requestAction(data, val) {
        console.log(data, val);
        switch (val) {
            // case 'view':
            //     console.log(data.requestID, val)
            //     if (customValue == 'custom_request') { this.router.navigate['/organization/transport-manager/request/single-custom-requrst-view'] }
            //     else { this.router.navigate(['/organization/transport-manager/request/single-view-request', { id: data.requestID }]) }
            //     break;
            case 'edit':
                this.router.navigate(['/organization/transport-manager/request/edit-request', { id: data.requestID }]);
                break;
            case 'delete':
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'You want be able to delete this!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log('delete click');
                        this.deleteRequestData(data.requestID);
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                    }
                });
                break;
            default:
        }
    }
    deleteRequestData(reqID) {
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: reqID
        };
        this.api.DeleteRequest(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                // this.getListRequest()
                this.router.navigate(['/organization/transport-manager/request/view-request']);
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }


}
