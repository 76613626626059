import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreatePrimaryAdminModel } from 'src/app/modules/organization/create-primary-admin.model';
import { StorageService } from 'src/app/Services/storage.service';
declare const popup: any, snackbar: any;

@Component({
  selector: 'app-create-primary-admin',
  templateUrl: './create-primary-admin.component.html',
  styleUrls: ['./create-primary-admin.component.css']
})
export class CreatePrimaryAdminComponent implements OnInit {

  @Input() userInfo: any;

  @Output() dataTransferToAlert: EventEmitter<any> = new EventEmitter();
  public createPrimaryAdminFields = CreatePrimaryAdminModel;
  constructor(public storage: StorageService) { }

  ngOnInit(): void {

    this.loadFormData();
  }

  loadFormData() {
    if (this.userInfo) {
      for (const info in this.createPrimaryAdminFields) {
        const temp = this.createPrimaryAdminFields[info];
        this.createPrimaryAdminFields[info].value = this.userInfo[temp.name];
      }
    }
  }
  submitted(e: any) {
    console.log(e.value);
    if (e.value.contactNumber && e.value.contactNumber.length == 10) {
       this.dataTransferToAlert.emit(e.value);
      // alert('bellow 10')
    } else {
      snackbar(`<span class="text-warning"> Need Mobile No 10 Digits</span>`);
      // alert('Need Mobile No 10 Digits ')
      // this.dataTransferToAlert.emit(e)
    }
  }
}
