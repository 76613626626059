import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { FormControl } from '@angular/forms';
import { SocketIOService } from 'src/app/Services/socket.io.service';
import { OrgVehicleReducer } from './../../../../store/orgVehicle/reducer';
import { VehicleLiveTrackService } from './../../../../store/vehicle/reducer';
import { SharedService } from './../../../../Services/shared.service';
import { MapService } from 'src/app/Services/map.service';
import { CommonServices } from 'src/app/Services/common.service';
import { element } from 'protractor';
import { Subject } from 'rxjs';
import  flatpickr from "flatpickr";
import { S3BudgetService } from 'src/app/Services/s3Budget.service';
import { RouteData } from './routedata';

@Component({
  selector: 'g10-transportManager-dashboardMenu',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('dateTimeCheck') toPicker: ElementRef;
  isShowMaps: boolean = false
  isVehicleDataHide: boolean = false;
  constructor(
    public storage: StorageService,
    public api: ApiService,
    public router: Router,
    private socket: SocketIOService,
    private orgVehReducer: OrgVehicleReducer,
    private socketStore: VehicleLiveTrackService,
    private share: SharedService,
    private map: MapService,
    private comService: CommonServices,
    private s3: S3BudgetService
  ) {
    this.User_type = this.storage.get('userType');
    console.log('Dashboard', this.User_type);
   
    this.share._subject.subscribe((data: any) => {
      // console.log('dashboard ', data);
      if (data.type == 'socketData') {
        this.filterData(this.selectedType);
        // console.log('socket -- ', data.data);
      }
    });

    setTimeout(()=>{
      this.socket.getMessages();
    },300)
  }
  routeInfoAll={
    vechileCnt:40,
    pickup:25,
    drop:15,
    assigned:35,
    running:30,
    employe:600,
    male:350,
    female:250,
    borded:60,
    deboarded:50,
    nopresent:10,
    sosCnt:20,
    activeCompliants:40,
    overdueCompliants:30,
    routeTime:'12:30',
    routePoint:'velachery',
    pickCnt:60
  }
public routeList= RouteData

  isShowTableSearch = false;
  isHideTab = false;
  public searchInput: any = new Subject<string>();
  searchedValue: any = '';
  selectedVehicle: any;
  selecte = 'vehicleposition' ;
  public tpmDashboardMenu = [{
    label: 'Dashboard',
    icon: 'dashboard',
    path: '/organization/transport-manager/dashboard',
  },
  {
    label: 'SOS Panel',
    icon: 'error',
    path: '/organization/transport-manager/sospanel',
  },
  {
    label: 'Request',
    icon: 'compare_arrows',
    path: '/organization/transport-manager/request-component',
  },
  {
    label: 'Rostering ',
    icon: 'call_split',
    path: '/organization/transport-manager/rostering ',
  },
  {
    label: 'Reports',
    icon: 'report_problem',
    path: '/organization/transport-manager/reports',
  },
  {
    label: 'Information',
    icon: 'info',
    path: '/organization/transport-manager/information',
  },
  // {
  //   label:'List View',
  //   icon:'list_alt',
  //   path:'/organization/transport-manager/listview',
  // },
  {
    label: 'Support Desk',
    icon: 'contact_support',
    path: '/organization/transport-manager/support-desk',
  },
  ];
  public dot;
  public myMap: any = this.map.retMap();
  public mousePos = 0;
  public currentTab = 'Dashboard';
  public currentPath = '/organization/transport-manager/dashboard';
  public allVehicleStatusInfo = [];


  selected = new FormControl(0);
  public vehicleStatusTab: any = [
    { lable: 'All', class: 'tablinks active', param: 'all' },
    { lable: 'Moving', class: 'tablinks', param: 'moving' },
    { lable: 'Halt', class: 'tablinks', param: 'halt' },
    { lable: 'Late Update', class: 'tablinks', param: 'lateUpdate' }
  ];
  public pieChartLabels: Label[] = [['All'], ['Denied'], 'Accept'];
  public pieChartData: number[] = [300, 200, 100];
  User_type: any;
  userData: any;
  VendorInfos: any;
  public showFiller = false;
  public navIcon = 'navigate_next';
  selectedType: any;
  routeInfoSearchedValue:string =''
  singleViewVehicle: any;
  // -------------------------
  public allVehiCnt: any = 0; movingCnt: any = 0; haltCnt = 0; lastCnt = 0;
  // ------------------------------------

  // ---------------------
  public allVehicle: any = {
    cnt: {
      all: 0,
      moment: 0,
      halt: 0,
      lastUpdate: 0
    },
    info: {
      all: [],
      moment: [],
      halt: [],
      lastUpdate: []
    }
  };
 
ngOnInit(): void {
    if (this.User_type != 'transport_manager') {
      this.router.navigate(['/organization/transport-manager/login']);
    }
    const orgInfo = this.storage.get('userInfo');
    this.getVehicleDetails(orgInfo.orgID);

    this.currentPath = this.router.url;
    this.defaultCircle(this.tpmDashboardMenu, this.currentPath);

    // setTimeout(()=>{
    this.loadTabContant('all');
    // },300);
    // this.getS3()
    
  }
async getS3(){
  console.log('GET S3 Budget')
  let res =   await this.s3.s3GetFile({
    "Bucket": "dzrjjsqnpdynylcuvsgahznfioxxkjuj-emps.g10.pw/org-assets/vehicles/60866279d774d4408635033b/60cae868ef11c5a004e910b6/testimageload/testimageload",
    "Key": "rajatest"
   });

   console.log('RES S3 ', res);
}
  selectRoute(val){
    console.log(val)
    this.routeInfoAll = val
  }
  dashbordMenuChange(val) {
    console.log(val)
    if (val == 'routeinformation') this.isVehicleDataHide = true
    if (val == 'vehicleposition') this.isVehicleDataHide = false

  }
  loadRouteInfo(val) {
    console.log(val)
  }
  currentPage(tabIndex: number) {
    this.currentTab = this.tpmDashboardMenu[tabIndex].label;
    this.currentPath = this.tpmDashboardMenu[tabIndex].path;
    this.getLable(tabIndex);
  }

  // Moving Dashboard Menu circleIcon
  changeCirclePos(value: number) {
    this.mousePos = value;
  }
  loadTabContant(type: string) {
    this.selectedType = type;
    this.allVehicleStatusInfo = [];
    if (type == 'all') {
      this.allVehicleStatusInfo = this.allVehicle.info.all;
    } else if (type == 'moving') {
      this.allVehicleStatusInfo = this.allVehicle.info.moment;
    } else if (type == 'halt') {
      this.allVehicleStatusInfo = this.allVehicle.info.halt;
    } else if ('lateUpdate') {
      this.allVehicleStatusInfo = this.allVehicle.info.lastUpdate;
    }
    // console.log(type);
    // this.filterData(type);
  }

  async filterData(type?) {
    // console.log('update ', type);
    this.allVehicleStatusInfo = [];
    this.allVehicle.info.all = [];
    this.allVehicle.cnt.all = 0;
    this.allVehicle.cnt.moment = 0;
    this.allVehicle.cnt.halt = 0;
    this.allVehicle.cnt.lastUpdate = 0;
    this.allVehicle.info.lastUpdate = [];
    this.allVehicle.info.halt = [];
    this.allVehicle.info.moment = [];
    const data = this.socketStore.get();
    this.allVehicle.info.all = data;
    this.allVehicle.cnt.all = data.length;
    data.filter(async (item: any) => {
      const currnentTMS = new Date().getTime();
      const timeDiff = this.comService.timeDiff(item.gpsts, currnentTMS);
      if (timeDiff.hh > 4) {
        this.allVehicle.info.lastUpdate.push(item);
        this.allVehicle.cnt.lastUpdate = this.allVehicle.info.lastUpdate.length;
      } else if (item.spd <= 5 && timeDiff.hh <= 4) {
        this.allVehicle.info.halt.push(item);
        this.allVehicle.cnt.halt = this.allVehicle.info.halt.length;
      } else if (item.spd > 5) {
        this.allVehicle.info.moment.push(item);
        this.allVehicle.cnt.moment = this.allVehicle.info.moment.length;
        // console.log(this.allVehicle);
      }

    });

    // console.log(this.allVehicle);
    if (type == 'all') {
      this.allVehicleStatusInfo = this.allVehicle.info.all;
    } else if (type == 'moving') {
      this.allVehicleStatusInfo = this.allVehicle.info.moment;
    } else if (type == 'halt') {
      this.allVehicleStatusInfo = this.allVehicle.info.halt;
    } else if ('lateUpdate') {
      this.allVehicleStatusInfo = this.allVehicle.info.lastUpdate;
    }
  }
  // Default Dashboard Menu circleIcon
  defaultCirclePos() {
    this.mousePos = this.dot;
  }
  defaultCircle(obj, value) {
    this.dot = Object.keys(obj).find(key => obj[key].path == value);
    this.currentTab = obj[this.dot].label;
    this.mousePos = this.dot;
  }
  getVehicleStatus(obj: any) {
    // console.log('geT DATa', this.socketStore.get());
    this.loadTabContant('all');
    this.filterData(this.selectedType);

  }
  getVehicleDetails(orgID: any) {
    const obj = { orgID };
    this.api.transportManagerVehicleList(obj).then((res: any) => {
      const orgVeh: any = {};
      orgVeh[orgID] = res.data;
      // console.log('RES DATA Vehicle -->>> ', res.data);
      this.orgVehReducer.addVehicleInfo(orgVeh);



      // console.log('this.orgVehReducer', this.orgVehReducer)
    });
  }

  getLable(t) {
    this.storage.setData({ tabValue: t });
    // console.log(t, this.selected)
  }
  sideNav() {
    this.showFiller = !this.showFiller;
    if (this.showFiller) {
      this.navIcon = 'navigate_before';
    } else {
      this.navIcon = 'navigate_next';
    }
  }
  checkSpeedColor(value) {
    const spd = Number(value);
    if (spd > 0) {
      if (spd > 59) { return 'fontcolor_blue'; } else { return 'fontcolor_green'; }
    } else { return 'fontcolor_red'; }
  }
  openNav(item) {
    this.selectedVehicle = '';
    this.singleViewVehicle = item;
    this.selectedVehicle = item;
    // console.log(item);
    document.getElementById('mySidenav').style.width = '350px';
  }
  selectedData(e){
    // console.log(e);
    this.isShowMaps=!this.isShowMaps;
    /* this.openNav(e); */
  } 
  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }
}
