import { Component, OnInit, Input } from '@angular/core';
import { Section } from '../dashboard/dashboard.component';
declare const mapUtilityMapBox;
@Component({
    selector: 'app-custom-vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.css']
})

export class VehicleComponent {
    value: any;
    values: any;
    @Input() _type: any;
    folders: Section[] = [
        {
            name: 'Photos',
            updated: new Date('1/1/16'),
        },
        {
            name: 'Recipes',
            updated: new Date('1/17/16'),
        },
        {
            name: 'Work',
            updated: new Date('1/28/16'),
        }
    ];
    notes: Section[] = [
        {
            name: 'Vacation Itinerary',
            updated: new Date('2/20/16'),
        },
        {
            name: 'Kitchen Remodel',
            updated: new Date('1/18/16'),
        }
    ];
    ngOnInit(): void {
        this.mapLoad();

    }
    mapLoad() {
        try {
            const mapBoxKey = 'pk.eyJ1Ijoib3dlbmxhbWIiLCJhIjoiY2lleWljcnF4MDBiOXQ0bHR0anRvamtucSJ9.t3YnHHqvQZ8Y0MTCNy0NNw';

            if (mapBoxKey) {

                // let x:any = document.getElementById('mapboxKeyInput');
                // x.value
                const MyMap = new mapUtilityMapBox();
                MyMap.setup('markerExampleMapBox', {
                    key: mapBoxKey
                }, function(map) {
                    map.addMarkers([
                        {
                            id: 'A',
                            content: '📌',
                            // content : '<div class="mumb_marker_car_red"></div>',

                            lat: 12.929310,
                            lng: 80.202917,
                            popup: {
                                content: 'Hello <b>A</b> Marker',
                                // open: true
                            }
                        },
                        {
                            id: 'B',   // Important & Mandatory for Marker
                            content: '<i class="material-icons md-24 red">place</i>',
                            // content : '&LT;div class="mumb_marker_car_red">&LT;/div>',
                            // We can write HTML inside content, Some ready made ICONS can be used by add icon class names
                            lat: 12.925310,
                            lng: 80.202917,
                            popup: { // if required, other wise dont include it
                                content: 'Hello B Marker',
                                // open : true
                            }
                        }
                    ]);

                    map.focus({
                        lat: 12.929310,
                        lng: 80.202917,
                        zoom: 13
                    });
                });
                console.log(MyMap);
            }
        } catch (error) {
            console.log(error);
        }
    }
}
