import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VehicleLiveTracking } from './interface';
// import { Store } from './store';
import { SharedService } from './../../Services/shared.service';

export class Store<T> {

  private state$: BehaviorSubject<VehicleLiveTracking[]>;
  protected constructor(initialState: VehicleLiveTracking[] = []) {
    this.state$ = new BehaviorSubject(initialState);
  }
  getValue(): VehicleLiveTracking[] {
    return this.state$.getValue();
  }
  getState(): Observable<VehicleLiveTracking[]> {
    return this.state$.asObservable();
  }
  setState(item: VehicleLiveTracking[]): void {
    this.state$.next(item);
  }
}

@Injectable({
  providedIn: 'root'
})

export class VehicleLiveTrackService extends Store<VehicleLiveTracking[]> {
  constructor(
    private share: SharedService
  ) {
    super();
  }
  addVehicleInfo(newItem: any): void {
    try {
      const data = this.getValue();
      const newData = (typeof newItem == 'string') ? JSON.parse(newItem) : newItem;
      if (data.filter(itm => itm.reg == newData.reg).length > 0) {
        const returnData: any = data.map((item: any) => {
          if (item.reg == newData.reg) {
            item.serverts = newData.serverts;
            item.near = newData.near;
            item.lat = newData.lat;
            item.lng = newData.lng;
            item.imei = newData.imei;
            item.spd = newData.spd;
            item.gpsts = newData.gpsts;
            item.ac = newData.ac;
          }
          return item;
        });
        // console.log('Next triggered ', returnData);
        this.share._subject.next({
          type: 'socketData',
          data: returnData
        });
        this.setState(returnData);
      } else {
        this.setState([...this.getValue(), newData]);
      }
    } catch (error) {
      console.log('Error', error);
    }

  }
  get(): VehicleLiveTracking[] {
    return this.getValue();
  }
  listen(): Observable<VehicleLiveTracking[]> {
    return this.getState();
  }


}
