import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ShiftModel } from 'src/app/modules/information/shift';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

@Component({
  selector: 'app-add-shift',
  templateUrl: './add-shift.component.html',
  styleUrls: ['./add-shift.component.css']
})
export class AddShiftComponent implements OnInit {
  public shiftData = ShiftModel;
  startDate: any;
  public branchInformation=[];
  public fieldOptions=[{
    branch:[],
  }];
  shiftForm: FormGroup;
  startTime: any;
  endTime: any;
  public exportTime = { hour: 0, minute: 0, meriden: 'PM', format: 12 , val: 'startData'};
  // private exportTime2 = { hour: 11, minute: 0, meriden: 'PM', format: 12 ,val:'endData'};
  constructor(public api: ApiService,
              public storage: StorageService,
              private formBuilder: FormBuilder,
              public router: Router,
              public swal: SweetAlert) { }

  ngOnInit(): void {
    this.shiftFormLoad();
    this.getBranchList();
  }
  // submitted(e: any) {
  //   console.log(e.value)
  //   if (e.valid) {
  //     let obj = {
  //       orgID: this.storage.get('userInfo').orgID,
  //       ...e.value
  //     }
  //     this.api.CreateShift(obj).then((res: any) => {
  //       if (res.status == 'success') {
  //         this.swal.swalFunction('success', res.status, res.message, 2000)
  //         this.router.navigate(['/organization/transport-manager/information/list-shift'])
  //       }
  //       if (res.status == 'failure') {
  //         this.swal.swalFunction('error', res.status, res.message, 2000)
  //       }
  //     }).catch((err) => {
  //       console.log(err)
  //     })
  //   }
  // }
 
getBranchList() {
  const obj = {
    orgID: this.storage.get('userInfo').orgID
  };
  this.api.transportManagerBranchList(obj).then((res: any) => {
    console.log(res);
    if (res.status == 'success') {
      this.branchInformation = res.data;
      this.addBranchOptions();
    }
  }).catch((err) => {
    console.log(err);
  });
}
addBranchOptions(){
  if(this.branchInformation && this.branchInformation.length>0){
    this.branchInformation.filter((e)=>{
      let option={name:e.branchName,value:e.branchID};
      this.fieldOptions[0].branch.push(option);
    });
  }
}
  shiftFormLoad() {
    this.shiftForm = this.formBuilder.group({
      shiftName: [],
      branch: [],
      // startTime: [''],
      // endTime: [''],
      status: []
    });
  }
  onSubmit() {
    console.log(this.shiftForm);

    console.log(this.shiftForm.value, this.startTime, this.endTime);
    const obj = {
      orgID: this.storage.get('userInfo').orgID,
      ...this.shiftForm.value,
      startTime: this.startTime,
      endTime: this.endTime
    };
    console.log(obj);
    this.api.CreateShift(obj).then((res: any) => {
      if (res.status == 'success') {
        console.log(res);
        this.swal.swalFunction('success', res.status, res.message, 2000);
        this.router.navigate(['/organization/transport-manager/information/list-shift']);
      }
      if (res.status == 'failure') {
        this.swal.swalFunction('error', res.status, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err);
    });

  }
  onChangeHour(event, val) {
    // console.log('event', event, val);
    if (val == 'start-time') { this.startTime = event; }
    // event.hour + ':' + event.minute + ':' + event.meriden;
    if (val == 'end-time') { this.endTime = event; }
    // .hour + ':' + event.minute + ':' + event.meriden;


  }
}
