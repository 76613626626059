import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SharedService } from 'src/app/Services/shared.service';
import { PROFILE, ProfileState } from 'src/app/State/profile.state';
import { ActivatedRoute, Router } from '@angular/router';
import { SocketIOService } from 'src/app/Services/socket.io.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  @Output() tiggleSideBar: EventEmitter<any> = new EventEmitter();
  token: string;
  userInfo: PROFILE = {
    contactNumber: 0,
    designation: '',
    name: '',
    orgID: '',
    primary: 0,
    status: 0,
    userEmail: '',
    userID: '',
  };
  getUrl: string;
  avatar: boolean;
  constructor(
    public api: ApiService,
    public storage: StorageService,
    public shared: SharedService,
    public profile: ProfileState,
    public socket: SocketIOService,
    public router: Router, private route: ActivatedRoute
  ) {
    this.token = this.storage.get('token');
    this.getUrl = this.router.url,
    this.userInfo =  this.storage.get('userData');
  }

  ngOnInit() {
    this.getUserData();
    // console.log(this.getUrl)
    // this.getCurUser()
    // this.getProfile()
  }

  toggleSideBar() {
    this.tiggleSideBar.emit('over');
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }
getUserData() {
  this.api.GetCurUserOrg({}).then((res: any) => {
if (res.status == 'success') {
  this.userInfo = res.data[0];
  this.storage.setData({userInfo: this.userInfo});
  this.socket.getMessages();
}
  }).catch((err) => {
    console.log(err);
  });
}
  getProfile() {
    console.log(this.profile.get);
    if (this.profile.get == null) {
      this.profile.listen.subscribe((value) => {
        this.userInfo = value;
        // this.userInfo = this.profile.get;
        console.log(this.userInfo);
      });
    } else {
      this.userInfo = this.profile.get;
    }
    console.log('profile state', this.userInfo);
  }
  signOut() {

    const urlPathArr = this.getUrl.split('/');
    if (urlPathArr && urlPathArr[1] === 'master') {
      this.router.navigate([`/${urlPathArr[1]}/login`]);
      localStorage.clear();
      this.storage.removeAll();
    }
    if (urlPathArr && urlPathArr[2] === 'vendor' || urlPathArr[2] === 'transport-manager' ) {
      this.router.navigate([`/organization/${urlPathArr[2]}/login`]);

      localStorage.clear();
      this.storage.removeAll();


    }


  }
  // getCurUser() {
  //   this.api.GetCurUserOrg({})
  //     .then((res: any) => {
  //       if (res.status == 'success') {
  //         this.userInfo = res.data[0]
  //         this.storage.setData({userInfo:this.userInfo})
  //         console.log("get curen user header",this.userInfo)
  //       }
  //     })
  //     .catch((err) => console.log(err))
  // }

}
