const { Component, Input } = require("@angular/core");

declare const ApexCharts: any;

@Component({
    selector: 'apex-radial-bar-chart',
    template: `
    <div id="{{elemId}}"></div>
    `
})

export class ApexRadialBarChart {
    @Input() labels = '';
    @Input() values = [];
    @Input() height = 350;
    public elemId = 'apex-radiabar-chart-' + Math.round(Math.random() * 10000);
    constructor() {

    }

    ngOnInit() {

        setTimeout(() => {
            this.loadChart();
        }, 1000);
    }

    loadChart() {


        const options = {
            series: this.values, //[70],
            chart: {
                height: this.height,//350,
                type: 'radialBar',
                animations: {
                    enabled: true,
                    easing: "linear",
                    dynamicAnimation: {
                        speed: 1000
                    }
                },
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: this.values + '%', //'70%',
                    }
                },
            },
            labels: this.labels//['Cricket'],
        };

        var chart = new ApexCharts(document.querySelector("#" + this.elemId), options);
        chart.render();
    }

}