
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { SharedService } from 'src/app/Services/shared.service';



@Component({
  selector: 'g10-ms-line-chart',
  templateUrl: './linechart.component.html',
  styleUrls: ['./linechart.component.css']
})
export class LineChartComponent {
  @Input() lineChartData: ChartDataSets[];
  @Input() lineChartLabels: Label[];
  // @Input() chartType: ChartType;


  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    plugins: {
      datalabels: {
        color: '#ffffff',
        font: {
          weight: 'bold',
          size: 0,
        }
      }

    },
    // scales: {
    //   // We use this empty structure as a placeholder for dynamic theming.
    //   xAxes: [{}],
    //   yAxes: [
    //     {
    //       id: 'y-axis-0',
    //       position: 'left',
    //     },
    //     {
    //       id: 'y-axis-1',
    //       position: 'right',
    //       gridLines: {
    //         color: 'rgba(201, 96, 189.3)',
    //       },
    //       ticks: {
    //         fontColor: 'red',
    //       }
    //     }
    //   ]
    // },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'vertical',
          scaleID: 'x-axis-0',
          value: 'March',
          borderColor: 'orange',
          borderWidth: 2,
          label: {
            enabled: true,
            fontColor: 'orange',
            content: 'LineAnno'
          }
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: 'rgb(73, 113, 208, 0.2)',
      borderColor: 'rgba(73, 113, 208,1.0)',
      pointBackgroundColor: 'rgba(73, 113, 208,1.0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(73, 113, 208,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(150, 106, 204,0.2)',
      borderColor: 'rgba(150, 106, 204,1.0)',
      pointBackgroundColor: 'rgba(150, 106, 204,1.0)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(150, 106, 204,1.0)'
    },
    { // red
      backgroundColor: 'rgba(201, 96, 189, 0.3)',
      borderColor: 'rgba(201, 96, 189,1)',
      pointBackgroundColor: 'rgba(201, 96, 189,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(201, 96, 189,0.8)'
    },
    { // red
      backgroundColor: 'rgba(239, 88, 167, 0.3)',
      borderColor: 'rgba(239, 88, 167,1)',
      pointBackgroundColor: 'rgba(239, 88, 167,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(239, 88, 167,0.8)'
    },
    { // red
      backgroundColor: 'rgba(254, 94, 138,0.3)',
      borderColor: 'rgba(254, 94, 138,1)',
      pointBackgroundColor: 'rgba(254, 94, 138,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(254, 94, 138,0.8)'
    },
    { // red
      backgroundColor: 'rgba(254, 116, 106,0.3)',
      borderColor: 'rgba(254, 116, 106,1)',
      pointBackgroundColor: 'rgba(254, 116, 106,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(254, 116, 106,0.8)'
    }, { // red
      backgroundColor: 'rgba(255, 144, 74,0.3)',
      borderColor: 'rgba(255, 144, 74,1)',
      pointBackgroundColor: 'rgba(255, 144, 74,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255, 144, 74,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor() { }

  ngOnInit(): void {

  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.length; i++) {
      for (let j = 0; j < this.lineChartData[i].data.length; j++) {
        this.lineChartData[i].data[j] = this.generateNumber(i);
      }
    }
    this.chart.update();
  }

  private generateNumber(i: number): number {
    return Math.floor((Math.random() * (i < 2 ? 100 : 1000)) + 1);
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public hideOne(): void {
    const isHidden = this.chart.isDatasetHidden(1);
    this.chart.hideDataset(1, !isHidden);
  }

  public pushOne(): void {
    this.lineChartData.forEach((x, i) => {
      const num = this.generateNumber(i);
      const data: number[] = x.data as number[];
      data.push(num);
    });
    this.lineChartLabels.push(`Label ${this.lineChartLabels.length}`);
  }

  public changeColor(): void {
    this.lineChartColors[2].borderColor = 'green';
    this.lineChartColors[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;
  }

  public changeLabel(): void {
    this.lineChartLabels[2] = ['1st Line', '2nd Line'];
  }


}
