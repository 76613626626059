import { AddRegularManualRosteringComponent } from "./add-regular-manual-rostering/add-regular-manual-rostering.component";
import { EditRegularManualRosteringComponent } from "./edit-regular-manual-rostering/edit-regular-manual-rostering.component";
import { SingleViewRegularManualRoastering } from "./view-regular-manual-rostering/single-view-regular-manual-roastering/single-view-regular-manual-roastering";
import { ViewRegularManualRosteringComponent } from "./view-regular-manual-rostering/view-regular-manual-rostering.component";

export const RegularManualRosteringRouting = [
    { path: '', redirectTo: 'view-route', pathMatch: 'full' },
    { path: 'add-route', component: AddRegularManualRosteringComponent },
    { path: 'edit-route', component: EditRegularManualRosteringComponent },
    { path: 'view-route', component: ViewRegularManualRosteringComponent },
    { path: 'single-view-route', component: SingleViewRegularManualRoastering }
];