import { Component } from '@angular/core';

@Component({
    selector: 'app-employee-billing',
    template: `<mat-toolbar class="mb-4">
    <mat-toolbar-row>
      <span  *ngIf="!isShowIcon">Employee Billings</span>
      <span *ngIf="isShowIcon"> Add Employee Bill</span>
      <span class="example-spacer"></span>
      <button mat-icon-button *ngIf="isShowIcon" (click)="isShowIcon = !isShowIcon" class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <g10-ms-icon class="mr-2 text-accent" [icon]="'ViewListTree'" [mat]="ture" title="View"></g10-ms-icon>
  </button>
  <button mat-icon-button *ngIf="!isShowIcon"  (click)="isShowIcon = !isShowIcon" class="example-icon favorite-icon"  aria-label="Example icon-button with heart icon">
            <g10-ms-icon class="mr-2 text-accent" [icon]="'Add'" [mat]="ture" title="Add"></g10-ms-icon>
        </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <app-addemployeebilling *ngIf="isShowIcon" ></app-addemployeebilling>
  <app-viewemployeebilling *ngIf="!isShowIcon" ></app-viewemployeebilling>
  `,
    styles: []
})
export class EmployeeBillingComponent {
    isShowIcon = false;
    constructor() {

    }

}
