import { OrganizationComponent } from './organization.component';
import { VendorComponent } from './transportmanger/information/vendor/vendor.component';
import { TransportmangerComponent } from './transportmanger/transportmanger.component';
import { TransportManggerRouting } from './transportmanger/transportmanger.routing';
import { VendorRouting } from './vendor/vendor.routing';

export const OrganizationRouting = [
    { path: 'organization-comp', component: OrganizationComponent },
    { path: 'transport-comp', component: TransportmangerComponent },
    { path: 'vendor-comp', component: VendorComponent },
    { path: '', redirectTo: 'transport-manager', pathMatch: 'full' },
    { path: 'transport-manager', children: TransportManggerRouting },
    { path: 'vendor', children: VendorRouting },
];

