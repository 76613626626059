import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-routes',
  templateUrl: './view-routes.component.html',
  styleUrls: ['./view-routes.component.css']
})
export class ViewRoutesComponent implements OnInit {
public availRoutes = [
  {type:'Regular',pickPoint:'3',vehCnt:23,title:'velachery',maleCn:33,femaleCnt:23,others:0,empCnt:53,status:0},
  {type:'Occasional',pickPoint:'4',vehCnt:23,title:'tambaram',maleCn:23,femaleCnt:23,others:1,empCnt:43,status:0},
  {type:'Regular',pickPoint:'3',vehCnt:23,title:'madipakkam',maleCn:13,femaleCnt:23,others:0,empCnt:63,status:0},
  {type:'Occasional',pickPoint:'3',vehCnt:23,title:'porur',maleCn:23,femaleCnt:23,others:0,empCnt:33,status:0},
  {type:'Occasional',pickPoint:'1',vehCnt:23,title:'guindy',maleCn:12,femaleCnt:23,others:0,empCnt:53,status:0},
  {type:'Occasional',pickPoint:'2',vehCnt:23,title:'pallavaram',maleCn:43,femaleCnt:23,others:0,empCnt:33,status:0},
  {type:'Regular',pickPoint:'3',vehCnt:23,title:'vadapalani',maleCn:37,femaleCnt:23,others:0,empCnt:23,status:0},

  {type:'Regular',pickPoint:'3',vehCnt:23,title:'velachery',maleCn:33,femaleCnt:23,others:1,empCnt:35,status:0},
  {type:'Occasional',pickPoint:'4',vehCnt:23,title:'tambaram',maleCn:23,femaleCnt:23,others:0,empCnt:23,status:0},
  {type:'Regular',pickPoint:'3',vehCnt:23,title:'madipakkam',maleCn:43,femaleCnt:23,others:0,empCnt:35,status:0},
  {type:'Occasional',pickPoint:'3',vehCnt:23,title:'porur',maleCn:43,femaleCnt:23,others:0,empCnt:47,status:0},
  {type:'Occasional',pickPoint:'1',vehCnt:23,title:'guindy',maleCn:43,femaleCnt:23,others:0,empCnt:23,status:0},
  {type:'Occasional',pickPoint:'2',vehCnt:23,title:'pallavaram',maleCn:43,femaleCnt:23,others:0,empCnt:43,status:0},
  {type:'Regular',pickPoint:'3',vehCnt:23,title:'vadapalani',maleCn:43,femaleCnt:23,others:0,empCnt:37,status:0},

]
  constructor() { }

  ngOnInit(): void {
  }

}
