import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
// import { LoaderComponent } from 'src/app/Directives/loader/loader.directive';
import { CommonServices } from 'src/app/Services/common.service';
import { VehicleLiveTrackService } from 'src/app/store/vehicle/reducer';

import { SharedService } from './../../../../Services/shared.service';
@Component({
  selector: 'app-listview',
  templateUrl: './listview.component.html',
  styleUrls: ['./listview.component.css']
})
export class ListviewsComponent implements OnInit {
  @Output() selectedData = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public searchInput: any = new Subject<string>();
  searchedValue: any = '';
  cusValu: any = "reg";
  selectedVehicle: any;
  mapData: any;
  constructor(
    private socketStore: VehicleLiveTrackService,
    private comService: CommonServices,
    private share: SharedService
  ) {
    this.share._subject.subscribe((data: any) => {
      if (data.type == 'socketData') {
        this.loadListView();
      }
    });
  }
  vehicelData = [
    { value: 'moving', viewValue: 'Moving' },
    { value: 'halt', viewValue: 'Halt' },
    { value: 'lateupdate', viewValue: 'Late update' },
  ];
  public dataSource: any = [];
  displayedColumns: string[] = [
    'srno',
    'vehicle',
    'status',
    'speed',
    // 'ignition',
    // 'Ac_status',
    'Temperature',
    'UpdatedOn',
    'map'
  ];
  ngOnInit(): void {
    // this.loader.s
    // this.dataSource.paginator = this.paginator;
    this.loadListView();
  }
  showSingleVehicle(item) {
    this.selectedVehicle = ''
    console.log(item)
    this.mapData = item
    this.selectedVehicle = item;
  }
  requstTypeValue(e) {
    console.log(e)
    this.cusValu = 'status'
  }
  srFun() {
    this.cusValu = 'reg'
  }
  sendSelectedData(item) {
    this.selectedData.emit(item);
  }
  loadListView() {
    this.dataSource = this.socketStore.get().filter((item: any) => {
      const currnentTMS = new Date().getTime();
      const timeDiff = this.comService.timeDiff(item.gpsts, currnentTMS);
      if (timeDiff.hh > 4) {
        item.status = 'Late Update';
      } else if (item.spd <= 5) {
        item.status = 'Halt';
      } else if (item.spd > 5) {
        item.status = 'Moving';
      }
      return item;
    });
  }
  // public dataDetails = [
  //   {
  //     vehicleName: 'ashok layland bus',
  //     vehicleNo: 'ABC-TN55X4001',
  //     location: '0.09km from Guindy,Chennai.',
  //     status: 'offline',
  //     speed: '0',
  //     ignition: false,
  //     Ac_status: false,
  //     Temperature: '-',
  //     UpdatedOn: '1621439529833',
  //     map: 'lattitude,longtitude'
  //   },
  //   {
  //     vehicleName: 'ashok layland bus',
  //     vehicleNo: 'BCD-TN55X4002',
  //     location: '0.09km from Velachery,Chennai.',
  //     status: 'last update',
  //     speed: '17',
  //     ignition: false,
  //     Ac_status: false,
  //     Temperature: '20',
  //     UpdatedOn: '1621439529833',
  //     map: 'lattitude,longtitude'
  //   },
  //   {
  //     vehicleName: 'ashok layland bus',
  //     vehicleNo: 'CDE-TN55X4003',
  //     location: '0.09km from Perungudi,Chennai.',
  //     status: 'halt',
  //     speed: '0',
  //     ignition: false,
  //     Ac_status: false,
  //     Temperature: '70',
  //     UpdatedOn: '1621439529833',
  //     map: 'lattitude,longtitude'
  //   },
  //   {
  //     vehicleName: 'ashok layland bus',
  //     vehicleNo: 'DEF-TN55X4004',
  //     location: '0.09km from Guindy,Chennai.',
  //     status: 'moving',
  //     speed: '45',
  //     ignition: false,
  //     Ac_status: false,
  //     Temperature: '34',
  //     UpdatedOn: '1621439529833',
  //     map: 'lattitude,longtitude'
  //   },
  //   {
  //     vehicleName: 'ashok layland bus',
  //     vehicleNo: 'EFG-TN55X4005',
  //     location: '0.09km from Guindy,Chennai.',
  //     status: 'overspeed',
  //     speed: '60',
  //     ignition: true,
  //     Ac_status: true,
  //     Temperature: '70',
  //     UpdatedOn: '1621439529833',
  //     map: 'lattitude,longtitude'
  //   },
  //   {
  //     vehicleName: 'ashok layland bus',
  //     vehicleNo: 'FGH-TN55X4006',
  //     location: '0.09km from Anna Nagar,Chennai.',
  //     status: 'offline',
  //     speed: '0',
  //     ignition: false,
  //     Ac_status: false,
  //     Temperature: '70',
  //     UpdatedOn: '1621439529833',
  //     map: 'lattitude,longtitude'
  //   }
  // ];

  // public dataSource = new MatTableDataSource(this.dataDetails);

  applyFilter(value: string) {
    this.dataSource.filter = value.trim().toLowerCase();
  }
  checkStatusColor(value) {
    switch (value) {
      case 'offline':
        return 'color_gray';
      case 'overspeed':
        return 'color_blue';
      case 'halt':
        return 'color_red';
      case 'moving':
        return 'color_green';
      case 'last update':
        return 'color_yellow';
    }
  }
  checkSpeedColor(value) {
    const spd = Number(value);
    if (spd > 0) {
      if (spd > 59) { return 'fontcolor_blue'; } else { return 'fontcolor_green'; }
    } else { return 'fontcolor_red'; }
  }
  // checkSpeedColor(value, tStatus) {
  //   var spd = Number(value);
  //   if (tStatus != 'offline') {
  //     if (spd > 0) {
  //       if (spd > 59) return 'fontcolor_blue';
  //       else return 'fontcolor_green';
  //     } else return 'fontcolor_red';
  //   } else return 'fontcolor_black';
  // }

}
