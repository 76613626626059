import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { VendorModule } from '../../../../../../modules/information/vendor';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
declare const Swal;
@Component({
  selector: 'app-view-vendor',
  templateUrl: './view-vendor.component.html',
  styleUrls: ['./view-vendor.component.css']
})
export class ViewVendorComponent implements OnInit {
  constructor(public api: ApiService,
              public storage: StorageService,
              public dialogService: DialogActionService,
              public dialog: MatDialog,
              public location: Location,
              public router: Router,
              public swal: SweetAlert) {
    this.userType = this.storage.get('userType');
    this.userData = this.storage.get('userInfo');
    // this.sysToken = this.storage.get('token')
  }

  public VendorInfo: any = VendorModule;
  sysToken: any;
  userType: any;
  public VendorInfos: any ;
  vendorId: any;
  userData: any;
  orgId: any = {};
  isSearchShow: boolean;
  public editForm: boolean;
  public cols = [
    { key: '#', display: '#' },
    { key: 'vendorName', display: 'Vendor Name' },
    { key: 'email', display: 'Email' },
    // { key: "city", display: "City" },
    // { key: "state", display: "State" },
    { key: 'mobile', display: 'Mobile.No' },
    {
      key: 'action',
      display: 'Action',
      config: {
        isAction: true,
        actions: [
          { display: 'view', color: 'primary', icon: 'View' },
          { display: 'delete', color: 'warn', icon: 'Delete' },
          { display: 'update', color: 'primary', icon: 'EditContact' }
        ]
      }
    }
  ];
  ngOnInit(): void {
    if (this.userType != 'transport_manager') {
      this.router.navigate(['/organization/transport-manager/login']);
    }

    this.getVenderData();
  }
  fiterData() {
    // console.log(this.orgName, this.city, this.status)
    // let obj = {
    //   orgName: this.orgName,
    //   city: this.city,
    //   status: this.status
    // }
    // this.api.listOrg({}).then((res:any) => {
    //   // console.log(res)
    //   if(res.status == 'success'){
    //   this.swal.swalFunction(res.status,res.status,res.message,2000)
    //   }
    //   if(res.status == 'failure'){
    //     this.swal.swalFunction('error',res.status,res.message,2000)
    //   }
    // }).catch((err) => {
    //   console.log(err)
    // })
  }

  showSearch() {
    this.isSearchShow = !this.isSearchShow;
  }
  onActionHandler(e: any) {
    console.log(e);
  }
  vendorAction(e, item, event) {
    // event.stopPrevent()
    // console.log(e,item)
    if (e == 'view') {
      this.storage.setData({ vendorID_orgID: item });
      this.router.navigate(['/organization/transport-manager/information/vendor/single-view-vendor', { orgID: item.orgID, vendorID: item.vendorID }]);
    }

    if (e == 'edit') {
      this.router.navigate(['/organization/transport-manager/information/vendor/edit-view-vendor', { orgID: item.orgID, vendorID: item.vendorID }]);

    } else if (e == 'delete') {

      console.log(e, item);
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want be able to delete this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('delete click');
          this.deleteVendeData(item);
          // Swal.fire(
          //   'Deleted!',
          //   'Your file has been deleted.',
          //   'success'
          // )
        }
      });
      // ('error', 'Warning', 'Unable to Fetch the User Details', 2000);
    }
  }

  deleteVendeData(val?) {

    const obj = {
      orgID: this.userData.orgID,
      vendorID: val.vendorID
    };
    console.log(obj);
    this.api.transportManagerVendorDelete(obj).catch((res) => {
      if (res.status == 'success') {
        this.getVenderData();
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
        this.getVenderData();

      } else {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  getPage(e, p) {
    console.log(e, p);
  }

  submitForm(e: any) {

    if (e == 0) { this.editForm = false; } else {
      console.log(e);
    }
  }
  getVenderData() {
    console.log(this.userData);
    const obj = {
      orgID: this.userData.orgID
    };
    this.api.transportManagerVendorList(obj).then((res: any) => {
      console.log(res);
      this.VendorInfos = res.data;
      console.log(this.VendorInfo);
    }).catch((err) => {
      console.log(err);
    });
  }
  //   vendorActions(path, item, event) {
  //     console.log(path, item)
  //     if (path == 'vehicle') {
  //       this.storage.setData({ vehicleData: item })
  //       this.router.navigate(['/organization/transport-manager/information/vehicle/view-vehicle'])
  //     }
  //     if (path == 'driver') {
  //       this.storage.setData({ driverData: item })
  //       this.router.navigate(['/organization/transport-manager/information/driver/view-driver'])

  //     }
  //     if (path == 'employee') {
  //       this.storage.setData({ employeeData: item })
  //       this.router.navigate(['/organization/transport-manager/information/employee/view-employee'])

  //     }
  //   }

  searchValue(e) {
    console.log(e);
  }
}



