import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { OrgVehicleReducer } from 'src/app/store/orgVehicle/reducer';
import { RouteService } from 'src/app/Services/route.service';

import { filter } from 'src/assets/fontawesome/js/v4-shims';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

declare const Swal;

@Component({
  selector: 'app-roastering',
  templateUrl: './roastering.component.html',
  styleUrls: ['./roastering.component.css']
})
export class RoasteringComponent implements OnInit {
  /*components*/
  public listPageShow: boolean = true;
  public addPageShow: boolean = false;
  public viewPageShow: boolean = false;
  public editPageShow: boolean = false;
  public singleView: boolean = false;
  /*storage Info*/
  public orgID = '';
  public userData;
  public employeeID = '';
  
  public singleViewData;
  public selectedRouteID='';
  public shiftwiseData = [];
  public shiftwiseRoute = [];

  public selectedRoute;
  constructor(
    public vehicleDetail: OrgVehicleReducer,
    public api: ApiService,
    public route: ActivatedRoute,
    public storage: StorageService,
    public routeGenerate: RouteService,
    public swal: SweetAlert,
  ) {
    this.userData = this.storage.get('userInfo')
    this.route.paramMap.subscribe(paramas => {
      this.orgID = paramas.get('orgID')
      this.employeeID = paramas.get('employeeID')
    })
  }
  ngOnInit() {
  }
  currentPage(e: any) {
    if (e == 'viewRoutePage') {
      this.addPageShow = false;
      this.editPageShow = false;
      this.singleView = false;
      this.listPageShow = true;
    }
    else if(e=='singleViewPage'){
      this.addPageShow=false;
      this.editPageShow=false;
      this.listPageShow=false;
      this.singleView=true;
    }
    else if(e=='editPage'){
      this.singleView=false;
      this.addPageShow=false;
      this.listPageShow=false;
      this.editPageShow=true;
    }
    else if(e=='add'){
      this.listPageShow = false;
      this.viewPageShow = false;
      this.addPageShow = true;
      this.editPageShow = false;
      this.singleView = false;
    }
  }

  searchValue(e: any) {
    if (e == 'click') {
     this.currentPage('add');
    }
    if(e=='clickView'){
     this.currentPage('viewRoutePage');
    }
  }
  singleViewRouteID(e){
    this.selectedRouteID=e;
  }
  editRouteObj(e){
    console.log(e)
  }
}