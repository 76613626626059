import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { MapService } from 'src/app/Services/map.service';
import { RouteService } from 'src/app/Services/route.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { OrgVehicleReducer } from './../../../../../../../store/orgVehicle/reducer';

@Component({
  selector: 'app-edit-regular-manual-rostering',
  templateUrl: './edit-regular-manual-rostering.component.html',
  styleUrls: ['./edit-regular-manual-rostering.component.css']
})
export class EditRegularManualRosteringComponent implements OnInit {

  @ViewChild('search', {static: true}) searchTextBox: ElementRef;

    selectFormControl = new FormControl();
    searchTextboxControl = new FormControl();
    selectedValues = [];
    data: any = [];

  filteredOptions: Observable<any[]>;

  public routeForm:FormGroup;
  public pickupPointForm:FormGroup;
  public userData;

  public routeObj=[];
  public employeeObj=[];
  public shiftObj=[];
  public vehicleObj=[];
  public branchInformation=[];

  public startTime = { hour: 0, minute: 0, meriden: 'AM', format: 12, val: 'startData' };
  public etaTime = { hour: 0, minute: 0, meriden: 'AM', format: 12, val: 'startData' };
  public endTime = { hour: 0, minute: 0, meriden: 'AM', format: 12, val: 'startData' };
  public modifiedStartTime;

  public selectedShift;
  public shiftwiseRoute;
  public shiftwiseEmployee;
  public selectedEmployee=[];
  public selectedEmpCount;
  /* Employee Table Columns*/

  public empCols = [
    { key: "select", display: "" },
    { key: "empRefID", display: "Employee Id" },
    { key: "name", display: "Name" },
    { key: "location", display: "Area" },
    //{ key: "action", display: "Action" },
  ];
  /* Table view */
  
  public tableView:boolean=false;
  public tableObj=[];
  public tableCols=[];
  public tableDataType='';

  /* map variables*/
  public pickupLocation:any;
  public latlong:any;
  public selectedValueClose = false;
  public locationSuggestions: any;
  public myMap: any = this.map.retMap();
  public searchInput: any = new Subject<string>();
  public destroy$: any = new Subject();
  
  /*PickupPoint*/
  public pickupPointObj=[{
    ETA: {hour: "6", minute: "30", meriden: "AM", format: 12},
    employees: [ {
      "orgID": "60866279d774d4408635033b",
      "empRefID": "Test2",
      "name": "Nithya",
      "dob": 1622764800000,
      "gender": "Female",
      "mobile": "9876543210",
      "email": "test2@gmail.com",
      "emergencyContact": "9876542234",
      "bloodGroup": "A",
      "address": "Guindy",
      "city": "chennai",
      "state": "Tamil Nadu",
      "country": "India",
      "pincode": "600042",
      "location": {
          "lat": 13.017106,
          "lng": 80.258827,
          "name": "Sanabel Biriyani Point, Chennai, Tamil Nadu, India"
      },
      "workLocation": "chennai",
      "shift": "60e68181d6518e6a115a2155",
      "transportType": "company",
      "profile": "/home/devuser/EMPS/API/emps-images/emp_Test2.png",
      "employeeID": "60e690f731fa011c99263d43",
      "locationName": "Sanabel Biriyani Point, Chennai, Tamil Nadu, India"
  },
  {
      "orgID": "60866279d774d4408635033b",
      "empRefID": "Test2",
      "name": "kannan",
      "dob": 1622764800000,
      "gender": "Male",
      "mobile": "9876543210",
      "email": "test2@gmail.com",
      "emergencyContact": "9876542234",
      "bloodGroup": "A",
      "address": "Guindy",
      "city": "chennai",
      "state": "Tamil Nadu",
      "country": "India",
      "pincode": "600042",
      "location": {
          "lat": 13.015914,
          "lng": 80.258709,
          "name": "Sanabel Biriyani Point, Chennai, Tamil Nadu, India"
      },
      "workLocation": "chennai",
      "shift": "60e68181d6518e6a115a2155",
      "transportType": "company",
      "profile": "/home/devuser/EMPS/API/emps-images/emp_Test2.png",
      "employeeID": "60e6869531fa011c9926358c",
      "locationName": "Sanabel Biriyani Point, Chennai, Tamil Nadu, India"
  }],
    location: {center: Array(2), name: "Axis Bank ATM, 9th Street, Abirami Nagar, Chennai, Tamil Nadu, India"},
    pickupPointAttendance: "Wifi",
    pickupPointRadius: 200,
    pickupPointStatus: "true",
    title: "PP1",
    vehicles: [ {
      "orgID": "60866279d774d4408635033b",
      "vendorID": "60cae868ef11c5a004e910b6",
      "vehRegNo": "TN01AA0009",
      "imei": "2452345234523423",
      "make": "maruti",
      "model": "hatchback",
      "chassisNo": "kjdf987fdasdf",
      "engineNo": "en39847712",
      "purchasedDate": 1625097600000,
      "routePermitCost": "500",
      "startDate": 1625097600000,
      "expiryDate": 1627689600000,
      "roadTaxStatus": true,
      "rcNo": "RC1234",
      "rcStartDate": 1625097600000,
      "rcExpiryDate": 1627689600000,
      "vehicleInfo": "car",
      "rcfile": "C:\\fakepath\\profilePng.png",
      "capacity": "3",
      "vehicleType": "Owned",
      "boxType": "groupten",
      "location": {
          "lat": 13.059152,
          "lng": 80.203238,
          "name": "Raghavendra Swami Mutt, Ramar Street, Chennai, Tamil Nadu, India"
      },
      "createdBy": null,
      "status": 1,
      "createdAt": 1625828428666,
      "simNo": "7896134918",
      "vehicleID": "60e82c4c7ea33095b67daa6f"
  }],
  }
  ];
  //table
  public PickupPointCols = [
    { key: "#", display: "#" },
    { key: "title", display: "Pickup Point Title" },
    { key: "location", display: "Area" },
    { key: "ETA", display: "ETA" },
    { key:"vehicles",display:"#Vehicles"},
    { key:"employees",display:"#Employees"},
    { key:"pickupPointAttendance",display:"Attendance"},
    { key:"pickupPointStatus",display:"Status"},
    { key: "action", display: "Action" },
  ];
  public pickupPointDataSource=new MatTableDataSource(this.pickupPointObj);;
   /* get keys*/
   get keys() {
    return this.PickupPointCols.map(({ key }) => key);
  }
  constructor(
    public api: ApiService,
    public swal: SweetAlert,
    private fb: FormBuilder,
    public storage: StorageService,
    public routeGenerate: RouteService,
    private map: MapService,
    private vehicleReducer:OrgVehicleReducer,
    ) {
    this.userData = this.storage.get('userInfo',)

   }
   public fieldOptions = [{
    routeType: ['pickup', 'drop'],
    routeShift: [],
    notification:['push','sms','email','ivr calls'],
    branch: [],
    pickupPointAttendance:['OTP','Beacon','Wifi','RFID'],
    vehicles:[],
  }];

  ngOnInit(): void {
    this.createRouteForm();
    this.createPickupPoint();
    this.getBranchList();
    this.getVehicleList();
    this.getListShift();
    this.getEmployeeData();
    this.getRouteData();

    let vehicleList=this.vehicleReducer.get();
    console.log('vehicle',vehicleList);
    /* Set filter event based on value changes */
    this.filteredOptions = this.searchTextboxControl.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this._filter(name))
    );
    
    /* Map */
    this.loadMap();
    this.myMap.mymap.onMapClick((e: any) => {
      this.locationSuggestions = [];
      this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
    });

    this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
      if (value.val.target.value != '') {
        const val = { key: 'pickupLocation', val: value.val.target.value };
        this.getGeoCode(val);
        console.log(val);
      } else if (value.val.target.value.trim() == '') {
        this.emptySuggestions();
      }
      console.log(value.val.target.value);
      console.log(value.val);
    });
  }
  /**
     * Used to filter data based on search input
     */
   /* private _filter(name: string): String[] {
     const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state
    this.setSelectedValues();
    this.routeForm.controls.vehicles.patchValue(this.selectedValues);
    const filteredList = this.data.filter(option => 
      {
        console.log('option',option,'filtervalue',filterValue);
        option['vehRegNo'].toLowerCase().indexOf(filterValue) === 0
      });
        
    console.log('filteredList',filteredList);
    return filteredList;
  } */
  private _filter(name: string): String[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    const filteredList = this.data.filter(option => option.vehRegNo.toLowerCase().indexOf(filterValue) === 0);
    return filteredList;
  }

/**
 * Remove from selected values based on uncheck
 */
  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      const index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state
   */
  /* setSelectedValues() {
    console.log('selected route values', this.routeForm.value.vehicles);
    if (this.routeForm.value.vehicles && this.routeForm.value.vehicles.length > 0) {
      this.routeForm.value.vehicles.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  } */
  setSelectedValues() {
    console.log('selectFormControl', this.selectFormControl.value);
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }
  /* Map functions*/
  loadMap(marker: any = []) {
    this.map.loadMap('pickuppoint', marker);
  }
  getGeoCode(value) {
    const key = value.key;
    if (key == 'pickupLocation') {
      this.locationSuggestions[key] = [];
    }
    this.myMap.mymap.geocoder(value.val, (res) => {
      try {
        const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
        console.log('Loading GEO code ', key);
        if (key == 'mapclick') {
          this.locationSuggestions = {};
          //this.address = result[0];
          // this.vehicleloc = this.address.name;
          //this.vehicleLocation = this.address;
           this.selectedValueClose = true;
          this.pickupLocation=result[0];
          console.log(result, result[0].name);

        } else {
          this.locationSuggestions[key] = result;
          console.log('Else Part', this.locationSuggestions);
        }
      } catch (er) { console.log(er); }
    });
  }
  selectedValue(value) {
    this.selectedValueClose = true;
    this.locationSuggestions = {};
    this.pickupLocation = value;
  }
  emptySuggestions() {
    this.locationSuggestions = {};
    this.selectedValueClose = false;
    this.pickupLocation = null;
  }
  close() {
    this.latlong = null;
    if (this.pickupLocation != null && this.pickupLocation.name) {
      this.pickupPointForm.patchValue({
        location: this.pickupLocation
      });
      this.latlong=this.pickupLocation.name;
    } else {
      this.pickupPointForm.patchValue({
        location: undefined,
      });
      this.latlong = null;
    }
    console.log(this.pickupPointForm.value);
    //this.pickupPointTable();
  }
  pickupPointTable(){
    console.log('pickupPointTable');
    if(this.pickupPointForm.valid){
      let obj=this.pickupPointForm.value;
      console.log(this.pickupPointForm.value);
      this.pickupPointObj.push(obj);
      this.pickupPointDataSource = new MatTableDataSource(this.pickupPointObj);
    }
  }
  createRouteForm(){
    this.routeForm=this.fb.group({
      routeName:['',[Validators.required,Validators.pattern('[A-Z|a-z|0-9]{0,30}')]],
      branch:['',[Validators.required]],
      routeShift:['',[Validators.required]],
      routeTime:['',[Validators.required]],
      routeType:['',[Validators.required]],
      attendance:['',[Validators.required]],
      routeStatus:['',[Validators.required]],
      notification:['',[Validators.required]],
      vehicles:['',Validators.required],
      ivrCalls:['',Validators.required],
      pickupPoints:['',Validators.required],
    });
  }
  createPickupPoint(){
    this.pickupPointForm=this.fb.group({
      title:['',[Validators.required,Validators.pattern('[A-Z|a-z|0-9]{0,40}')]],
      location:['',[Validators.required]],
      ETA:['',[Validators.required]],
      pickupPointRadius:['',Validators.required,Validators.pattern("^[0-9]*$")],
      pickupPointStatus:['',[Validators.required]],
      vehicles:[''],/* ,Validators.required], */
      employees:[''],/* [Validators.required]], */
      pickupPointAttendance:['',[Validators.required]],
    });
  }
  /* API */
  getBranchList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.branchInformation = res.data;
        this.addBranchOptions();
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  addBranchOptions(){
    if(this.branchInformation && this.branchInformation.length>0){
      this.branchInformation.filter((e)=>{
        let option={name:e.branchName,value:e.branchID};
        this.fieldOptions[0].branch.push(option);
      });
    }
  }

  getRouteData() {
    let obj = {
      orgID: this.userData.orgID,
    };
    this.api.transportMangerRouteList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.routeObj=res.data;
        this.getCabTable();
      } else {
        console.log('else', res);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  getEmployeeData() {
    let obj = {
      orgID: this.userData.orgID,
    }
    this.api.transportManagerEmployeeList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.employeeObj = res.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getListShift() {
    let obj = {
      orgID: this.userData.orgID
    }
    this.api.ListShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.shiftObj = res.data;
        this.getBranchOptions();
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getVehicleList(){
    let obj = {
      orgID: this.userData.orgID,
    }
    this.api.transportManagerVehicleList(obj).then((res:any)=>{
      if (res.status == 'success') {
      this.vehicleObj=res.data;
      this.data=res.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }  
  getBranchOptions() {
    let getBranch = [];
    this.shiftObj.filter((e) => {
      if (getBranch.indexOf(e.branch) == -1) {
        getBranch.push(e.branch);
      }
    });
    this.fieldOptions[0].branch = getBranch;
  }
  /* Field Change */
  fieldChange(field,type,e:any){
    let value='';
    if (field == 'employees') {
      this.getEmployeeTable();
    }
    if (type != 'dropdown') {
      if (field != 'employeeInfo' && field != 'cabInfo') {
        value = e.target.value;
      }
      if (field == 'employeeInfo' || field == 'cabInfo') {
        value = e;
      }
    }
    else if(type=='dropdown'){
      value=e.value;
      if(field=='branch'&&e.value!=undefined){
        /* clear table data*/
        let filteredShift=this.shiftObj.filter((item)=>item.branch==e.value);
        let filteredShiftData=[];
        filteredShift.filter((item)=>{
          filteredShiftData.push(
          {shiftName:item.shiftName,
          shiftID:item.shiftID,
          }
          )});
        this.fieldOptions[0].routeShift=filteredShiftData;
      }
      if(field=='routeShift'){
        if(this.routeForm.controls.branch.errors==null){
          if(e.value!=undefined){
            this.selectedShift=e.value;
            let filteredShift=this.shiftObj.filter((item)=>item.shiftID==e.value);
            this.startTime=filteredShift[0].startTime;
            let changedHour;
            let changedMeriden = this.startTime.meriden;
            let assignedTime = Number(this.startTime.hour);

            if (assignedTime == 12) {
              changedHour = 11;
              if (this.startTime.meriden == 'AM') {
                changedMeriden = 'PM';
              }
              else if (this.startTime.meriden == 'PM') {
                changedMeriden = 'AM';
              }
            }
            else if (assignedTime == 1) {
              changedHour = 12;
            }
            else {
              changedHour = assignedTime - 1;
            }
            let changedTime = { hour: changedHour, minute: this.startTime.minute, meriden: changedMeriden, format: 12, val: 'startData' };
            this.startTime = changedTime;
            this.onChangeHour(this.startTime, 'start-time');
          }
        }
        else if (this.routeForm.controls.branch.errors != null) {
          this.routeForm.controls.branch.markAsTouched();
        }
      }
    }
  }
  /* filter cabs */
  getCabTable() {
    let filteredCabsList = [];
    this.routeObj.filter((item) => {
      if (item.cabInfo) {
        item.cabInfo.filter((cab) => {
          if (filteredCabsList.indexOf(cab) == -1) {
            filteredCabsList.push(cab);
          }
        })
      }
    })
   /*  this.data=filteredCabsList;
    console.log('options',this.data); */
    this.fieldOptions[0].vehicles= filteredCabsList;
    let finalObj=this.vehicleObj;
      filteredCabsList.filter((item)=>{
        this.vehicleObj.filter((element,index)=>{
          if(item.vehicleID==element.vehicleID){
            finalObj.splice(index,1);
          }
        })
      })
      //this.fieldOptions[0].vehicles= finalObj;
      console.log(finalObj);
      console.log(this.routeObj);
    }
  /* Get Employee Table*/
  getEmployeeTable(){
    this.tableView=false;
    let companyTransport=[];
    console.log('employee obj',this.employeeObj);
    this.shiftwiseEmployee = this.employeeObj.filter((item) => item.shift == this.selectedShift);
    companyTransport = this.employeeObj.filter((item) => item.shift == this.selectedShift && item.transportType == 'company');
    this.shiftwiseRoute = [];
    this.routeObj.filter((route) => {
      if (route.routeShift == this.selectedShift) {
        if (route.employeeInfo) {
          route.employeeInfo.filter((singleEmp) => {
            if (this.shiftwiseRoute.indexOf(singleEmp) == -1) {
              this.shiftwiseRoute.push(singleEmp);
            }
          })
        }
      }
    });
    console.log('shiftwise employee',this.shiftwiseEmployee);
    console.log('shiftwise route employee',this.shiftwiseRoute);
      let final=this.shiftwiseEmployee;
      this.shiftwiseRoute.filter((item)=>{
        this.shiftwiseEmployee.filter((element,index)=>{
          if(item.employeeID==element.employeeID){
            final.splice(index,1);
          }
        })
      })
      this.tableObj = final;
      this.tableCols = this.empCols;
      //this.checkTableError(this.tableDataType,'employeeTable');
      this.tableDataType = 'employeeTable';
      this.tableView=true;
  }
  /* Selected Table Data */
  selectedTableData(e) {
    console.log(e);
    console.log(e.data.length);
    if(e.type=='employeeTable'){
      this.routeForm.controls.employeeInfo.patchValue(e.data);
      this.selectedEmployee=e.data;
      this.selectedEmpCount=e.data.length;
    /*   if(e.data.length==0){
        this.employeeBtnClicked=true;
      } */
      this.fieldChange('employeeInfo','text',e.data);
    }
  }
  isNumber(o): boolean {
    return ! isNaN (o - 0) && o !== null && o !== '' && o !== false;
  }
  onChangeHour(event, val) {
    if (val == 'start-time') 
    { 
      this.startTime = event;
      this.modifiedStartTime= event.hour + ':' + event.minute + ' ' + event.meriden;
      if(Number(event.minute)<10){
        event.minute=event.minute.substring(1);
      }
      this.startTime = event;
    }
    else if(val=='eta'){
      this.pickupPointForm.controls['ETA'].patchValue('');
      this.etaTime=event;
      this.pickupPointForm.controls['ETA'].patchValue(event);
    }
  }
}
