import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
declare const Swal;

@Component({
    selector: 'app-custom-report',
    templateUrl: './single-custom-request.html',
    styles: [`.imgSet{
        background-image: url("https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/upwk58854696-wikimedia-image.jpg?w=1300&dpr=1&fit=default&crop=default&q=80&vib=3&con=3&usm=15&bg=F4F4F3&ixlib=js-2.2.1&s=edc8c9eaea7958ed402548b4e03a7e7a");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 89%;
        z-index: 2;
    }
    ul {
        list-style-type: none;
    }`]
})
//
// <i class="fas fa-edit icons text-primary" (click)="requestAction()></i>
//    <i class="far fa-trash-alt icons text-danger" (click)="requestAction()></i>
export class SingleCustomRequrstComponent {
    requestID: string;
    custRequestData: any;
    constructor(public api: ApiService,
                public router: Router,
                public route: ActivatedRoute,
                public storage: StorageService,
                public swal: SweetAlert
    ) {

        this.route.paramMap.subscribe(paramas => {
            this.requestID = paramas.get('cusmID');
        });
    }

    ngOnInit() {
        this.getCustListRequest();
    }
    // requestAction(){

    // }

    getCustListRequest() {
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: this.requestID
        };
        this.api.ListCustomRequest(obj).then((res: any) => {
            console.log(res);
            this.custRequestData = res.data[0];
            // console.log(this.convertStampDate(this.custRequestData[0].startDate))

        }).catch((err) => {
            console.log(err);
        });
    }
    requestAction(data, val) {
        console.log(data, val);
        switch (val) {
            // case 'view':
            //     console.log(data.requestID, val)
            //     if (customValue == 'custom_request') { this.router.navigate['/organization/transport-manager/request/single-custom-requrst-view'] }
            //     else { this.router.navigate(['/organization/transport-manager/request/single-view-request', { id: data.requestID }]) }
            //     break;
            case 'edit':
                this.router.navigate(['/organization/transport-manager/request/edit-request', { custID: data.requestID }]);
                break;
            case 'delete':
                Swal.fire({
                    title: 'Are you sure?',
                    text: 'You want be able to delete this!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log('delete click');
                        this.deleteRequestData(data.requestID);
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        );
                    }
                });
                break;
            default:
        }
    }
    deleteRequestData(reqID) {
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: reqID
        };
        this.api.DeleteCustomRequest(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                this.router.navigate(['/organization/transport-manager/request-component']);
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    upDateStatus(data) {
        console.log(data);
        if (data.status == '0') { data.status = '1'; } else if (data.status == '1') { data.status = '0'; }
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: data.requestID,
            status: parseInt(data.status)
        };
        this.api.UpdateCustomRequest(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                this.router.navigate(['/organization/transport-manager/request/view-request']);
            }
            if (res == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    convertStampDate(unixtimestamp) {


        // Months array
        const months_arr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        // Convert timestamp to milliseconds
        const date = new Date(unixtimestamp * 1000);

        // Year
        const year = date.getFullYear();

        // Month
        const month = months_arr[date.getMonth()];

        // Day
        const day = date.getDate();

        // Hours
        const hours = date.getHours();

        // Minutes
        const minutes = '0' + date.getMinutes();

        // Seconds
        const seconds = '0' + date.getSeconds();

        // Display date time in MM-dd-yyyy h:m:s format
        const fulldate = month + ' ' + day + '-' + year + ' ' + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

        // final date
        const convdataTime = month + ' ' + day;
        return convdataTime;
    }
}
