import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../Directives/snackbar/snackbar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  snackBarAutoHide = '3000';

  constructor(private snackBar: MatSnackBar) { }

  //   openSnackBar1(props) {
  //     this.snackBarConfig = new MatSnackBarConfig();
  //     this.snackBarConfig.horizontalPosition = props.horizontalPosition || this.horizontalPosition;
  //     this.snackBarConfig.verticalPosition = props.verticalPosition || this.verticalPosition;
  //     this.snackBarConfig.duration = parseInt(props.duration || this.snackBarAutoHide , 0);
  //     this.snackBarConfig.panelClass = 'custom-snackbar';
  //     this.snackBarRef = this.snackBar.open(props.message, 'Do it', this.snackBarConfig);
  // }

  openSnackBar(props) {
    this.snackBarConfig = new MatSnackBarConfig();
    this.snackBarConfig.horizontalPosition = props.horizontalPosition || this.horizontalPosition;
    this.snackBarConfig.verticalPosition = props.verticalPosition || this.verticalPosition;
    this.snackBarConfig.duration = parseInt(props.duration || this.snackBarAutoHide, 0);
    this.snackBarConfig.panelClass = 'custom-snackbar';

    this.snackBar.openFromComponent(SnackbarComponent, {
      data: {
        html: props.message
      },
      // data: props.message,
      panelClass: 'custom-snackbar',
      duration: props.duration || 30000,
      horizontalPosition: props.horizontalPosition || this.horizontalPosition,
      verticalPosition: props.verticalPosition || this.verticalPosition,
      //   ...this.snackBarConfig
    });
  }
}
