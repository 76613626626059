import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { MapService } from 'src/app/Services/map.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.css']
})
export class AddBranchComponent implements OnInit {
  public addBranchForm:FormGroup;
  public branchLocation:any;
  public latlong:any;
  public selectedValueClose = false;
  public locationSuggestions: any;
  public myMap: any = this.map.retMap();
  public searchInput: any = new Subject<string>();
  public destroy$: any = new Subject();
  constructor(
    public api: ApiService,
    public swal: SweetAlert,
    public storage: StorageService,
    public router: Router,
    private map: MapService,) { 
  }

  ngOnInit(): void {
    this.addBranchLoad();
    this.loadMap();
    this.myMap.mymap.onMapClick((e: any) => {
      this.locationSuggestions = [];
      this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
    });

    this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
      if (value.val.target.value != '') {
        const val = { key: 'branchLocation', val: value.val.target.value };
        this.getGeoCode(val);
        console.log(val);
      } else if (value.val.target.value.trim() == '') {
        this.emptySuggestions();
      }
      console.log(value.val.target.value);
      console.log(value.val);
    });
  }
  addBranchLoad(){
    this.addBranchForm = new FormGroup({
      branchName: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
    });
  }
  openLocation() {
    document.getElementById('branchLocationBtn').click();
  }
  loadMap(marker: any = []) {
    this.map.loadMap('pickuppoint', marker);
  }
  getGeoCode(value) {
    const key = value.key;
    if (key == 'branchLocation') {
      this.locationSuggestions[key] = [];
    }
    this.myMap.mymap.geocoder(value.val, (res) => {
      try {
        const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
        console.log('Loading GEO code ', key);
        if (key == 'mapclick') {
          this.locationSuggestions = {};
          //this.address = result[0];
          // this.vehicleloc = this.address.name;
          //this.vehicleLocation = this.address;
           this.selectedValueClose = true;
          this.branchLocation=result[0];
          console.log(result, result[0].name);

        } else {
          this.locationSuggestions[key] = result;
          console.log('Else Part', this.locationSuggestions);
        }
      } catch (er) { console.log(er); }
    });
  }
  selectedValue(value) {
    this.selectedValueClose = true;
    this.locationSuggestions = {};
    this.branchLocation = value;
  }
  emptySuggestions() {
    this.locationSuggestions = {};
    this.selectedValueClose = false;
    this.branchLocation = null;
  }
  close() {
    this.latlong = null;
    if (this.branchLocation != null && this.branchLocation.name) {
      this.addBranchForm.patchValue({
        location: this.branchLocation.name
      });
      this.latlong=this.branchLocation.name;
    } else {
      this.addBranchForm.patchValue({
        location: undefined,
      });
      this.latlong = null;
    }
  }
  /* checking error in fields*/
  public checkError = (controlName: string, errorName: string) => {
    return this.addBranchForm.controls[controlName].hasError(errorName);
  }

  /* Cancel Edit*/
  cancel(){
    this.router.navigate(['/organization/transport-manager/information/branch/view-branch']);
  }

  /* submit data*/
  onSubmit(){
    const obj = {
      orgID: this.storage.get('userInfo').orgID,
      ...this.addBranchForm.value
    };
    delete obj.location;
    obj.location = {
      lat: this.branchLocation.center[1],
      lng: this.branchLocation.center[0],
      name: this.branchLocation.name,
    };
    console.log(obj);
    this.api.transportManagerBranchCreate(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
        this.router.navigate(['/organization/transport-manager/information/branch/view-branch'])
      }
      if (res.status == 'failure') {
        this.swal.swalFunction('error', res.status, res.message, 3000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
