import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'field-builder',
  template: `
  <form class="form-group " [formGroup]="form">
    <div [ngSwitch]="field.type" >
      <textbox *ngSwitchCase="'text'" [field]="field" [form]="form"></textbox>
      <text_Area *ngSwitchCase="'textarea'" [field]="field" [form]="form"></text_Area>
      <dropdown *ngSwitchCase="'dropdown'" [field]="field" [form]="form" [__edit]="_edit"></dropdown>
      <dropdownNew *ngSwitchCase="'dropdownNew'" [field]="field" [form]="form"></dropdownNew>
      <password *ngSwitchCase="'password'" [field]="field" [form]="form"></password>
      <number *ngSwitchCase="'number'" [field]="field" [form]="form"></number>
      <radio *ngSwitchCase="'radio'" [field]="field" [form]="form"></radio>
      <checkbox *ngSwitchCase="'checkbox'" [field]="field" [form]="form"></checkbox>
      <date *ngSwitchCase="'date'" [field]="field" [form]="form"></date>
      <file *ngSwitchCase="'file'" [field]="field" [form]="form"></file>
      <forms-button *ngSwitchCase="'button'"></forms-button>
     <div class="mt-n1 text-danger fadeInDown animated">
           <p  *ngIf="!formSubmit && !isValid && isDirty " class="p-0 m-0">{{field.label}} is required</p>
           <p  *ngIf="formSubmit && !isValid && f[field.name].errors" class="p-0 m-0">  {{field.label}} is required</p>
           </div>
     </div>
  </form>
  `
})
export class FieldBuilderComponent implements OnInit {
  @Input() field: any;
  @Input() form: any;
  @Input() _edit: any;
  @Input() formSubmit: boolean;

  get isValid() {
    return this.form.controls[this.field.name].valid;
  }
  get isDirty() {
    return this.form.controls[this.field.name].dirty;
  }

  get f() { return this.form.controls; }

  constructor() { }

  ngOnInit() {

  }
  ngOnChanges() {
    // console.log(this.form.controls)

  }
}


// d-none d-md-block d-sm-none d-xs-none d-xl-block
