export const FeedBackModel: any = [
    {
        type: 'text',
        name: 'title',
        label: 'Title',
        value: '',
        col: 'col-md-4',
        required: true,
        // multiline: true
      },
      {
        type: 'text',
        name: 'description',
        label: 'Description',
        value: '',
        col: 'col-md-4',
        required: true,
        multiline: true
      },
      {
        type: 'dropdown',
        name: 'question',
        label: 'Question',
        value: '',
        required: true,
        options: [
          { value: 'check-box', name: 'Check-box' },
          { value: 'radio', name: 'Radio' },
          { value: 'objective', name: 'Objective' },
          { value: 'rating', name: 'Rating' }
        ]
      },
      {
        type: 'date',
        name: 'publishOn',
        label: 'Publish-on',
        value: '',
        required: true,
      },
      {
        type: 'date',
        name: 'validity',
        label: 'Validity',
        value: '',
        required: true,
      },
      {
        type: 'dropdown',
        name: 'feedbackType',
        label: 'Feedback-Type',
        value: '',
        required: true,
        options: [
          { value: 'check-box', name: 'Check-box' },
          { value: 'radio', name: 'Radio' },
          { value: 'objective', name: 'Objective' },
          { value: 'rating', name: 'Rating' }
        ]
      },
];
