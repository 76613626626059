import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'title-bar',
  template: `
    <div class="row p-0 m-0 filterStickey" >
    <mat-toolbar color="#6264a794" style="background-color: #6264a794;" class="mat-typography">
    <div class="col">
    <h4 style="color:white;" class="ml-n2 ">{{titleName}}</h4>
    </div>
    <div class="col" *ngIf="serchBar">
    <div fxFlex></div>
    <app-search [(ngModel)]="hi" (searchClick)="reciveData($event)">
    </app-search></div>
    <button  *ngIf="buttonName && buttonType =='add'" mat-raised-button
     class="mr-2" routerLink="{{routeLink}}" >
     <i class="fas fa-plus mr-1"></i>
      {{buttonName}}
    </button>
    <button  *ngIf="buttonName && buttonType =='view'" mat-raised-button
    class="mr-2" routerLink="{{routeLink}}" >

     <i class="fas fa-list mr-1"></i> {{buttonName}}
   </button>
    <button *ngIf="buttonType=='click' && buttonName" mat-raised-button
     class="mr-2" (click)="emit()"><i class="fas fa-plus mr-1"></i> <i class="fas fa-list mr-1"
      *ngIf="buttonType =='view'"></i> {{buttonName}}</button>

    <button mat-raised-button color="secondary" style="margin-right: 10px;" goBack matTooltip="Go Back" *ngIf="!goBackButton"> <i class="fas fa-arrow-left"></i> Go
    Back</button>
    <button *ngIf="buttonType=='clickView' && buttonName" mat-raised-button
     class="mr-2" (click)="emit()">
     <i class="fas fa-list mr-1"
      *ngIf="buttonType =='clickView'"></i>
       {{buttonName}}</button>
  </mat-toolbar>
    </div>
  `,
  styles: [`.mat-toolbar {
    min-height: 50px !important;
    height: 50px !important;
    padding:0 6px !important;
  }

  .mat-toolbar-row {
    min-height: 50px !important;
    height: 50px !important;
  }
  .filterStickey{
    position: sticky;
    top: 0;
    z-index: 999;
    background: #e6e6e6;


}`]
})

export class TitleBarComponent {
  @Output() serchFinalData: EventEmitter<any> = new EventEmitter<any>();
  @Input() serchBar: boolean;
  @Input() goBackButton: boolean;
  @Input() titleName: string;
  @Input() buttonName: string;
  @Input() routeLink: string;
  @Input() buttonType: string;
  @Input() clickto: any;
  valueFromSearch: any;
  constructor(
  ) {

  }

  ngOnInit() {
    // console.log(this.buttonName, this.routeLink, this.buttonType)

  }
  emit() {
    this.serchFinalData.emit(this.buttonType);
  }
  reciveData(e) {
    console.log(e);
    this.valueFromSearch = e;
    console.log(this.serchFinalData.emit(this.valueFromSearch));
    this.serchFinalData.emit(this.valueFromSearch);
  }
}
//  <button mat-raised-button color="accent" class="mr-2"><i class="fas fa-list mr-1"></i> {{buttonName}}</button>
