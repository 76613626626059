import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
  submitted(e) {
    console.log(e);
  }
}
