import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';


@Component({
  selector: 'app-transportmanger',
  templateUrl: './transportmanger.component.html',
  styleUrls: ['./transportmanger.component.css']
})
export class TransportmangerComponent implements OnInit {
  userType: any;

  constructor(public api: ApiService,
              public storage: StorageService,
              public router: Router) {
    this.userType = this.storage.get('transport-manager');
  }

  ngOnInit(): void {
    console.log('transport-mamanger manin componert');
    if (this.userType != 'transport-manager') {
      this.router.navigate(['/organization/transport-manager/login']);
    }
  }

}
