import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { RouteService } from 'src/app/Services/route.service';
import { SharedService } from 'src/app/Services/shared.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

@Component({
  selector: 'g10-edit-rostering',
  templateUrl: './edit-rostering.component.html',
  styleUrls: ['./edit-rostering.component.css']
})
export class EditRoasteringComponent implements OnInit {
  public selectedRoute;
  @Input() routeID;
  @Output() currentPage = new EventEmitter();

  public shiftObj;
  public employeeObj;
  public routeObj;
  public vehicleObj;
  tableForm:FormGroup;
  moreInfo:FormGroup;

  public routeForm=this.fb.group({
    routeName:['',[Validators.required,Validators.pattern('[A-Z|a-z|0-9]{0,30}')]],
    branch:['',[Validators.required]],
    routeShift:['',[Validators.required]],
    routeType:['',[Validators.required]],
    cabInfo:['',[Validators.required]],
    employeeInfo:['',[Validators.required]], 
  });

  public fieldOptions=[{
    routeType: ['temporary', 'regular'],
    routeShift: [],
    branch: [],
  }];
  
  public tableObj=[];
  public tableCols=[];
  public tableDataType='';
  public empCols = [
    { key: "select", display: "" },
    { key: "empRefID", display: "Employee Id" },
    { key: "name", display: "Name" },
    { key: "location", display: "Area" },
    //{ key: "action", display: "Action" },
  ];
  public cabCols = [
    { key: "select", display: "" },
    { key: 'vehRegNo', display: "Cab Reg " },
    {key:"vehicleType",display:"Cab Type"},
    { key: 'model', display: "Model" },
    { key: 'capacity', display: "Seat Capacity " },
    //{ key: "action", display: "Action" },
  ];

  public userData;

  public startTime={ hour: 0, minute: 0, meriden: 'AM', format: 12 ,val:'startData'};
  public endTime={ hour: 0, minute: 0, meriden: 'AM', format: 12 ,val:'startData'};
  public modifiedStartTime;
  public modifiedEndTime;
  public minStartDate:Date=new Date(Date.now());
  public minEndDate:Date;

  public selectedShift;
  public shiftwiseRoute;
  public shiftwiseEmployee;
  public employeeBtnClicked:boolean=false;
  public cabBtnClicked:boolean=false;

  public selectedEmployee=[];
  public selectedCabs=[];
  public selectedEmpCount;
  public selectedCabCount;
  public tableView:boolean=false;
  public moreInfoView:boolean=false;
  public vehCumulativeView: any;
  public finalRouteObj=[];
  public formShiftValue;
  public initialShift;

  public updateValues=[];
  public branchInformation=[];

  constructor(
    public api:ApiService,
    public swal: SweetAlert,
    private fb:FormBuilder,
    private loader:LoaderService,
    public storage: StorageService,
    public routeGenerate: RouteService,
    private datePipe: DatePipe,){ 
    this.userData = this.storage.get('userInfo') 
  }
  ngOnInit(){
    this.loader.show();
    this.getBranchList();
    this.getListShift();
    this.tableForm=this.fb.group({
      tableInfo:this.fb.group({
        cabsInfo:['',[Validators.required]],
        employeeInfo:['',[Validators.required]],
      }),
     
    });
    this.moreInfo=this.fb.group({
      startDate:['',[Validators.required]],
      endDate:['',[Validators.required]],
    })
  }

  /* API's*/

  getBranchList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.branchInformation = res.data;
        this.addBranchOptions();
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  addBranchOptions(){
    if(this.branchInformation && this.branchInformation.length>0){
      this.branchInformation.filter((e)=>{
        let option={name:e.branchName,value:e.branchID};
        this.fieldOptions[0].branch.push(option);
      });
    }
    console.log(this.fieldOptions[0].branch);
  }
  getRouteData() {
    this.loader.show();
    let obj = {
      orgID: this.userData.orgID,
    };
    this.api.transportMangerRouteList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.routeObj=res.data;
        let routes=res.data;
        this.selectedRoute=routes.filter((e)=>e.routeID==this.routeID);
        this.routeValues();
        this.loader.hide();
      } else {
        console.log('else', res);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  getEmployeeData() {
    this.loader.show();
    let obj = {
      orgID: this.userData.orgID,
    }
    this.api.transportManagerEmployeeList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.employeeObj = res.data;
        this.getVehicleList();
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getListShift() {
    this.loader.show();
    let obj = {
      orgID: this.userData.orgID
    }
    this.api.ListShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.shiftObj = res.data;
        this.getEmployeeData();
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getVehicleList(){
    this.loader.show();
    let obj = {
      orgID: this.userData.orgID,
    }
    this.api.transportManagerVehicleList(obj).then((res:any)=>{
      if (res.status == 'success') {
      
      this.vehicleObj=res.data;
      this.getRouteData();
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  /* set form Values*/
  routeValues(){
    let valKeys=Object.keys(this.routeForm.value);
    valKeys.filter((e)=>{
      if(e=='branch'){
        this.getShiftData(this.selectedRoute[0][e]);
      }
       if(e=='routeShift'){
        //  this.fieldOptions[0]['routeShift'].filter((item,i)=>{
        //    if(item.shiftID===this.selectedRoute[0][e].shiftID){
        //      this.initialShift=this.fieldOptions[0]['routeShift'][i];
        //      console.log(this.initialShift);
        //    }
        //  })
        //this.formShiftValue=this.selectedRoute[0][e];
        this.selectedShift=this.selectedRoute[0][e];
        this.initialShift=e;
        this.routeForm.controls[e].patchValue(this.selectedRoute[0][e])
      }
      if(e=='employeeInfo'){
        if(this.selectedRoute[0][e]!=undefined){
            this.selectedEmployee=this.selectedRoute[0][e];
            this.selectedEmpCount=this.selectedEmployee.length;
            this.getEmployeeTable();
            this.checkTableError('cabsTable','employeeTable');
        }
      }
      if(e=='cabInfo'){
        if(this.selectedRoute[0][e]!=undefined){
            this.selectedCabs=this.selectedRoute[0][e];
            this.selectedCabCount=this.selectedCabs.length;
            this.getCabTable();
            this.checkTableError('employeeTable','cabsTable');
          }
        }
      this.routeForm.controls[e].patchValue(this.selectedRoute[0][e]);
    })
    if(this.selectedRoute[0]['startTime']){
      this.startTime=this.selectedRoute[0]['startTime'];
      if(Number(this.startTime.minute)<10){
        let val='0'+String(this.startTime.minute);
        this.startTime.minute=Number(val);
      }
    }
    this.finalRouteList();
  }
  
  getShiftData(branch){
    let filteredShift=this.shiftObj.filter((item)=>item.branch==branch);
    let filteredShiftData=[];
    filteredShift.filter((item)=>{
      filteredShiftData.push(
      {shiftName:item.shiftName,
      shiftID:item.shiftID,
      }
      )});
    this.fieldOptions[0].routeShift=filteredShiftData;
  }
  
  // / Get Employee data from selected shift and omit already assigned route employees /
  getEmployeeTable(){
    this.tableView=false;
    let companyTransport=[];
    this.shiftwiseEmployee = this.employeeObj.filter((item) => item.shift == this.selectedShift);
    companyTransport=this.employeeObj.filter((item) => item.shift == this.selectedShift && item.transportType=='company');
    this.shiftwiseRoute = [];
    this.routeObj.filter((route) => {
      if (route.routeShift == this.selectedShift) {
        if (route.employeeInfo) {
          route.employeeInfo.filter((singleEmp) => {
            if (this.shiftwiseRoute.indexOf(singleEmp) == -1) {
              this.shiftwiseRoute.push(singleEmp);
            }
          })
        }
      }
    });
    let final=this.shiftwiseEmployee;
    this.shiftwiseRoute.filter((item)=>{
      this.shiftwiseEmployee.filter((element,index)=>{
        if(item.employeeID==element.employeeID){
          final.splice(index,1);
        }
      })
    })
    let selectedEmpList=[]
    this.selectedEmployee.filter((emp)=>{
      let finded=true;
      final.filter((item)=>{
      if(item.employeeID==emp.employeeID){
        finded=false;
      }
    })
    if(finded){
      selectedEmpList.push(emp);
    }
  })
  this.tableObj = [
    ...selectedEmpList,
    ...final,
  ];
    this.tableCols = this.empCols;
    this.checkTableError(this.tableDataType,'employeeTable');
    this.tableDataType = 'employeeTable';
    this.moreInfoView=false;
    this.tableView=true;
    }
    /* Table data error message checking*/
    checkTableError(previousTableType,currentTableType){
      console.log(previousTableType);
      console.log(currentTableType);
      if(previousTableType!=''){
        if(previousTableType!=currentTableType){
          if(previousTableType=='employeeTable'){
            if(this.selectedEmployee.length==0){
              this.employeeBtnClicked=true;
            }
          }
          if(previousTableType=='cabsTable'){
            console.log(this.selectedCabs.length);
            if(this.selectedCabs.length==0){
              this.cabBtnClicked=true;
            }
          }
        }
      }
    }
    /* get Cab data for table and getting non assigned vehicles*/
    getCabTable(){ 
      this.tableView=false;
      let filteredCabsList=[];
      this.routeObj.filter((item)=>{
      if(item.cabInfo){
        item.cabInfo.filter((cab)=>{
          if(filteredCabsList.indexOf(cab)==-1){
            filteredCabsList.push(cab);
          }
        })
        }
      })
      let finalObj=this.vehicleObj;
        filteredCabsList.filter((item)=>{
          this.vehicleObj.filter((element,index)=>{
            if(item.vehicleID==element.vehicleID){
              finalObj.splice(index,1);
            }
          })
        })
        let selectedCabList=[]
        this.selectedCabs.filter((cab)=>{
          let finded=true;
          finalObj.filter((item)=>{
          if(item.vehicleID==cab.vehicleID){
            finded=false;
          }
        })
        if(finded){
          selectedCabList.push(cab);
        }
      })
      this.tableCols = this.cabCols;
      this.checkTableError(this.tableDataType,'cabsTable');
      this.tableDataType = 'cabsTable';
      this.tableObj = [
        ...selectedCabList,
        ...finalObj,
      ];
      this.moreInfoView=false;
      this.tableView=true;
    }
    // / field changes  /
  fieldChange(field,type,e:any){
    this.vehCumulativeView=[];
    let value='';
    if(field=='moreInfoStartDate'){
      let startTime=new Date(this.tableForm.value.moreInfo['startDate']);
      let oneDay= 60*60*24*1000;
      this.minEndDate=new Date(startTime.getTime()+oneDay);
    };
    if(type=='tableBtn'){
      if(this.routeForm.controls.routeShift.errors==null && this.routeForm.controls.branch.errors==null){
        if(field=='employees'){
          this.getEmployeeTable();
        }
        else if(field=='cabs'){
          this.getCabTable();
        }
      }
      if(this.routeForm.controls.branch.errors==null){
        if(this.routeForm.controls.routeShift.errors!=null){
          this.routeForm.controls.routeShift.markAsTouched();
        }
      }
      else if(this.routeForm.controls.branch.errors!=null){
        this.routeForm.controls.branch.markAsTouched();
      }
    }
    if(type!='dropdown'){
      if(field!='employeeInfo'&&field!='cabInfo'){
        value=e.target.value;
      }
      if(field=='employeeInfo'||field=='cabInfo'){
          value=e;
      }
    }
    else if(type=='dropdown'){
      value=e.value;
      if(field=='branch'&&e.value!=undefined){
        this.tableView=false;
        /* clear selected emp and cabs*/
        this.clearTableData();
        /*----*/
        let filteredShift=this.shiftObj.filter((item)=>item.branch==e.value);
        let filteredShiftData=[];
        filteredShift.filter((item)=>{
          filteredShiftData.push(
          {shiftName:item.shiftName,
          shiftID:item.shiftID,
          shiftTime:item.startTime,
          }
          )});
        this.fieldOptions[0].routeShift=filteredShiftData;
      }
      if(field=='routeShift'){
        this.tableView=false;
        if(this.routeForm.controls.branch.errors==null){
          if(e.value!=undefined){
            this.clearTableData();
            this.selectedShift=e.value;
            let filteredShift=this.shiftObj.filter((item)=>item.shiftID==e.value);
            this.startTime=filteredShift[0].startTime;
            let changedHour;
            let changedMeriden=this.startTime.meriden;
            let assignedTime=Number(this.startTime.hour);
            
            if(assignedTime==12){
            changedHour=11;
            if(this.startTime.meriden=='AM'){
              changedMeriden='PM';  
            }
            else if(this.startTime.meriden=='PM'){
              changedMeriden='AM';
            }
            }
            else if(assignedTime==1){
              changedHour=12;
            }
            else{
              changedHour=assignedTime-1;
            }
            let changedTime={ hour: changedHour, minute: this.startTime.minute, meriden: changedMeriden, format: 12 ,val:'startData'};
            this.startTime=changedTime;
            this.onChangeHour(this.startTime,'start-time');
          }
        }
        else if(this.routeForm.controls.branch.errors!=null){
          this.routeForm.controls.branch.markAsTouched();
        }
      }
    }
    if(this.routeID){
      if(field=='employeeInfo'||field=='cabInfo'){
        if(e && e.length>0){
          this.filterUpdate(field,value);
          //this.updateRoute(field,value);
        }
      }
      else if(field!='employees' && field!='cabs'){
        this.filterUpdate(field,value);
        //this.updateRoute(field,value);
      }
    }
  }
  /* update uniques values*/
  filterUpdate(field,value){
    if(this.updateValues.length>0){
      let filterUpdate=this.updateValues.filter((e)=>e.field!=field);
      console.log('remove previous field',filterUpdate);
      let obj={field:field,value:value,};
      console.log('adding field',obj);
      this.updateValues=[
        ...filterUpdate,
        obj,
      ]
      console.log('final update obj',this.updateValues);
      this.updateRoute();
    }
    else{
      let obj={field:field,value:value,};
      this.updateValues.push(obj);
      console.log('else part',this.updateValues);
       this.updateRoute();
    }
  }
  /* clear table data*/
  clearTableData(){
    this.selectedCabCount=undefined;
    this.selectedCabs=[];
    this.selectedEmployee=[];
    this.selectedEmpCount=undefined;
    this.selectedShift=undefined;
    this.tableObj=[];
    this.tableCols=[];
    this.tableDataType='';
    this.startTime={ hour: 0, minute: 0, meriden: 'AM', format: 12 ,val:'startData'};
  }
  /*time picker changes */
  onChangeHour(event, val) {
    if (val == 'start-time') 
    {
      this.startTime = event;
      //this.updateRoute('startTime',this.startTime);
    this.modifiedStartTime= event.hour + ':' + event.minute + ' ' + event.meriden;
    if(Number(event.minute)<10){
      event.minute=event.minute.substring(1);
    }
    }
    if (val == 'end-time') this.endTime = event
    this.modifiedEndTime=event.hour + ':' + event.minute + ' ' + event.meriden;
  }
  // / update route /
  updateRoute(){
    let obj={
      orgID:this.userData.orgID,
      routeID:this.routeID,
    };
    this.updateValues.filter((e)=>{
      obj[e.field]=e.value;
    })
    console.log(obj);
    //obj[name]=e;
    this.api.transportMangerRouteUpdate(obj).then((res: any) => {
      if (res.status == 'success') {
        console.log('updated');
      } else {
        this.swal.swalFunction('error', res.status, res.message, 2000)
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  // / Getting selected Table data /
  selectedTableData(e){
    console.log(e);
    if(e.type=='employeeTable'){
      this.routeForm.controls.employeeInfo.patchValue(e.data);
      this.selectedEmployee=e.data;
      this.selectedEmpCount=e.data.length;
      if(e.data.length==0){
        this.employeeBtnClicked=true;
      }
      this.fieldChange('employeeInfo','text',e.data);
    }
    else if(e.type=='cabsTable'){
      this.routeForm.controls.cabInfo.patchValue(e.data);
      this.selectedCabs=e.data;
      this.selectedCabCount=e.data.length;
      if(e.data.length==0){
        this.cabBtnClicked=true;
      }
      this.fieldChange('cabInfo','text',e.data);
  }
}
/* add more fields*/
routeDetail(){
  this.tableView=false;
  this.moreInfoView=true;
  this.tableForm.addControl('moreInfo',this.moreInfo);
}
/* final route list view in after submitted the form values*/
async finalRouteList(){
  if(this.routeForm.valid){
    this.finalRouteObj=[];
    this.finalRouteObj.push(this.routeForm.value);
    console.log('final route Object',this.finalRouteObj);
    this.vehCumulativeView = await this.routeGenerate.routeCreate(this.finalRouteObj);
    console.log('vehicle view',this.vehCumulativeView);
    this.tableView=false;
    this.moreInfoView=false;
  }
}
  submitForm(){
    console.log(this.routeForm.value);
    if(this.routeForm.valid)
    {   
      //this.updateRoute();
      this.routeForm.value.routeName=this.routeForm.value.routeName.replace(/ +/g, ' ').trim();
      this.finalRouteList();
      this.swal.swalFunction('success','success','Route updated successfully...',2000)
      this.tableView=false;
      //this.currentPage.emit("viewRoutePage"); 
      //this.routeForm.reset();
    }
    else{
      console.log(this.routeForm);
      this.employeeBtnClicked=true;
      this.cabBtnClicked=true;
    }
  }
  cancelForm(){
    this.routeForm.reset();
    this.currentPage.emit('viewRoutePage'); 
  }
}