import { Component } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { MapService } from 'src/app/Services/map.service';
import { SharedService } from 'src/app/Services/shared.service';
import { SocketIOService } from 'src/app/Services/socket.io.service';
import { VehicleLiveTrackService } from 'src/app/store/vehicle/reducer';

@Component({
  selector: 'app-live-vehicle-tracking',
  templateUrl: './live-tracking.component.html',
  styleUrls: ['./live-tracking.component.css'],

})

export class LiveVehicleTrackingComponent {


  public mapHight = '80vh';
  public myMap: any = this.map.retMap();
  public data = [
    {serverdate: '31-01-19', servertime: '12:20:04', imei: '868728031926279', gpstime: '235623', gpsdate: '120119', lattitude: '283801', longitude: '0771335', speed: 0, sos: '1', engine: '0', rfid: '', accdec: '', temp: '', batterysts: '1', ac: '1', internalbattery: '3.9', angle: '267.00', mobilebattery: '', altitude: '', mainbattery: '11.0', tamperedsts: '', boxopenclose: '', gpsvalid: 'NA', dmssts: '', lat: '12.983233985429468', lng: '80.2166813539095', gpsts: 1621437009, serverts: 1621437009, near: '0.55 km from Sansad Marg H.O, Central Delhi, Delhi, IN', reg: 'TN02KM9388', org: '605471600e7dae2fdce593d8'},
    {serverdate: '31-01-19', servertime: '12:20:04', imei: '868728031926279', gpstime: '235623', gpsdate: '120119', lattitude: '283801', longitude: '0771335', speed: 10, sos: '1', engine: '0', rfid: '', accdec: '', temp: '', batterysts: '1', ac: '1', internalbattery: '3.9', angle: '267.00', mobilebattery: '', altitude: '', mainbattery: '11.0', tamperedsts: '', boxopenclose: '', gpsvalid: 'NA', dmssts: '', lat: '12.951209556484356', lng: '80.24823196595145', gpsts: 1621437129, serverts: 1621437129, near: '0.55 km from Sansad Marg H.O, Central Delhi, Delhi, IN', reg: 'TN02KM9389', org: '605471600e7dae2fdce593d8'},
    {serverdate: '31-01-19', servertime: '12:20:04', imei: '868728031926279', gpstime: '235623', gpsdate: '120119', lattitude: '283801', longitude: '0771335', speed: 44, sos: '1', engine: '0', rfid: '', accdec: '', temp: '', batterysts: '1', ac: '1', internalbattery: '3.9', angle: '267.00', mobilebattery: '', altitude: '', mainbattery: '11.0', tamperedsts: '', boxopenclose: '', gpsvalid: 'NA', dmssts: '', lat: '12.916254680745029', lng: '80.22629944736612', gpsts: 1621437789, serverts: 1621437789, near: '0.55 km from Sansad Marg H.O, Central Delhi, Delhi, IN', reg: 'TN02KM9386', org: '605471600e7dae2fdce593d8'},
    {serverdate: '31-01-19', servertime: '12:20:04', imei: '868728031926279', gpstime: '235623', gpsdate: '120119', lattitude: '283801', longitude: '0771335', speed: 22, sos: '1', engine: '0', rfid: '', accdec: '', temp: '', batterysts: '1', ac: '1', internalbattery: '3.9', angle: '267.00', mobilebattery: '', altitude: '', mainbattery: '11.0', tamperedsts: '', boxopenclose: '', gpsvalid: 'NA', dmssts: '', lat: '13.003068334116925', lng: '80.25586076766878', gpsts: 1621438689, serverts: 1621438689, near: '0.55 km from Sansad Marg H.O, Central Delhi, Delhi, IN', reg: 'TN02KM9378', org: '605471600e7dae2fdce593d8'},

  ];

  constructor(
    private loader: LoaderService,
    private map: MapService,
    private state: VehicleLiveTrackService,
    private socket: SocketIOService,
    private share: SharedService,
    private api: ApiService
  ) {

  }

  ngOnInit() {
    this.socket.getMessages();
    this.getCurrentVehicleLocation();

    const Vehicles = this.data.map((info, i) => {
      const color = info.speed > 5 ? 'green-dot' : 'red-dot';
      const value =   {
          id: info.reg,
          content: `<div class="${color} dot--basic" ></div>`,
          lat: info.lat,
          lng: info.lng,
         popup: { // if required, other wise dont include it
          content: '<p>Reg. No :-' + info.reg + '</p>',
          // open : true
        }
      };
      return value;
    });

    this.loadMap(Vehicles);
    this.share._subject.subscribe((data: any) => {

     data.map(item => {
         console.log('Item ', item);
         const color = item.speed > 5 ? 'green-dot' : 'red-dot';
         const data =  {
          id: item.reg,
          content: `<div class="${color} dot--basic" ></div>`,
          lat: item.lat,
          lng: item.lng,
          bearing: true
        };
         console.log('data ;', data);
         this.addMarker(data);
      });

    });
    //   // console.log('vehicleData',vehicleData)
    //   // let len = val.length -1;
    //   // if(val && val[len] && val[len].lat){
    //   //     let temp = {
    //   //         id : 'A',
    //   //         content : '<div class="blink" style="color: red;margin-right: 0.5rem;animation: blink 2s steps(5, start) infinite;-webkit-animation: blink 1s steps(5, start) infinite;">🔴</div>',
    //   //         lat : val[len].lat,
    //   //         lng : val[len].lng,
    //   //         bearing : true,   // <<<<<<<<<<<---------
    //   //       }
    //   //       console.log(temp);
    //   //       this.addMarker(temp);
    //   // }

    // })
  }
 getCurrentVehicleLocation() {
   try {
      this.api.getCurrentLocation({}).then((res: any) => {
          console.log('Res --->>> ', res);
      });
   } catch (error) {
              console.log('Error', error);
   }
}
  loadMap(marker: any = []) {
console.log('Load --- > ', marker);
this.map.loadMap('live-tracking', marker);
  }

  addMarker(data) {
    this.map.updateMarker(data);
  }
}
