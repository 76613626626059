import { Component, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestModel } from 'src/app/modules/request/requset.model';
import { ApiService } from 'src/app/Services/api.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { COMMA, ENTER, W } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { LeaveRequest, LocationRequest, OnBoardRequest, RouteRequest, SiftRequest, TimeRequest } from 'src/app/modules/request/allrequest';
import { element } from 'protractor';
declare const mapUtilityMapBox, $;
import { employeDetailsModule } from '../../../../../modules/information/employeedetails';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatTable } from '@angular/material/table';
import { every } from 'src/assets/fontawesome/js/v4-shims';
import { of } from 'rxjs';


export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
}


@Component({
    selector: 'add-request',
    templateUrl: './add-request.component.html',
    styleUrls: ['./add-request.component.css']
})
export class AddRequestComponent {

    constructor(
        public api: ApiService,
        public router: Router,
        public Route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private storage: StorageService,
        private swal: SweetAlert,
    ) {
    }
    // convenience getter for easy access to form fields
    get f() { return this.requestForm.controls; }
    get fb() { return this.modalFormFields.controls; }
    get editfb() { return this.EditmodalFormFields.controls; }
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton2') closebutton2;
    minDate = new Date();
    maxDate = new Date();
    startDateValues: any = new Date();
    serializedDate = new FormControl((new Date()).toISOString());
    emplyeeData = employeDetailsModule;
    public leavrequset = LeaveRequest;
    public shiftRequest = SiftRequest;
    public onBoardReq = OnBoardRequest;
    public timeRequest = TimeRequest;
    public locatioRequest = LocationRequest;
    public routeRequset = RouteRequest;

    public editNewObject: any = {};

    reqType: string; // requst type change ngModel
    requestData: any;
    visible = true;
    selectable = true;
    removable = true;
    // addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    options = { value: '', name: '' };
    customFormFInalValue: any[] = [];
    actions: any[] = [];
    fieldsOptions: any[] = [];
    filedsOptionsArry: any[] = [];
    EditfieldsOptions: any[] = [];
    EditfiledsOptionsArry: any = [];
    customFormFieldsArr: any[] = [];

    formLoadPreview = true;
    RequestModelInfo = RequestModel;
    requestForm: FormGroup;
    modalFormFields: FormGroup;
    EditmodalFormFields: FormGroup;
    isLocationDiv = false;
    isTimeDiv = false;
    submitted = false;
    isRouteDiv = false;
    isShiftDiv = false;
    isLeaveDiv = false;
    isOnBoard = false;
    isCoustomRequest = false;
    actionGetNewArry = [];
    miniDate: any;
    myDate: any;
    fields = ['text', 'textarea', 'date', 'checkbox', 'radio', 'dropdown']; // checkbox

    whoReqTo = ['Employee', 'Driver', 'Vendor', 'Manager'];
    reqTest = ['Employee', 'Driver', 'Vendor', 'Manager'];
    reqTo = ['Employee', 'Driver', 'Vendor', 'Manager'];
    requestValue = [
        { value: 'custom_request', viewValue: 'Custom request' },
        { value: 'on_Board_request', viewValue: 'On board request' },
        { value: 'location_request', viewValue: 'Location request' },
        { value: 'route_change', viewValue: 'Route change' },
        { value: 'time_change', viewValue: 'Time change' },
        { value: 'shift_change', viewValue: 'Shift change' },
        { value: 'leave_request', viewValue: 'Leave request' }
    ];
    isCoustomRequestForm: boolean;
    customData: any[] = [];
    // customData: any[] = RequestModel;
    OptionsValue: any[] = [];
    isShowfielsOptionValues: boolean;
    isShowSelectOptions: boolean;
    isShowModelFormPreview: boolean;
    istexAreaHide: boolean;
    typeValue: any;
    formValData: any;
    NewArrOfCustomForm: any = [];
    isCharacter: any;
    isNumbers: boolean;
    isHidePlaceHolder: boolean;
    isDate: boolean;
    editCustomFormData: any = [];


    @ViewChild('table') table: MatTable<PeriodicElement>;
    displayedColumns: string[] = ['no', 'label', 'type', 'required', 'action'];


    dataSource: any = [];
    isTabelShowHide: boolean;
    uniqID: any;
    isDisableSubmit: boolean;
    customLastDateObj: {
        orgID: any; title: any; requestTo: any; whomToShow: any;
        // requestName: this.requestForm.value.requestName,
        note: any; action: any; status: any; startDate: any; endDate: any;
    };
    isShowCustomForm = false;
    isFormShowHide = true;
    selectedindex: any;
    editValues: any;
    formShow = false;
    isCancelShow: boolean;
    editCustomForm: boolean;
    editCustomFormDataObj: any = {};
    isShowEditFormsData = false;
    public addNewObject: any = {};


    // add model form data
    public updateArryData = [];
    public tabelArry = [];


    dateFormet = (date) => {
        return date.getDate() + '-' + '0' + date.getMonth() + '-' + date.getFullYear();
    }

    dropTable(event: CdkDragDrop<PeriodicElement[]>) {
        // console.log(event)
        const prevIndex = this.dataSource.findIndex((d) => d === event.item.data);

        moveItemInArray(this.dataSource, prevIndex, event.currentIndex);

        this.table.renderRows();
    }
    ngOnInit() {
        this.requestFormLoad();
        this.fieldFormLoad();
        this.EditfieldFormLoad();
    }
    fieldsSelect(e: any) {
        let formFeilds: any = {};
        const newField = [];
        // console.log(e)
        e.forEach((e, i) => {
            // console.log(e, i)
            formFeilds = {
                type: '',
                name: '',
                label: '',
                value: '',
                required: true,
                col: 'col-md-6',
                // multiline: true
            };
            formFeilds.type = e;
            formFeilds.name = 'name' + i;
            formFeilds.label = 'lable' + i;
            newField.push(formFeilds);
        });
        // console.log(newField)
        return newField;
    }
    preview() {
        // let newObj = this.fieldsSelect(this.requestForm.value.field)
        // this.maxDate = this.requestForm.value.fromDate
        // this.minDate = this.requestForm.value.toDate
        // this.customData = newObj
        this.isCoustomRequestForm = true;
        this.OptionsValue = this.actionGetNewArry;
        // console.log(this.customData, this.emplyeeData)
    }
    requestToValue(event) {
        // console.log(event.value)
        // let arry = event.value
        // let arr2 = arry.filter(function (obj) {
        //     return this.reqTest.indexOf(obj) == -1
        // })
        // console.log(arr2)
        let newReqsto = [];
        // console.log(newReqsto)
        newReqsto = this.reqTo.filter(function (val) {
            const newVar = event.value.indexOf(val) == -1;
            const newVa2 = event.value.indexOf(val) != -1;
            if (newVar) {
                return newVar;
            } else {
                newReqsto.push(val);
                // console.log(newReqsto, val)
            }
            return newVar;

        });
        this.reqTo = newReqsto;
    }


    add(event: MatChipInputEvent): void {
        if (event.value.length != 0) {
            this.actionGetNewArry.push(event.value);
        }
        // console.log(event.value, this.actionGetNewArry)
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            // this.actions.push({ name: value.trim() });
            this.actions.push({ name: value.trim() });
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(val): void {
        const index = this.actions.indexOf(val);

        if (index >= 0) {
            this.actions.splice(index, 1);
        }
    }


    // chips

    addFieldsSet(event: MatChipInputEvent): void {
        console.log(event);
        if (event.value.length != 0) {
            // console.log(event.value.length != 0)
            // this.filedsOptionsArry.push(event.value)
            // console.log(this.filedsOptionsArry)
            // this.AddOnchangeFormPreview(this.filedsOptionsArry, 'options')
            // alert('fun')

        }
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            // this.actions.push({ name: value.trim() });
            this.fieldsOptions.push({ name: value.trim() });
            // console.log(this.fieldsOptions)
            this.AddOnchangeFormPreview(this.fieldsOptions, 'options');
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    removeFieldsSet(val): void {
        const id = this.filedsOptionsArry.indexOf(val.name);


        const index = this.fieldsOptions.indexOf(val);
        // console.log(val.name, this.filedsOptionsArry.indexOf(val.name), this.filedsOptionsArry)
        if (index >= 0) {
            this.fieldsOptions.splice(index, 1);
            this.filedsOptionsArry.splice(id, 1);
        }
        // console.log(this.filedsOptionsArry)
        // this.onchangeFormPreview(this.filedsOptionsArry, 'options-remove')
    }

    // ------------------------------
    addEditFieldsSet(event: MatChipInputEvent): void {
        // editCustomFormData
        // if (event.value.length != 0) {

        //     let optionsValue = this.editCustomFormData[0].options.push(event.value)
        //     this.onchangeFormPreview(optionsValue, 'options')
        // }
        const input = event.input;
        const value = event.value;

        // Add our fruit
        // console.log(this.editCustomFormData)
        if ((value || '').trim()) {
            // this.actions.push({ name: value.trim() });
            this.EditfieldsOptions.push({ name: value.trim(), value });
            // console.log(this.EditfieldsOptions)
            if (this.editNewObject.option == undefined) {
                this.editNewObject.option = [];
            }
            // if (this.editCustomFormData[0]['options'] == undefined) {
            //     this.editCustomFormData[0]['options'] = []
            // }
            this.editNewObject.option.push({ name: value.trim(), value });
            // this.editCustomFormData[0]['options'].push({ name: value.trim(), value: value });
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    removeEditFieldsSet(val): void {
        this.isShowfielsOptionValues = false;
        const id = this.EditfieldsOptions.indexOf(val.name);


        const index = this.EditfieldsOptions.indexOf(val);
        // console.log(val.name, this.EditfieldsOptions.indexOf(val.name), this.EditfieldsOptions)
        if (index >= 0) {
            this.EditfieldsOptions.splice(index, 1);
            // this.EditfieldsOptions.splice(id, 1)
        }
        // console.log(this.EditfieldsOptions)

        this.editOnchangeFormPreview(this.EditfieldsOptions, 'options-remove');
        this.isShowfielsOptionValues = true;
    }

    // -----------------------------------------
    requestFormLoad() {
        this.requestForm = this.formBuilder.group({
            title: ['', [Validators.required]],
            requestTo: ['', [Validators.required]],
            whomToShow: ['', [Validators.required]],
            requestType: [''],
            note: ['', [Validators.required]],
            action: [''],
            status: ['', [Validators.required]],
            priority: [''],
            startDate: [''],
            endDate: [''],
            formFields: [''],
            newMenu: ['']

        });
    }
    // getFormFeild() {
    //     return
    // }


    fieldFormLoad() {
        this.modalFormFields = this.formBuilder.group({
            label: ['', [Validators.required]],
            type: ['', [Validators.required]],
            name: [Math.random() * 100],
            textType: [''],
            required: [''],
            placeholder: [''],
            // formatValidation: [''],
            options: [''],
            multiselect: [''],
            hint: [''],
            characterLength: [''],
            sizemax: [''],
            sizemin: [''],
            // historyDate:[''],
            // futureDate:[''],
            historyFutureDate: [''],
            col: ['col-md-12'],
            multiline: [false]
        });
    }

    // ------------------------------------------
    EditfieldFormLoad() {
        this.EditmodalFormFields = this.formBuilder.group({
            label: ['', [Validators.required]],
            type: ['', [Validators.required]],
            textType: [''],
            name: [Math.random() * 100],
            required: [''],
            placeholder: [''],
            // formatValidation: [''],
            options: [''],
            multiselect: [''],
            hint: [''],
            characterLength: [''],
            sizemax: [''],
            sizemin: [''],
            // historyDate:[''],
            // futureDate:[''],
            historyFutureDate: [''],
            col: ['col-md-12'],
            multiline: [false]
        });
    }
    editFeilds(data: any, index: number) {
        this.isShowEditFormsData = false;
        // console.log('before', this.editCustomFormData.length)
        this.editCustomFormData.pop();
        // console.log('DAta', data, index)
        this.enableDisableFields(data.type);
        this.selectedindex = index;
        this.editCustomFormData.push(data);
        this.isShowEditFormsData = true;
        // console.log('after', this.editCustomFormData.length)
        document.getElementById('modbtn').click();
        if (data.options) {
            this.EditfieldsOptions = [];
            this.EditfieldsOptions = data.options;
        }
        // console.log(this.EditfieldsOptions)
        this.setDataToForm(index);
        if (data.textType == 'text') {
            this.isCharacter = true;
            this.isNumbers = false;
        } else if (data.textType == 'number') {
            this.isNumbers = true;
            this.isCharacter = false;
        } else {
            this.isNumbers = false;
            this.isCharacter = false;
        }


    }
    addModelOptions(event: any) {
        this.customData.pop();
        const objKey = Object.keys(this.addNewObject);
        this.isFormShowHide = false;
        this.fieldsOptions = [];
        for (const key of objKey) {
            if (key != 'type') {
                delete this.addNewObject[key];
            }
        }
        const objKey1 = Object.keys(this.modalFormFields.value);
        this.isFormShowHide = false;
        for (const key of objKey1) {
            if (key != 'type') {
                this.modalFormFields.controls[key].reset();
            }
        }
        this.enableDisableFields(event.value);
        this.isFormShowHide = true;
        this.addNewObject.type = event.value;
        this.addNewObject.value = '';
        // if (event.value == 'date') {
        //     this.addNewObject['value'] = ''
        // } else {
        //     delete this.addNewObject['value']
        // }
        if (event.value == 'checkbox' || event.value == 'radio' || event.value == 'dropdown') {
            this.addNewObject.options = [];
        } else {
            delete this.addNewObject.options;
        }
        // console.log(this.addNewObject)

    }
    AddOnchangeFormPreview(event: any, name: string) {
        this.addNewObject[name] = event.value;
        if (name == 'required') { this.addNewObject[name] = event.checked; }
        if (name == 'multiselect') { this.addNewObject[name] = event.checked; }
        if (name == 'sizemin') { }
        // if (name == 'sizemax') {
        //     console.log(this.addNewObject['sizemin'],
        //         this.addNewObject['sizemax'])
        //     if (this.addNewObject['sizemin'] > this.addNewObject['sizemax']) {
        //         alert('sizemax')
        //         this.addNewObject['sizemax'] = ''
        //     }
        // }
        if (name == 'options') {
            const obj = [];
            event.forEach(element => {
                obj.push({ name: element.name, value: element.name });
            });
            // console.log(obj)
            this.addNewObject.options = obj;
        }
        if (name == 'text' || name == 'textare' || name == 'date') {

            delete this.addNewObject.options;
        }
        if (name == 'textType') {
            // console.log(event, name, 'textType')
            this.isCharacter = false;
            this.isNumbers = false;
            if (event.value == 'text') {
                this.isCharacter = true;
            }
            if (event.value == 'number') {
                this.isNumbers = true;
            }
        }
        // console.log(this.addNewObject)
        this.customData.pop();
        this.customData.push(this.addNewObject);
        this.addNewObject.name = '';
        // console.log(this.customData, 'final data')
        this.isShowModelFormPreview = true;
    }
    // -------------------------------------------------------------------------------------

    // edit modal form data----------------------------------------------------------------


    editModelOptions(event: any) {
        const objKey1 = Object.keys(this.EditmodalFormFields.value);
        this.isFormShowHide = false;
        for (const key of objKey1) {
            if (key != 'type') {
                this.EditmodalFormFields.controls[key].reset();
            }
        }
        this.editCustomForm = false;
        this.isShowEditFormsData = false;
        // console.log(event.value)
        this.enableDisableFields(event.value);
        const objKey = Object.keys(this.EditmodalFormFields.value);
        this.isFormShowHide = false;
        for (const key of objKey) {
            if (key != 'type') {
                this.EditmodalFormFields.controls[key].reset();
            }
        }
        this.EditfieldsOptions = [];
        this.editCustomFormData = [];
        this.editNewObject.type = event.value;
        this.editNewObject.value = '';
        if (this.editNewObject.type == 'checkbox' || this.editNewObject.type == 'dropdown' || this.editNewObject.type == 'radio') {
            this.editNewObject.option = [];
        } else {

            delete this.editNewObject.option;
        }

        const obj2 = {
            value: this.EditmodalFormFields.value.type
        };
        // console.log(obj2, this.editNewObject)
        this.isFormShowHide = true;
        this.editCustomForm = true;
        setTimeout(() => {
            this.isShowEditFormsData = true;
        }, 300);
    }



    editOnchangeFormPreview(event: any, name: string) {
        this.isShowEditFormsData = false;
        this.isFormShowHide = false;
        this.editCustomForm = false;
        // console.log('line 546 this.new edit Object', name, this.editNewObject);
        if (name == 'textType') {
            // console.log(event, name, 'textType inside ')
            this.isCharacter = false;
            this.isNumbers = false;
            if (event.value == 'text') {
                this.isCharacter = true;

            }
            if (event.value == 'number') {
                this.isNumbers = true;
            }
        }
        const checkNew = Object.keys(this.editNewObject);
        // console.log('line no 56-0 ', checkNew.length, checkNew);
        if (checkNew.length > 0) {
            this.editNewObject[name] = event.value;
            if (name == 'required') { this.editNewObject[name] = event.checked == undefined ? false : true; }
            if (name == 'multiselect') { this.editNewObject[name] = event.checked == undefined ? false : true; }
        } else {
            this.editCustomFormData[0][name] = event.value;
            // this.editNewObject[name] = event.value;
            if (name == 'required') { this.editCustomFormData[0][name] = event.checked; }
            if (name == 'multiselect') { this.editCustomFormData[0][name] = event.checked; }
            // console.log('line No 569 ', this.selectedindex, this.editCustomFormData);
            // this.editCustomFormDataObj = this.editCustomFormData[0]
        }
        // console.log(
        //     'line No 572 selectedindex', this.selectedindex, 'this.editNewObject', this.editNewObject,
        //     'this.editCustomFormData ', this.editCustomFormData
        // );
        this.isFormShowHide = true;
        this.editCustomForm = true;
        setTimeout(() => {
            this.isShowEditFormsData = true;
        }, 200);


    }
    // --------------------------------------------------------------------------------------
    setDataToForm(i) {
        this.EditmodalFormFields.patchValue(
            {
                label: this.editCustomFormData[0].label,
                type: this.editCustomFormData[0].type,
                textType: this.editCustomFormData[0].textType,
                name: this.editCustomFormData[0].name,
                required: this.editCustomFormData[0].required,
                placeholder: this.editCustomFormData[0].placeholder,
                options: this.EditfieldsOptions,
                multiselect: this.editCustomFormData[0].multiselect,
                hint: this.editCustomFormData[0].hint,
                characterLength: this.editCustomFormData[0].characterLength,
                sizemax: this.editCustomFormData[0].sizemax,
                sizemin: this.editCustomFormData[0].sizemin,
                // historyDate:[''],
                // futureDate:[''],
                historyFutureDate: this.editCustomFormData[0].historyFutureDate,
                col: this.editCustomFormData[0].col
            });
        // console.log('Line 594 ', this.EditmodalFormFields.value)
    }

    customFornSingleData(valName, data, index) {
        // console.log(valName, data, index)
        if (this.editCustomFormData.length > 0) {
            this.editCustomFormData.pop();
        }

        const obj = data;
        if (valName == 'edit') {
            this.editFeilds(data, index);
        }
        if (valName == 'delete') {
            this.isTabelShowHide = false;
            // console.log(index)
            this.dataSource.splice(index, 1);
            setTimeout(() => {
                this.isTabelShowHide = true;
            }, 20);
            const data = {
                ...this.customLastDateObj,
                uniqID: this.uniqID,
                formFields: this.dataSource,
            };
            this.customFormDelete(data);
            // console.log(obj)
        }
    }
    customFormDelete(datas) {
        const obj = {
            ...datas
        };
        this.api.UpdateCustomRequest(obj).then((res: any) => {
            // console.log(res)
            if (res.status == 'success') {
                this.swal.swalFunction('success', res.title, res.message, 2000);
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.title, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    enableDisableFields(type) {
        // console.log(type, 'enabel disabe')
        this.istexAreaHide = false;
        this.isHidePlaceHolder = false;
        this.isCharacter = false;
        this.isDate = false;
        this.isNumbers = false;
        this.isShowfielsOptionValues = false;
        this.isShowSelectOptions = false;
        if (type == 'text') {
            this.istexAreaHide = true;
            this.isHidePlaceHolder = true;
            this.isCharacter = true;
        } else if (type == 'textarea') {
            this.isHidePlaceHolder = true;
            this.isCharacter = true;
        } else if (type == 'date') {
            this.isDate = true;
        } else if (type == 'radio') {
            this.isShowfielsOptionValues = true;
        } else if (type == 'checkbox') {
            this.isShowfielsOptionValues = true;
        } else if (type == 'dropdown') {
            this.isShowfielsOptionValues = true;
            this.isShowSelectOptions = true;
        }
    }

    modelClose() {
        this.modalFormFields.reset();
        this.fieldsOptions = [];
    }
    onModalFormSubmit() {
        // console.log(this.addNewObject)
        this.submitted = true;
        // stop here if form is invalid
        if (this.modalFormFields.invalid) {
            return;
        }
        this.isTabelShowHide = false;
        this.formShow = false;
        this.dataSource.push(this.addNewObject);
        setTimeout(() => {
            this.isShowCustomForm = true;
            this.isTabelShowHide = true;
            this.formShow = true;
            console.log(this.dataSource)
            // this.closebutton.nativeElement.click();
        }, 200);

        const oobj = {
            // ...this.customLastDateObj,
            orgID: this.storage.get('userInfo').orgID,
            uniqID: this.uniqID,
            formFields: this.dataSource,
        };
        this.api.UpdateCustomRequest(oobj).then((res: any) => {
            if (res.status == 'success') {
                // console.log(res)
                this.closebutton.nativeElement.click();
                this.swal.swalFunction('success', res.status, res.message, 2000);
                // this.getListCustomReport()
            }
            if (res.status == 'failure') {
                // console.log(res)
                const msg = [];
                if (Array.isArray(res.message)) {
                    res.message.forEach(element => {
                        // console.log(element)
                        msg.push(element);
                    });

                }
                // console.log(msg.toString())
                const msssg = msg.toString();
                this.swal.swalFunction('error', res.status, msssg, 2000);
                this.addNewObject = {};
            }
        }).catch((err) => {
            console.log(err);
        });
        this.fieldsOptions = [];
        this.filedsOptionsArry = [];
        this.modalFormFields.reset();
        this.addNewObject = {};
    }
    getListCustomReport() {
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            uniqID: this.uniqID,
        };
        // console.log('servert list data ping', obj)
        this.api.ListCustomRequest(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction('success', res.status, res.message, 2000);
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
            // console.log('servert list data recive data', res)
        }).catch((err) => {
            console.log(err);
        });
    }
    onEditModalFormSubmit() {
        // console.log(this.editCustomFormData, this.editCustomFormData.length)
        if (this.editCustomFormData.length > 0) {
            this.dataSource[this.selectedindex] = this.editCustomFormData[0];
        } else {
            this.dataSource[this.selectedindex] = this.editNewObject;
            this.editNewObject = {};
        }
        // console.log('index', this.selectedindex, 'old value', this.editCustomFormData, 'New value', this.editNewObject, 'final ', this.dataSource)
        const obj = {
            ...this.customLastDateObj,
            uniqID: this.uniqID,
            formFields: this.dataSource,
        };
        // console.log(obj, 'edit valuer check')
        this.api.UpdateCustomRequest(obj).then((res: any) => {
            // console.log(res, 'after edit secess')
            if (res.status == 'success') {
                this.isTabelShowHide = false;
                this.formShow = false;
                this.swal.swalFunction('success', res.status, res.message, 2000);
                this.closebutton2.nativeElement.click();
                setTimeout(() => {
                    this.isShowCustomForm = true;
                    this.isTabelShowHide = true;
                    this.formShow = true;
                    // console.log(this.dataSource)
                    // this.closebutton.nativeElement.click();
                }, 200);
                this.editNewObject = {};
                this.editCustomFormData = [];
                this.isShowEditFormsData = false;
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }

        }).catch((err) => {
            console.log(err);
        });
    }
    convert(str) {
        const date = new Date(str),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join('-');
    }
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.requestForm.invalid) {
            return;
        }
        if (this.requestForm.value.requestType == 'custom_request') {
            // alert()
            // console.log(this.requestForm.value)
            const obj = {
                orgID: this.storage.get('userInfo').orgID,
                title: this.requestForm.value.title,
                requestType: this.requestForm.value.requestType,
                requestTo: this.requestForm.value.requestTo,
                whomToShow: this.requestForm.value.whomToShow,
                note: this.requestForm.value.note,
                action: this.requestForm.value.action,
                status: this.requestForm.value.status,
                startDate: this.convert(this.requestForm.value.startDate),
                endDate: this.convert(this.requestForm.value.endDate),
                priority: this.requestForm.value.priority
            };
            obj.action = this.actions;
            obj.status = obj.status == '0' ? 0 : 1;
            console.log(obj, 'cistom-requst');
            if (!this.uniqID) {
                console.log('not uniq id');
                this.api.CreateCustomRequest(obj).then((res: any) => {
                    // console.log(res)
                    if (res.status == 'success') {
                        this.customLastDateObj = obj;
                        this.uniqID = res.uniqID;
                        // this.requestForm.disable()
                        this.formShow = true;
                        this.isCancelShow = true;
                        this.isDisableSubmit = true;
                        const msg = 'Request Details Successful, Please add more fields';
                        this.swal.swalFunction('success', res.status, msg, 5000);
                    }
                    if (res.status == 'failure') {
                        this.swal.swalFunction('error', res.status, res.message, 2000);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
            if (this.uniqID) {
                const obj3 = {
                    ...obj,
                    uniqID: this.uniqID
                };

                obj3['formFields'] = this.customFormFInalValue;
                console.log(obj3, 'formField chekong before update');
                this.api.UpdateCustomRequest(obj3).then((res: any) => {
                    // console.log(res)
                    if (res.status == 'success') {
                        this.formShow = true;
                        this.isDisableSubmit = true;
                        const msg = "Request Details Successful Please add more fields";
                        this.swal.swalFunction('success', res.status, msg, 5000);
                    }
                    if (res.status == 'failure') {
                        this.swal.swalFunction('error', res.status, res.message, 2000);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            }
        } else {
            const obj = {
                orgID: this.storage.get('userInfo').orgID,
                // ...this.requestForm.value,
                title: this.requestForm.value.title,
                requestType: this.requestForm.value.requestType,
                requestTo: this.requestForm.value.requestTo,
                whomToShow: this.requestForm.value.whomToShow,
                note: this.requestForm.value.note,
                action: this.requestForm.value.action,
                status: this.requestForm.value.status,
                startDate: this.convert(this.requestForm.value.startDate),
                endDate: this.convert(this.requestForm.value.endDate),
                priority: this.requestForm.value.priority


                // action: this.requestForm.value['actions'] = this.actions,
            };
            obj.action = this.actions;
            obj.status = obj.status == '0' ? 0 : 1;
            if (obj.requestType) {
                obj['formFields'] = this.reqFunLoadForm(obj.requestType)
            }

            console.log(obj, 'normal request form s');
            this.api.CreateRequest(obj).then((res: any) => {
                if (res.status == 'success') {
                    console.log(res);
                    this.swal.swalFunction(res.status, res.status, res.message, 2000);
                    this.router.navigate(['/organization/transport-manager/request/view-request']);
                } if (res == 'failure') {
                    this.swal.swalFunction('error', res.status, res.message, 2000);
                }
            }).catch((err) => {
                console.log(err);
            });

        }
    }
    reqFunLoadForm(req) {
        alert(req)
        if (req == 'on_Board_request') { return this.onBoardReq }
        if (req == 'location_request') { return this.locatioRequest }
        if (req == 'route_change') { return this.routeRequset }
        if (req == 'time_change') { return this.timeRequest }
        if (req == 'shift_change') { return this.shiftRequest }
        if (req == 'leave_request') { return this.leavrequset }
    }
    onReset() {
        this.submitted = false;
        this.requestForm.reset();
    }

    requstTypeValue(val) {
        this.formLoadPreview = false;
        // console.log(this.formLoadPreview)
        switch (val) {
            case 'location_request':
                this.isLocationDiv = true;
                this.isTimeDiv = false;
                this.isRouteDiv = false;
                this.isShiftDiv = false;
                this.isLeaveDiv = false;
                this.isCoustomRequest = false;
                this.isOnBoard = false;
                break;
            case 'on_Board_request':
                this.isLocationDiv = false;
                this.isTimeDiv = false;
                this.isRouteDiv = false;
                this.isShiftDiv = false;
                this.isLeaveDiv = false;
                this.isCoustomRequest = false;
                this.isOnBoard = true;
                break;
            case 'time_change':
                this.isLocationDiv = false;
                this.isTimeDiv = true;
                this.isRouteDiv = false;
                this.isShiftDiv = false;
                this.isLeaveDiv = false;
                this.isCoustomRequest = false;
                this.isOnBoard = false;
                break;
            case 'route_change':
                this.isLocationDiv = false;
                this.isTimeDiv = false;
                this.isRouteDiv = true;
                this.isShiftDiv = false;
                this.isLeaveDiv = false;
                this.isCoustomRequest = false;
                this.isOnBoard = false;
                break;
            case 'shift_change':
                this.isLocationDiv = false;
                this.isTimeDiv = false;
                this.isRouteDiv = false;
                this.isShiftDiv = true;
                this.isLeaveDiv = false;
                this.isCoustomRequest = false;
                this.isOnBoard = false;
                break;
            case 'leave_request':
                this.isLocationDiv = false;
                this.isTimeDiv = false;
                this.isRouteDiv = false;
                this.isShiftDiv = false;
                this.isLeaveDiv = true;
                this.isCoustomRequest = false;
                this.isOnBoard = false;
                break;
            case 'custom_request':
                this.isLocationDiv = false;
                this.isTimeDiv = false;
                this.isRouteDiv = false;
                this.isShiftDiv = false;
                this.isLeaveDiv = false;
                this.isCoustomRequest = true;
                this.isOnBoard = false;
                break;
            default:
                this.isLocationDiv = false;
                this.isTimeDiv = false;
                this.isRouteDiv = false;
                this.isShiftDiv = false;
                this.isLeaveDiv = false;
                this.isCoustomRequest = false;
                this.formLoadPreview = true;
                this.isOnBoard = false;
        }
    }
    deleteobject(data, id) {
        this.customData.forEach((e, i) => {
            if (id == i) {
                this.customData.splice(i, 1);
            }
        });
    }
    cardViewToForm() {
        this.formShow = !this.formShow;
    }
}



