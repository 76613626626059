import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { MapService } from 'src/app/Services/map.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
  selector: 'g10-pickup-point-rostering',
  templateUrl: './pickup-point-rostering.component.html',
  styleUrls: ['./pickup-point-rostering.component.css']
})
export class PickupPointRosteringComponent implements OnInit {

  public userData;
  public pickupPointModalForm:FormGroup;
  public pickupPointForm:FormGroup;
  /* map variables*/
  public pickupLocation:any;
  public latlong:any;
  public selectedValueClose = false;
  public locationSuggestions: any;
  public myMap: any = this.map.retMap();
  public searchInput: any = new Subject<string>();
  public destroy$: any = new Subject();
  
  /* API objects*/

  public routeObj=[];
  public employeeObj=[];
  public shiftObj=[];
  public vehicleObj=[];

  /*PickupPoint*/
  public pickupPointObj=[{
    ETA: {hour: "6", minute: "30", meriden: "AM", format: 12},
    employees: [ {
      "orgID": "60866279d774d4408635033b",
      "empRefID": "Test2",
      "name": "Nithya",
      "dob": 1622764800000,
      "gender": "Female",
      "mobile": "9876543210",
      "email": "test2@gmail.com",
      "emergencyContact": "9876542234",
      "bloodGroup": "A",
      "address": "Guindy",
      "city": "chennai",
      "state": "Tamil Nadu",
      "country": "India",
      "pincode": "600042",
      "location": {
          "lat": 13.017106,
          "lng": 80.258827,
          "name": "Sanabel Biriyani Point, Chennai, Tamil Nadu, India"
      },
      "workLocation": "chennai",
      "shift": "60e68181d6518e6a115a2155",
      "transportType": "company",
      "profile": "/home/devuser/EMPS/API/emps-images/emp_Test2.png",
      "employeeID": "60e690f731fa011c99263d43",
      "locationName": "Sanabel Biriyani Point, Chennai, Tamil Nadu, India"
  },
  {
      "orgID": "60866279d774d4408635033b",
      "empRefID": "Test2",
      "name": "kannan",
      "dob": 1622764800000,
      "gender": "Male",
      "mobile": "9876543210",
      "email": "test2@gmail.com",
      "emergencyContact": "9876542234",
      "bloodGroup": "A",
      "address": "Guindy",
      "city": "chennai",
      "state": "Tamil Nadu",
      "country": "India",
      "pincode": "600042",
      "location": {
          "lat": 13.015914,
          "lng": 80.258709,
          "name": "Sanabel Biriyani Point, Chennai, Tamil Nadu, India"
      },
      "workLocation": "chennai",
      "shift": "60e68181d6518e6a115a2155",
      "transportType": "company",
      "profile": "/home/devuser/EMPS/API/emps-images/emp_Test2.png",
      "employeeID": "60e6869531fa011c9926358c",
      "locationName": "Sanabel Biriyani Point, Chennai, Tamil Nadu, India"
  }],
    location: {center: Array(2), name: "Axis Bank ATM, 9th Street, Abirami Nagar, Chennai, Tamil Nadu, India"},
    pickupPointAttendance: "Wifi",
    pickupPointRadius: 200,
    pickupPointStatus: "true",
    title: "PP1",
    vehicles: [ {
      "orgID": "60866279d774d4408635033b",
      "vendorID": "60cae868ef11c5a004e910b6",
      "vehRegNo": "TN01AA0009",
      "imei": "2452345234523423",
      "make": "maruti",
      "model": "hatchback",
      "chassisNo": "kjdf987fdasdf",
      "engineNo": "en39847712",
      "purchasedDate": 1625097600000,
      "routePermitCost": "500",
      "startDate": 1625097600000,
      "expiryDate": 1627689600000,
      "roadTaxStatus": true,
      "rcNo": "RC1234",
      "rcStartDate": 1625097600000,
      "rcExpiryDate": 1627689600000,
      "vehicleInfo": "car",
      "rcfile": "C:\\fakepath\\profilePng.png",
      "capacity": "3",
      "vehicleType": "Owned",
      "boxType": "groupten",
      "location": {
          "lat": 13.059152,
          "lng": 80.203238,
          "name": "Raghavendra Swami Mutt, Ramar Street, Chennai, Tamil Nadu, India"
      },
      "createdBy": null,
      "status": 1,
      "createdAt": 1625828428666,
      "simNo": "7896134918",
      "vehicleID": "60e82c4c7ea33095b67daa6f"
  }],
  }
  ];
  //table
  public PickupPointCols = [
    { key: "#", display: "#" },
    { key: "title", display: "Title" },
    { key: "location", display: "Location" },
    { key: "ETA", display: "ETA" },
    { key:"vehicles",display:"#Vehicles"},
    { key:"employees",display:"#Employees"},
    { key:"pickupPointAttendance",display:"Attendance"},
    { key:"pickupPointStatus",display:"Status"},
    { key: "action", display: "Action" },
  ];
  public pickupPointDataSource;
   /* get keys*/
   get keys() {
    return this.PickupPointCols.map(({ key }) => key);
  }
  /* Field options */
  public fieldOptions = [{
    routeType: ['pickup', 'drop'],
    routeShift: [],
    notification:['push','sms','email','ivr calls'],
    branch: [],
    pickupPointAttendance:['OTP','Beacon','Wifi','RFID'],
    vehicles:[],
  }];
  public etaTime = { hour: 0, minute: 0, meriden: 'AM', format: 12, val: 'startData' };

  /* Table view */
  public empCols = [
    { key: "select", display: "" },
    { key: "empRefID", display: "Employee Id" },
    { key: "name", display: "Name" },
    { key: "location", display: "Area" },
    //{ key: "action", display: "Action" },
  ];
  
  public tableView:boolean=false;
  public tableObj=[];
  public tableCols=[];
  public tableDataType='';
  
  /* table data */
  public selectedShift;
  public shiftwiseRoute;
  public shiftwiseEmployee;
  public selectedEmployee=[];
  public selectedEmpCount;

  constructor(
    public api: ApiService,
    private fb: FormBuilder,
    public storage: StorageService,
    private map: MapService,
    ) { 
      this.userData = this.storage.get('userInfo',)
    }

  ngOnInit(): void {
    this.getVehicleList();
    this.getListShift();
    this.getEmployeeData();
    this.getRouteData();
    this.createPickupPoint();

    /* Map */
    this.loadMap();
    this.myMap.mymap.onMapClick((e: any) => {
      this.locationSuggestions = [];
      this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
    });

    this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
      if (value.val.target.value != '') {
        const val = { key: 'pickupLocation', val: value.val.target.value };
        this.getGeoCode(val);
        console.log(val);
      } else if (value.val.target.value.trim() == '') {
        this.emptySuggestions();
      }
      console.log(value.val.target.value);
      console.log(value.val);
    });

    this.pickupPointDataSource=new MatTableDataSource(this.pickupPointObj);
  }
  
  /* create Form */
  createPickupPoint(){
    this.pickupPointModalForm=this.fb.group({
      title:['',[Validators.required,Validators.pattern('[A-Z|a-z|0-9]{0,40}')]],
      location:['',[Validators.required]],
      ETA:['',[Validators.required]],
      pickupPointRadius:['',Validators.required,Validators.pattern("^[0-9]*$")],
      pickupPointStatus:['',[Validators.required]],
      vehicles:[''],/* ,Validators.required], */
      employees:[''],/* [Validators.required]], */
      pickupPointAttendance:['',[Validators.required]],
    });
    this.pickupPointForm=this.fb.group({
      vehicles:[''],
    })
  }
  /* Pickup Point Table*/
  pickupPointTable(){
    console.log('pickupPointTable');
    if(this.pickupPointModalForm.valid){
      let obj=this.pickupPointModalForm.value;
      console.log(this.pickupPointModalForm.value);
      this.pickupPointObj.push(obj);
      this.pickupPointDataSource = new MatTableDataSource(this.pickupPointObj);
    }
  }
  /* Map functions*/
  loadMap(marker: any = []) {
    this.map.loadMap('pickuppoint', marker);
  }
  getGeoCode(value) {
    const key = value.key;
    if (key == 'pickupLocation') {
      this.locationSuggestions[key] = [];
    }
    this.myMap.mymap.geocoder(value.val, (res) => {
      try {
        const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
        console.log('Loading GEO code ', key);
        if (key == 'mapclick') {
          this.locationSuggestions = {};
          //this.address = result[0];
          // this.vehicleloc = this.address.name;
          //this.vehicleLocation = this.address;
           this.selectedValueClose = true;
          this.pickupLocation=result[0];
          console.log(result, result[0].name);

        } else {
          this.locationSuggestions[key] = result;
          console.log('Else Part', this.locationSuggestions);
        }
      } catch (er) { console.log(er); }
    });
  }
  selectedValue(value) {
    this.selectedValueClose = true;
    this.locationSuggestions = {};
    this.pickupLocation = value;
  }
  emptySuggestions() {
    this.locationSuggestions = {};
    this.selectedValueClose = false;
    this.pickupLocation = null;
  }
  close() {
    this.latlong = null;
    if (this.pickupLocation != null && this.pickupLocation.name) {
      this.pickupPointModalForm.patchValue({
        location: this.pickupLocation
      });
      this.latlong=this.pickupLocation.name;
    } else {
      this.pickupPointModalForm.patchValue({
        location: undefined,
      });
      this.latlong = null;
    }
    console.log(this.pickupPointModalForm.value);
    //this.pickupPointTable();
  }
  getRouteData() {
    let obj = {
      orgID: this.userData.orgID,
    };
    this.api.transportMangerRouteList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.routeObj=res.data;
        this.getCabTable();
      } else {
        console.log('else', res);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  getEmployeeData() {
    let obj = {
      orgID: this.userData.orgID,
    }
    this.api.transportManagerEmployeeList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.employeeObj = res.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getListShift() {
    let obj = {
      orgID: this.userData.orgID
    }
    this.api.ListShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.shiftObj = res.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }
  getVehicleList(){
    let obj = {
      orgID: this.userData.orgID,
    }
    this.api.transportManagerVehicleList(obj).then((res:any)=>{
      if (res.status == 'success') {
      this.vehicleObj=res.data;
      }
    }).catch((err) => {
      console.log(err)
    })
  }  
  /* Field Change */
  fieldChange(field,type,e:any){
    let value='';
    if (field == 'employees') {
      this.getEmployeeTable();
    }
    if (type != 'dropdown') {
      if (field != 'employeeInfo' && field != 'cabInfo') {
        value = e.target.value;
      }
      if (field == 'employeeInfo' || field == 'cabInfo') {
        value = e;
      }
    }
    else if(type=='dropdown'){
      value=e.value;
    }
  }
  isNumber(o): boolean {
    return ! isNaN (o - 0) && o !== null && o !== '' && o !== false;
  }
  onChangeHour(event, val) {
    if(val=='eta'){
      this.pickupPointModalForm.controls['ETA'].patchValue('');
      this.etaTime=event;
      this.pickupPointModalForm.controls['ETA'].patchValue(event);
    }
  }
  /* filter cabs */
  getCabTable() {
    let filteredCabsList = [];
    this.routeObj.filter((item) => {
      if (item.cabInfo) {
        item.cabInfo.filter((cab) => {
          if (filteredCabsList.indexOf(cab) == -1) {
            filteredCabsList.push(cab);
          }
        })
      }
    })
   /*  this.data=filteredCabsList;
    console.log('options',this.data); */
    this.fieldOptions[0].vehicles= filteredCabsList;
    let finalObj=this.vehicleObj;
      filteredCabsList.filter((item)=>{
        this.vehicleObj.filter((element,index)=>{
          if(item.vehicleID==element.vehicleID){
            finalObj.splice(index,1);
          }
        })
      })
      //this.fieldOptions[0].vehicles= finalObj;
      console.log(finalObj);
      console.log(this.routeObj);
    }
  /* Get Employee Table*/
  getEmployeeTable(){
    this.tableView=false;
    let companyTransport=[];
    console.log('employee obj',this.employeeObj);
    this.shiftwiseEmployee = this.employeeObj.filter((item) => item.shift == this.selectedShift);
    companyTransport = this.employeeObj.filter((item) => item.shift == this.selectedShift && item.transportType == 'company');
    this.shiftwiseRoute = [];
    this.routeObj.filter((route) => {
      if (route.routeShift == this.selectedShift) {
        if (route.employeeInfo) {
          route.employeeInfo.filter((singleEmp) => {
            if (this.shiftwiseRoute.indexOf(singleEmp) == -1) {
              this.shiftwiseRoute.push(singleEmp);
            }
          })
        }
      }
    });
    console.log('shiftwise employee',this.shiftwiseEmployee);
    console.log('shiftwise route employee',this.shiftwiseRoute);
      let final=this.shiftwiseEmployee;
      this.shiftwiseRoute.filter((item)=>{
        this.shiftwiseEmployee.filter((element,index)=>{
          if(item.employeeID==element.employeeID){
            final.splice(index,1);
          }
        })
      })
      this.tableObj = final;
      this.tableCols = this.empCols;
      //this.checkTableError(this.tableDataType,'employeeTable');
      this.tableDataType = 'employeeTable';
      this.tableView=true;
  }
}
