import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { CreateVehicleComponent } from './create-vehicle/create-vehicle.component';
import { EditVehicleComponent } from './edit-vehicle/edit.vehicle.component';
import { EditvehicleComponent } from './editvehicle/editvehicle.component';
import { SingleVehicleViewComponet } from './view-vehicle/single-vehicle.component';
import { ViewVehicleComponent } from './view-vehicle/view-vehicle.component';

export const VehicleRouting = [
    { path: '', redirectTo: 'view-vehicle', pathMatch: 'full' },
    // { path: '',component:ViewVehicleComponent },
    { path: 'add-vehicle', component: AddVehicleComponent},
    {path: 'create-vehicle', component: CreateVehicleComponent},
    { path: 'view-vehicle', component: ViewVehicleComponent},
    { path: 'single-view-vehicle', component: SingleVehicleViewComponet},
    { path: 'edit-view-vehicle', component: EditVehicleComponent},
    {path: 'edit-vehicle', component: EditvehicleComponent}

];
