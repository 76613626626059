import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
import { MapService } from 'src/app/Services/map.service';
import { SharedService } from 'src/app/Services/shared.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { VehicleModule } from '../../../../../../modules/information/vehicle';


@Component({
  selector: 'add-vehicle',
  templateUrl: './add-vehicle.component.html',
  styles: ['./add-vehicle.component.css']
})


export class AddVehicleComponent {
  public VehicleInfo = VehicleModule;
  // public VehicelModuleInfo =
  userType: string;
  userData: any;
  VendorInfos: any;
  vendorOptions = {};
  public selectedValueClose = false;
  public address;
  public vehicleloc;
  public locationSuggestions: any;
  public vehicleLocation;
  public myMap: any = this.map.retMap();
  public searchInput: any = new Subject<string>();
  public destroy$: any = new Subject();

  constructor(public api: ApiService,
              public storage: StorageService,
              public dialogService: DialogActionService,
              public swal: SweetAlert,
              public location: Location,
              public router: Router,
              private sub: SharedService,
              public fb: FormBuilder,
              private map: MapService,
  ) {
    this.userType = this.storage.get('userType');
    this.VendorInfos = this.storage.get('vendorID_orgID');
    this.userData = this.storage.get('userInfo');

  }
  ngOnInit() {
    if (this.userType != 'transport_manager') {
      this.router.navigate(['/organization/transport-manager/login']);
    }
    this.loadMap();
    this.myMap.mymap.onMapClick((e: any) => {
      this.locationSuggestions = [];
      this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
    });

    this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
      if (value.val.target.value != '') {
        const val = { key: 'vehicleLocation', val: value.val.target.value };
        this.getGeoCode(val);
        console.log(val);
      } else if (value.val.target.value.trim() == '') {
        this.emptySuggestions();
      }
      console.log(value.val.target.value);
      console.log(value.val);
    });
  }
  loadMap(marker: any = []) {
    this.map.loadMap('pickuppoint', marker);
  }
  getGeoCode(value) {
    const key = value.key;
    if (key == 'vehicleLocation') {
      this.locationSuggestions[key] = [];
    }
    this.myMap.mymap.geocoder(value.val, (res) => {
      try {
        const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
        console.log('Loading GEO code ', key);
        if (key == 'mapclick') {
          this.locationSuggestions = {};
          this.address = result[0];
          this.vehicleloc = this.address.name;
          this.vehicleLocation = this.address;
          this.selectedValueClose = true;

        } else {
          this.locationSuggestions[key] = result;
          console.log('Else Part', this.locationSuggestions);
        }
      } catch (er) { console.log(er); }
    });
  }
  selectedValue(value) {
    this.selectedValueClose = true;
    this.locationSuggestions = {};
    this.address = value;
    this.vehicleloc = this.address.name;
    this.vehicleLocation = value;
  }
  emptySuggestions() {
    this.locationSuggestions = {};
    this.selectedValueClose = false;
    this.vehicleLocation = null;
  }
  close() {
    const empLocation: any = document.getElementById('location');
    let sendData = null;
    if (this.vehicleLocation != null && this.vehicleLocation.name) {
      empLocation.value = this.vehicleLocation.name;
      sendData = this.vehicleLocation.name;
    } else {
      empLocation.value = null;
      sendData = null;
    }
    this.sub._subject.next({
      type: 'vehicleLocation',
      data: sendData,
    });
  }
  submitted(e: any) {
    if (e.valid) {
      const latLong = this.vehicleLocation.center;
      console.log('latlong', latLong);
      const obj = {
        orgID: this.userData.orgID,
        vendorID: this.VendorInfos.vendorID,
        ...e.value
      };
      const obj2 = {
        lat: latLong[1],
        lng: latLong[0],
        name: this.vehicleLocation.name,
      };
      obj.location = obj2;
      console.log(obj);
      this.api.transportManagerVehicleCreate(obj).then((res: any) => {
        if (res.status == 'success') {
          this.swal.swalFunction(res.status, res.status, res.message, 2000);
          this.router.navigate(['/organization/transport-manager/information/vehicle/view-vehicle', { orgID: obj.orgID, vendorID: obj.vendorID }]);
        } else {
          this.swal.swalFunction('error', res.status, res.message, 2000);
        }

      }).catch((err) => {
        console.log(err);
      });
    } else {
      return;
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
