import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caps'
})
export class CapsPipe implements PipeTransform {

  transform(value: string): string {
    // console.log(value)
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }

}
