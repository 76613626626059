import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
    selector: 'app-single-vehicle',
    templateUrl: './single.html'
})

export class SingleVehicleViewComponet {
    vendorID: string;
    vehicelData: any;
    orgID: any;
    vehicleID: any;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public storage: StorageService,
        public api: ApiService

    ) {
        this.route.paramMap.subscribe(params => {
            this.vendorID = params.get('vendorID');
            this.orgID = params.get('orgID');
            this.vehicleID = params.get('vehicleID');
        });

        this.vehicelData = this.storage.get('vehicleData');
    }

    ngOnInit() {
        this.getVehicleData();
    }
    getVehicleData() {
        const obj = {
            orgID: this.orgID,
            vendorID: this.vendorID,
            vehicleID: this.vehicleID
        };
        this.api.transportManagerVehicleList(obj).then((res: any) => {
            if (res.status == 'success') {
                this.vehicelData = res.data[0];
            }
        }).catch((err) => {
            console.log(err);
        });
    }
}
