import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ShiftModel } from 'src/app/modules/information/shift';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
declare const Swal;

@Component({
  selector: 'app-list-shift',
  templateUrl: './list-shift.component.html',
  styleUrls: ['./list-shift.component.css']
})
export class ListShiftComponent implements OnInit {
  public shiftInformation = [];
  public branchInformation=[];
  public searchInput: any = new Subject<string>();
  searchedValue: any;
  constructor(public api: ApiService,
              public storage: StorageService,
              public router: Router,
              public swal: SweetAlert) { }

  ngOnInit(): void {
    this.getListShift();
    this.getBranchList();
  }
  getListShift() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.ListShift(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.shiftInformation = res.data;
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  getBranchList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.branchInformation = res.data;
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  getBranchName(id){
    let name='';
    this.branchInformation.filter((e)=>{
    if(e.branchID==id){
      name=e.branchName;
    }  
    });
    return name;
  }
  deleteShiftData(e: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want be able to delete this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('delete click');
        this.deleteShift(e);
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      }
    });
  }
  deleteShift(e: any) {
    console.log(e);
    const obj = {
      orgID: this.storage.get('userType').orgID,
      shiftID: e.shiftID
    };
    this.api.DeleteShift(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') { this.getListShift(); }
      if (res.status == 'failure') { this.swal.swalFunction('error', res.status, res.message, 2000); }

    }).catch((err) => {
      console.log(err);
    });
  }
  editShiftData(e: any) {
    this.router.navigate(['/organization/transport-manager/information/edit-shift', { shiftID: e.shiftID }]);
    console.log(e);
  }
  viewShift(item) {
    console.log(item);
  }

}
