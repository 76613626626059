import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShiftModel } from 'src/app/modules/information/shift';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
declare const Swal;

@Component({
  selector: 'app-edit-shift',
  templateUrl: './edit-shift.component.html',
  styleUrls: ['./edit-shift.component.css']
})
export class EditShiftComponent implements OnInit {
  shiftID: string;
  editShiftDataValue: any;
  enableForm = false;
  editShiftForm: FormGroup;
  public branchInformation=[];
  public fieldOptions=[{
    branch:[],
  }];
  public exportTime;
  //  = { hour: 0, minute: 0, meriden: 'PM', format: 12 ,val:'startData'};
  public exportTime2;
  startTime: any;
  endTime: any;
  orgID: any;
  // = { hour: 0, minute: 0, meriden: 'PM', format: 12 ,val:'startData'};
  constructor(public api: ApiService, private formBuilder: FormBuilder,
              public swal: SweetAlert,
              public storage: StorageService,
              public router: Router,
              public route: ActivatedRoute) {
    this.route.paramMap.subscribe(paramas => {
      // console.log(paramas.get('shiftID'))
      this.shiftID = paramas.get('shiftID');
    });
    this.orgID = this.storage.get('userInfo').orgID;
  }

  ngOnInit(): void {
    this.getEditList();
    this.shiftFormLoad();
    this.getBranchList();
  }

  getEditList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID,
      shiftID: this.shiftID
    };
    this.api.ListShift(obj).then((res: any) => {
      console.log(res);
      this.editShiftDataValue = res.data;
      this.setValueShiftData();

    }).catch((err) => {
      console.log(err);
    });
  }
  getBranchList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.branchInformation = res.data;
        this.addBranchOptions();
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  addBranchOptions(){
    if(this.branchInformation && this.branchInformation.length>0){
      this.branchInformation.filter((e)=>{
        let option={name:e.branchName,value:e.branchID};
        this.fieldOptions[0].branch.push(option);
      });
    }
    console.log(this.fieldOptions[0].branch);
  }

  formatDate(nowDate) {
    return (
      nowDate.getDate() +
      '/' +
      (nowDate.getMonth() + 1) +
      '/' +
      nowDate.getFullYear()
    );
  }
  shiftFormLoad() {
    this.editShiftForm = this.formBuilder.group({
      shiftName: [],
      branch: [],
      // startTime: [''],
      // endTime: [''],
      status: []
    });

  }
  setValueShiftData() {
    this.editShiftForm.patchValue({
      shiftName: this.editShiftDataValue[0].shiftName,
      branch: this.editShiftDataValue[0].branch,
      status: this.editShiftDataValue[0].status.toString(),
      // startTime:this.exportTime,
      // endTime:this.exportTime2
    });

    this.exportTime = this.editShiftDataValue[0].startTime
    this.exportTime2 = this.editShiftDataValue[0].endTime
    this.startTime = this.exportTime;
    this.endTime = this.exportTime2;
    if(Number(this.exportTime.minute)<10){
      this.exportTime.minute=this.exportTime.minute.substring(1);
    }
    if(Number(this.exportTime2.minute)<10){
      this.exportTime2.minute=this.exportTime2.minute.substring(1);
    }
      console.log(this.editShiftForm.value, this.exportTime, this.exportTime2)
  }
  onSubmit() {

    console.log(this.editShiftForm.value);
    console.log(this.startTime, this.endTime);
    const obj = {
      ...this.editShiftForm.value,
      startTime: this.startTime,
      endTime: this.endTime,
      shiftID: this.shiftID
    };
    obj['status']=Number(obj['status']);
    this.api.UpdateShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction('success', res.status, res.message, 2000);
        this.router.navigate(['/organization/transport-manager/information/list-shift']);
      }
      if (res.status == 'failure') {
        // this.swal.swalFunction('error','res','msg',2000)
        this.swal.swalFunction('error', res.status, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  onChangeHour(event, val) {
    if (val == 'start-time') { this.startTime = event; }
    if (val == 'end-time') { this.endTime = event; }

  }
  delete() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want be able to delete this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('delete click');
        this.deleteShift();
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      }
    });
  }
  deleteShift() {
    const obj = {
      orgID: this.orgID,
      shiftID: this.shiftID
    };
    this.api.DeleteShift(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 20000);
        this.router.navigate(['/organization/transport-manager/information/list-shift']);
      }
      if (res.status == 'failure') {
        this.swal.swalFunction('error', res.status, res.message, 20000);
      }
    });
  }
}
