import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-rostering',
  templateUrl: './rostering.component.html',
  styleUrls: ['./rostering.component.css']
})
export class RosteringComponent implements OnInit {
  routeInfoAll={
    vechileCnt:40,
    pickup:25,
    drop:15,
    assigned:35,
    running:30,
    employe:600,
    male:350,
    female:250,
    borded:60,
    deboarded:50,
    nopresent:10,
    sosCnt:20,
    activeCompliants:40,
    overdueCompliants:30,
    routeTime:'12:30',
    routePoint:'velachery',
    pickCnt:60
  }

 
  constructor() { }

  ngOnInit(): void {
  }

}
