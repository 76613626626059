import { Component, OnInit } from '@angular/core';
import { complaintModule } from '../../../../../../modules/complaints';
@Component({
  selector: 'view-complaints',
  templateUrl: './view-complaints.component.html',
  styleUrls: ['./view-complaints.component.css']
})
export class ViewComplaintsComponent implements OnInit {
  constructor() { }
  isShowCom: boolean;
  public complaintDetails: any = complaintModule;
  public values: any = [];
  public editForm: boolean;
  public cols = [
    { key: 'complaintName', display: 'Complaint Name' },
    { key: 'department', display: 'Department' },
    { key: 'email', display: 'Email' },
    { key: 'accusedName', display: 'Accused Name' },
    //   { key: "country", display: "Country" },
    {
      key: 'action',
      display: 'Action',
      config: {
        isAction: true,
        actions: [
          { display: 'view', color: 'primary', icon: 'View' },
          { display: 'delete', color: 'warn', icon: 'Delete' },
          { display: 'update', color: 'primary', icon: 'EditContact' }
        ]
      }
    }
  ];
  public complaintData = [
    {
      complaintName: 'sdafs',
      department: 'Dept1',
      phoneNumber: '232332',
      email: 'rajae@groupten.com',
      date: '20/10/2020',
      accusedName: 'asfsdaf',
      accusedDepartment: 'Dept1',
      relationship: 'sdfdfsa',
      accusedPhoneNumber: '34344334',
      accusedEmail: 'dss@gmail.com',
      dateOfIncident: '22/10/2020',
      eventOccur: 'sdfsdfd',
      explainOccurred: 'sdfdsfdsf'
    },
    {
      complaintName: 'Com2',
      department: 'Dept2',
      phoneNumber: '232332443',
      email: 'rajasanthi19@groupten.com',
      date: '20/10/2020',
      accusedName: 'asfsdaf',
      accusedDepartment: 'Dept2',
      relationship: 'sdfdfsa',
      accusedPhoneNumber: '34344334',
      accusedEmail: 'dsseee@gmail.com',
      dateOfIncident: '24/10/2020',
      eventOccur: 'sdfsdfd',
      explainOccurred: 'sdfdsfdsf'
    }
  ];

  ngOnInit(): void {
  }
  onActionHandler(e: any) {
    // console.log(e)
    const data = e.data;
    if (e.action.display == 'update') {
      for (const f of this.complaintDetails) {
        const name = f.name;
        let value = '';
        if (f.type == 'dropdown') {
          const val = data[name].split(',');
          const temp = [], str = [];
          val.map(item => { temp.push({ value: val, name: val }); str.push(item); });
          f.selected = temp;
          f.value = str.join(',');
        } else {
          value = name;
          f.value = data[value];
        }

      }
      //   console.log(this.empFields)
      this.editForm = true;
    } else if (e.action.display.toLowerCase() == 'delete') {
      console.log('openDeleteProductModal');
      //   this.openDeleteProductModal();
    }
  }
  submitForm(e: any) {

    if (e == 0) { this.editForm = false; } else {
      console.log(e);
    }
  }
  takeActionComp() {
    this.isShowCom = !this.isShowCom;
  }
  deleteComp() {

  }
}
