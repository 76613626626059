import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

declare const Swal;

@Component({
  selector: 'view-vehicle',
  templateUrl: './view-vehicle.component.html',
  styles: ['./view-vehicle.component.css']
})


export class ViewVehicleComponent {
  constructor(public router: Router, public storage: StorageService,
              public dialogService: DialogActionService,
              public swal: SweetAlert,
              public location: Location,
              public api: ApiService,
              public route: ActivatedRoute) {
    this.route.paramMap.subscribe(params => {
      console.log(params.get('orgID'));
      this.orgID = params.get('orgID');
      this.vendorID = params.get('vendorID');
    });
    this.userType = this.storage.get('userType');


  }
  userType: any;
  noDataMsg: string;
  orgID: string;
  vendorID: string;
  public values: any = [];
  public editForm: boolean;
  public cols = [
    { key: '#', display: '#' },
    { key: 'vehRegNo', display: 'Vehicle Reg No' },
    { key: 'make', display: 'Make' },
    { key: 'capacity', display: 'Capacity' },
    { key: 'vehicleInfo', display: 'Vehicle Info' },
    { key: 'boxType', display: 'Box Type' },
    { key: 'model', display: 'Model Vehicle' },
    {
      key: 'rcExpiryDate', display: 'RC Expiry Date',
      config: {
        isDate: true,
        format: 'dd MMM YYYY'
      }
    },
    {
      key: 'action',
      display: 'Action',
      config: {
        isAction: true,
        actions: [
          { display: 'view', color: 'primary', icon: 'View' },
          { display: 'delete', color: 'warn', icon: 'Delete' },
          { display: 'update', color: 'primary', icon: 'EditContact' }
        ]
      }
    }
  ];
  public VehicleInfo;
  ngOnInit() {
    if (this.userType != 'transport_manager') {
      this.router.navigate(['/organization/transport-manager/login']);
    }
    this.getListVehicle();
  }

  onActionHandler(e: any) {
    // console.log(e)
    const data = e.data;
    if (e.action.display == 'update') {
      this.router.navigate(['/organization/transport-manager/information/vehicle/edit-vehicle', { orgID: e.data.orgID, vendorID: e.data.vendorID, vehicleID: e.data.vehicleID }]);
      // for (let f of this.VehicleInfo) {
      //   let name = f['name'];
      //   let value = '';
      //   if (f.type == 'dropdown') {
      //     let val = data[name].split(',');
      //     let temp = [], str = [];
      //     val.map(item => { temp.push({ value: val, name: val }); str.push(item) });
      //     f.selected = temp;
      //     f['value'] = str.join(',');
      //   } else if (f.type == 'checkbox') {
      //     let spStr = data[name].split(',')
      //     for (let op of f.options) {
      //       if (spStr.indexOf(op.value) !== -1) op.selected = true;
      //     }
      //   } else {
      //     if (name == 'email') value = 'emailID';
      //     else value = name;
      //     f['value'] = data[value];
      //   }

      // }
      // this.editForm = true;
    } else if (e.action.display.toLowerCase() == 'delete') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want be able to delete this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('delete click');
          this.deleteVehicleData(e);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        }
      });
    } else if (e.action.display.toLowerCase() == 'view') {
      console.log(e);
      this.router.navigate(['/organization/transport-manager/information/vehicle/single-view-vehicle', { orgID: e.data.orgID, vendorID: e.data.vendorID, vehicleID: e.data.vehicleID }]);
    }
  }
  deleteVehicleData(e?) {

    const obj = {
      orgID: e.data.orgID,
      vendorID: e.data.vendorID,
      vehicleID: e.data.vehicleID,
    };
    this.api.transportManagerVehicleDelete(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
        this.getListVehicle();
      } else {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  submitted(e: any) {
    console.log(e);
  }
  submitForm(e: any) {

    if (e == 0) { this.editForm = false; } else {
      console.log(e);
    }
  }
  getListVehicle() {
    // if (this.orgID && this.vendorID == null) {
    //   let obj = {
    //     orgID: this.storage.get('vendorID_orgID').orgID,
    //     vendorID: this.storage.get('vendorID_orgID').vendorID
    //   }
    // }
    const obj = {
      orgID: this.orgID == null ? this.storage.get('vendorID_orgID').orgID : this.orgID,
      vendorID: this.vendorID == null ? this.storage.get('vendorID_orgID').vendorID : this.vendorID,
    };
    this.api.transportManagerVehicleList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.VehicleInfo = res.data;
        console.log(this.VehicleInfo.length);

      }
    }).catch((err) => {
      console.log(err);
    });
  }
  searchValue(e) {
    console.log(e);
  }
}
