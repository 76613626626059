import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { MapService } from 'src/app/Services/map.service';
import { SharedService } from 'src/app/Services/shared.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { VehicleModule } from '../../../../../../modules/information/vehicle';

@Component({
    selector: 'app-edit-vehicle',
    templateUrl: './edit-vehicle.component.html'
})

export class EditVehicleComponent {
    vehicleModuleData = VehicleModule;
    orgID: string;
    vendorID: string;
    vehicleID: string;
    enableForm: boolean;

    public selectedValueClose = false;
    public address;
    public vehicleloc;
    public vehicleLocation;
    public locationSuggestions: any = {};
    public myMap: any = this.map.retMap();
    public searchInput: any = new Subject<string>();
    public destroy$: any = new Subject();

    constructor(
        public api: ApiService,
        public router: Router,
        public route: ActivatedRoute,
        public swal: SweetAlert,
        public sub: SharedService,
        public fb: FormBuilder,
        private map: MapService,
    ) {
        this.route.paramMap.subscribe(params => {
            console.log(params.get('orgID'), params.get('vehicleID'), params.get('vendorID'));
            this.orgID = params.get('orgID');
            this.vendorID = params.get('vendorID');
            this.vehicleID = params.get('vehicleID');
        });
    }
    ngOnInit() {
        this.getVehicleData();
        this.loadMap();
        this.myMap.mymap.onMapClick((e: any) => {
            this.locationSuggestions = [];
            this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
        });

        this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
            if (value.val.target.value != '') {
                const val = {key: 'vehicleLocation', val: value.val.target.value};
                this.getGeoCode(val);
                console.log(val);
            } else if (value.val.target.value.trim() == '') {
              this.emptySuggestions();
            }
        });
    }
    loadMap(marker: any = []) {
        this.map.loadMap('pickuppoint', marker);
      }
      getGeoCode(value) {
        const key = value.key;
        if (key == 'vehicleLocation') {
          this.locationSuggestions[key] = [];
        }
        this.myMap.mymap.geocoder(value.val, (res) => {
          try {
            const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
            console.log('Loading GEO code ', key);
            if (key == 'mapclick') {
              this.locationSuggestions = {};
              this.address = result[0];
              this.vehicleloc = this.address.name;
              this.vehicleLocation = this.address;
              this.selectedValueClose = true;

            } else {
              this.locationSuggestions[key] = result;
              console.log('Else Part', this.locationSuggestions);
            }
          } catch (er) { console.log(er); }
        });
      }
    selectedValue(value) {
        this.selectedValueClose = true;
        this.locationSuggestions = {};
        this.address = value;
        this.vehicleloc = this.address.name;
        this.vehicleLocation = value;
      }
      emptySuggestions() {
        this.locationSuggestions = {};
        this.selectedValueClose = false;
        this.vehicleLocation = null;
      }
      close() {
        const empLocation: any = document.getElementById('location');
        let sendData = null;
        if (this.vehicleLocation != null && this.vehicleLocation.name) {
          empLocation.value = this.vehicleLocation.name;
          sendData = this.vehicleLocation.name;
        } else {
          empLocation.value = null;
          sendData = null;
        }
        this.sub._subject.next({
          type: 'vehicleLocation',
          data: sendData,
        });
    }
    getVehicleData() {
        const obj = {
            orgID: this.orgID,
            vendorID: this.vendorID,
            vehicleID: this.vehicleID
        };
        this.api.transportManagerVehicleList(obj).then((res: any) => {
            if (res.status == 'success') {
                console.log(res.data[0]);
                for (const f of this.vehicleModuleData) {
                    const name = f.name;
                    let value = '';
                    if (f.name == 'location') {
                        this.vehicleLocation = res.data[0].location;
                        console.log(this.vehicleLocation);
                        res.data[0].location = this.vehicleLocation.name ? this.vehicleLocation.name : '';
                        this.selectedValueClose = true;
                    }
                    if (f.type == 'date') {
                        // let date = res.data.[0][]
                    }
                    if (f.type == 'dropdown') {
                        const val = res.data[0][name].split(',');
                        const temp = [], str = [];
                        val.map(item => { temp.push({ value: val, name: val }); str.push(item); });
                        // f.selected = temp;
                        f.value = str.join(',');

                        if (f.name == 'boxType') {
                            const boxTypeOther = {
                                type: 'text',
                                name: 'vendorName',
                                label: 'Vendor Name',
                                value: res.data[0].vendorName,
                                required: true,
                                col: 'col-md-4'
                              };
                            const boxTypeGroupten = {
                                type: 'text',
                                name: 'simNo',
                                label: 'Sim No',
                                value: res.data[0].simNo,
                                required: true,
                                col: 'col-md-4'
                              };
                            if (f.value == 'others') {
                            const index = this.vehicleModuleData.findIndex(e => e.name == 'others');
                            if (res.data[0].others && index == -1) {
                                  this.vehicleModuleData.push(boxTypeOther);
                                }
                            } else if (f.value == 'groupten') {
                              const index = this.vehicleModuleData.findIndex(e => e.name == 'simNo');
                              if (res.data[0].simNo && index == -1) {
                                this.vehicleModuleData.push(boxTypeGroupten);
                            }
                          }
                        }
                    } else if (f.type == 'date') {
                        f.value = this.formatDate(new Date(res.data[0][name]));
                        console.log('date', f.value);
                    } else {
                        value = name;
                        f.value = res.data[0][name];
                    }
                }
                console.log(this.vehicleModuleData);
                this.enableForm = true;
            }
        });
    }
    submitted(e: any) {
        if (e.valid) {
            const obj = {
                orgID: this.orgID,
                vendorID: this.vendorID,
                vehicleID: this.vehicleID,
                ...e.value
            };
            let latLong;
            let obj2;
            if (this.vehicleLocation.center) {
                latLong = this.vehicleLocation.center;
                obj2 = {
                lat: latLong[1],
                lng: latLong[0],
                name: this.vehicleLocation.name,
                };
                obj.location = obj2;
            } else {
                latLong = this.vehicleLocation;
                obj.location = latLong;
            }
            console.log(obj);
            this.api.transportManagerVehicleUpdate(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                this.router.navigate(['/organization/transport-manager/information/vehicle/view-vehicle', {orgID: obj.orgID, vendorID: obj.vendorID}]);
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
        });
    } else {
        return;
    }
}
formatDate(nowDate) {
    return (
        nowDate.getDate() +
        '/' +
        (nowDate.getMonth() + 1) +
        '/' +
        nowDate.getFullYear()
    );
}
ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
   }
}
