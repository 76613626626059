import { Component } from '@angular/core';

@Component({
    selector: 'app-pagination',
    template: `<div class="container-fluid pt-2" style="position: fixed;bottom: 19px;    background: white;">
    <div class="row text-center">
    <div class="col align-self-center">
    <div class="pagination">
  <a disabled><i class="fas fa-angle-double-left"></i></a>
  <a class="active">1</a>
  <a  >2</a>
  <a >3</a>
  <a >4</a>
  <a >5</a>
  <a >6</a>
  <a ><i class="fas fa-angle-double-right"></i></a>
</div>
</div>
</div>
</div>

    `,
    styles: [
        ` .pagination {
            display: inline-block;
          }

          .pagination a {
            color: black;
            float: left;
            border-radius: 41px;
            padding: 1px 7px;
            text-decoration: none;
            transition: background-color .3s;
            border: 1px solid #4d7cfe;
            margin: 0 1px;
        
          }

          .pagination a.active {
            background-color: #4d7cfe;
            color: white;
            border: 1px solid #c5c5c7;
          }

          .pagination a:hover:not(.active) {background-color: #ddd}`
    ]
})
export class PaginationComponent {

}
