import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-route-trip-mangement',
  templateUrl: './route-trip-mangement.component.html',
  styleUrls: ['./route-trip-mangement.component.css']
})
export class RouteTripMangementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
