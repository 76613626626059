import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit {
  userData: any;
  VendorInfos: any;
  isShowOrgFields = false;

  constructor(public storage: StorageService,
              public api: ApiService) {
    this.userData = this.storage.get('userData');
  }

  ngOnInit(): void {
  //  this.getVenderData()
  }

}
