import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-semi-automatic-rostering',
  templateUrl: './semi-automatic-rostering.component.html',
  styleUrls: ['./semi-automatic-rostering.component.css']
})
export class SemiAutomaticRosteringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
