import { Component, OnInit } from "@angular/core";
import { StorageService } from "src/app/Services/storage.service";

@Component({
    selector: 'single-view-regular-roastering',
    templateUrl: './single-view-regular-manual-roastering.html',
    styles: [`
    .s43 {
        font-size: 43px;
        color: darkgray;
    }
    .vehBoard {
        border: solid 1px #c3c2c2;
        border-radius: 3px;
        background: yellow;
        font-weight: 600;
        padding: 2px;
    }
    `]
})
export class SingleViewRegularManualRoastering implements OnInit {
    branchData: any;
    constructor(
        public storage: StorageService
    ) {
        this.branchData = this.storage.get('branchData')
    }
    ngOnInit() {
        console.log(this.branchData)
    }
}