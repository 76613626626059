import { Component, OnInit, ViewChild } from '@angular/core';
import { ticketModule } from '../../../../../../modules/tickets';
import { interval } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { VehicleModule } from '../../../../../../modules/information/vehicle';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';


@Component({
    selector: 'view-ticket',
    templateUrl: './view.ticket.component.html',
    styles: ['./view.ticket.component.css']
})


export class ViewTicketComponent {
  constructor() {}
  public VehicleInfo = VehicleModule;


  // scrollLeft(el: Element) {
  //   const animTimeMs = 800;
  //   const pixelsToMove = 250;
  //   const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
  //   interval(animTimeMs / 8)
  //     .pipe(
  //       takeWhile(value => value < 8),
  //       tap(value => el.scrollLeft -= (pixelsToMove * stepArray[value])),
  //     )
  //     .subscribe();
  // }

  // scrollRight(el: Element) {
  //   const animTimeMs = 800;
  //   const pixelsToMove = 250;
  //   const stepArray = [0.001, 0.021, 0.136, 0.341, 0.341, 0.136, 0.021, 0.001];
  //   interval(animTimeMs / 8)
  //     .pipe(
  //       takeWhile(value => value < 8),
  //       tap(value => el.scrollLeft += (pixelsToMove * stepArray[value])),
  //     )
  //     .subscribe();
  // }



  todo = [
    {title: 'Cab clubbing issue', description: 'On daily basis, we are having issues with the cab routing .. its being clubbed.....it take around 2-3 hours to reach home ... If cab routing is fine enough we can reach with in 1 hours max', status: 'Urgent', assignedDate: '12/10/2020'},
    {title: 'Driver being rude and shrewd', description: 'Easy cabs is very unprofessional and unethical working with shrewd people like this driver for instance and its too expensive.', status: 'Urgent', assignedDate: '12/10/2020'},
    {title: 'Non arrival of cab', description: ' Cab driver did not come . Neither he picked up phone despite several tries . Upon contacting booking Centre again they simply say sorry can\'t arrange cab .', status: 'Urgent', assignedDate: '12/10/2020'},
  ];

  hold = [
    {title: 'Poor service provided ', description: 'I needed to be travelled from Khanapara Fly Over, Guwahati to Kalibari Rest Camp, Guwahati. I had stood there for about 75 minutes but the pilot did not arrived', status: 'Normal', assignedDate: '12/10/2020'},
    {title: 'Driver did not return my phone', description: 'I took a ride from uber on ** June **** from Salt Lake, Kolkata at *:** p.m. and reached Birla Planetarium at *:** p.m. But unfortunately, I left my Asus Zenfone Max cell phone inside the cab. After ** minutes I realised that the phone is not w..', status: 'Normal', assignedDate: '12/10/2020'},
    {title: 'Accidental dash by the cab', description: 'Today, On Hinjewadi crossing an UBER cab No. MH ** NB **** has dashed our car. The damage is on the front of the right wheel side. After giving a series of horns he doesn\'t stop and hit. Without any indicator, he was coming....', status: 'Normal', assignedDate: '12/10/2020'},

  ];

  inprogress = [
    {title: 'CAB NOT COMING ON TIME', description: 'I called the cab driver that he was supposed to report at 8:45 PM and where is he? Cab driver was very adamant and answered that you can arrange your vehicle i would take another 20-25 mins to reach,', status: 'Urgent', assignedDate: '12/10/2020'},
    {title: 'No show', description: 'Cab driver did not come . Neither he picked up phone despite several tries . Upon contacting booking Centre again they simply say sorry can\'t arrange cab', status: 'Urgent', assignedDate: '12/10/2020'},
    {title: 'Regarding abusive driver ', description: 'Driver used the abusive language to the women though it was not our fault he was saying to charge ** rs extra as the road work was in progress and he was busy talking the shits with us with loud voice and verbal aggression and he said us to get      ', status: 'Urgent', assignedDate: '12/10/2020'},
  ];

  done = [
    {title: 'Pathetic Experience - Various Complaints', description: 'horrible Cab - Exteriors of Vehicle was run down, dented and badly scarred - worse than the worse', status: 'Urgent', assignedDate: '12/10/2020'},
    {title: 'Pathetic Vehicle & Service', description: 'Horrible Cab - Exteriors of Vehicle was run down, dented and badly scarred - worse than the worse', status: 'Urgent', assignedDate: '12/10/2020'},

  ];
  ngOnInit() {

  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
}
