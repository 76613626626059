import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/Services/shared.service';
// text,email,tel,textarea,password,
@Component({
    selector: 'textbox',
    template: `
      <div [formGroup]="form">
      <mat-form-field appearance="outline" *ngIf="!field.multiline">
        <mat-label>{{field.label}} {{field.required ? '*':''}}</mat-label>
        <ng-container *ngIf="field && field.click">
        <input matInput [attr.type]="field.type" [id]="field.name" [name]="field.name" [formControlName]="field.name"  [placeholder]="field.placeholder" (click)="field.click($event)">
        </ng-container>
        <ng-container *ngIf="field && !field.click ">
        <input matInput [attr.type]="field.type" [id]="field.name" [name]="field.name" [formControlName]="field.name" [placeholder]="field.placeholder" [value]="field.value">
        </ng-container>
        <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>
      </mat-form-field>


       
      </div>




    `
})
export class TextBoxComponent implements OnInit {
  // <input  [attr.type]="field.type" class="form-control"  [id]="field.name" [name]="field.name" [formControlName]="field.name">
    @Input() field: any = {};
    @Input() form: FormGroup;
    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }

    constructor(
      private sub: SharedService
    ) {
        this.sub._subject.subscribe((e: any) => {
            if (e.type == 'employeeLocation' && this.field.name == 'location' || e.type == 'vehicleLocation' && this.field.name == 'location') {
              const fields: any = this.form.controls[this.field.name];
              console.log(fields);
              if (e.data != null) {
                fields.value = e.data;
                fields.status = 'VALID';
                this.form.value[this.field.name] = e.data;
              }
              if (e.data == null) {
                this.form.value[this.field.name] = e.data;
                fields.status = 'INVALID';
                fields.markAsTouched();
                fields.dirty;
              }
            }
        });
    }
    ngOnInit() {
      const fields: any = this.form.controls[this.field.name];
      if (this.field.name == 'location') {
        if (this.field.value != '') {
          fields.value = this.field.value;
          fields.status = 'VALID';
          this.form.value[this.field.name] = this.field.value;
        } else {
          fields.value = '';
          fields.status = 'INVALID';
          this.form.value[this.field.name] = '';
        }
      }
    }

}


// <mat-form-field appearance="outline"  *ngIf="field.multiline">
// <mat-label>{{field.label}} {{field.required ? '*':''}}</mat-label>
// <textarea style="height: 30px;" [class.is-invalid]="isDirty && !isValid" [formControlName]="field.name" [id]="field.name"
// rows="9" [placeholder]="field.placeholder" matInput></textarea>
// </mat-form-field>