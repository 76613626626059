export const userModule: any = [
  {
    type: 'dropdown',
    name: 'orgID',
    label: 'Organization Name',
    value: '',
    required: true,
    options: [
      { value: '', name: 'Select' },
    ]
  },
     {
        type: 'text',
        name: 'firstName',
        label: 'First Name',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'lastName',
        label: 'Last Name',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'email',
        label: 'Email ID',
        value: '',
        required: true,
      },
      {
        type: 'radio',
        name: 'gender',
        label: 'Gender',
        value: '',
        required: true,
        options: [
          // { value: '', name: 'Select' },
          { value: 'male', name: 'Male'},
          { value: 'female', name: 'Female'  },
          { value: 'other', name: 'Other'}
        ]
      },
      {
        type: 'date',
        name: 'DOB',
        label: 'Date of Birth',
        value: '',
        required: true,
      },

      {
        type: 'number',
        name: 'contactNumber',
        label: 'Contact Number',
        value: '',
        required: true,
      },
      {
        type: 'number',
        name: 'emergencyContact',
        label: 'Emergency Contact',
        value: '',
        // required: false,
      },
      {
        type: 'text',
        name: 'address',
        label: 'Address',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'city',
        label: 'City',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'state',
        label: 'State',
        value: '',
        required: true,
        options: [
          {value: '', name: 'Select'},
          {value: 'tn', name: 'TN'},

        ]
      },
      {
        type: 'dropdown',
        name: 'country',
        label: 'Country',
        value: '',
        required: true,
        options: [
          {value: '', name: 'Select'},
          {value: 'in', name: 'India'},

        ]

      },
      {
        type: 'number',
        name: 'pincode',
        label: 'Pincode',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'jobTitle',
        label: 'Job Title',
        value: '',
        required: true,
      },
      {
        type: 'date',
        name: 'dateOfJoining',
        label: 'Date of Joining',
        value: '',
        required: true,
      },
      {
        type: 'dropdown',
        name: 'jobType',
        label: 'Job Type',
        value: '',
        required: true,
        options: [
          { value: '', name: 'Select' },
          { value: 'full time', name: 'Full Time'},
          { value: 'part time', name: 'Part Time'  },
          { value: 'intern', name: 'Intern'}
        ]
      },
      {
        type: 'text',
        name: 'employeeCode',
        label: 'Employee Code',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'empDesignation',
        label: 'Employee Designation',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'workLocation',
        label: 'work Location',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'reportingTo',
        label: 'Reporting To',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'userRole',
        label: 'User Role',
        value: '',
        required: true,
      },

];
