import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { StorageService } from 'src/app/Services/storage.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/Services/shared.service';
import { SnackBarService } from 'src/app/Services/snackbar.service.js';

@Component({
  selector: 'app-default',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  isShowDirectives = false;
  isShowl = false;
  sideBarOpen = false;
  mobileQuery: MediaQueryList;
  mobileSideBar = true;
  iconMenu: any = '50px';
  menuWithIcon: any = '180px';
  public href = '';
  isShowContent = true;
  token: any;
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              public storage: StorageService,
              private router: Router,
              public shared: SharedService,
              public snackBar: SnackBarService) {

    this.shared._subject.subscribe((data: any) => {
      if (data.type == 'navBar') {
        this.isShowDirectives = data.isShowNavbar;
      }
      // console.log(data.isShowNavbar, this.isShowDirectives);
    });
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.href = this.router.url;
    // console.log(this.href);
    // this.sideBarOpen = !this.mobileQuery.matches
    this.mobileSideBar = !this.mobileQuery.matches;
    this.urlRemove();
    // this.isShowContent = false
    // setInterval(() => {
    //   if(this.router.url != '/organization/transport-manager/login'){
    //     this.triggerSnackbar()
    //   }
    //   console.log(this.router.url,this.href != '/organization/transport-manager/login')
    // }, 100000);
  }
  urlRemove() {
    const url3 = this.href.split('/')[3];
    const url = this.href.split('/')[2];
    let url2, token;
    if (url && url.includes('?')) {
      url2 = url.split('?')[0];
      token = url.split('?token=')[1];
    }


    this.storage.setData({ master_token: token });
    // this.token = this.href.split('?token=')[2]
    // console.log(url2,'token',token)
    if (url == 'login' || url == 'active-link' || url2 == 'update-password' || url == 'update-password' || url3 == 'login') {
      this.isShowContent = false;
      this.isShowDirectives = true;
    } else {
      this.isShowContent = true;
      this.isShowDirectives = false;
    }
  }
  sideBarToggler(val?) {
    // console.log(val)
    this.isShowl = !this.isShowl;
    this.sideBarOpen = !this.sideBarOpen;
    if (this.mobileQuery.matches) {
      this.mobileSideBar = !this.mobileSideBar;
    }
  }


  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  // resposive menu time decomment
  fun() {
    this.sideBarToggler();
  }


  triggerSnackbar() {
    const config = {
      message: '<p class=\'p-0 m-0\'> <span > <i class=\'ms-Icon ms-Icon--InfoSolid text-primary h4 m-0 p-0 icon\' aria-hidden=\'true\'> </i> </span> Notification! </p>',
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5000000
    };

    const snackBarRef: any = this.snackBar.openSnackBar(config);

  }


}
