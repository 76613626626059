import { Component, OnInit } from '@angular/core';
import { KDTreeService } from 'src/app/Services/kdtree.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { MapService } from 'src/app/Services/map.service';
import { AlertData, DriverData, UserData, vehicleData, VendorData } from './alert.data';
declare const $;
@Component({
  selector: 'app-sosalert',
  templateUrl: './sosalert.component.html',
  styleUrls: ['./sosalert.component.css']
})
export class SOSalertComponent implements OnInit {
  isShowNearby = false;
  audioisShow = false;
  isShowEmployee = false;
  date = new Date();
  height: any = '380px';
  public mapHight = '78vh';
  EmployeeData: any = UserData;
  notificationData: any = AlertData;
  public vehicleInfo: any = vehicleData;
  driverData = DriverData
  vendorData = VendorData
  nearbycabData: any = [];
  isShowCabData: any = false;
  isShowEmployeeData: any = false;
  public selectedNotificationDetails: any;
  public empLatLngs: any = [];
  public vehLatLng: any = [];
  public nearestEmployees: any = [];
  public nearestVehicles: any = [];
  constructor(
    private map: MapService,
    private loader: LoaderService,
    private kdTree: KDTreeService
  ) { }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.cabsToEmployee();
    // }, 100);
    this.loadMap();
    this.getEmployeeLatLng();
  }

  getEmployeeLatLng() {
    for (const veh in this.vehicleInfo) {
      this.vehLatLng.push(this.vehicleInfo[veh].location);
    }
    for (const emp in this.EmployeeData) { this.empLatLngs.push(this.EmployeeData[emp].location); }

  }
  loadMap(marker: any = []) {
    this.map.loadMap('leaflet-custom-map', marker);
  }

  employeeToCabs() {
    this.isShowEmployeeData = true;
    this.isShowCabData = false;
    console.log('isShowEmployeeData', this.isShowEmployeeData, 'isShowCabData', this.isShowCabData);
  }
  cabsToEmployee() {
    this.isShowCabData = true;
    this.isShowEmployeeData = false;
    console.log('isShowCabData', this.isShowCabData, 'isShowEmployeeData', this.isShowEmployeeData);
  }

  findLatLngToData(values, data) {
    return new Promise((res, rej) => {
      try {


        const responce = values.map((item) => {
          let temp;
          const lat = item[0].lat;
          const lng = item[0].lng;
          for (const vehicle in data) {
            const location = data[vehicle].location;
            if (lat == location.lat && lng == location.lng) { temp = data[vehicle]; }

          }
          return temp;
        });
        res(responce);

      } catch (error) {
        rej(error);
      }
    });
  }
  async notifiyData(val) {
    this.isShowNearby = true;
    this.loader.show();
    this.selectedNotificationDetails = val;
    this.isShowEmployee = true;
    this.isShowEmployeeData = false;
    // this.mapHight = '50vh';
    const vehicles: any = await this.kdTree.findNearestLocation(this.vehLatLng, val.location);
    const location = await this.kdTree.findNearestLocation(this.empLatLngs, val.location);

    // this.nearestVehicles = await this.findLatLngToData(vehicles, this.vehicleInfo);
    this.nearestEmployees = await this.findLatLngToData(location, this.EmployeeData);
    this.isShowEmployeeData = true;
    const marker = [
      {
        id: 'A',
        content: 'T.Aththipakkam',
        // content : '<div class="mumb_marker_car_red"></div>',
        lat: val.location.lat,
        lng: val.location.lng,
        popup: {
          content: 'Hello <b>A</b> Marker',
          // open: true
        }
      }];
    this.loadMap(marker);
    this.loader.hide();

  }
  close() {
    this.isShowEmployee = false;
    // this.height = '500px'
    this.mapHight = '78vh';
  }
}
