import { Component, OnInit, EventEmitter, Output } from '@angular/core';
let serviceStoreData;
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  @Output() dataTransferToSettingsFinal: EventEmitter<any> = new EventEmitter();
  access: { name: string; control: string; checked: boolean; }[];
  selectAll: any;

  constructor() { }

  ngOnInit(): void {
    if (serviceStoreData) {
      this.access = serviceStoreData;
    } else {
      this.access = [
        { name: 'Fleet Tracking', control: 'fleet', checked: false },
        { name: 'Employee Solution', control: 'empSolution', checked: false },
      ];
    }
  }
  updateCheck() {
    console.log(this.selectAll);

    this.access.map((item) => {
      item.checked = false;
    });
    // }
  }
  onClickEventReceived(event) {
    serviceStoreData = this.access;
    const final = {};
    console.log(this.access);
    const finalOblService = {};
    for (let i = 0; this.access.length > i; i++) {
      finalOblService[this.access[i].control] = this.access[i].checked;
    }
    console.log(finalOblService);
    this.dataTransferToSettingsFinal.emit(finalOblService);
  }
}
