import { Component , OnInit } from '@angular/core';

@Component({
    selector: 'app-notification-dashboard',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.css']

})


export class NotificationComponent {

    constructor() {

    }

    ngOnInit() {

    }
}
