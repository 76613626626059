import { Component } from '@angular/core';

@Component({
    selector: 'app-vendor',
    template: `

  `,
    styles: []
})

export class VendorComponent {
    isShowIcon = false;
    constructor() {

    }
}
// <app-add-vendor *ngIf="isShowIcon"></app-add-vendor>
//   <app-view-vendor *ngIf="!isShowIcon" ></app-view-vendor>
