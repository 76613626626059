export const editSettingsModel = [
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    value: '',
    required: true,
  },

  {
    type: 'text',
    name: 'userEmail',
    label: 'User Email',
    textType: 'email',
    value: '',
    required: true,
  }, {
    type: 'text',
    name: 'designation',
    label: 'Designation',
    value: '',
    required: true,
  }, {
    type: 'number',
    name: 'contactNumber',
    textType: 'mobile',
    label: 'Contact Number',
    value: '',
    required: true,
  },
  {
    type: 'checkbox',
    name: 'alert',
    label: 'alert',
    value: '',
    required: true,
    options: [
      { value: 'push', name: 'push', selected: true },
      { value: 'sms', name: 'sms' },
      { value: 'email', name: 'email' },
      { value: 'call', name: 'call' }
    ],
  },
  {
    type: 'checkbox',
    name: 'services',
    label: 'services',
    value: '',
    required: true,
    options: [
      { value: 'empSolution', name: 'empSolution' },
      { value: 'fleet', name: 'fleet' },
    ],
  },
];
