import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/Services/shared.service';

@Component({
  selector: 'dropdown',
  template: `
      <div [formGroup]="form">

        <searchSelect *ngIf="!field.multiselect"
        [_label]="field.label"   [_inputBG]="'#ffff'"  [_lists]="field.options" [_selected]="field.selected" (result)="action($event)"></searchSelect>

    <div *ngIf="field.multiselect">
            <searchSelect-multi  [_label]="field.label" [_lists]="field.options"
            (result)="actionMulti($event)"
            [_inputBG]="'#ffff'"
            [_hideHeaderOnSelect] = "true"
            [_selected] = "selectedLists"
            [_selectAllRequired]="false"
            ></searchSelect-multi>
    </div>
    <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>
</div>
    `, /**
     <select *ngIf="!field.multiselect" class="form-control" [id]="field.name"
        [formControlName]="field.name">
          <option *ngFor="let opt of field.options" [value]="opt.key">{{opt.label}}</option>
        </select>
         */
  styles: [`
    /* **
    css for multi select drop down
**/
.drop-toggle{
    background-color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    cursor: hand;
    border: 1px solid #ccc;
    width: 233px;
    text-align: left;
}
.drop-toggle i{
    float:right;
}
.drop-show {
    padding: 4px;
    width: 222px;
    background-color: #FFF;
    border: 1px solid #BABABA;
    position: absolute;
    z-index: 100;
    -webkit-box-shadow: 0 6px 10px rgba(0,0,0,.15);
    -moz-box-shadow: 0 6px 10px rgba(0,0,0,.15);
    box-shadow: 0 6px 10px rgba(0,0,0,.15);
    margin-left: 1px;
}
.drop-show label{
    display:block;
    font-size:15px;
    cursor: pointer;
}
.drop-show label input{
    vertical-align: top;
}
.drop-show label span{
    display:inline-block;
}
    `]
})
export class DropDownComponent {
  @Input() field: any = {};
  @Input() form: FormGroup;
  @Input() list: any[];
  @Input() __edit: any;

  checkedList: any[];
  currentSelected: {};
  selectedLists: any = [];
  public showDropDown: boolean;
  constructor(private sub: SharedService, ) {

  }
  ngOnInit() {
    this.loadData();
    this.sub._subject.subscribe((e: any) => {
      if (e.type == 'employeeShift') {
        if (this.field.name == 'shift') {
        /*   let fields=this.form.controls[this.field.name];
          fields.value = '';
          fields.status = "INVALID";
          this.form.value[this.field.name] = ''; */
          this.field.options = e.data;
        }
      }
    });
  }
  loadData() {

    // console.log(this.form.value,this.form.controls,)
  }
  actionMulti(obj: any) {
    this.checkedList = [];
    for (const arr of obj) {
      const chk = this.checkedList.indexOf(arr.value); // console.log(chk)
      if (chk == -1) { this.checkedList.push(arr.value); }
    }
    const fields: any = this.form.controls[this.field.name];
    if (this.checkedList.length > 0) {
      fields.value = this.checkedList.join(',');
      fields.status = 'VALID';
      this.form.value[this.field.name] = this.checkedList.join(',');

    } else {
      fields.value = '';
      fields.status = 'INVALID';
      this.form.value[this.field.name] = '';

    }

  }

  action(ob: any) {
    const fields: any = this.form.controls[this.field.name];
    if (ob.value != '' && ob.value != undefined && ob.value != null) {
      fields.value = ob.value;
      fields.status = 'VALID';
      this.form.value[this.field.name] = ob.value;

    } else {
      fields.value = '';
      fields.status = 'INVALID';
      this.form.value[this.field.name] = '';
    }
    if (this.field.name == 'boxType') {
      this.sub._subject.next({
        type: 'vehicleBoxType',
        data: ob.value,
      });
    }
    if (this.field.name == 'branch') {
      this.sub._subject.next({
        type: 'employeeBranch',
        data: ob.value,
      });
    }
    console.log(this.form.controls[this.field.name]);
    console.log(this.form.controls[this.field.name].valid);

  }
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

}
