import { Component, OnInit } from '@angular/core';
import { complaintModule } from '../../../../../../modules/complaints';

@Component({
  selector: 'create-complaints',
  templateUrl: './create-complaints.component.html',
  styleUrls: ['./create-complaints.component.css']
})
export class CreateComplaintsComponent implements OnInit {

  public complaintDetails = complaintModule;

  constructor() { }

  ngOnInit(): void {
    console.log(this.complaintDetails);
  }

  submitted(e: any) {
    console.log(e);
  }
}
