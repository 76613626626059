import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/Directives/directives.module';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PagesDeclaration } from 'src/app/Pages/pages.declaration';
import { CustomMaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogDataExampleDialog } from 'src/app/Pages/documentation/documentation.component';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/Directives/dialog/dialog.component';
import { PrismModule } from '@ngx-prism/core';
import { SnackbarComponent } from 'src/app/Directives/snackbar/snackbar';
import { ChartsModule } from 'ng2-charts';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { NgxPaginationModule } from 'ngx-pagination';
import { DatePipe } from '@angular/common';
import { CustomPipes } from 'src/app/pipe/pipe.declaration';
// import { FilterPipe } from 'src/app/pipe/filter.pipe';
import { RosteringPageDeclaration } from './../../Directives/roastering/roastering.declaration';
import { MaterialTimePickerModule } from '@candidosales/material-time-picker';



@NgModule({
  declarations: [
    AdminComponent,
    PagesDeclaration,
    DialogDataExampleDialog,
    CustomPipes,
    RosteringPageDeclaration

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    MatSidenavModule,
    MatDividerModule,
    FlexLayoutModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    CustomMaterialModule,
    MatDialogModule,
    PrismModule,
    ChartsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSliderModule,
    MatSlideToggleModule,
    EditorModule,
    MatExpansionModule,
    NgxPaginationModule,
    MaterialTimePickerModule
  ],
  providers: [DatePipe],
  entryComponents: [DialogDataExampleDialog, DialogComponent, SnackbarComponent]
})
export class AdminModule { }
