import { Component , OnInit } from '@angular/core';
import { VehicleModule } from '../../modules/information/vehicle';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})


export class SettingsComponent {
    public VehicleInfo = VehicleModule;

    constructor() {

    }
}
