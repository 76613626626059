export const LeaveRequest = [
    {
        type: 'date',
        name: 'startdate',
        label: 'start date',
        value: '',
        required: true,
        col: 'col-md-6'
    },
    {
        type: 'dropdown',
        name: 'session',
        label: 'Session',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiselect:true,
        options: [
            { value: 'fullday', name: 'Full day' },
            { value: 'firstsession', name: 'First session' },
            { value: 'secondsession', name: 'Second session' },
        ]
    },
    {
        type: 'date',
        name: 'enddate',
        label: 'end date',
        value: '',
        required: true,
        col: 'col-md-6'
    },
    {
        type: 'dropdown',
        name: 'session',
        label: 'Session',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiselect:true,
        options: [
            { value: 'fullday', name: 'Full day' },
            { value: 'firstsession', name: 'First session' },
            { value: 'secondsession', name: 'Second session' },
        ]
    },
    {
        type: 'text',
        name: 'message',
        label: 'Message',
        value: '',
        required: true,
        col: 'col-md-12',
        multiline: true,
        height: '60px'
    }
];
export const SiftRequest = [
    {
        type: 'date',
        name: 'date',
        label: 'date',
        value: '',
        required: true,
        col: 'col-md-6'
    },
    {
        type: 'dropdown',
        name: 'shift',
        label: 'Current shift',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiselect:true,
        options: [
            { value: 'shift-1', name: 'Shift-1' },
            { value: 'shift-2', name: 'Shift-2' },
            { value: 'shift-3', name: 'Shift-3' },
        ]
    },
    {
        type: 'dropdown',
        name: 'shift',
        label: 'Select shift',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiselect:true,
        options: [
            { value: 'shift-1', name: 'Shift-1' },
            { value: 'shift-2', name: 'Shift-2' },
            { value: 'shift-3', name: 'Shift-3' },
        ]
    },
    {
        type: 'text',
        name: 'message',
        label: 'Message',
        value: '',
        required: true,
        col: 'col-md-12',
        multiline: true,
        height: '60px'
    }
];
export const TimeRequest = [
    {
        type: 'date',
        name: 'date',
        label: 'date',
        value: '',
        required: true,
        col: 'col-md-6'
    },
    {
        type: 'text',
        name: 'currenttime',
        label: 'Current time',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiline: true
    },
    {
        type: 'text',
        name: 'timechange',
        label: 'Time change',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiline: true
    },
   {
        type: 'text',
        name: 'message',
        label: 'Message',
        value: '',
        required: true,
        col: 'col-md-12',
        multiline: true,
        height: '60px'
    }
];
export const LocationRequest = [
    {
        type: 'text',
        name: 'currentlocation',
        label: 'Current location',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiline: true
    },
    {
        type: 'text',
        name: 'latlong',
        label: 'Location',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiline: true
    },
    {
        type: 'date',
        name: 'date',
        label: 'date',
        value: '',
        required: true,
        col: 'col-md-6'
    },
    {
        type: 'text',
        name: 'message',
        label: 'Message',
        value: '',
        required: true,
        col: 'col-md-12',
        multiline: true,
        height: '60px'
    }
];
export const RouteRequest = [
    {
        type: 'date',
        name: 'date',
        label: 'date',
        value: '',
        required: true,
        col: 'col-md-6'
    },
    {
        type: 'dropdown',
        name: 'currentroutes',
        label: 'Current routes',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiselect:true,
        options: [
            { value: 'route-1', name: 'Route-1' },
            { value: 'route-2', name: 'Route-2' },
            { value: 'route-3', name: 'Route-3' },
        ]
    },
    {
        type: 'dropdown',
        name: 'shift',
        label: 'Select routes',
        value: '',
        required: true,
        col: 'col-md-6',
        options: [
            { value: 'route-11', name: 'Route-1' },
            { value: 'route-2', name: 'Route-2' },
            { value: 'route-3', name: 'Route-3' },
        ]
    },
    {
        type: 'text',
        name: 'message',
        label: 'Message',
        value: '',
        required: true,
        col: 'col-md-12',
        multiline: true,
        height: '60px',

    }
];
export const OnBoardRequest = [
    {
        type: 'text',
        name: 'onboard',
        label: 'On board',
        value: '',
        required: true,
        col: 'col-md-6',
        // multiline: true
    },
    {
        type: 'text',
        name: 'message',
        label: 'Message',
        value: '',
        required: true,
        col: 'col-md-12',
        multiline: true,
        height: '60px'
    }
];
