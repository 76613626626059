import { Injectable } from '@angular/core';
import { ReportsRoutingBreadcrumb } from './../Pages/organization/transportmanger/reports/reports.routing';
// import { TransportBreadCrubsMenu } from '../Pages/organization/transportmanger/transportmanger.routing'

@Injectable({
     providedIn: 'root'
})

export class BreadcrumbService {


     getMenu() {
          const menu = [

               {
                    name: 'Organization',
                    path: '/organization',
                    children: [
                         {
                              name: 'Transport-manager',
                              path: '/transport-manager',
                              children: [

                                   { name: 'Home', path: '/dashboard' },
                                   {
                                        name: 'Reports', path: '/reports',
                                        children: ReportsRoutingBreadcrumb
                                   },
                                   // {
                                   //      name: 'Informaton', path: '/information-component',

                                   // },
                                   {
                                        name: 'information', path: '/information',
                                        children: [
                                             {
                                                  name: 'Vendor', path: '/vendor',
                                                  children: [
                                                       {
                                                            name: 'Dashboard', path: '/view-vendor'
                                                       },
                                                       {
                                                            name: 'View' , path: '/single-view-vendor'
                                                       }
                                                  ]
                                             },
                                             {
                                                  name: 'Employee', path: '/employee',
                                                  children: [
                                                       {
                                                            name: 'View', path: '/view-employee'
                                                       },
                                                       {
                                                            name: 'Create', path: '/add-empolyee'
                                                       }
                                                  ]
                                             },
                                             {
                                                  name: 'Shift', path: '/list-shift'
                                             },
                                             {
                                                  name: 'Create', path: '/add-shift'
                                             }
                                        ]
                                   }

                              ]
                         }
                    ]
               },

          ];

          return menu;
     }



}
