import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
  selector: 'edit-view',
  template: `
  <!-- The Modal -->
<div class="userModule">

  <!-- Modal content -->
  <div class="modal-content">
    <div align="right">
      <span (click)="modalClose()" class="close">&times;</span>
    </div>

     <h4>Edit User Form</h4>
        <mat-card>
  <g10-ms-dynamic-form-builder
    [fields]="__values" [__edit]="true"
    (values)="submitted($event)"
  ></g10-ms-dynamic-form-builder>
</mat-card>

  </div>

</div>
  `,
  styles: [`


  `]
})
export class EditUserComponent {

  constructor() { }
  @Input() __values: string;
  @Output() emit = new EventEmitter();

  ngOnInit() {

  }
  submitted(e: any) {
    // console.log('Edit',e);
  }


  modalClose() {

    this.emit.emit('0');
  }
}
