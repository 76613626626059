import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Services/storage.service';
import { ApiService } from 'src/app/Services/api.service';
import { SharedService } from 'src/app/Services/shared.service';
import { DialogActionService } from 'src/app/Services/dialog-actions.service.js';
declare const popup: any, snackbar: any;

@Component({
  selector: 'app-master-login',
  templateUrl: './master-login.component.html',
  styleUrls: ['./master-login.component.css']
})
export class MasterLoginComponent implements OnInit {
  username = '';
  password = '';
  confirm_password = '';
  showSpinner: boolean;
  inputType = 'password';
  adPswdSt: boolean;
  adEmail: string;
  isShowContent: any;
  isShowEyes = true;
  constructor(
    private router: Router,
    public storage: StorageService,
    public api: ApiService,
    public shared: SharedService,
    public dialogService: DialogActionService,
  ) {

  }
  ngOnInit(): void {
    this.getAdminCheckData();
    this.shared._subject.next({
      isShowNavbar: false
    }
    );
  }



  enterToLogin(e) {
    if (e.keyCode === 13) {
      this.login();
    }
  }

  login(): void {

    const isValidEmail = this.username.match('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
    console.log(isValidEmail, this.username);
    if (isValidEmail && this.password) {

      // snackbar(`<span class="text-warning"> Login success </span>`)
      this.api.masterLogin({ email: this.username, password: this.password })
        .then((res: any) => {

          if (res.status == 'success') {
            this.shared._subject.next({
              isShowNavbar: true
            });
            this.storage.setData({ userType: 'maste-admin', token: res.token });
            this.router.navigate(['master/dashboard']);
            this.shared._subject.next({
              type: 'navBar',
              isShowNavbar: false
            });
            // this.openLogoutModal(res)

          } else {
            console.log(JSON.stringify(res));
            // snackbar(`<span class="text-warning"> ${res.message} </span>`)
            this.openLogoutModal(res);

            // snackbar(`<span class="text-warning"> ${res.message} </span>`)
            // this.showSpinner = false;
          }
        })


        .catch((err) => console.log(err));
    } else if (this.password) {
      if (isValidEmail == null && this.username != '') {
        snackbar(`<span class="text-warning"> Invalid username</span>`);
      } else if (isValidEmail == null && this.username == '') {
        snackbar(`<span class="text-warning"> username required</span>`);
      }
    } else if (this.username != '' && isValidEmail != null) {

      snackbar(`<span class="text-warning"> Password required </span>`);
    } else {
      snackbar(`<span class="text-warning"> Username and password required </span>`);
    }
  }
  openLogoutModal(value) {
    const userId = 'user01';
    const config = {
      name: value.message,
      title: value.status,
      description: value.message,
      actionButtonText: '',
      userId
    };
    this.dialogService.popup(config);
    // const modalDialog = this.dialog.open(DialogComponent, dialogConfig);
  }
  keychange(event) {
    if (event.keyCode == 32) {
      this.username = '';
      // alert('press space')
    }
  }
  viewPassword() {
    console.log(this.isShowEyes);
    this.isShowEyes = !this.isShowEyes;

    this.inputType = this.inputType == 'text' ? 'password' : 'text';
  }

  getAdminCheckData() {

    this.api.getMasterLoginData({})
      .then((res: any) => {
        if (res.status) {
          this.adPswdSt = res.adminPasswordSet;
          this.adEmail = res.email;
          if (res.adminPasswordSet) {
            this.storage.setData({ adEmail: res.email });
            this.router.navigate(['master/active-link']);
          }
        } else {
          alert(res.status);
        }
        console.log(res.adminPasswordSet, res.email, res.status);
      }).catch((err: any) => {
        console.log(err);
      });
  }

}
