import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
import { SnackBarService } from 'src/app/Services/snackbar.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { OrgModule } from '../../../../modules/organization';
import { alertSettings } from '../../../../modules/organization/alert-settings';
declare const popup: any, snackbar: any;

import indiaStates from './../../../../../assets/js/india.state.list.json';
export interface Subject {
  name: string;
  phone: number;
}

@Component({
  selector: 'app-createorg',
  templateUrl: './createorg.component.html',
  styleUrls: ['./createorg.component.css']
})

export class CreateOrgComponent {

  public organization;
  public alertSettings;
  isUserShow = true;
  isAlertShow: any;
  isServiceShow: boolean;
  settingsInfo:any = {};
  test:any = {};
  alertFunName: string;
  isEnableClickCard: boolean;
  valueCount: any;
  setInfoLgth: any;
  systoken: any;
  selectedIndex:number = 0;
  createOrgDataDetails: any;
  userType: string;
  constructor(
    public api: ApiService,
    public router: Router,
    public snackBar: SnackBarService,
    public storage: StorageService,
    public swal: SweetAlert,
    public dialogService: DialogActionService) {
    this.userType = this.storage.get('userType');
  }

  ngOnInit() {
    if (this.userType != 'maste-admin') {
      this.router.navigate(['/master/login']);
    }
    this.organization = OrgModule;
    this.alertSettings = alertSettings;

    // state arrays ------
    const states = {};
    const stateList = this.getStateOptions();
    for (const item of this.organization) {
      if (item.name == 'state') {
        item.options = stateList;
      }
      if (item.name == 'country') {
        item.value = 'India';
      }

    }
    // ----------------
  }

// -------------------------------
  getStateOptions() {
    const states = [];
    for (const country in indiaStates) {
      indiaStates[country].map((item) => {
        states.push({ value: item, name: item });
      });
    }
    return states;
  }

  // --------------------------

  submitted(e: any) {
    console.log(e.value);
    if (e.valid) {
      this.api.createOrg(e.value)
        .then((res: any) => {
          if (res.status == 'success') {
            const msg = res.message + 'please follow and complete setings';
            this.swal.swalFunction(res.status, res.status, msg, 5000);
            this.storage.setData({ orgId: res.orgID });
            this.selectedIndex = 1;
            // this.router.navigate(['/master/view-org'])
          } if (res.status == 'failure') {
            this.swal.swalFunction('error', res.status, res.message, 2000);
          }
        })
        .catch((error: any) => {
          console.log('Error', error);
        });
    } else {
      console.log('form missing');
    }
  }


  draft(e: any) {
    console.log(e);
  }

  createUserFun(val) {
    switch (val) {
      case 'user':

        this.isUserShow = true;
        this.isServiceShow = false;
        this.isAlertShow = false;
        break;
      case 'alert':
        let allow;
        this.settingsInfo.userInfo ? allow = true : allow = false;
        if (allow) {
          this.isAlertShow = true;
          this.isUserShow = false;
          this.isServiceShow = false;
        }
        break;
      case 'services':
        let serviceAllow;
        this.settingsInfo.alert ? serviceAllow = true : serviceAllow = false;
        if (serviceAllow) {
          this.isServiceShow = true;
          this.isAlertShow = false;
          this.isUserShow = false;
        }

        break;
    }
  }


  receivingDataFromPrimaryAdmin(e) {
    console.log(e);
    this.settingsInfo.userInfo = e;
    console.log(Object.values(this.settingsInfo));
    const objLength = Object.values(this.settingsInfo);
    console.log(objLength.length);
    this.createUserFun('alert');
    this.storage.setData({ settingsInfoData: this.settingsInfo });
  }
  receivingDataFromAlerts(e) {
    console.log('alertInfo', e);
    this.settingsInfo.alert = e;
    console.log(Object.values(this.settingsInfo));
    const objLength = Object.values(this.settingsInfo);
    this.createUserFun('services');
    console.log(objLength.length);
    this.storage.setData({ settingsInfoData: this.settingsInfo });
  }
  receivingDataFromSrvices(e) {
    console.log('receivingDataFromSrvices', e);
    this.settingsInfo.service = e;
    console.log('final', this.settingsInfo);
    // this.router.navigate(['/master/view/org'])
    // console.log(Object.values(this.settingsInfo))
    // let va = Object.values(this.settingsInfo)
    const obj = {
      orgID: this.storage.get('orgId'),
      ...this.settingsInfo
    };
    this.api.org_settings(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
        this.router.navigate(['/master/view-org']);
      }
      if (res.status == 'failure') {
        console.log('settings error');
        const msg = [];
        const rstmsg = res.message;
        console.log(rstmsg.orgID);
        rstmsg.orgID.forEach(function(eror) {
          console.log(eror);
          this.msg.push(eror);
        });

        this.swal.swalFunction('error', res.status, msg, 2000);
      }

    })
    .catch((err) => {
      console.log(err);
    });
  }
}
