import { Component, OnInit } from '@angular/core';
declare const $;
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // validation example for Login form
$('#btnLogin').click(function(event) {

  const form = $('#loginForm');

  if (form[0].checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
  }

  // if validation passed form
  // would post to the server here

  form.addClass('was-validated');
});
  }

}
