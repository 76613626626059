import { OnInit, Component } from '@angular/core';


@Component({
  selector: 'app-info-vehicle',
  template: `
  `,
  styles: []
})

export class VehicleInformationComponent implements OnInit {
  constructor() {

  }

  ngOnInit() {

  }
}
