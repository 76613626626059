import { AddRequestComponent } from './add-requesst/add-request.component';
import { EditRequestComponent } from './edit-request/edit-request.component';
import { SingleCustomRequrstComponent } from './view-request/single-custom-request/single-custom-request';
import { SingleViewRequestComponent } from './view-request/single-view-request/single-view-request.component';
import { ViewRequestComponent } from './view-request/view-request.component';

export const RequestRouting = [
{path: '', redirectTo: 'view-request', pathMatch: 'full' },
{path: 'add-request', component: AddRequestComponent},
{path: 'view-request', component: ViewRequestComponent},
{path: 'single-view-request', component: SingleViewRequestComponent},
{path: 'edit-request', component: EditRequestComponent},
{path: 'single-custom-requrst-view', component: SingleCustomRequrstComponent},
];
