import { Component, OnInit } from '@angular/core';
import { paymentModule } from '../../../../../../modules/information/payment';

@Component({
  selector: 'app-addpayments',
  templateUrl: './addpayments.component.html',
  styleUrls: ['./addpayments.component.css']
})
export class AddpaymentsComponent implements OnInit {
public paymentInfo = paymentModule;
  constructor() { }

  ngOnInit(): void {
  }
  submitted(e: any) {
    console.log(e);
}
}
