import { AddTestComponent } from './add-test/add-test.component';
import { TestInformationComponent } from './test.component';
import { ViewRouteComponent } from './view-route/view-route.component';
import { ViewTestComponent } from './view-test/view-test.component';

export const TestRouting = [
    // { path: '', redirectTo: 'view-route', pathMatch: 'full' },
    {path: '', component: TestInformationComponent},
    { path: 'add-test', component: AddTestComponent},
    {path: 'view-route', component: ViewRouteComponent},
    { path: 'view-test', component: ViewTestComponent},

];
