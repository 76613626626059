import { AddRegularManualRosteringComponent } from "./add-regular-manual-rostering/add-regular-manual-rostering.component";
import { EditRegularManualRosteringComponent } from "./edit-regular-manual-rostering/edit-regular-manual-rostering.component";
import { PickupPointRosteringComponent } from "./pickup-point-rostering/pickup-point-rostering.component";
import { SingleViewRegularManualRoastering } from "./view-regular-manual-rostering/single-view-regular-manual-roastering/single-view-regular-manual-roastering";
import { ViewRegularManualRosteringComponent } from "./view-regular-manual-rostering/view-regular-manual-rostering.component";

export const RegularManualRosteringDeclaration = [
    AddRegularManualRosteringComponent,
    EditRegularManualRosteringComponent,
    ViewRegularManualRosteringComponent,
    PickupPointRosteringComponent,
    SingleViewRegularManualRoastering
];
