import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
  selector: 'app-view-feedback',
  templateUrl: './view-feedback.component.html',
  styleUrls: ['./view-feedback.component.css']
})
export class ViewFeedbackComponent implements OnInit {
  feedBackData: any;

  constructor(
    public api: ApiService,
    public storage: StorageService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.getFeedbackList();
  }



  getFeedbackList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.FeedbackList(obj).then((res: any) => {
      console.log(res);
      this.feedBackData = res.data;
    }).catch((err) => {
      console.log(err);
    });
  }

  comments() {

  }
}
