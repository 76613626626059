import { AddRoasteringComponent } from './add-rostering/add-rostering.component';
import { EditRoasteringComponent } from './edit-rostering/edit-rostering.component';
import { TableRoasteringComponent } from './table-rostering/table-rostering.component';
import { TableRouteComponent } from './table-route/table-route.component';
import { SingleViewRoasteringComponent } from './view-rostering/single-view-rostering/single-view-rostering.component';
import { ViewRoasteringComponent } from './view-rostering/view-rostering.component';


export const RoasteringDeclaration = [
    EditRoasteringComponent,
    AddRoasteringComponent,
    TableRoasteringComponent,
    ViewRoasteringComponent,
    SingleViewRoasteringComponent,
    TableRouteComponent,
];
