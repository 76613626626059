import { Component, Input, Output, EventEmitter, Pipe, PipeTransform, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
  /*
Steps
1. Copy paste searchSelect.module.ts in your Project folder
2. Include class name "SearchModule"
3. Declare "SearchModule" class inside NgModule.imports
4. <searchSelect [_lists]="lists" (result)="action($event)"> </searchSelect>
5. Have fun... :)

*/
const commonStyles = `
  .mat-border{
    border-right: 0px solid;
    border-left: 0px solid;
     border-top: 0px solid;
     border-bottom: 1px solid grey;
    padding-top: 0px;
    padding-left: 0px;
  }
  .mat-border::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: black;
    opacity: 1; /* Firefox */
  }

  .mat-border:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: black;
  }

  .mat-border::-ms-input-placeholder { /* Microsoft Edge */
    color: black;
  }
  .dropDownBoxSearchSelectModule{ cursor:pointer; background:transparent; border-radius:5px; }
      .pop{ position: absolute; width: 85%; background: white; z-index: 999999;}
      .close{
        font-family: arial;
        color: #3e3e3e;
        cursor: pointer;
        text-align: right;
        margin-right: 32px;
        margin-top: -31px;
        background: white;
        padding: 3px;
        width: 5px;
        float: right;
        z-index: 99999;
        position: relative;
        font-weight: 100;
        font-size: 15px;
      }
      input{
        display: block;
        padding: 4px;
        width: 95%;
        margin: 5px;
        border: 1px solid silver;
        border-radius: 2px;
        padding: 10px 5px;
        color: #444;

      }
      input:focus{
      outline: none;
  }
    ul{

      margin-left: 5px;
      margin-right: 15px;
      margin-top: -5px;
      list-style: none;
      height: 200px;
      overflow: auto;
      border: 1px solid silver;
    }
    ul li{
      margin-left: -30px;
      padding: 3px;
      cursor: pointer;
    }
    li:hover{
      background: #D4D3D3;
    }
    .checkbox{
        padding: 10px; height: 20px;
    }
     .success{
       background: green!important;
     }
     .danger{
       background: #dc3545!important; /*red*/
     }
     .default{
       background: silver!important;
     }
     .warning{
       background: orange!important;
     }
     .info{
       background-color: #3498db;
     }
     .pastel{
       background-color : #fcba7e;
     }
     .pastel::placeholder {
        color: #444;
        font-size: 1.1em;
      }

     #dot {
      height: 8px;
      width: 8px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      top: -5px;
    }
    .badge {
      box-sizing: border-box;
      display: inline-block;
      background-color: #2c3e50;
      color: #ffffff;
      border-radius: 3rem;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 400;
      padding: .05rem .8rem .1rem;
      line-height: inherit;
    }


    .badge--line {
      background-color: transparent;
      color: #95a5a6;
      box-shadow: 0 0 0 1px #95a5a6;
    }

    .badge--info {
      background-color: #3498db;
    }

    .badge--warning {
      background-color: #f1c40f;
    }

    .badge--danger {
      background-color: red/*#e74c3c*/;
    }

    .badge--success {
      background-color: #2ecc71;
    }

    .badge--small {
      font-size: 1.2rem;
      padding: .1rem .65rem .2rem;
    }

    .badge--smaller {
      font-size: .7rem;
      padding: .05rem .4rem .15rem;
    }

    .input-badge{
       position:relative; top:-38px; right: 5px; float: right !important;

    }

    .badge-pill {
      padding-right: .6em;
      padding-left: .6em;
      border-radius: 10rem;
      font-size: 1em;
      font-family: arial;
    }
    .badge-pill-round {
      padding: 5px;
      border-radius: 10rem;
      font-size: 14px;
      font-family: arial;
    }

    /* For Badge example look "https://codepen.io/RaguNS/pen/dyyBwqW" */


    `;

@Pipe({
  name: 'searchPipe'
})

export class SearchPipe implements PipeTransform {
  transform(options, srch) {
    if ( srch != '' ) {
    return options.filter( x =>
    x.name && ( x.name.toLowerCase().includes(srch.toLowerCase()) ||
    ( x.value && x.value.toLowerCase().includes(srch.toLowerCase()) )
    )
      );
    } else {
     return options;
    }
  }
}

@Component({
  selector : 'searchSelect',
  template : `

   <input placeholder="{{_label}}" class="dropDownBoxSearchSelectModule
    {{_inputBG}}" [(ngModel)]="srchPlaceholder"
    [ngClass]="{'mat-border':_matBorder}"
    (keyup)="dropDownFlag=true"(click)="dropDownFlag=true;srchFocus(search)" />
    <span *ngIf="_inputBadge!=null" class="badge badge-pill-round input-badge danger">{{_inputBadge}}</span>
    <div class="dropDownBoxSearchSelectModule close"
    (click) = "srch=''; srchPlaceholder=''; selected=''; update();" [hidden]="srchPlaceholder.length==0" id="searchSelectClose_a">X</div>
    <ul class="dropDownBoxSearchSelectModule pop" style="padding-left:30px"
    [ngStyle]="{height:dropDownHeight}"
     (mouseout)="dropDownFlagSlowHide()"
     (mouseover)="dropDownFlagSlowShow()"
     siz="5" [hidden]="dropDownFlag==false">
     <li class="dropDownBoxSearchSelectModule">
        <input #search placeholder="search" class="dropDownBoxSearchSelectModule" [(ngModel)]="srch"
    (keyup)="dropDownFlag=true"(click)="dropDownFlag=true" />
    <div class="dropDownBoxSearchSelectModule close"
    (click) = "srch=''" *ngIf="srch.length>1" id="searchSelectClose_b">X</div><!-- 🔍⌕ -->
      </li>
     <ng-container *ngFor="let item of data | searchPipe : srch;trackBy:identify; ">

      <li class="dropDownBoxSearchSelectModule"
      (click)="selected=item;srchPlaceholder=item.name;dropDownFlagSlowHide();update();" >{{item.name}}
      <span *ngIf="item.badge" class="badge badge--{{item.badgeColor}} badge--smaller">{{item.badge}}</span>
      <span *ngIf="item.marker" id="dot" [ngClass]="item.marker"> </span>


      </li>
      </ng-container>
    </ul>
    `,
    styles: [commonStyles]
})

export class SearchSelectComponent {
  srch = ''; srchPlaceholder = '';
  dropDownFlag = false;
  selected: any = '';
  timer: any;
  data: any = [];
  dropDownHeight = '200px';
  @Input() _selected: any;
  @Output() result: EventEmitter<any> = new EventEmitter();
  @Input('_dropDownOpen')
  set _dropDownOpenFunc($event) { this.dropDownFlag = $event; }
  @Input('_dropDownHeight')
  set _dropDownHeightFunc($event) { this.dropDownHeight = $event; }

  @Input() _label = 'Select';
  @Input() _inputBG = '';
  @Input() _inputBadge: string = null;
  @Input() _matBorder = false;
  @Input('_lists')
  set assignFunc($event) {
    this.data = $event;
  }
  // data : any = [
  //   {name:'Arun'},
  //   {name:'Bala',value: 'bal'},
  //   {name:'Kumar'}
  // ];

  ngOnInit() {
    if (this._selected && this._selected[0]) { this.srchPlaceholder = this._selected[0].value; }
    document.body.addEventListener('click', ($event: any) => {
      const flagClassKeys = Object.keys($event.target.classList);
      const flagClass = $event.target.classList[flagClassKeys[0]];
      if ( flagClass == undefined || flagClass.includes('dropDownBoxSearchSelectModule') == false ) {
        this.dropDownFlag = false;
      }
    });
  }
  srchFocus(search) {
    setTimeout( () => {
      search.focus();
    }, 100 );
  }

  dropDownFlagSlowHide() {
    this.timer = setTimeout( () => {
      this.dropDownFlag = false;
      this.srch = '';
     }, 1000 );

  }
  dropDownFlagSlowShow() {
    clearTimeout(this.timer);
  }
  update() {

    this.result.emit(this.selected);
  }

  identify(index, item) {
      return item;
      // return item.name
   }
}


@Component({
  selector : 'searchSelect-multi',
  template : `
  <div [hidden]="dropDownFlag==true && _hideHeaderOnSelect == true">
    <input placeholder="{{_label}}"
    [ngClass]="{'mat-border':_matBorder}"class="dropDownBoxSearchSelectModule {{_inputBG}}" [(ngModel)]="srchPlaceholder"
    (keyup)="dropDownFlag=true"(click)="dropDownFlag=true;srchFocus(search);" />
    	<span *ngIf="_inputBadge!=null" class="badge badge-pill-round input-badge danger">{{_inputBadge}}</span>
      </div>
    <!-- 🔍⌕ -->
    <ul class="dropDownBoxSearchSelectModule pop"
     (mouseout)="dropDownFlagSlowHide()"
     (mouseover)="dropDownFlagSlowShow()"
     siz="5" [hidden]="dropDownFlag==false"
     [ngStyle]="{height:dropDownHeight}">
     <li class="dropDownBoxSearchSelectModule">
     <input class="dropDownBoxSearchSelectModule" #search [(ngModel)]="srch" placeholder="Search" />
     <div class="dropDownBoxSearchSelectModule close"
    (click) = "srch=''" *ngIf="srch.length>1" id="searchSelectMultiClose_b">X</div>
     </li>

    <table class="dropDownBoxSearchSelectModule" *ngIf="_selectAllRequired">
       <tr class="dropDownBoxSearchSelectModule">
        <td width="50" class="dropDownBoxSearchSelectModule">
        <input type="checkbox" class="dropDownBoxSearchSelectModule checkbox" (click)="selectAll($event)"  />
      </td>
        <td class="dropDownBoxSearchSelectModule"  >
        Select All
         </td>
       </tr>
      </table>

     <ng-container *ngFor="let item of data | searchPipe : srch;trackBy:identify;let i =index; ">
      <li class="dropDownBoxSearchSelectModule"
      (click)="update(item.name,i,item)" >
      <table class="dropDownBoxSearchSelectModule">
       <tr class="dropDownBoxSearchSelectModule">
        <td width="50" class="dropDownBoxSearchSelectModule">
        <input type="checkbox" class="dropDownBoxSearchSelectModule checkbox" [checked]="tempSelected[i]||item.checked"
         />
      </td>
        <td class="dropDownBoxSearchSelectModule">
        {{item.name}}
        <span *ngIf="item.badge" class="badge badge--{{item.badgeColor}} badge--smaller">{{item.badge}}</span>
        <span *ngIf="item.marker" id="dot" [ngClass]="item.marker"> </span></td>
       </tr>
      </table>
      </li>
      </ng-container>
    </ul>
    `,
    styles: [commonStyles]
})
export class SearchSelectMultiComponent {
 srch = ''; srchPlaceholder = '';
  dropDownFlag = false;
  selected: any = []; tempSelected = {};
  timer: any;
  data: any = [];
  dropDownHeight = '200px';

  @Output() result: EventEmitter<any> = new EventEmitter();
  @Input() _selectAllRequired = false;
  @Input() _hideHeaderOnSelect = false;
  @Input() _matBorder = false;
  @Input('_dropDownOpen')
  set _dropDownOpenFunc($event) { setTimeout( () => { this.dropDownFlag = $event; }, 1000 );  }
  @Input('_dropDownHeight')
  set _dropDownHeightFunc($event) {  setTimeout( () => { this.dropDownHeight = $event; }, 1000 ); }

  @Input() _label = 'Select';
  @Input() _inputBG = '';
  @Input() _inputBadge: string = null;
  @Input('_lists')
  set assignFunc($event) {
    this.data = $event;
  }
  @Input('_selected')
  set selectedFunc($event) {
    const selected = $event;
    if (selected.length == 0) { return; }
    const selectedName = $event.map(x => x.name);
    let i = 0;
    for (const item of this.data) {
      if (selectedName.indexOf(item.name) > -1 ) {
       this.update(item.name, i, item);
      }
      i++;
    }

  }
  // data : any = [
  //   {name:'Arun'},
  //   {name:'Bala',value: 'bal'},
  //   {name:'Kumar'}
  // ];

  ngOnInit() {
    document.body.addEventListener('click', ($event: any) => {
      const flagClassKeys = Object.keys($event.target.classList);
      const flagClass = $event.target.classList[flagClassKeys[0]];
      if ( flagClass == undefined || flagClass.includes('dropDownBoxSearchSelectModule') == false ) {
        this.dropDownFlag = false;
      }
    });
  }

  dropDownFlagSlowHide() {
    this.timer = setTimeout( () => {
      this.dropDownFlag = false;
      this.srch = '';
     }, 1000 );

  }
  dropDownFlagSlowShow() {
    clearTimeout(this.timer);
  }
  srchFocus(search) {
    setTimeout( () => {
      search.focus();
    }, 100 );
  }

  selectAll($event) {
    if ($event.target.checked == false) {
      this.tempSelected = {};
      this.result.emit([]);
    } else {
       for (let i = 0; i < this.data.length; i++) {
         this.update( this.data[i].name, i, this.data[i]);
       }
    }

  }

  update(name, i, item) {
    this.srchPlaceholder = '';

    let deleteFlag = false;
    if (this.tempSelected[i]) {
     delete this.tempSelected[i];
     deleteFlag = true;
    } else {
     this.tempSelected[i] = item;
    }

    const objLen = Object.keys(this.tempSelected).length;

    this.selected = [];
    for (const i of Object.keys(this.tempSelected)) {
     this.selected.push(this.tempSelected[i]);
    }

    if ( objLen == 0 || objLen == 1 ) {
     this.srchPlaceholder = deleteFlag ? (this.selected[0] ? this.selected[0].name : '') :  name;
    } else {
     this.srchPlaceholder = deleteFlag ? (this.selected[0] ? this.selected[0].name : '') : name + ' + ' + (Object.keys(this.tempSelected).length - 1) + ' more';
    }

    setTimeout(() => { item.checked = this.tempSelected[i]; }, 10);
    this.result.emit(this.selected);
  }

  identify(index, item) {
      return item;
      // return item.name
   }



}
@NgModule({
  imports: [
     BrowserModule, FormsModule
  ],
  declarations: [ SearchSelectComponent, SearchPipe, SearchSelectMultiComponent ],
  exports : [SearchSelectComponent, SearchPipe, SearchSelectMultiComponent],
  bootstrap : [SearchSelectComponent]
})

export class SearchModule {

}
