import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { MapService } from 'src/app/Services/map.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import indiaStates from '../../../../../../../assets/js/india.state.list.json';
declare const mapUtilityMapBox;
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface User {
  name: string;
}
@Component({
    selector: 'app-edit-employee',
    templateUrl: './edit-employee.component.html'
})

export class EditEmployeeComponent implements OnInit {
    addEmployeeForm: FormGroup;
    submitted = false;
    isShowSimno = false;
    isShowVendorName = false;
    public addShopFormGroup: FormGroup;
  
  
    myControl = new FormControl();
    public stateSelect = indiaStates.IND;
    options: User[] = [];
    filteredOptions: Observable<User[]>;
  
    strDate: any;
    latlong: any;
    purchDate: any;
    rcStrDate: any;
    shiftArr: any[] = []
    public stateData = []
    public selectedValueClose = false;
    public address;
    public vehicleloc;
    public locationSuggestions: any;
    public vehicleLocation;
    public searchInput: any = new Subject<string>();
    public destroy$: any = new Subject();
    // public stateSelect = indiaStates.IND;
    public mapObj: any;
    public myMap: any = this.map.retMap();
    minFromDate = new Date();
    maxToDate = new Date().setDate(2);
    //VendorInfos: any;
    shiftInformation: any = [];
    branchInformation: any[];
    public orgID;
    public employeeID;
    constructor(
      public fb: FormBuilder,
      public api: ApiService,
      public route: ActivatedRoute,
      public swal: SweetAlert,
      public storage: StorageService,
      public router: Router,
      private map: MapService,
    ) {
        this.route.paramMap.subscribe(paramas => {
            this.orgID = paramas.get('orgID');
            this.employeeID = paramas.get('employeeID');
        });
      //this.VendorInfos = this.storage.get('vendorID_orgID');
    }
  
    ngOnInit(): void {
      this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filter(name) : this.options.slice())
        );
      console.log(this.myControl)
      this.getStateOptions();
      this.loadMap();
      this.myMap.mymap.onMapClick((e: any) => {
        this.locationSuggestions = [];
        this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
      });
  
      this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
        if (value.val.target.value != '') {
          const val = { key: 'vehicleLocation', val: value.val.target.value };
          this.getGeoCode(val);
          console.log(val);
        } else if (value.val.target.value.trim() == '') {
          this.emptySuggestions();
        }
        console.log(value.val.target.value);
        console.log(value.val);
      });
      this.addFormLoad();
      this.getListShift();
      this.getEmployeeData();
    }
  
    displayFn(user: User): string {
      this.stateData = []
      this.stateData.push(user)
      console.log(this.stateData)
      // this.addEmployeeForm.value.patchValue({state:user}
      return user && user.name ? user.name : '';
    }
  
    private _filter(name: string): User[] {
      const filterValue = name.toLowerCase();
  
      return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }
    loadMap(marker: any = []) {
      this.map.loadMap('pickuppoint', marker);
    }
    getGeoCode(value) {
      const key = value.key;
      if (key == 'vehicleLocation') {
        this.locationSuggestions[key] = [];
      }
      this.myMap.mymap.geocoder(value.val, (res) => {
        try {
          const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
          console.log('Loading GEO code ', key);
          if (key == 'mapclick') {
            this.locationSuggestions = {};
            this.address = result[0];
            // this.vehicleloc = this.address.name;
            this.vehicleLocation = this.address;
            // this.selectedValueClose = true;
            console.log(result, result[0].name);
  
          } else {
            this.locationSuggestions[key] = result;
            console.log('Else Part', this.locationSuggestions);
          }
        } catch (er) { console.log(er); }
      });
    }
    selectedValue(value) {
      this.selectedValueClose = true;
      this.locationSuggestions = {};
      this.address = value;
      this.vehicleloc = this.address.name;
      this.vehicleLocation = value;
    }
    emptySuggestions() {
      this.locationSuggestions = {};
      this.selectedValueClose = false;
      this.vehicleLocation = null;
    }
    close() {
      this.addEmployeeForm.patchValue({
        location: this.address.name
      });
    }
    getListShift() {
      const obj = {
        orgID: this.storage.get('userInfo').orgID
      };
      this.api.ListShift(obj).then((res: any) => {
        console.log(res);
        if (res.status == 'success') {
  
          // console.log(this.shiftInformation)
          let branchArray = []
          let vall = res.data.filter((v, i, a) => {
            console.log(v.branch, i, a)
            branchArray.push(v.branch)
            this.shiftInformation.push(v)
          })
  
          this.branchInformation = [...new Set(branchArray)];
          console.log(this.branchInformation)
        }
      }).catch((err) => {
        console.log(err);
      });
    }
    onChangeBrach(val) {
      // console.log(val, this.shiftInformation)
      this.shiftArr = []
      this.shiftInformation.forEach((element, i) => {
        // console.log(element.branch == val)
        if (element.branch == val) {
          this.shiftArr.push(element)
        }
      });
      // console.log(this.shiftArr)
    }
    getStateOptions() {
      this.stateSelect.forEach(element => {
        // console.log(element)
        this.options.push({ name: element })
      });
    }
  
    addFormLoad() {
      this.addEmployeeForm = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.minLength(5)]),
        empRefID: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        dob: new FormControl('', [Validators.required]),
        gender: new FormControl('', [Validators.required]),
        mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{9}')]),
        emergencyContact: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{9}')]),
        bloodGroup: new FormControl('', [Validators.required]),
        address: new FormControl('', [Validators.required]),
        city: new FormControl('', [Validators.required]),
        state: new FormControl(''),
        country: new FormControl({ value: 'India', disabled: true }),
        pincode: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{5}')]),
        transportType: new FormControl('', [Validators.required]),
        workLocation: new FormControl('', [Validators.required]),
        branch: new FormControl('', [Validators.required]),
        shift: new FormControl('', [Validators.required]),
        location: new FormControl('', [Validators.required]),
        profile: new FormControl('', [Validators.required]),
      });
    }
    public checkError = (controlName: string, errorName: string) => {
      return this.addEmployeeForm.controls[controlName].hasError(errorName);
    }
  getEmployeeData(){
    const obj = {
        orgID: this.orgID,
        employeeID: this.employeeID
    };
    this.api.transportManagerEmployeeList(obj).then((res: any) => {
        if (res.status == 'success') {
            const data = res.data[0];
            this.setValueEditEmployeeForm(data);
        }  
    }).catch((err) => {
        console.log(err);
    });
    }
    setValueEditEmployeeForm(data){
        console.log(data);
        this.addEmployeeForm.patchValue({
            name: data.name,
            empRefID: data.empRefID,
            email: data.email,
            dob: new Date(data.dob),
            gender: data.gender,
            mobile: data.mobile,
            emergencyContact: data.emergencyContact,
            bloodGroup: data.bloodGroup,
            address: data.address,
            city: data.city,
            state: data.state,
            country: data.country,
            pincode: data.pincode,
            transportType: data.transportType,
            workLocation: data.workLocation,
            branch: data.branch,
            shift: data.shift,
            location: data.location,
            //profile: data.profile,
        });
        this.latlong=data.location.name;
        this.onChangeBrach(data.branch);
        this.selectedValue(data.location);   
        console.log(this.addEmployeeForm.value);  
    }

    onSubmit() {
      // this.submitted = true
      // if (!this.addEmployeeForm.valid) { return; }
      console.log(this.addEmployeeForm.value);
      const obj = {
        orgID: this.orgID,
        employeeID: this.employeeID,
        ...this.addEmployeeForm.value
      };
      delete obj.location;
      // let stdata = this.stateData[0]
      console.log(this.stateData, this.myControl.value.name)
      obj.state = this.myControl.value.name
      obj.country = 'india'
      obj.location = {
        lat: this.address.center[1],
        lng: this.address.center[0],
        name: this.address.name,
      };
      console.log(obj, this.address);
      this.api.transportManagerEmployeeCreate(obj).then((res: any) => {
        if (res.status == 'success') {
          this.swal.swalFunction(res.status, res.status, res.message, 2000);
          this.router.navigate(['/organization/transport-manager/information/employee/view-employee'])
        }
        if (res.status == 'failure') {
          this.swal.swalFunction('error', res.status, res.message, 3000);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
    _keyPress(event: any) {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
  
      }
    }
    onChangeBoxType(e) {
      console.log(e.value);
      this.isShowVendorName = false;
      this.isShowSimno = false;
      if (e.value == 'others') { this.isShowVendorName = true; }
      if (e.value == 'group10') { this.isShowSimno = true; }
    }
    openLocation() {
      document.getElementById('vehicleLocationId').click();
    }
    initMap(markers: any, type: string) {
  
      try {
        const mapBoxKey = 'pk.eyJ1Ijoib3dlbmxhbWIiLCJhIjoiY2lleWljcnF4MDBiOXQ0bHR0anRvamtucSJ9.t3YnHHqvQZ8Y0MTCNy0NNw';
        const parent = this;
        if (mapBoxKey) {
          const MyMap1 = new mapUtilityMapBox();
          MyMap1.setup('markerExampleMapBox1', {
            key: mapBoxKey
          },
            function (map) {
              // if (type == 'add') {
              parent.mapObj = map;
              map.clusters = [
                {
                  name: 'dashboardMap',  // unique id
                  maxZoom: 10, // max zoom on cluster click event
                  radius: 20,
                  color: 'rgba(255, 0, 0, 0.2)', // '#be9694'  // for opacity use rgb
                  textColor: 'green' // count color
                }
              ];
  
              map.addMarkers(markers);
              setTimeout(() => {
                map.clusterSynch();
              }, 500);
  
              map.onMapZoom(function (z) {
                map.clusterSynch();
                setTimeout(() => { map.marker(markers); }, 200);
              });
  
              map.focus({
                lat: parseFloat(markers[0].lat),
                lng: parseFloat(markers[0].lng),
                zoom: 10
              });
  
              map.onClick({
                type: 'marker',
              }, (e: any) => {
                map.focus({
                  lat: e._lngLat.lat,
                  lng: e._lngLat.lng,
                  zoom: 18
                });
              });
              // } else {
              //   console.log(markers)
              //   map.marker(markers);
              //   setTimeout(() => {
              //     map.clusterSynch();
              //   }, 500);
              // }
            });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  
