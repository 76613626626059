import { Component, OnInit, ViewChild } from '@angular/core';
import { userModule } from './../../../../modules/user';
import { ApiService } from 'src/app/Services/api.service';
import { SnackBarService } from 'src/app/Services/snackbar.service';
import { Router } from '@angular/router';
@Component({
  selector: 'users',
  templateUrl: './createUser.component.html',
  styleUrls: ['./createUser.component.css']
})
export class CreateUserComponent {
  constructor(
    public api: ApiService,
    private snackBar: SnackBarService,
    private router: Router) {
  }

  public userFields = userModule;
  public orgDetails: any;
  public orgList = [];

  ngOnInit() {
    // console.log('this.userFields',this.userFields)
    this.getOrgList();
  }
  getOrgList() {
    this.api.listOrg({})
      .then((res: any) => {
        // console.log('Success', res); temp1.filter((item)=>{ if(item.name=='orgID'){ console.log(item.options); } })
        if (res.status == 'success') {
          this.orgDetails = res.data;
        }

        const list = Object.keys(res.data).map((item, i) => {
          return { value: res.data[item].orgID, name: res.data[item].orgName };
        });
        this.userFields.filter((item) => { if (item.name == 'orgID') { item.options = list; } });
        // console.log('this.userFields', this.userFields)
      })
      .catch((error: any) => {
        console.log('Error', error);
      });
  }


  submitted(e: any) {
    // console.log(e)
    const obj: any = {
      orgID: e.orgID,
      firstName: e.firstName,
      lastName: e.lastName,
      email: e.email,
      gender: e.gender,
      //   DOB: formFields.DOB,
      DOB: e.DOB,
      contactNumber: e.contactNumber,
      address: e.address,
      city: e.city,
      state: e.state,
      country: e.country,
      pincode: e.pincode,
      jobTitle: e.jobTitle,
      empDesignation: e.empDesignation,
      //   dateOfJoining: formFields.dateOfJoining,
      dateOfJoining: e.dateOfJoining,
      jobType: e.jobType,
      employeeCode: e.employeeCode,
      workLocation: e.workLocation,
      reportingTo: e.reportingTo,
      userRole: e.userRole,
    };
    if (obj.emergencyContact) { obj.emergencyContact = e.emergencyContact; }
    this.api.createUser(obj).then((res: any) => {

      let config = {};

      if (res.status == 'success') {
        config = {
          message: '<p class=\'p-0 m-0\'> <span > <i class=\'ms-Icon ms-Icon--InfoSolid text-primary h4 m-0 p-0 icon\' aria-hidden=\'true\'> </i> </span> ' + res.message + ' </p>',
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 5000
        };
        this.router.navigate(['/master/view-user']);
      } else {
        const msg: any = res.message;
        const errorMsg = (typeof msg == 'string') ? msg : msg.join(',');
        config = {
          message: '<p class=\'p-0 m-0\'> <span > <i class=\'ms-Icon ms-Icon--InfoSolid text-primary h4 m-0 p-0 icon\' aria-hidden=\'true\'> </i> </span> ' + errorMsg + ' </p>',
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 5000
        };
      }
      this.snackBar.openSnackBar(config);
    });
  }
}
