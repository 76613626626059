export const driverModule = [
   {
        type: 'text',
        name: 'name',
        label: 'Driver Name',
        value: '',
        required: true,
        col: 'col-md-4',
    },
    {
        type: 'date',
        name: 'dob',
        label: 'Date of birth',
        // minDate: 'today',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'licenceNo',
        label: 'Licence No',
        value: 'hi',
        required: true,
        col: 'col-md-4',
    },
    {
        type: 'date',
        name: 'licenceExpiry',
        label: 'Licence-Expiry',
        // minDate:'today',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'number',
        name: 'mobile',
        label: 'Mobile Number',
        value: '',
        required: true,
        col: 'col-md-4',
        textType: 'mobile'
    },
    {
        type: 'number',
        name: 'emergencyContact',
        label: 'Emergency Contact',
        value: '',
        required: true,
        col: 'col-md-4',
        textType: 'mobile'
    },
    {
        type: 'text',
        name: 'bloodGroup',
        label: 'Blood Group',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    // {
    //     type: 'dropdown',
    //     name: 'bloodGroup',
    //     label: 'Blood Group',
    //     value: '',
    //     required: true,
    //     col: 'col-4',
    //     options: [
    //       { value: 'a+', name: 'TN' },
    //       { value: 'b+', name: 'Kerala' },
    //       { value: 'o+', name: 'Bangalore' },
    //       { value: 'a-', name: 'Mumbai' },
    //       { value: 'b-', name: 'Mumbai' },
    //       { value: 'o-', name: 'Mumbai' },
    //       { value: 'ab+', name: 'Mumbai' },
    //       { value: 'ab-', name: 'Mumbai' },
    //     ]
    //   },
    {
        type: 'text',
        name: 'address',
        label: 'Address',
        value: '',
        required: false,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'city',
        label: 'City',
        value: '',
        required: false,
        col: 'col-md-4'
    },

    {
        type: 'dropdown',
        name: 'state',
        label: 'State',
        value: '',
        required: true,
        col: 'col-md-4',
        options: [
          { value: 'TN', name: 'TN' },
          { value: 'Kerala', name: 'Kerala' },
          { value: 'Bangalore', name: 'Bangalore' },
          { value: 'Mumbai', name: 'Mumbai' }
        ]
      },
    {
        type: 'text',
        name: 'country',
        label: 'Country',
        value: 'India',
        required: false,
        col: 'col-md-4'
    },
    {
        type: 'number',
        name: 'pincode',
        label: 'Pin-code',
        value: '',
        required: false,
        col: 'col-md-4'
    },
    {
        type: 'file',
        name: 'licenceUpload',
        label: 'Licence-Upload',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'file',
        name: 'profilePic',
        label: 'Drive Image Upload',
        value: '',
        required: true,
        col: 'col-md-4'
    },
];
