import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';

@Directive({
    selector: '[goBack]'
})
export class BackButtonDirective {
    constructor(private location: Location) { }

    // @HostListener('click')
    // onClick() {
    //     alert()
    //     this.location.back();

    // }
    @HostListener('click', ['$event']) myMethod(e) {
        // alert(e);
        console.log(window.history);
        if (window.history.length > 1) {
            this.location.back();
          }
      }
}
