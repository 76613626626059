import { AddVendorComponent } from './add-vendor/add-vendor.component';
import { EditVendorComponent } from './edit-vendor/edit-vendor.component';
import { SingleViewVendorComponent } from './view-vendor/single-view-vendor';
import { ViewVendorComponent } from './view-vendor/view-vendor.component';

export const VendorRouting = [
    { path: '', redirectTo: 'view-vendor', pathMatch: 'full' },
    // {path : '' , component:ViewVendorComponent},
    { path: 'add-vendor', component: AddVendorComponent},
    { path: 'view-vendor', component: ViewVendorComponent},
    { path: 'single-view-vendor', component: SingleViewVendorComponent},
    { path: 'edit-view-vendor', component: EditVendorComponent}
];
