import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DialogDeleteService {

  constructor() { }

  alertDelete(modalData: any) {
     console.log('modal--------->>>', modalData);
  }
}
