import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})


export class S3BudgetService {
    public awsS3BugetURL = 'https://s3.ap-south-1.amazonaws.com/';


    s3GetFile(params: any) {
        return new Promise((resolve, reject) => {
            try {
                console.log('GET S3 Budget')
                let url = this.awsS3BugetURL + params.Bucket;
                console.log('URL ', url);
                fetch(url, {
                    method: 'POST',
                    headers: { "Access-Control-Allow-Origin": "*" }
                }).then(response => response.json()).then(data => console.log('*****-->>>> ',data));
                // const oReq = new XMLHttpRequest();
                // oReq.open('GET', url, true);
                // // oReq.responseType = 'arraybuffer';
                // oReq.onload = function(oEvent) {
                //     const arrayBuffer = oReq.response; // Note: not oReq.responseText
                //     console.log('arrayBuffer----> ', arrayBuffer)
                // }
                // oReq.onerror = function(e) {
                //     console.log('onErro', e);
                // };
                // oReq.send(null);

            } catch (error) {
                console.log('Error ', error);
            }
        });
    }




}