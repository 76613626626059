
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { SharedService } from 'src/app/Services/shared.service';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';



@Component({
  selector: 'g10-ms-pie-chart',
  templateUrl: './piechart.component.html',
  styleUrls: ['./piechart.component.css']
})
export class PieChartComponent {

  constructor() { }
  @Input() pieChartData: ChartDataSets[];
  @Input() pieChartLabels: Label[];
  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        color: '#ffffff',
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
        font: {
          weight: 'bold',
          size: 8,
        }
      }

    }
  };
  // public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  // public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['#6264a7', '#e84393', '#ff7675', '#00b894', '#fdcb6e', '#82589F', '#e17055', '#182C61', '#6D214F', '#B33771', '#55E6C1', '#6c5ce7', '#0984e3', '#fd79a8' ],
    },
  ];
  s;

  ngOnInit(): void {
  }

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
}
