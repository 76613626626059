import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Services/storage.service';
import { ApiService } from 'src/app/Services/api.service';
declare const popup: any, snackbar: any;

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
  mailId = 'mailId@groupten.com';
  password: any;
  confrimpassword: any;
  inputType: string;
  token: any;
  isPasswordNotMatch: boolean;
  constructor(private router: Router, public storage: StorageService, public api: ApiService) { }

  ngOnInit(): void {
    this.token = this.storage.get('token');
    this.viewPassword();
  }
  submit(): void {
    console.log(this.confrimpassword && this.password);
    if (this.confrimpassword && this.password) {
      const obj = {
        activationKey: this.token,
        password: this.password,
        confirmPassword: this.confrimpassword
      };
      console.log(obj);
      this.api.updatepassword(obj)
        .then((res: any) => {
          if (res.status == 'success') {
            this.router.navigate(['master/login']);
          }
          console.log(res);
        }).catch((err) => {
          console.log(err);
        });
      snackbar(`<span class="text-warning"> Password success </span>`);
    } else {
      snackbar(`<span class="text-warning"> Password and confrim password required </span>`);
    }
  }
  viewPassword() {
    this.inputType = this.inputType == 'text' ? 'password' : 'text';
  }
  checkPin(val) {
    if (this.password !== this.confrimpassword) {
      this.isPasswordNotMatch = true;
    } else {
      this.isPasswordNotMatch = false;
    }
  }
}
