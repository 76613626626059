import { CreateOrgComponent } from './organization/create-org/createorg.component';
import { CreateUserComponent } from './user/create/createUser.component';
import { ViewUserComponent } from './user/view/viewUser.component';
import { CreateEmployeeComponent } from './employee/create/createEmployee.component';
import { ViewEmployeeComponent } from './employee/view/viewEmployee.component';
import { ViewOrgComponent } from './organization/view-org/vieworg.component';
import { EditOrgComponent } from './organization/edit-org/editorganization.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { MasterLoginComponent } from './master-login/master-login.component';
import { GetActivateLinkComponent } from './get-activate-link/get-activate-link.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { SingleOrgViewComponent } from './organization/view-org/single-view-org.component';
export const MasterRouting = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    // { path:'', component:MasterDashboardComponent},
    { path: 'login', component: MasterLoginComponent},
    { path: 'active-link', component: GetActivateLinkComponent},
    { path: 'update-password', component: UpdatePasswordComponent},
    { path: 'dashboard', component: MasterDashboardComponent},
    { path: 'create-org', component: CreateOrgComponent},
    { path: 'view-org', component: ViewOrgComponent},
    { path: 'edit-org', component: EditOrgComponent},
    { path: 'create-user', component: CreateUserComponent},
    { path: 'view-user', component: ViewUserComponent},
    { path: 'create-employee', component: CreateEmployeeComponent},
    { path: 'view-employee', component: ViewEmployeeComponent},
    { path: 'single-view-org', component: SingleOrgViewComponent},



];
