import { HaltReportsComponent } from './halt-reports/halt-reports.component';
import { MomentReportsComponent } from './moment-reports/moment-reports.component';
import { OverspeedReportsComponent } from './over-speed-reports/over-speed-reports.component';
import { ProductivityComponent } from './productivity-reports/productivity-reports.component';
import { ReportsComponent } from './reports.component';
import { SosReportComponent } from './sos-report/sos-report.component';

export const ReportsDeclaration = [
    MomentReportsComponent,
    HaltReportsComponent,
    OverspeedReportsComponent,
    SosReportComponent,
    ReportsComponent,
    ProductivityComponent
];
