import { AddVehicleComponent } from './add-vehicle/add-vehicle.component';
import { ViewVehicleComponent } from './view-vehicle/view-vehicle.component';
import { SingleVehicleViewComponet } from './view-vehicle/single-vehicle.component';
import { EditVehicleComponent } from './edit-vehicle/edit.vehicle.component';
import { CreateVehicleComponent } from './create-vehicle/create-vehicle.component';
import { EditvehicleComponent } from './editvehicle/editvehicle.component';
export const VehicleDeclaration = [
   AddVehicleComponent,
   CreateVehicleComponent,
   ViewVehicleComponent,
   EditVehicleComponent,
   SingleVehicleViewComponet,
   EditvehicleComponent
];
