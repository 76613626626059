import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { VendorModule } from '../../../../../../modules/information/vendor';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
import { StorageService } from 'src/app/Services/storage.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/Services/shared.service';
import { Location } from '@angular/common';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.css']
})
export class AddVendorComponent implements OnInit {
  public vendorInfo = VendorModule;
  vendorDetails: any;
  userType: string;
  getUserData: any;
  userData: any;
  constructor(public api: ApiService,
              public dialogService: DialogActionService,
              public storage: StorageService,
              public router: Router,
              public location: Location,
              public swal: SweetAlert,
              public shared: SharedService) {
    this.userType = this.storage.get('userType');
    this.userData = this.storage.get('userInfo');
  }

  ngOnInit(): void {

    if (this.userType != 'transport_manager') {
      this.router.navigate(['/organization/transport-manager/login']);
    }
    console.log('getuser add vendor', this.getUserData);
  }
  submitted(e: any) {

    console.log(e);
    if (e.valid) {
    const obj = {
      orgID: this.userData.orgID,
      ...e.value
    };
    console.log(obj);
    this.api.transportManagerVendorCreate(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.swal.swalFunction('success', res.status, res.message, 2000);
        this.router.navigate(['/organization/transport-manager/information/vendor/view-vendor']);
      }
      if (res.responseJSON.status == 'failure') {
        console.log(res.responseJSON.message);
        if (res.message) {
          this.swal.swalFunction(res.status, res.status, res.message, 2000);
        }
      }
    }).catch((err) => {
      console.log(err);
    });
  } else {
    return;
  }
  }
}
