import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { BreadcrumbService } from 'src/app/Services/breadcrumb.service';
import { MapService } from 'src/app/Services/map.service';
import { SharedService } from 'src/app/Services/shared.service';
import { StorageService } from 'src/app/Services/storage.service';
import { PROFILE, ProfileState } from 'src/app/State/profile.state';

@Component({
  selector: 'app-top-menu-header',
  templateUrl: './top-menu-header.component.html',
  styleUrls: ['./top-menu-header.component.css']
})
export class TopMenuHeaderComponent implements OnInit {
  constructor(public storage: StorageService,
              public api: ApiService,
              public shared: SharedService,
              public profile: ProfileState,
              public router: Router, private route: ActivatedRoute,
              private map: MapService,
              private _router: Router,
              private menuService: BreadcrumbService
    ) {
      this.menu = this.menuService.getMenu();
      this.listenRouting();
      this.token = this.storage.get('token');
      this.getUrl = this.router.url,
      this.userInfo = this.storage.get('userData');
  }
  @Output() tiggleSideBar: EventEmitter<any> = new EventEmitter();

  public currentTab = 'Dashboard';
  token: string;
  avatar: any;
  userInfo: PROFILE = {
    contactNumber: 0,
    designation: '',
    name: '',
    orgID: '',
    primary: 0,
    status: 0,
    userEmail: '',
    userID: '',
  };
  getUrl: string;

  public masterMenu = [{
    label: 'Dashboard',
    icon: 'dashboard',
    path: '/master/dashboard',
  }, {
    label: 'Dashboard',
    icon: 'Org',
    path: '/master/view-org',
  }];
  public vendorMenu = [{
    label: 'Dashboard',
    icon: 'dashboard',
    path: '/master/dashboard',
  }, {
    label: 'Dashboard',
    icon: 'Org',
    path: '/master/view-org',
  }];
  public currentPath = '/organization/transport-manager/dashboard';
  public tpmDashboardMenu = [{
    label: 'Dashboard',
    icon: 'dashboard',
    path: '/organization/transport-manager/dashboard',
  },
  {
    label: 'SOS Panel',
    icon: 'error',
    path: '/organization/transport-manager/sospanel',
  },
  {
    label: 'Request',
    icon: 'compare_arrows',
    path: '/organization/transport-manager/request-component',
  },
 
  {
    label: 'Route / Rostering ',
    icon: 'call_split',
    path: '/organization/transport-manager/rostering',
    // path: '/organization/transport-manager/rostering',
    
  },
  {
    label: 'Support Desk',
    icon: 'contact_support',
    path: '/organization/transport-manager/support-desk',
  },
  {
    label: 'Reports',
    icon: 'insert_chart_outlined',
    path: '/organization/transport-manager/reports',
  },
  {
    label: 'Information',
    icon: 'info',
    path: '/organization/transport-manager/information',
  },
  // {
  //   label:'List View',
  //   icon:'list_alt',
  //   path:'/organization/transport-manager/listview',
  // },
 
  ];

  name: string;
  menu: any = [];
  breadcrumbList: Array<any> = [];
  topMenu: { label: string; icon: string; path: string; }[];
  public dot;
  public myMap: any = this.map.retMap();
  public mousePos;
  public profileBtnClicked = false;

  ngOnInit(): void {
    this.getUserData();
    this.topMenuSetup();
    this.currentPath = this.router.url;
    this.defaultCircle(this.tpmDashboardMenu, this.currentPath);
  }
  topMenuSetup() {
    const url = this.router.url.split('/');
    if (url && url[1] === 'master') {
      this.topMenu = this.masterMenu;
    }
    if (url && url[2] === 'transport-manager') {
      this.topMenu = this.tpmDashboardMenu;
    }
    if (url && url[2] === 'vendor') {
      this.topMenu = this.vendorMenu;
    }
  }



  listenRouting() {
    let routerUrl: string, routerList: Array<any>, target: any;
    this._router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        target = this.menu;
        this.breadcrumbList.length = 0;
        routerList = routerUrl.slice(1).split('/');
        routerList.forEach((router, index) => {
          if (target != undefined) {
            target = target.find(page => page.path.slice(1) === router);
            if (target != undefined) { this.breadcrumbList.push({
              name: target.name,
              path:
                index === 0
                  ? target.path
                  : `${this.breadcrumbList[index - 1].path}/${target.path.slice(
                    1
                  )}`
            });
            }

            if (index + 1 !== routerList.length) {
              target = target.children;
             // console.log('target --> ', target)
            }
          }
          if (routerList.length == index + 1) {
              console.log('Bread C', this.breadcrumbList);

            }
        });

      }
    });
  }


  getLable(t) {
    this.storage.setData({ tabValue: t });
    // console.log(t, this.selected)
  }
  toggleSideBar() {
    this.tiggleSideBar.emit('over');
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);
  }

  getUserData() {
    this.api.GetCurUserOrg({}).then((res: any) => {
      if (res.status == 'success') {
        this.userInfo = res.data[0];
        this.storage.setData({ userInfo: this.userInfo });
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  // getProfile() {
  //   console.log(this.profile.get)
  //   if (this.profile.get == null) {
  //     this.profile.listen.subscribe((value) => {
  //       this.userInfo = value;
  //       console.log(this.userInfo)
  //     })
  //   } else {
  //     this.userInfo = this.profile.get;
  //   }
  //   console.log("profile state", this.userInfo)
  // }
  signOut() {

    const urlPathArr = this.getUrl.split('/');
    if (urlPathArr && urlPathArr[1] === 'master') {
      this.shared._subject.next({
        type: 'navBar',
        isShowNavbar: true

      });
      this.router.navigate([`/${urlPathArr[1]}/login`]);
      localStorage.clear();
      this.storage.removeAll();
    }

    if (urlPathArr && urlPathArr[2] === 'vendor' || urlPathArr[2] === 'transport-manager') {
      this.shared._subject.next({
        type: 'navBar',
        isShowNavbar: true

      });
      this.router.navigate([`/organization/${urlPathArr[2]}/login`]);

      localStorage.clear();
      this.storage.removeAll();
    }
  }
  // Moving Dashboard Menu circleIcon
  changeCirclePos(value: number) {
      this.mousePos = value;
  }
  currentPage(tabIndex: number) {
    this.profileBtnClicked = false;
    this.currentTab = this.tpmDashboardMenu[tabIndex].label;
    this.currentPath = this.tpmDashboardMenu[tabIndex].path;
    this.getLable(tabIndex);
    this.mousePos = tabIndex;
  }
  // Default Dashboard Menu circleIcon
  defaultCirclePos() {
    this.currentPath = this.router.url;
    this.defaultCircle(this.tpmDashboardMenu, this.currentPath);
    this.mousePos = this.dot;
  }
  defaultCircle(obj, value) {
    this.dot = Object.keys(obj).find( key => value.includes(obj[key].path));
    if (this.dot) {
      this.currentTab = obj[this.dot].label;
      this.mousePos = this.dot;
    }
  }
  checkSelectedTab(tabPath) {
    if (!this.profileBtnClicked) {
      if (this.currentPath.includes(tabPath)) {
        return 'selectedTab';
      }
    }
  }
  selectProfile() {
    this.profileBtnClicked = true;
    this.changeCirclePos(-1);
    this.checkSelectedTab('/organization/transport-manager/profile');
    this.router.navigate(['./organization/transport-manager/profile']);
  }
}
