import { Injectable } from '@angular/core';
declare const mapUtilityMapBox;

@Injectable({
    providedIn: 'root'
})


export class MapService {

    public mapKey = 'pk.eyJ1Ijoib3dlbmxhbWIiLCJhIjoiY2lleWljcnF4MDBiOXQ0bHR0anRvamtucSJ9.t3YnHHqvQZ8Y0MTCNy0NNw';
    public MyMap = new mapUtilityMapBox();
    public mapValue: any;
     ngOnInit() {
        //  this.initMap();
     }
     retMap() {
         return {mymap: this.MyMap, key: this.mapKey};
     }

     loadMap(id: string, markers: any) {
         this.MyMap.setup(id, {
             key: this.mapKey
         }, (map) => {
             this.mapValue = map;
             map.addMarkers(markers);
             map.focus({
                 zoom: 18
             });
            //  map.onClick({
            //     type : 'marker', // marker --or-- cluster
            //     id :  'B'// null , // null --or-- specific marker id  or cluster name
            //    },function(e){
            //     console.log('@@@@@@@@@@@----> ',e)
            //   });

         });
     }

     updateMarker(data) {

            if (this.mapValue) {
                 this.mapValue.marker(data);
                 this.mapValue.clusterSynch();
            }
     }

     flatLine(id: string, points: any) {

        this.MyMap.setup(id, {
            key: this.mapKey
        }, (map) => {
           map.addLine(points);
        //    let url = "./assets/map_images/arrow.png"
        //    map.loadImage(url, function(err, image) {
        //     if (err) {
        //       console.error('err image', err);
        //       return;
        //     }
        //     else console.log("no err");
        //    map.addImage('arrow', image);
        //     map.addLayer({
        //     'id': 'arrow-layer',
        //     'type': 'symbol',
        //     'source': 'mapDataSourceId',
        //     'layout': {
        //         'symbol-placement': 'line',
        //         'symbol-spacing': 1,
        //         'icon-allow-overlap': true,
        //         // 'icon-ignore-placement': true,
        //         'icon-image': 'arrow',
        //         'icon-size': 0.045,
        //         'visibility': 'visible'
        //     }
        //     });
        // });
        });
    }

}
