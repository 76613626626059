import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';

import { LoaderService } from './../../../Services/loader.service';
import { MapService } from './../../../Services/map.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'g10-add-pickup-point',
    templateUrl: './pickupPoints.component.html',
    styleUrls: ['./pickupPoints.component.css']
})


export class AddPickupPointComponent {
    @ViewChild('openModal') openModal: ElementRef;

    public mapHight = '100vh';
    public routeForms: FormGroup;
    public startLocationSugationList:any = {};

    public destroy$: any = new Subject();

    constructor(
        private map: MapService,
        private fb: FormBuilder,
    ) { }

    public myMap: any = this.map.retMap();
    public searchInput: any = new Subject<string>();
    @Output() pickupInfo = new EventEmitter();
    ngOnInit() {
        this.routeForms = this.fb.group({
            pickupvalue: this.fb.array([]),
        });
        this.loadMap();
        this.addPickupPoint();
        this.myMap.mymap.onMapClick((e: any) => {
            this.startLocationSugationList.pickuppoint = [];
            this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
        });
        this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
            if (value.val) {
                this.getGeoCode(value);
            }

        });
    }
    ngAfterViewInit() {
        console.log('Trigger');
        this.openModal.nativeElement.click();
    }

    getGeoCode(value) {
        const key = value.key.key;
        this.startLocationSugationList[key] = [];
        this.myMap.mymap.geocoder(value.val, (res) => {
            try {
                const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
                if (value.key && value.key == 'mapclick') {
                    this.routeForms.value.pickupvalue[this.routeForms.value.pickupvalue.length - 1].pickup = result[0];
                    console.log(result[0], this.routeForms.value);

                } else {
                    this.startLocationSugationList[key][value.key.i] = result;
                    //   if (key == 'pickuppoint') this.startLocationSugationList[key][value.key.i] = result;
                    //   else this.startLocationSugationList[key] = result;
                }
            } catch (er) { console.log(er); }
        });
    }
    ngOnDestroy() {

        this.destroy$.next();
        this.destroy$.unsubscribe();
    }

    Pickupvalues(): FormArray {
        return this.routeForms.get('pickupvalue') as FormArray;
    }
    newPickup(): FormGroup {
        return this.fb.group({
            title: ['', Validators.required],
            pickup: ['']
        });
    }
    addPickupPoint() {
        this.Pickupvalues().push(this.newPickup());
    }
    removePickupoint(indexValue: number) {
        this.Pickupvalues().removeAt(indexValue);
    }
    loadMap(marker: any = []) {
        this.map.loadMap('pickuppoint', marker);
    }
    selectedValue(value, option) {

        if (option.key == 'pickuppoint') {
            this.routeForms.value.pickupvalue[option.val].pickup = value;
            this.startLocationSugationList[option.key][option.val] = [];
            // console.log(this.routeForms.value['pickupvalue'][option.val]);
        } else {
            this.routeForms.value[option.key] = value;
            this.startLocationSugationList[option.key] = [];
        }

    }

    close() {
        // this.pickupInfo.emit(false);
    }
    submitData() {

        // if (this.routeForms.valid) this.pickupInfo.emit(this.routeForms.value);

    }
}
