import {Injectable} from '@angular/core';
declare const Swal;
@Injectable({
  providedIn: 'root'
})

export class SweetAlert {
    constructor() {

    }
    swalFunction(Icon: any, Title: any, Text: any, Timer: any) {
      console.log(Title, Text, Timer, typeof(Text));

      // let msg = this.titleCase(Text)
      // if(typeof(Text)){}
      const ttl = this.titleCase(Title);

      Swal.fire({
            icon: Icon,
            title: ttl,
            text: Text,
            timer: Timer,
        });
      return;
    }

    // 1st letter cabitalized------------------------
    titleCase(str) {
      console.log('titleCase ---> ', str);
      const splitStr = str.toLowerCase().split(' ');
      for (let i = 0; i < splitStr.length; i++) {
          // You do not need to check if i is larger than splitStr length, as your for does that for you
          // Assign it back to the array
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
   }
// --------------------------------------


swalDeleteFunction() {
    Swal.fire({
        title: 'Are you sure?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        }
      });
    //   return
}
}
