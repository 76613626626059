import { NgModule  , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MaterialElevationDirective } from './mat-directives/material-elevetion.directive';
import { CalendarComponent } from './calendar/calendar.component';
import { MatTreeComponent } from './mat-tree/mattree.component';
import { ProfileComponent } from './profile/profile.component';
import { CardComponent } from './card/card.component';
import { ButtonComponent } from './button/button.component';
import { TableComponent } from './table/table.component';
import { DialogComponent } from './dialog/dialog.component';
import { PrismModule } from '@ngx-prism/core';
import { IconComponent } from './icon/icon.component';
import { SnackbarComponent } from './snackbar/snackbar';
import { BarChartComponent } from './charts/barcharts/barchart.component';

import { ChartsModule } from 'ng2-charts';
import { LineChartComponent } from './charts/linecharts/linechart.component';
import { PieChartComponent } from './charts/piecharts/piechart.component';
import { formPageDeclaration } from './dynamic-form-builder/dynamicFormDeclaration';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchModule } from './search-select/search-select';
import { StarRatingComponent } from './star-rating/star-ratting.component';
import { D3ChartComponent } from './charts/d3charts/d3chart.component';
import { D3ChartComponent2 } from './charts/d3charts2/d3chart.component';
import { BackButtonDirective } from './back-button/back-button.directives';
import { NoDataComponents } from './no-data-found/no-data-found.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { SearchComponent } from './search/search.component';
import { FloatingButton } from './floating-button/floating-button.directive';
import { TopMenuHeaderComponent } from './top-menu-header/top-menu-header.component';
import { PaginationComponent } from './pagination/pagination';
import { FilterComponents } from './filters/filter.page.declaration';
// import { RosteringPageDeclaration } from './roastering/roastering.declaration';
import { ApexRadialBarChart } from './charts/apex/radial-bar-chart';


@NgModule({
  declarations: [
    MaterialElevationDirective,
    MatTreeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    CalendarComponent,
    ProfileComponent,
    CardComponent,
    ButtonComponent,
    TableComponent,
    DialogComponent,
    IconComponent,
    SnackbarComponent,
    BarChartComponent,
    LineChartComponent,
    PieChartComponent,
    ApexRadialBarChart,
    formPageDeclaration,
    StarRatingComponent,
    D3ChartComponent,
    D3ChartComponent2,
    BackButtonDirective,
    NoDataComponents,
    TitleBarComponent,
    SearchComponent,
    FloatingButton,
    // RosteringPageDeclaration
    TopMenuHeaderComponent,
    PaginationComponent,
    FilterComponents
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule,
    MatTreeModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatCardModule,
    MatTooltipModule,
    MatDatepickerModule,
    PrismModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    SearchModule,
    MatAutocompleteModule,
    MatStepperModule,

  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ProfileComponent,
    SidebarComponent,
    CalendarComponent,
    CardComponent,
    ButtonComponent,
    TableComponent,
    DialogComponent,
    IconComponent,
    SnackbarComponent,
    MaterialElevationDirective,
    MatTreeComponent,
    BarChartComponent,
    LineChartComponent,
    PieChartComponent,
    ApexRadialBarChart,
    formPageDeclaration,
    StarRatingComponent,
    D3ChartComponent,
    D3ChartComponent2,
    BackButtonDirective,
    NoDataComponents,
    TitleBarComponent,
    SearchComponent,
    FloatingButton,
    TopMenuHeaderComponent,
    PaginationComponent,
    FilterComponents
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class SharedModule { }
