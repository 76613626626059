import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
  selector: 'app-view-regular-manual-rostering',
  templateUrl: './view-regular-manual-rostering.component.html',
  styleUrls: ['./view-regular-manual-rostering.component.css']
})
export class ViewRegularManualRosteringComponent implements OnInit {
  branchObj = [
    {
      branchName: 'Velachery',
      vechicleCnt: 10,
      shifCnt: 2
    }, {
      branchName: 'Tambaram',
      vechicleCnt: 23,
      shifCnt: 4
    }, {
      branchName: 'Pallavaram',
      vechicleCnt: 13,
      shifCnt: 5
    }, {
      branchName: 'Chrompet',
      vechicleCnt: 8,
      shifCnt: 6
    }, {
      branchName: 'Porur',
      vechicleCnt: 11,
      shifCnt: 9
    },
    {
      branchName: 'Meenambakkam',
      vechicleCnt: 34,
      shifCnt: 5
    },
    {
      branchName: 'Guindy',
      vechicleCnt: 15,
      shifCnt: 3
    },
    {
      branchName: 'Tharamani',
      vechicleCnt: 17,
      shifCnt: 5
    },

  ]
 
  constructor(
    public router: Router,
    public storage: StorageService
  ) { }

  ngOnInit(): void {
  }
  selectBrachData(item) {
    console.log(item)
    this.router.navigate(['./organization/transport-manager/rostering/manual/regular/single-view-route'])
    this.storage.setData({ branchData: item })
  }
}
