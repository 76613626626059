export const employeeBillingModule = [
    {
        type: 'text',
        name: 'name',
        label: 'Employee Name',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'dropdown',
        name: 'facility',
        label: 'Facility',
        value: '',
        required: true,
        options: [
            { value: '', name: 'Select' },
            { value: 'Facility1', name: 'Facility1' },
            { value: 'Facility2', name: 'Facility2' },
        ],
        col: 'col-md-4'
    },
    {
        type: 'dropdown',
        name: 'vendor',
        label: 'Vendor',
        value: '',
        required: true,
        options: [
            { value: '', name: 'Select' },
            { value: 'V1', name: 'V1' },
            { value: 'V12', name: 'V12' },
        ],
        col: 'col-md-4'
    },
    {
        type: 'date',
        name: 'dateOfInduction',
        label: 'dateOfInduction',
        minDate: 'today',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'dropdown',
        name: 'vehicleStickerNo',
        label: 'Attached Vehicle Sticker No',
        value: '',
        required: true,
        options: [
            { value: '', name: 'Select' },
            { value: 'Sticker1', name: 'Sticker1' },
            { value: 'Sticker2', name: 'Sticker2' },
        ],
        col: 'col-md-4'
    },
    {
        type: 'date',
        name: 'DOB',
        label: 'Date of Birth',
        // minDate:'today',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'address',
        label: 'Address',
        value: '',
        required: true,
        col: 'col-md-4',
        multiline: true
    },
    {
        type: 'number',
        name: 'mobileNo',
        label: 'Mobile Number',
        value: '',
        required: true,
        col: 'col-md-4',
        textType: 'mobile'
    },
    {
        type: 'number',
        name: 'landlineNo',
        label: 'Landline Number',
        value: '',
        required: false,
        col: 'col-md-4'
    },
    {
        type: 'number',
        name: 'emergencyContactNo',
        label: 'Emergency Contact Number',
        value: '',
        required: false,
        col: 'col-md-4',
        textType: 'mobile'
    },
    {
        type: 'text',
        name: 'emergencyContact',
        label: 'Emergency Contact',
        value: '',
        required: false,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'relationship',
        label: 'Relationship with Emergency Contact',
        value: '',
        required: false,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'licenceNo',
        label: 'Licence Number',
        value: '',
        required: false,
        col: 'col-md-4'
    },
    {
        type: 'date',
        name: 'licenceExpiry',
        label: 'Licence Expiry',
        value: '',
        required: true,
        col: 'col-md-4'
    },
];
