import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import pako from 'pako';
@Injectable({
    providedIn: 'root'
})
export class ApiService {

    // public base: string = "https://demo-tml.g10.pw/";
    public base: string = "http://159.65.155.199:6025/"; // raja system
    // public base: string ="http://localhost:6025/";
    // public base = 'http://emps.g10.pw:6025/';
    // public base = 'https://api-emps.g10.pw/'; //https://api-emps.g10.pw/
    public token;
    public awsS3BugetURL = 'http://ha7cj8rgnvsp2qwq-release-deskapp.placer.in.s3-website.ap-south-1.amazonaws.com/datalog/'; // "https://s3.ap-south-1.amazonaws.com/ha7cj8rgnvsp2qwq-release-deskapp.placer.in/RND/",";

    constructor(public http: HttpClient, public storage: StorageService) {
        this.token = this.storage.get('token');
    }

    // OrganizationCreate(data: any) {
    //     return this.post('master/organisation/create', data);
    // }

    // OrganizationUpdate(data: any) {
    //     return this.post('', data);
    // }

    // OrganizationFetch(data?: any) {
    //     return this.post('master/vieworganisation', data);

    // }


    // // -----------------USER------------------------
    // getOrganization(data: any) {
    //     return this.post('student/login',data);
    //     // return new Promise((resolve) => { console.log('API --->', data); resolve({ status: 'success' }) });
    // }
    // UserCreate(data: any) {
    //     // return this.post('student/login',data);
    //     return new Promise((resolve) => { console.log('API --->', data); resolve({ status: 'success' }) });
    // }


    // UserFetch() {
    //     // return this.post('student/login',data);
    //     return new Promise((resolve) => {
    //         resolve({
    //             status: 'success', data: {
    //                 userfirstname: "arun",
    //                 userlasttname: "kumar",
    //                 useremail: "arun@gmail.in",
    //                 userrole: "depotadmin",
    //                 usercontactno: "984543536363",
    //                 userid: "TC5-323",
    //                 userdesignation: "Depot Admin",
    //                 reportingmanager: "satish"

    //             }
    //         })
    //     });
    // }

    // Read-API

    readS3Data(url) {
        return new Promise((res, rej) => {
            try {
                const oReq = new XMLHttpRequest();
                oReq.open('GET', this.awsS3BugetURL + url, true);
                oReq.responseType = 'arraybuffer';

                oReq.onload = function(oEvent) {
                    const arrayBuffer = oReq.response; // Note: not oReq.responseText
                    // console.log('arrayBuffer----> ', arrayBuffer)
                    if (oReq.status == 200 && oReq.readyState == 4) {
                        if ((arrayBuffer && arrayBuffer != undefined) && (oReq.status == 200 && oReq.readyState == 4)) {
                            const binData = new Uint8Array(arrayBuffer);
                            const data = pako.inflate(binData);
                            const uint8Array = new Uint8Array(data);
                            let finalData = null;
                            if (Array.isArray(uint8Array)) {
                                finalData = uint8Array.reduce(function(acc, i) {
                                    return acc += String.fromCharCode.apply(null, [i]);
                                }, '');
                            } else {
                                // alert("not an array");
                                finalData = Object.keys(uint8Array).map(function(item) {
                                    return uint8Array[item];
                                }).filter(function(notnull) {
                                    if (notnull != null) { return notnull; }
                                }).reduce(function(acc, i) {
                                    return acc += String.fromCharCode.apply(null, [i]);
                                });
                            }

                            if (finalData != undefined) {
                                // console.log(finalData);
                                const subStr = finalData.substr(0, 1);
                                let movementReportdata = '';
                                if (subStr === '{') {

                                } else {
                                    const split = finalData.split('"ts"');
                                    split[0] = '{';
                                    finalData = split.join('"ts"');
                                }
                                movementReportdata = '[' + finalData.split('\n').join(',') + '{}]';
                                const commits = JSON.parse(movementReportdata);
                                setTimeout(function() { res(commits); }, 200);
                            } else {
                                res({});
                                // console.log('if else 1')
                            }
                        } else {
                            res({});
                        }
                    } else {
                        res({});
                    }
                    oReq.onerror = function(e) {
                        console.log('onErro', e);

                        res({});
                    };
                };
                oReq.send(null);

            } catch (e) {
                res({});
            }
        });
    }
    //



    // -----------------------MASTER-------------------
    masterLogin(data: Object) {
        return this.post('master/login', data);
    }
    getMasterLoginData(data: Object) {
        return this.post('master/checkAdminExits', data);
    }
    getlink(data: Object) {
        return this.post('master/getAdminAccountActivationLink', data);
    }
    updatepassword(data: Object) {
        return this.post('master/activateAdminAccount', data);
    }
    org_settings(data: Object) {
        return this.post('master/organization/settings', data);
    }
    org_settingsList(data: Object) {
        return this.post('master/organization/settings_list', data);
    }
    org_settings_update(data: Object) {
        return this.post('master/organization/settings_update', data);
    }
    // GetCurUser(data: any) {
    //     return this.post('organization/getCurUser', data);
    // }
    // org api
    createOrg(data: Object) {
        return this.post('master/organization/create', data);
    }

    listOrg(data: Object) {
        return this.post('master/organization/list', data);
    }
    updateOrg(data: Object) {
        return this.post('master/organization/update', data);
    }
    deleteOrg(data: Object) {
        return this.post('master/organization/delete', data);
    }
    createUser(data: Object) {
        return this.post('user/create', data);
    }
    viewUser(data: Object) {
        return this.post('user/view', data);
    }
    // deleteUser(data: Object) {
    //     return this.post('user/delete', data);
    // }



    // -----------------------------Transport-Manager-----------------------
    transportManagerLogin(data: Object) {
        return this.post('organization/login', data);
    }
    GetCurUserOrg(data: any) {
        return this.post('organization/getCurUser', data);
    }
    GetCurUserVendor(data: any) {
        return this.post('organization/vendor/getCurUser', data);
    }
    forgotPassword(data: any) {
        return this.post('organization/organization/forgetPassword', data);
    }
    changePassword(data: any) {
        return this.post('organization/organization/changePassword', data);
    }

    // -----------------------------------------------------------------------------------



    // ------------------- vendor api's ----------------------------
    transportManagerVendorCreate(data: Object) {
        return this.post('organization/vendor/create', data);
    }
    transportManagerVendorList(data: Object) {
        return this.post('organization/vendor/list', data);
    }
    transportManagerVendorDelete(data: Object) {
        return this.post('organization/vendor/delete', data);
    }
    transportManagerVendorUpdate(data: Object) {
        return this.post('organization/vendor/update', data);
    }
    // ------------------------------------------------------------

    // Vehicle api's ------------------------------------------------
    transportManagerVehicleCreate(data: Object) {
        return this.post('organization/vehicle/create', data);
    }
    transportManagerVehicleList(data: Object) {
        return this.post('organization/vehicle/list', data);
    }
    transportManagerVehicleDelete(data: Object) {
        return this.post('organization/vehicle/delete', data);
    }
    transportManagerVehicleUpdate(data: Object) {
        return this.post('organization/vehicle/update', data);
    }
    // ---------------------------------------------------------------
    // Dreiver api's ------------------------------------------------
    transportManagerDriverCreate(data: Object) {
        return this.post('organization/driver/create', data);
    }
    transportManagerDriverList(data: Object) {
        return this.post('organization/driver/list', data);
    }
    transportManagerDriverDelete(data: Object) {
        return this.post('organization/driver/delete', data);
    }
    transportManagerDriverUpdate(data: Object) {
        return this.post('organization/driver/update', data);
    }
    // -------------------------------------------------------------------
    // Branch api's ------------------------------------------------
    transportManagerBranchCreate(data: Object) {
        return this.post('organization/branch/create', data);
    }
    transportManagerBranchList(data: Object) {
        return this.post('organization/branch/list', data);
    }
    transportManagerBranchDelete(data: Object) {
        return this.post('organization/branch/delete', data);
    }
    transportManagerBranchUpdate(data: Object) {
        return this.post('organization/branch/update', data);
    }
    // Employee ---------------------------------------------
    transportManagerEmployeeCreate(data: Object) {
        return this.post('organization/employee/create', data);
    }
    transportManagerEmployeeList(data: Object) {
        return this.post('organization/employee/list', data);
    }
    transportManagerEmployeeDelete(data: Object) {
        return this.post('organization/employee/delete', data);
    }
    transportManagerEmployeeUpdate(data: Object) {
        return this.post('organization/employee/update', data);
    }

    listBranches(data: Object){
        return this.post('organization/branch/list',data);
    }
    getEmployeeFilter(data:any){
        return this.post('organization/employee/getEmployeeFilter',data);
    }
    filterEmployees(data:Object){
        return this.post('organization/employee/employeeSearch',data);
    }
    // --------------------------------------------------------------------
    // -----------------------------route----------------------------------
    transportMangerRouteCreate(data) {
        return this.post('organization/route/create', data);
    }
    transportMangerRouteList(data) {
        return this.post('organization/route/list', data);
    }
    transportMangerRouteDelete(data) {
        return this.post('organization/route/delete', data);
    }
    transportMangerRouteUpdate(data) {
        return this.post('organization/route/update', data);
    }

    // -----------------------------vendor----------------------------------
    vendorLogin(data: Object) {
        return this.post('organization/vendor/login', data);
    }
    // -------------------------------------------------------------

    // Feedback -------------------------------------------------
    FeedbackCreate(data: Object) {
        return this.post('organization/supportdesk/createFeedback', data);
    }
    FeedbackList(data: Object) {
        return this.post('organization/supportdesk/viewFeedback', data);
    }
    FeedbackUpdate(data: Object) {
        return this.post('organization/supportdesk/updateFeedback', data);
    }
    FeedbackDelete(data: Object) {
        return this.post('organization/supportdesk/deleteFeedback', data);
    }
    // -----------------------------------------------------------
    //  --------Request --------------------
    CreateRequest(data: Object) {
        return this.post('organization/request/create', data);
    }
    ListRequest(data: Object) {
        return this.post('organization/request/list', data);
    }
    UpdateRequest(data: Object) {
        return this.post('organization/request/update', data);
    }
    DeleteRequest(data: Object) {
        return this.post('organization/request/delete', data);
    }

    statusUpdate(data: any) {
        return this.post('organization/statusUpdate', data);
    }
    // customRequest---------------------------------
    CreateCustomRequest(data: any) {
        return this.post('organization/request/createCustomRequest', data);
    }
    ListCustomRequest(data: any) {
        return this.post('organization/request/viewCustomRequest', data);
    }
    UpdateCustomRequest(data: any) {
        return this.post('organization/request/updateCustomRequest', data);
    }
    DeleteCustomRequest(data: any) {
        return this.post('organization/request/deleteCustomRequest', data);
    }

    getCurrentLocation(data: any) {
        return this.post('organization/organization/getCurrentLocation', data);
    }
    // -----------------------------------------------------
    // shift ---------------------------------
    DeleteShift(data: any) {
        return this.post('organization/organization/deleteShift', data);
    }
    CreateShift(data: any) {
        return this.post('organization/organization/createShift', data);
    }
    UpdateShift(data: any) {
        return this.post('organization/organization/updateShift', data);
    }
    ListShift(data: any) {
        return this.post('organization/organization/listShift', data);
    }

    
    // ------------------------------------------------
    // ------get nearest location for given emp location-------
    GetNearestLocation(data: any) {
        return this.post('organization/algorithm/getNearestLocationList', data);
    }

    post(url: string, params: any) {
        try {
            const data = params;
            let headers: any = new HttpHeaders();
            headers = headers.set('Content-Type', 'application/json; charset=utf-8');
            return this.http.post(this.base + url, data, headers)
                .toPromise()
                .then()
                .catch();
        } catch (error) {
            console.log(error);
        }

    }

    get(url: string) {
        try {
            return this.http.get(url);
        } catch (error) {
            console.log(error);
        }
    }


    // post2(url: string, params: any) {
    //     try {
    //         var data = params;
    //         let headers: any = new HttpHeaders();




    //         // headers = headers.set('Content-Type', 'application/json; charset=utf-8');

    //         // const headerOptions:any = {
    //         //     headers: new HttpHeaders({
    //         //         "Access-Control-Allow-Origin": "*",
    //         //         'Content-Type': 'application/json; charset=utf-8',
    //         //         "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT, DELETE",
    //         //         'Token': this.token
    //         //     })

    //         // }
    //         // console.log(headerOptions.headers)

    //         return this.http.post(this.base + url, data, headers)
    //             .toPromise()
    //             .then()
    //             .catch()
    //     } catch (error) {
    //         console.log(error)
    //     }

    // }

}
