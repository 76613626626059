import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { driverModule } from '../../../../../../modules/information/driver';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
import { Location } from '@angular/common';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import indiaStates from '../../../../../../../assets/js/india.state.list.json';

@Component({
    selector: 'add-driver',
    templateUrl: './add-driver.component.html',
    styles: ['./add-driver.component.css']
})


export class AddDriverComponent {
    public driverInfo = driverModule;
    userType: string;
    userData: any;
    vendorOptions: { value: any; name: any; };
    constructor(
        public api: ApiService,
        public storage: StorageService,
        public dialogService: DialogActionService,
        public router: Router,
        public location: Location,
        public swal: SweetAlert
    ) {
        this.userType = this.storage.get('userType');
        this.userData = this.storage.get('vendorID_orgID');
    }
    ngOnInit() {

        if (this.userType != 'transport_manager') {
            this.router.navigate(['/organization/transport-manager/login']);
        }

        const states = {};
        const stateList = this.getStateOptions();
        for (const item of this.driverInfo) {
            if (item.name == 'state') {
                item.options = stateList;
            }
            if (item.name == 'bloodGroup') {
                console.log(item.name == 'bloodGroup');
                console.log(item.value);
            }

        }
    }
    getStateOptions() {
        const states = [];
        for (const country in indiaStates) {
            indiaStates[country].map((item) => {
                states.push({ value: item, name: item });
            });
        }
        return states;
    }

    submitted(e: any) {
        console.log(e.valid, e.value);
        if (e.valid) {
            const obj = {
                orgID: this.userData.orgID,
                vendorID: this.userData.vendorID,
                ...e.value
            };
            console.log(obj, 'final api send data');
            this.api.transportManagerDriverCreate(obj).then((res: any) => {
                if (res.status == 'success') {
                    this.swal.swalFunction(res.status, res.status, res.message, 2000);
                    this.router.navigate(['/organization/transport-manager/information/driver/view-driver', { orgID: obj.orgID, vendorID: obj.vendorID }]);
                } else {
                    this.swal.swalFunction(res.status, res.status, res.message, 2000);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            console.log('error');
        }
    }
}

