import { Component, OnInit, ViewChild } from '@angular/core';
import { ticketModule } from './../../../../../../modules/tickets';



@Component({
    selector: 'create-ticket',
    templateUrl: './create.ticket.component.html',
    styles: ['./create.ticket.component.css']
})


export class CreateTicketComponent {
    public ticketObj: any = ticketModule;
    constructor() {}
    ngOnInit() {
        console.log(ticketModule);
    }


    submitted(e: any) {
         console.log(e);
    }

}
