export const RouteData = [
    {
        routeName: 'route-1',
        vechileCnt: 20,
        pickup: 15,
        drop: 5,
        assigned: 20,
        running: 10,
        employe: 200,
        male: 150,
        female: 50,
        borded: 100,
        deboarded: 50,
        nopresent: 4,
        sosCnt: 3,
        activeCompliants: 8,
        overdueCompliants: 10,
        routeTime: '06:30',
        routePoint: 'velachery',
        pickCnt:10
    }, {
        routeName: 'route-2',
        vechileCnt: 19,
        pickup: 17,
        drop: 2,
        assigned: 15,
        running: 10,
        employe: 190,
        male: 150,
        female: 40,
        borded: 170,
        deboarded: 150,
        nopresent: 0,
        sosCnt: 10,
        activeCompliants: 10,
        overdueCompliants: 5,
        routeTime: '10:30',
        routePoint: 'Tharamani',
        pickCnt:10
    }, {
        routeName: 'route-3',
        vechileCnt: 10,
        pickup: 5,
        drop: 5,
        assigned: 10,
        running: 10,
        employe: 80,
        male: 40,
        female: 40,
        borded: 60,
        deboarded: 50,
        nopresent: 5,
        sosCnt: 4,
        activeCompliants: 3,
        overdueCompliants: 5,
        routeTime: '09:30',
        routePoint: 'Guindy',
        pickCnt:16
    }, {
        routeName: 'route-4',
        vechileCnt: 20,
        pickup: 16,
        drop: 4,
        assigned: 17,
        running: 15,
        employe: 150,
        male: 50,
        female: 100,
        borded: 120,
        deboarded: 110,
        nopresent: 20,
        sosCnt: 6,
        activeCompliants: 3,
        overdueCompliants: 9,
        routeTime: '11:30',
        routePoint: 'Tambaram',
        pickCnt:4
    }, {
        routeName: 'route-5',
        vechileCnt: 5,
        pickup: 4,
        drop: 1,
        assigned: 5,
        running: 4,
        employe: 30,
        male: 20,
        female: 10,
        borded: 16,
        deboarded: 4,
        nopresent: 0,
        sosCnt: 4,
        activeCompliants: 1,
        overdueCompliants: 0,
        routeTime: '12:30',
        routePoint: 'T nagar',
        pickCnt:17
    }, {
        routeName: 'route-6',
        vechileCnt: 20,
        pickup: 15,
        drop: 5,
        assigned: 20,
        running: 10,
        employe: 200,
        male: 150,
        female: 50,
        borded: 100,
        deboarded: 50,
        nopresent: 4,
        sosCnt: 3,
        activeCompliants: 8,
        overdueCompliants: 10,
        routeTime: '01:30',
        routePoint: 'Vadapalani',
        pickCnt:14
    }, {
        routeName: 'route-7',
        vechileCnt: 10,
        pickup: 5,
        drop: 5,
        assigned: 10,
        running: 10,
        employe: 80,
        male: 40,
        female: 40,
        borded: 60,
        deboarded: 500,
        nopresent: 5,
        sosCnt: 4,
        activeCompliants: 3,
        overdueCompliants: 5,
        routeTime: '03:30',
        routePoint: 'Pallavaram',
        pickCnt:13
    }, {
        routeName: 'route-8',
        vechileCnt: 5,
        pickup: 4,
        drop: 1,
        assigned: 5,
        running: 4,
        employe: 30,
        male: 20,
        female: 10,
        borded: 16,
        deboarded: 4,
        nopresent: 0,
        sosCnt: 4,
        activeCompliants: 1,
        overdueCompliants: 0,
        routeTime: '04:30',
        routePoint: 'Koyampedu',
        pickCnt:11
    }, {
        routeName: 'route-9',
        vechileCnt: 20,
        pickup: 16,
        drop: 4,
        assigned: 17,
        running: 15,
        employe: 150,
        male: 50,
        female: 100,
        borded: 120,
        deboarded: 110,
        nopresent: 20,
        sosCnt: 6,
        activeCompliants: 3,
        overdueCompliants: 9,
        routeTime: '08:30',
        routePoint: 'Pallikaranai',
        pickCnt:7
    }, {
        routeName: 'route-10',
        vechileCnt: 5,
        pickup: 4,
        drop: 1,
        assigned: 5,
        running: 4,
        employe: 30,
        male: 20,
        female: 10,
        borded: 16,
        deboarded: 4,
        nopresent: 0,
        sosCnt: 4,
        activeCompliants: 1,
        overdueCompliants: 0,
        routeTime: '09:30',
        routePoint: 'Medavakkam',
        pickCnt:8
    }, {
        routeName: 'route-11',
        vechileCnt: 20,
        pickup: 16,
        drop: 4,
        assigned: 17,
        running: 15,
        employe: 150,
        male: 50,
        female: 100,
        borded: 120,
        deboarded: 110,
        nopresent: 20,
        sosCnt: 6,
        activeCompliants: 3,
        overdueCompliants: 9,
        routeTime: '05:30',
        routePoint: 'Poonamale',
        pickCnt:15
    }, {
        routeName: 'route-12',
        vechileCnt: 19,
        pickup: 17,
        drop: 2,
        assigned: 15,
        running: 10,
        employe: 190,
        male: 150,
        female: 40,
        borded: 170,
        deboarded: 150,
        nopresent: 0,
        sosCnt: 10,
        activeCompliants: 10,
        overdueCompliants: 5,
        routeTime: '03:30',
        routePoint: 'Porure',
        pickCnt:11
    }, {
        routeName: 'route-13',
        vechileCnt: 5,
        pickup: 4,
        drop: 1,
        assigned: 5,
        running: 4,
        employe: 30,
        male: 20,
        female: 10,
        borded: 16,
        deboarded: 4,
        nopresent: 0,
        sosCnt: 4,
        activeCompliants: 1,
        overdueCompliants: 0,
        routeTime: '11:30',
        routePoint: 'Iyyapathangal',
        pickCnt:6
    }
]