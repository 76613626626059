import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SosReportModel } from 'src/app/modules/reports/sos-reports.model';

@Component({
  selector: 'app-sos-report',
  templateUrl: './sos-report.component.html',
  styleUrls: ['./sos-report.component.css']
})
export class SosReportComponent implements OnInit {
  public sosreportInfo = SosReportModel;
  constructor(private location: Location,
              public router: Router) { }

  ngOnInit(): void {
console.log(this.sosreportInfo);
  }
  submitted(e: any) {
    console.log(e);
  }
}
