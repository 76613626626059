import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
import { LoaderService } from 'src/app/Services/loader.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { OrgModule } from '../../../../modules/organization';
declare const Swal;
import indiaStates from './../../../../../assets/js/india.state.list.json';

export interface Subject {
  name: string;
  phone: number;
}

@Component({
  selector: 'app-createorg',
  templateUrl: './vieworg.component.html',
  styleUrls: ['./vieworg.component.css']
})

export class ViewOrgComponent {

  public organization: any = OrgModule;
  public editForm: boolean;
  public orgDetails = [];

  orgID: any;
  userType: string;
  stateArr: any;
  isSearchShow: boolean;
  coutData = 4;
  // fillter ngModel
  orgName: any = '';
  city: any = '';
  status: any = '';
  // ---------

  collection = [];

  constructor(
    public api: ApiService,
    public dialogService: DialogActionService,
    public swal: SweetAlert,
    public storage: StorageService,
    public router: Router,
    public loderservice: LoaderService
  ) {
    for (let i = 1; i <= 200; i++) {
      this.collection.push(`item ${i}`);
    }
    this.userType = this.storage.get('userType');
  }
  public cols = [
    { key: '#', display: '#' },
    { key: 'orgName', display: 'Organization Name' },
    { key: 'orgEmail', display: 'Email' },
    { key: 'city', display: 'City' },

    { key: 'primaryContactName', display: 'Primary Contact Person' },
    { key: 'primaryContactEmail', display: 'Contact Email' },
    { key: 'primaryContactNumber', display: 'Contact Number' },

    {
      key: 'action',
      display: 'Action',
      config: {
        isAction: true,
        actions: [
          { display: 'view', color: 'primary', icon: 'View' },
          { display: 'delete', color: 'warn', icon: 'Delete' },
          { display: 'update', color: 'primary', icon: 'EditContact' }
        ]
      }
    }
  ];
  ngOnInit() {
    // console.log(this.loderservice.show())
    if (this.userType != 'maste-admin') {
      this.router.navigate(['/master/login']);
    }
    this.getOrgList();
    // console.log(this.submitted)

    const states = {};
    this.stateArr = indiaStates.IND;
    // console.log(this.stateArr)
  }

  fiterData() {
    // console.log(this.orgName, this.city, this.status)
    const obj = {
      orgName: this.orgName,
      city: this.city,
      status: this.status
    };
    this.api.listOrg(obj).then((res: any) => {
      // console.log(res)
      if (res.status == 'success') {
      this.swal.swalFunction(res.status, res.status, res.message, 2000);
      }
      if (res.status == 'failure') {
        this.swal.swalFunction('error', res.status, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  showSearch() {
    this.isSearchShow = !this.isSearchShow;
  }

  viewOrg(item) {
    // console.log(item)
    this.router.navigate(['./master/single-view-org', { id: item.orgID }]);
  }
// getPage(e,p){
//   console.log(e,p)
// }


  getOrgList() {
    const obj = {
      page: 1,
      limit: 30
    };
    this.api.listOrg(obj)
      .then((res: any) => {
        console.log('Success', res);
        if (res.status == 'success') {
          this.orgDetails = res.data;
        }
      })
      .catch((error: any) => {
        console.log('Error', error);
      });



  }

  viewOrgAction(action, item, event) {
    event.stopPropagation();

    console.log(action, item, event);
    if (action == 'delete') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want be able to delete this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('delete click');
          this.deleteOrganizationData(item);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        }
      });
    } else if (action == 'edit') {

      this.router.navigate(['/master/edit-org', { id: item.orgID }]);
    }


  }
  onActionHandler(e: any) {
    const data = e.data;
    console.log('Event ', e);
    if (e.action.display.toLowerCase() == 'update') {
      this.orgID = e.data.orgID;
      console.log();
      for (const f of this.organization) {
        // console.log(f)
        const name = f.name;
        let value = '';

        if (f.type == 'dropdown') {
          const val = data[name].split(',');
          const temp = [], str = [];
          val.map(item => { temp.push({ value: val, name: val }); str.push(item); });
          f.selected = temp;
          f.value = str.join(',');
        } else {
          value = name;
          f.value = data[value];

        }

      }
      console.log('organization', this.organization);

      this.editForm = true;
    } else if (e.action.display.toLowerCase() == 'delete') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want be able to delete this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('delete click');
          this.deleteOrganizationData(e);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        }
      });
    }
    if (e.action.display.toLowerCase() == 'view') {
      // this.storage.setData({orgSingleDate:e.data})
      console.log(e);
      this.router.navigate(['./master/single-view-org', { id: e.data.orgID }]);
    }

  }




  deleteOrganizationData(e) {
    console.log(e);
    const obj = {
      orgID: e.orgID
    };
    console.log(obj);
    this.api.deleteOrg(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.staus, res.staus, res.message, 2000);
        this.getOrgList();
      }
      if (res.status == 'failure') {
        this.swal.swalFunction(res.staus, res.staus, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  submitted(e: any) {
    console.log(e);
  }
  formSubmit(e: any) {
    if (e == 0) { this.editForm = false; } else {
      console.log(e);
    }
    console.log('----------', e);
  }
}
