import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employeedetails',
  template: `
`,
  styles: []
})
export class EmployeedetailsComponent implements OnInit {
  isShowIcon = false;
  constructor() { }

  ngOnInit(): void {
  }

}
