export const EmployeeModule: any = [
    {
        type: 'text',
        name: 'firstName',
        label: 'First Name',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'lastName',
        label: 'Last Name',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'email',
        label: 'email ID',
        value: '',
        required: true,
      },
      {
        type: 'date',
        name: 'DOB',
        label: 'Date of Birth',
        value: '',
        required: true,
      },
      {
        type: 'radio',
        name: 'gender',
        label: 'Gender',
        value: '',
        required: true,
        options: [
            {value: 'Male', name: 'Male'},
            {value: 'Female', name: 'Female'}
        ]
      },
      {
        type: 'number',
        name: 'contactNo',
        label: 'Contact Number',
        value: '',
        required: true,

      },
      {
        type: 'number',
        name: 'emergencyContact',
        label: 'Emergency Contact',
        value: '',
        required: false,
      },
      {
        type: 'text',
        name: 'address',
        label: 'Address',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'city',
        label: 'City',
        value: '',
        required: true,
      },
      {
        type: 'dropdown',
        name: 'state',
        label: 'State',
        value: '',
        required: true,
        options: [
          { value: '', name: 'Select' },
          { value: 'TN', name: 'TN'},
          { value: 'Kerala', name: 'Kerala'  },
          { value: 'Bangalore', name: 'Bangalore'}
        ]
      },
      {
        type: 'number',
        name: 'postCode',
        label: 'Post Code',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'jobTitle',
        label: 'Job Title',
        value: '',
        required: true,
      },
      {
        type: 'date',
        name: 'joiningDate',
        label: 'Joining Date',
        minDate: 'today',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'jobType',
        label: 'Job Type',
        value: '',
        required: true,
        options: [
            { value: '', name: 'Select' },
            { value: 'fullTime', name: 'Full Time'},
            { value: 'partTime', name: 'Part Time'  },
            // { value: 'Bangalore', name: 'Bangalore'}
          ]
      },
      {
        type: 'text',
        name: 'empCode',
        label: 'employee Code',
        value: '',
        required: true,
      },
      {
        type: 'text',
        name: 'workLocation',
        label: 'Working Location',
        value: '',
        required: true,
      },
      {
        type: 'dropdown',
        name: 'reportingManager',
        label: 'Reporting Manager',
        value: '',
        required: true,
        options: [
            { value: '', name: 'Select' },
            { value: 'ragu', name: 'Ragu'},
            { value: 'arun', name: 'Arun'  },
            // { value: 'Bangalore', name: 'Bangalore'}
          ]
      },
      {
        type: 'checkbox',
        name: 'language',
        label: 'Language',
        value: '',
        options: [
          { value: 'tamil', name: 'Tamil', selected: true},
          { value: 'english', name: 'English'}
        ],
        required: true,
      },
];
