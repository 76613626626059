import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { COMMA, ENTER, I } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { LeaveRequest, LocationRequest, OnBoardRequest, RouteRequest, SiftRequest, TimeRequest } from 'src/app/modules/request/allrequest';
declare const mapUtilityMapBox, Swal;
@Component({
    selector: 'app-edit-request',
    templateUrl: './edit-request.component.html',
    styles: [`
    `]
})

export class EditRequestComponent {

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private api: ApiService,
        private storage: StorageService,
        private formBuilder: FormBuilder,
        private swal: SweetAlert
    ) {
        this.route.paramMap.subscribe(paramas => {
            this.requestID = paramas.get('id');
            this.cusreqID = paramas.get('custID');
        });
    }
    get f() { return this.requestForm.controls; }
    get fb() { return this.modalFormFields.controls; }
    get editfb() { return this.EditmodalFormFields.controls; }
    requestType: any = [];
    public leaverequset = LeaveRequest;
    public shiftRequest = SiftRequest;
    public onBoardReq = OnBoardRequest;
    public timeRequest = TimeRequest;
    public locatioRequest = LocationRequest;
    public routeRequset = RouteRequest;

    minDate = new Date();
    maxDate = new Date();
    startDateValues: any = new Date();

    isShowFrom = false;
    editRequestData: any;
    requestID: any;
    requestForm: FormGroup;

    modalFormFields: FormGroup;
    @ViewChild('closebutton') closebutton;

    EditmodalFormFields: FormGroup;
    @ViewChild('closebutton2') closebutton2;
    public editNewObject: any = {};

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    actions = [];
    fields = ['text', 'textarea', 'date', 'checkbox', 'radio', 'dropdown'];
    toppingList = ['Employee', 'Driver', 'Vendor', 'Manager'];
    whoReqTo = ['Employee', 'Driver', 'Vendor', 'Manager'];
    requestValue = [
        { value: 'custom_request', viewValue: 'Custom request' },
        { value: 'on_Board_request', viewValue: 'On Board Request' },
        { value: 'location_request', viewValue: 'Location Request' },
        { value: 'route_change', viewValue: 'Route Change' },
        { value: 'time_change', viewValue: 'Time Change' },
        { value: 'shift_change', viewValue: 'Shift Change' },
        { value: 'leave_request', viewValue: 'Leave Request' }
    ];

    submitted = false;
    cusreqID: string;
    editCustomRequestData: any;
    isCoustomRequest = false;
    dataSource: any;
    show: boolean;
    addNewObject: any = {};
    filedsOptionsArry: any[];
    fieldsOptions: any[];
    isTabelShowHide: boolean;
    formShow: boolean;
    isShowCustomForm: boolean;
    uniqID: any;
    isFormShowHide: boolean;
    customData: any = [];
    istexAreaHide: boolean;
    isHidePlaceHolder: boolean;
    isCharacter: boolean;
    isDate: boolean;
    isNumbers: boolean;
    isShowSelectOptions: boolean;
    isShowfielsOptionValues: boolean;
    isShowModelFormPreview: boolean;
    EditfieldsOptions: any[];
    editCustomFormData: any[] = [];
    customLastDateObj: any;
    isShowEditFormsData: boolean;
    selectedindex: number;
    editCustomForm: boolean;

    public fld = [];
    public updateArryData = [];
    ngOnInit() {
        console.log(this.requestID, this.cusreqID);
        if (this.cusreqID) { this.getCustomRequest(); }
        if (this.requestID) { this.getListRequest(); }

        this.requestFormLoad();
        this.fieldFormLoad();
        this.EditfieldFormLoad();
    }

    requestFormLoad() {
        this.requestForm = this.formBuilder.group({
            title: ['', Validators.required],
            requestTo: ['', Validators.required],
            whomToShow: [''],
            requestType: ['', Validators.required],
            note: ['', Validators.required],
            action: [''],
            status: [''],
            priority:[''],
            startDate: [''],
            endDate: [''],
            newMenu:['']
        });
    }
    getCustomRequest() {
        this.isShowFrom = false;
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: this.cusreqID
        };
        this.api.ListCustomRequest(obj).then((res: any) => {
            this.editCustomRequestData = res.data[0];
            this.isCoustomRequest = true;
            this.requestForm.patchValue(this.editCustomRequestData);
            this.requestForm.patchValue({
                startDate: new Date(this.editCustomRequestData.startDate),
                endDate: new Date(this.editCustomRequestData.endDate)
            });
            setTimeout(() => {
                this.show = true;
            }, 300);
            this.actions = this.editCustomRequestData.action;
            if (this.editCustomRequestData.formFields) {
                // this.dataSource.push(this.editCustomRequestData.formFields)
                this.dataSource = this.editCustomRequestData.formFields;
                this.requestType = this.dataSource;
                this.isShowFrom = true;
            } else {
                this.dataSource = 'nodata';
            }
            this.requstTypeValue(this.editCustomRequestData.requestType);
        }).catch((err) => {
            console.log(err);
        });
    }
    getListRequest() {
        const obj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: this.requestID
        };
        this.api.ListRequest(obj).then((res: any) => {
            console.log(res);

            this.editRequestData = res.data[0];

            this.requestForm.patchValue(this.editRequestData);
            this.isShowFrom = true;
            this.requstTypeValue(this.editRequestData.requestType);
        }).catch((err) => {
            console.log(err);
        });
    }


    onSubmit() {

        this.submitted = true;
        console.log(this.requestForm);
        // stop here if form is invalid
        if (this.requestForm.invalid) {
            return;
        }
        if (this.requestID) {
            const obj = {
                orgID: this.storage.get('userInfo').orgID,
                requestID: this.requestID,
                ...this.requestForm.value,
            };
            obj.action = this.actions;
            this.api.UpdateRequest(obj).then((res: any) => {
                if (res.status == 'success') {
                    this.swal.swalFunction(res.status, res.status, res.message, 2000);
                    this.router.navigate(['/organization/transport-manager/request/view-request']);
                }
                if (res == 'failure') {
                    this.swal.swalFunction('error', res.status, res.message, 2000);
                }
            }).catch((err) => {
                console.log(err);
            });
        } else {
            const obj = {
                orgID: this.storage.get('userInfo').orgID,
                requestID: this.cusreqID,
                ...this.requestForm.value,
            };
            obj.action = this.actions;
            this.api.UpdateCustomRequest(obj).then((res: any) => {
                if (res.status == 'success') {
                    this.swal.swalFunction(res.status, res.status, res.message, 2000);
                    this.router.navigate(['/organization/transport-manager/request/view-request']);
                }
                if (res == 'failure') {
                    this.swal.swalFunction('error', res.status, res.message, 2000);
                }
            }).catch((err) => {
                console.log(err);
            });

        }

    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            // this.actions.push({ name: value.trim() });
            this.actions.push({ name: value.trim() });
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(val): void {
        const index = this.actions.indexOf(val);

        if (index >= 0) {
            this.actions.splice(index, 1);
        }
    }


    // formModelChange(val){
    //     switch (val) {
    //         case 'location_request':
    // }
    formLoadPreview() {
        this.isShowFrom = false;
        setTimeout(() => {
            this.isShowFrom = true;
        }, 200);
    }
    requstTypeValue(val?) {
        console.log(val);
        switch (val) {
            case 'location_request':
                this.requestType = this.locatioRequest;
                break;
            case 'time_change':
                this.requestType = this.timeRequest;
                break;
            case 'route_change':
                this.requestType = this.routeRequset;
                break;
            case 'shift_change':
                this.requestType = this.shiftRequest;
                break;
            case 'leave_request':
                this.requestType = this.leaverequset;
                break;
            case 'on_Board_request':
                this.requestType = this.onBoardReq;
                break;
            default:
            // this.isLocationDiv = false
        }
    }

    // modal form field
    fieldFormLoad() {
        this.modalFormFields = this.formBuilder.group({
            label: ['', [Validators.required]],
            type: ['', [Validators.required]],
            name: [Math.random() * 100],
            textType: [''],
            required: [''],
            placeholder: [''],
            // formatValidation: [''],
            options: [''],
            multiselect: [''],
            hint: [''],
            characterLength: [''],
            sizemax: [''],
            sizemin: [''],
            // historyDate:[''],
            // futureDate:[''],
            historyFutureDate: [''],
            col: ['col-md-12'],
            multiline: [false]
        });
    }
    onModalFormSubmit() {
        console.log(this.modalFormFields.value);
        this.submitted = true;
        // stop here if form is invalid
        if (this.modalFormFields.invalid) {
            return;
        }
        this.isTabelShowHide = false;
        this.formShow = false;
        // this.fld.push(this.editCustomRequestData.formFields)
        if (this.editCustomRequestData.formFields) {
            this.editCustomRequestData.formFields.forEach(element => {
                console.log(element);
                this.customData.push(element);
            });
        }

        setTimeout(() => {
            this.isShowCustomForm = true;
            this.isTabelShowHide = true;
            this.formShow = true;
        }, 200);

        const oobj = {
            orgID: this.storage.get('userInfo').orgID,
            requestID: this.editCustomRequestData.requestID,
            formFields: this.customData,
        };
        console.log(oobj);
        this.api.UpdateCustomRequest(oobj).then((res: any) => {
            if (res.status == 'success') {
                // console.log(res)
                this.closebutton.nativeElement.click();
                this.swal.swalFunction('success', res.status, res.message, 2000);
                // this.getListCustomReport()
                this.getCustomRequest();
            }
            if (res.status == 'failure') {
                // console.log(res)
                const msg = [];
                if (Array.isArray(res.message)) {
                    res.message.forEach(element => {
                        // console.log(element)
                        msg.push(element);
                    });

                }
                // console.log(msg.toString())
                const msssg = msg.toString();
                this.swal.swalFunction('error', res.status, msssg, 2000);
                this.addNewObject = {};
            }
        }).catch((err) => {
            console.log(err);
        });
        this.fieldsOptions = [];
        this.filedsOptionsArry = [];
        this.modalFormFields.reset();
        this.addNewObject = {};
    }
    // public addNewObject = {}
    addModelOptions(event: any) {
        console.log(event, typeof (this.addNewObject));
        if (this.customData) { this.customData.pop(); }
        const objKey = Object.keys(this.addNewObject);
        this.isFormShowHide = false;
        this.fieldsOptions = [];
        for (const key of objKey) {
            if (key != 'type') {
                delete this.addNewObject[key];
            }
        }
        const objKey1 = Object.keys(this.modalFormFields.value);
        this.isFormShowHide = false;
        for (const key of objKey1) {
            if (key != 'type') {
                this.modalFormFields.controls[key].reset();
            }
        }
        this.enableDisableFields(event.value);
        this.isFormShowHide = true;
        this.addNewObject.type = event.value;
        this.addNewObject.value = '';
        // if (event.value == 'date') {
        //     this.addNewObject['value'] = ''
        // } else {
        //     delete this.addNewObject['value']
        // }
        if (event.value == 'checkbox' || event.value == 'radio' || event.value == 'dropdown') {
            this.addNewObject.options = [];
        } else {
            delete this.addNewObject.options;
        }
        console.log(this.addNewObject);

    }
    modelClose() {
        this.modalFormFields.reset();
        this.fieldsOptions = [];
    }
    enableDisableFields(type) {
        console.log(type, 'enabel disabe');
        this.istexAreaHide = false;
        this.isHidePlaceHolder = false;
        this.isCharacter = false;
        this.isDate = false;
        this.isNumbers = false;
        this.isShowfielsOptionValues = false;
        this.isShowSelectOptions = false;
        if (type == 'text') {
            this.istexAreaHide = true;
            this.isHidePlaceHolder = true;
            this.isCharacter = true;
        } else if (type == 'textarea') {
            this.isHidePlaceHolder = true;
            this.isCharacter = true;
        } else if (type == 'date') {
            this.isDate = true;
        } else if (type == 'radio') {
            this.isShowfielsOptionValues = true;
        } else if (type == 'checkbox') {
            this.isShowfielsOptionValues = true;
        } else if (type == 'dropdown') {
            this.isShowfielsOptionValues = true;
            this.isShowSelectOptions = true;
        }
    }
    AddOnchangeFormPreview(event: any, name: string) {
        this.addNewObject[name] = event.value;
        if (name == 'required') { this.addNewObject[name] = event.checked; }
        if (name == 'multiselect') { this.addNewObject[name] = event.checked; }
        if (name == 'sizemin') { }
        // if (name == 'sizemax') {
        //     console.log(this.addNewObject['sizemin'],
        //         this.addNewObject['sizemax'])
        //     if (this.addNewObject['sizemin'] > this.addNewObject['sizemax']) {
        //         alert('sizemax')
        //         this.addNewObject['sizemax'] = ''
        //     }
        // }
        if (name == 'options') {
            const obj: any[] = [];
            event.forEach(element => {
                obj.push({ name: element.name, value: element.name });
            });
            // console.log(obj)
            this.addNewObject.options = obj;
        }
        if (name == 'text' || name == 'textare' || name == 'date') {

            delete this.addNewObject.options;
        }
        if (name == 'textType') {
            // console.log(event, name, 'textType')
            this.isCharacter = false;
            this.isNumbers = false;
            if (event.value == 'text') {
                this.isCharacter = true;
            }
            if (event.value == 'number') {
                this.isNumbers = true;
            }
        }
        // console.log(this.addNewObject)
        if (this.customData) { this.customData.pop(); }
        this.customData.push(this.addNewObject);
        this.addNewObject.name = '';
        console.log(this.customData, 'final data');
        this.isShowModelFormPreview = true;
    }

    // edit table and deleter
    editTable(item, id) {
        console.log(item, id);
        this.modalFormFields.patchValue(item);
        console.log(this.modalFormFields);
        document.getElementById('addMore').click();
        this.enableDisableFields(item.type);


        if (this.modalFormFields.value.options) {
            this.fieldsOptions = this.modalFormFields.value.options;
            // this.modalFormFields.value.options.forEach(element => {

            //     console.log(element)
            //     this.fieldsOptions.push(element)
            // });
            console.log(this.fieldsOptions);
        }



    }
    deleteTable(item, id) {
        console.log(item, id);
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want be able to delete this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                console.log('delete click');
                this.deleteTbleList(item, id);
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                );
            }
        });
    }
    deleteTbleList(item, id?) {
        // let newArry = []
        // this.editCustomRequestData.formFields.forEach((element,id) => {
        //     console.log(element,id)
        //     newArry.push(element)
        // console.log(newArry,id)
        // });
        // newArry.splice(1, item)
        this.dataSource.splice(id, 1);
        console.log(item, id, this.dataSource);
    }

    // chips

    addFieldsSet(event: MatChipInputEvent): void {
        if (event.value.length != 0) {
            // console.log(event.value.length != 0)
            // this.filedsOptionsArry.push(event.value)
            // console.log(this.filedsOptionsArry)
            // this.AddOnchangeFormPreview(this.filedsOptionsArry, 'options')
            // alert('fun')

        }
        const input = event.input;
        const value = event.value;

        // Add our fruit
        if ((value || '').trim()) {
            // this.actions.push({ name: value.trim() });
            this.fieldsOptions.push({ name: value.trim() });
            // console.log(this.fieldsOptions)
            this.AddOnchangeFormPreview(this.fieldsOptions, 'options');
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    removeFieldsSet(val): void {
        const id = this.filedsOptionsArry.indexOf(val.name);


        const index = this.fieldsOptions.indexOf(val);
        // console.log(val.name, this.filedsOptionsArry.indexOf(val.name), this.filedsOptionsArry)
        if (index >= 0) {
            this.fieldsOptions.splice(index, 1);
            this.filedsOptionsArry.splice(id, 1);
        }
        // console.log(this.filedsOptionsArry)
        // this.onchangeFormPreview(this.filedsOptionsArry, 'options-remove')
    }
    // ------------------------------------------
    EditfieldFormLoad() {
        this.EditmodalFormFields = this.formBuilder.group({
            label: ['', [Validators.required]],
            type: ['', [Validators.required]],
            textType: [''],
            name: [Math.random() * 100],
            required: [''],
            placeholder: [''],
            // formatValidation: [''],
            options: [''],
            multiselect: [''],
            hint: [''],
            characterLength: [''],
            sizemax: [''],
            sizemin: [''],
            // historyDate:[''],
            // futureDate:[''],
            historyFutureDate: [''],
            col: ['col-md-12'],
            multiline: [false]
        });
    }
    // --------------------------------------------------------------------------------------
    setDataToForm(i) {
        this.EditmodalFormFields.patchValue(
            {
                label: this.editCustomFormData[0].label,
                type: this.editCustomFormData[0].type,
                textType: this.editCustomFormData[0].textType,
                name: this.editCustomFormData[0].name,
                required: this.editCustomFormData[0].required,
                placeholder: this.editCustomFormData[0].placeholder,
                options: this.EditfieldsOptions,
                multiselect: this.editCustomFormData[0].multiselect,
                hint: this.editCustomFormData[0].hint,
                characterLength: this.editCustomFormData[0].characterLength,
                sizemax: this.editCustomFormData[0].sizemax,
                sizemin: this.editCustomFormData[0].sizemin,
                // historyDate:[''],
                // futureDate:[''],
                historyFutureDate: this.editCustomFormData[0].historyFutureDate,
                col: this.editCustomFormData[0].col
            });
        // console.log('Line 594 ', this.EditmodalFormFields.value)
    }
    customFornSingleData(valName, data, index) {
        console.log(valName, data, index);
        if (this.editCustomFormData.length > 0) {
            this.editCustomFormData.pop();
        }

        const obj = data;
        if (valName == 'edit') {
            this.editFeilds(data, index);
        }
        if (valName == 'delete') {
            this.isTabelShowHide = false;
            // console.log(index)
            this.dataSource.splice(index, 1);
            setTimeout(() => {
                this.isTabelShowHide = true;
            }, 20);
            const data = {
                orgID: this.storage.get('userInfo').orgID,
                // ...this.customLastDateObj,
                requestID: this.cusreqID,
                formFields: this.dataSource,
            };
            this.customFormDelete(data);
            // console.log(obj)
        }
    }
    customFormDelete(datas) {
        const obj = {
            ...datas
        };
        console.log(obj);
        this.api.UpdateCustomRequest(obj).then((res: any) => {
            // console.log(res)
            if (res.status == 'success') {
                console.log(res.message,);
                this.swal.swalFunction('success', res.status, res.message, 2000);
            }
            if (res.status == 'failure') {
                console.log(res.message);
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }


    // edit modal form data----------------------------------------------------------------


    editModelOptions(event: any) {
        const objKey1 = Object.keys(this.EditmodalFormFields.value);
        this.isFormShowHide = false;
        for (const key of objKey1) {
            if (key != 'type') {
                this.EditmodalFormFields.controls[key].reset();
            }
        }
        this.editCustomForm = false;
        this.isShowEditFormsData = false;
        // console.log(event.value)
        this.enableDisableFields(event.value);
        const objKey = Object.keys(this.EditmodalFormFields.value);
        this.isFormShowHide = false;
        for (const key of objKey) {
            if (key != 'type') {
                this.EditmodalFormFields.controls[key].reset();
            }
        }
        this.EditfieldsOptions = [];
        this.editCustomFormData = [];
        this.editNewObject.type = event.value;
        this.editNewObject.value = '';
        if (this.editNewObject.type == 'checkbox' || this.editNewObject.type == 'dropdown' || this.editNewObject.type == 'radio') {
            this.editNewObject.option = [];
        } else {

            delete this.editNewObject.option;
        }

        const obj2 = {
            value: this.EditmodalFormFields.value.type
        };
        // console.log(obj2, this.editNewObject)
        this.isFormShowHide = true;
        this.editCustomForm = true;
        setTimeout(() => {
            this.isShowEditFormsData = true;
        }, 300);
    }



    editOnchangeFormPreview(event: any, name: string) {
        console.log(event, name);
        this.isShowEditFormsData = false;
        this.isFormShowHide = false;
        this.editCustomForm = false;
        console.log('line 695 this.new edit Object', name, this.editNewObject);
        if (name == 'textType') {
            // console.log(event, name, 'textType inside ')
            this.isCharacter = false;
            this.isNumbers = false;
            if (event.value == 'text') {
                this.isCharacter = true;

            }
            if (event.value == 'number') {
                this.isNumbers = true;
            }
        }
        const checkNew = Object.keys(this.editNewObject);
        console.log('line no 56-0 ', checkNew.length, checkNew);
        if (checkNew.length > 0) {
            this.editNewObject[name] = event.value;
            if (name == 'required') { this.editNewObject[name] = event.checked == undefined ? false : true; }
            if (name == 'multiselect') { this.editNewObject[name] = event.checked == undefined ? false : true; }
        } else {
            this.editCustomFormData[0][name] = event.value;
            // this.editNewObject[name] = event.value;
            if (name == 'required') { this.editCustomFormData[0][name] = event.checked; }
            if (name == 'multiselect') { this.editCustomFormData[0][name] = event.checked; }
            console.log('line No 569 ', this.selectedindex, this.editCustomFormData);
            // this.editCustomFormDataObj = this.editCustomFormData[0]
        }
        console.log(
            'line No 572 selectedindex', this.selectedindex, 'this.editNewObject', this.editNewObject,
            'this.editCustomFormData ', this.editCustomFormData
        );
        this.isFormShowHide = true;
        this.editCustomForm = true;
        setTimeout(() => {
            this.isShowEditFormsData = true;
        }, 200);


    }
    editFeilds(data: any, index: number) {
        this.isShowEditFormsData = false;
        // console.log('before', this.editCustomFormData.length)
        this.editCustomFormData.pop();
        // console.log('DAta', data, index)
        this.enableDisableFields(data.type);
        this.selectedindex = index;
        this.editCustomFormData.push(data);
        this.isShowEditFormsData = true;
        // console.log('after', this.editCustomFormData.length)
        document.getElementById('modbtn').click();
        if (data.options) {
            this.EditfieldsOptions = [];
            this.EditfieldsOptions = data.options;
        }
        // console.log(this.EditfieldsOptions)
        this.setDataToForm(index);
        if (data.textType == 'text') {
            this.isCharacter = true;
            this.isNumbers = false;
        } else if (data.textType == 'number') {
            this.isNumbers = true;
            this.isCharacter = false;
        } else {
            this.isNumbers = false;
            this.isCharacter = false;
        }


    }
    onEditModalFormSubmit() {
        // console.log(this.editCustomFormData, this.editCustomFormData.length)
        if (this.editCustomFormData.length > 0) {
            this.dataSource[this.selectedindex] = this.editCustomFormData[0];
        } else {
            this.dataSource[this.selectedindex] = this.editNewObject;
            this.editNewObject = {};
        }
        // console.log('index', this.selectedindex, 'old value', this.editCustomFormData, 'New value', this.editNewObject, 'final ', this.dataSource)
        const obj = {
            // ...this.customLastDateObj,
            // uniqID: this.uniqID,
            requestID: this.cusreqID,
            orgID: this.storage.get('userInfo').orgID,
            formFields: this.dataSource,
        };
        // console.log(obj, 'edit valuer check')
        this.api.UpdateCustomRequest(obj).then((res: any) => {
            // console.log(res, 'after edit secess')
            if (res.status == 'success') {
                this.isTabelShowHide = false;
                this.formShow = false;
                this.swal.swalFunction('success', res.status, res.message, 2000);
                this.closebutton2.nativeElement.click();
                setTimeout(() => {
                    this.isShowCustomForm = true;
                    this.isTabelShowHide = true;
                    this.formShow = true;
                    // console.log(this.dataSource)
                    // this.closebutton.nativeElement.click();
                }, 200);
                this.editNewObject = {};
                this.editCustomFormData = [];
                this.isShowEditFormsData = false;
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }

        }).catch((err) => {
            console.log(err);
        });
    }
    // ------------------------------
    addEditFieldsSet(event: MatChipInputEvent): void {
        // editCustomFormData
        // if (event.value.length != 0) {

        //     let optionsValue = this.editCustomFormData[0].options.push(event.value)
        //     this.onchangeFormPreview(optionsValue, 'options')
        // }
        const input = event.input;
        const value = event.value;

        // Add our fruit
        // console.log(this.editCustomFormData)
        if ((value || '').trim()) {
            // this.actions.push({ name: value.trim() });
            this.EditfieldsOptions.push({ name: value.trim(), value });
            // console.log(this.EditfieldsOptions)
            if (this.editNewObject.option == undefined) {
                this.editNewObject.option = [];
            }
            // if (this.editCustomFormData[0]['options'] == undefined) {
            //     this.editCustomFormData[0]['options'] = []
            // }
            this.editNewObject.option.push({ name: value.trim(), value });
            // this.editCustomFormData[0]['options'].push({ name: value.trim(), value: value });
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    removeEditFieldsSet(val): void {
        this.isShowfielsOptionValues = false;
        const id = this.EditfieldsOptions.indexOf(val.name);


        const index = this.EditfieldsOptions.indexOf(val);
        // console.log(val.name, this.EditfieldsOptions.indexOf(val.name), this.EditfieldsOptions)
        if (index >= 0) {
            this.EditfieldsOptions.splice(index, 1);
            // this.EditfieldsOptions.splice(id, 1)
        }
        // console.log(this.EditfieldsOptions)

        this.editOnchangeFormPreview(this.EditfieldsOptions, 'options-remove');
        this.isShowfielsOptionValues = true;
    }
    fun() {
        this.customData = []
    }
}
