import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

@Component({
  selector: 'app-view-branch',
  templateUrl: './view-branch.component.html',
  styleUrls: ['./view-branch.component.css']
})
export class ViewBranchComponent implements OnInit {
  public branchInformation = [];
  /* public branchInformation=[
    { "orgID": "60866279d774d4408635033b",
      "branchID":'12341234',
      "name": "velachery",
      "location": {
          "lat": 12.982389,
          "lng": 80.223183,
          "name": "Velachery, Chennai, Tamil Nadu, India"
      }
  }
  ]; */
  public searchInput: any = new Subject<string>();
  searchedValue: any;
  constructor(
    public api: ApiService,
    public storage: StorageService,
    public router: Router,
    public swal: SweetAlert) { }

  ngOnInit(): void {
    this.getBranchList();
  }
  getBranchList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.branchInformation = res.data;
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  editBranchData(data){
    this.router.navigate(['/organization/transport-manager/information/branch/edit-branch', { orgID: data.orgID, branchID: data.branchID }]);
  }
}
