import { Location } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
    selector: 'app-single-view-vendor',
    template: `
    <title-bar [titleName]="'Vendor View'" class="p-0 m-0 "></title-bar>
<div class="container-fluid">
<div class="row">
<div class="col-md-12 col-12">
<g10-ms-card>
  <div card-content>
        <div class="row mt-2">
            <div class="col-md-2 col-12 text-center">
            <div class="row">
            <div class="col-md-12 col-6">
                <h6>
                 <img src="../../../../../../../assets/images/vendors.png" width="70" >

                </h6>
                </div>
                <div class="col-md-12 col-6">
                <h5> {{vendorViewData.vendorName}}</h5>
                </div>
                </div>
            </div>
            <div class="col-md-10 col-12">
                <div class="row">
                    <div class="col-md-2 col-6 " matTooltip="Mobile">
                       <p class="text-center"> <img src="../../../../../../../assets/images/phone-call.png" width="20"></p>
                        <p class="text-center">{{vendorViewData.mobile}}</p>
                    </div>
                    <div class="col-md-2 col-6  " matTooltip="Email">
                    <p class="text-center">   <img src="../../../../../../../assets/images/email.png" width="20"></p>
                        <p class="text-center">{{vendorViewData.email}}</p>
                    </div>
                    <div class="col-md-2 col-6 " matTooltip="Landline">
                    <p class="text-center">    <img src="../../../../../../../assets/images/telephone.png" width="20"> </p>
                        <p class="text-center">{{vendorViewData.landline}}</p>
                    </div>
                    <div class="col-md-2 col-6" matTooltip="pincode">
                    <p class="text-center">   <img src="../../../../../../../assets/images/mail.png" width="20"></p>
                        <p class="text-center">{{vendorViewData.pincode}} </p>
                    </div>


                <div class="col-md-1 col-6"  matTooltip="Zone">
               <p class="text-center"> <img src="../../../../../../../assets/images/safe-zone.png" width="20"></p>
                <p class="text-center">{{vendorViewData.zone |titlecase}}
                </p>
                </div>
                <div class="col-md-1 col-6" matTooltip="Zone">
               <p class="text-center"> <img src="../../../../../../../assets/images/time-zone.png" width="20"></p>
                <p class="text-center">{{vendorViewData.domain|titlecase }}
                </p>
                </div>

                
                  <div class="col-md-2 col-6" matTooltip="Address">
                   <p class="text-center">
                    <img src="../../../../../../../assets/images/home.png"  width="20">
                  </p>
                  <p class="text-center">  {{vendorViewData.address|titlecase }}</p>

                </div>
                </div>
            </div>
        </div>
    </div>
</g10-ms-card>

</div>
<div class="col-md-12 col-12">
<div class="row">
    <div class="col-md-4 col-12">

        <g10-ms-card (click)="onclick('vehicle',vendorViewData)" class="cursor-pointer" matTooltip="Vehicle">
            <div card-content>
            <div class="row">
            <div class="col-12 text-center">
                <h4>
                     Vehicle
                </h4></div>
                <div class="col-12 text-center">
                <img src="../../../../../../../assets/images/car.png" width="70" class="text-center m-4">
                </div>
                </div>
                <div class="row p-0 m-0 text-center">
                <div class="col ">

                    <h5> 20 </h5>
                     <p class="ft-colr">Total</p>
                </div>
                <div class="col">
                <h5> 20 </h5>
                <p class="ft-colr">Active</p>
                </div>
                <div class="col">
                <h5> 20 </h5>
                <p class="ft-colr">In-active</p>
                </div>
                </div>
            </div>
        </g10-ms-card>
    </div>
    <div class="col-md-4 col-12">

    <g10-ms-card (click)="onclick('driver',vendorViewData)" class="cursor-pointer"  matTooltip="Driver">
    <div card-content>
    <div class="row">
    <div class="col-12 text-center">
        <h4>
        Driver
        </h4></div>
        <div class="col-12 text-center">
        <img src="../../../../../../../assets/images/seatbelt.png" width="70" class="text-center m-4">
        </div>
        </div>
        <div class="row p-0 m-0 text-center">
        <div class="col ">

            <h5> 20 </h5>
             <p class="ft-colr">Total</p>
        </div>
        <div class="col">
        <h5> 20 </h5>
        <p class="ft-colr">Active</p>
        </div>
        <div class="col">
        <h5> 20 </h5>
        <p class="ft-colr">In-active</p>
        </div>
        </div>
    </div>
</g10-ms-card>
    </div>
   <!-- <div class="col-md-4 col-12">
    <g10-ms-card (click)="onclick('employee',vendorViewData)" class="cursor-pointer" matTooltip="Employee">
    <div card-content>
    <div class="row">
    <div class="col-12 text-center">
        <h4>
        Employee
        </h4></div>
        <div class="col-12 text-center">
        <img src="../../../../../../../assets/images/businessman.png" width="70" class="text-center m-4">
        </div>
        </div>
        <div class="row p-0 m-0 text-center">
        <div class="col ">

            <h5> 20 </h5>
             <p class="ft-colr">Total</p>
        </div>
        <div class="col">
        <h5> 20 </h5>
        <p class="ft-colr">Active</p>
        </div>
        <div class="col">
        <h5> 20 </h5>
        <p class="ft-colr">In-active</p>
        </div>
        </div>
    </div>
</g10-ms-card>

    </div> -->
    </div>
    </div>
</div>
</div>


    `,
    styles: [
        `  .card{
            border:none;
            cursor:pointer;
            padding:10px;
        }
        .card:hover{
            background-color:#0000ff24
        }
        .bgHover:hover{
            background-color:#0000ff24
        }
        .ft-colr{
            color:#808080b3;
        }
    `]
})
export class SingleViewVendorComponent {
    vendorViewData: any = {};
    vendorViewKeys = [];
    headerName: any;
    orgID: string;
    vendorID: string;
    constructor(public storage: StorageService, public router: Router,
        public location: Location,
        public route: ActivatedRoute,
        public api: ApiService
    ) {
        this.route.paramMap.subscribe(params => {
            console.log(params.get('orgID'), params.get('vendorID'));
            this.orgID = params.get('orgID');
            this.vendorID = params.get('vendorID');
        });

    }
    ngOnInit() {
        this.headerName = this.vendorViewData.vendorName;
        this.getVendorData();
    }
    // ngOnDestroy() {
    //     this.storage.remove('vendorViewData')
    // }
    getVendorData() {
        const obj = {
            orgID: this.orgID,
            vendorID: this.vendorID
        };
        this.api.transportManagerVendorList(obj).then((res: any) => {
            if (res.status == 'success') {
                this.vendorViewData = res.data[0];
                console.log(this.vendorViewData, 'single vendor data');
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    onclick(val, data) {
        console.log(val, data);
        this.storage.setData({ vendorsIdOrgId: data });
        if (val == 'vehicle') {
            this.router.navigate(['/organization/transport-manager/information/vehicle/view-vehicle', { orgID: data.orgID, vendorID: data.vendorID }]);
        }
        if (val == 'driver') {
            this.router.navigate(['/organization/transport-manager/information/driver/view-driver', { orgID: data.orgID, vendorID: data.vendorID }]);
        }
        // if (val == 'employee') {
        //     this.router.navigate(['/organization/transport-manager/information/employee/view-employee', { orgID: data.orgID, vendorID: data.vendorID }])
        // }
    }
}
