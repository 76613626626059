import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployeeModule } from './../../../../modules/employee';
@Component({
    selector: 'employee',
    templateUrl: './viewEmployee.component.html',
    styleUrls: ['./viewEmployee.component.css']
})
export class ViewEmployeeComponent {
    constructor() {
    }

    public empFields = EmployeeModule;

    public values: any = [];
    public editForm: boolean;
    cols = [
      { key: 'firstName', display: 'Name' },
      { key: 'emailID', display: 'Email' },
      { key: 'jobTitle', display: 'Job Title' },
      { key: 'contactNo', display: 'Contact Number' },
      { key: 'address', display: 'Address' },
      { key: 'city', display: 'City' },
      { key: 'state', display: 'State' },
    //   { key: "country", display: "Country" },
      {
        key: 'action',
        display: 'Action',
        config: {
          isAction: true,
          actions: [
            { display: 'view', color: 'primary', icon: 'View' },
            { display: 'delete', color: 'warn', icon: 'Delete' },
            { display: 'update', color: 'primary', icon: 'EditContact' }
          ]
        }
      }
    ];
    public empDetails: any = [
        {
            firstName: 'RRRRR',
            lastName: 'E',
            emailID: 'rajae@groupten.com',
            DOB: '09/10/2020',
            gender: 'Female',
            contactNo: '8348894944',
            emergencyContact: '3443393493',
            address: 'kjfdsfds',
            city: 'sadfdsafads',
            state: 'TN',
            postCode: '2344224',
            jobTitle: 'dasfdsfads',
            joiningDate: '07/10/2020',
            jobType: 'dafdsfdsf',
            empCode: 'sddfdsfds',
            language: 'tamil',
            workLocation: 'sddfdsfds',
            reportingManager: 'ragu'
          },
          {
            firstName: 'Raja',
            lastName: 'E',
            emailID: 'rajasanthi19@groupten.com',
            DOB: '09/10/2020',
            gender: 'Male',
            contactNo: '8348894944',
            emergencyContact: '3443393493',
            address: 'kjfdsfds',
            city: 'sadfdsafads',
            state: 'TN',
            postCode: '2344224',
            jobTitle: 'dasfdsfads',
            joiningDate: '07/10/2020',
            jobType: 'dafdsfdsf',
            language: 'tamil,english',
            empCode: 'sddfdsfds',
            workLocation: 'sddfdsfds',
            reportingManager: 'ragu'
          }

      ];

    ngOnInit() { console.log('this.empFields', this.empFields); }
    submitted(e: any) {
        console.log(e);
    }
    onActionHandler(e: any) {
      // console.log(e)
        const data = e.data;
        if (e.action.display == 'update') {
          for (const f of this.empFields) {
            const name = f.name;
            let value = '';
            if (f.type == 'dropdown') {
              const val = data[name].split(',');
              const temp = [], str = [];
              val.map(item => { temp.push({ value: val, name: val }); str.push(item); });
              f.selected = temp;
              f.value = str.join(',');
            } else if (f.type == 'checkbox') {
              const spStr = data[name].split(',');
              for (const op of f.options) {
                if (spStr.indexOf(op.value) !== -1) { op.selected = true; }
              }
            } else {
              if (name == 'email') { value = 'emailID'; } else { value = name; }
              f.value = data[value];
            }

          }
          // console.log('this.empFields',this.empFields)
          this.editForm = true;
        } else if (e.action.display.toLowerCase() == 'delete') {
          console.log('openDeleteProductModal');
        //   this.openDeleteProductModal();
        }
      }

      formSubmit(e: any) {
        if (e == 0) { this.editForm = false; } else {
          console.log(e);
        }
      }
}
