import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
// text,email,tel,textarea,password,
import * as _ from 'lodash';
@Component({
  selector: 'checkbox',
  template: `
    <section  [formGroup]="form">
  <ng-container *ngFor="let item of field.options"></ng-container>
  <p><mat-checkbox [formControlName]="field.name">{{item.name}}</mat-checkbox></p>
</section>
    `
})
export class CheckComponent {
  @Input() field: any = {};
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }
  public values: any;
  // public selectedNames = [String];
  // public personForm: FormGroup;
  constructor() { }
  ngOnInit() {
    // this.createFormInputs();
    // console.log(this.personForm.controls.hobbies)
  }

  // createFormInputs() {
  //   this.personForm = new FormGroup({
  //     hobbies: this.createHobbies(this.field.options)
  //   });
  //   this.getSelectedHobbies();
  // }

  // createHobbies(hobbiesInputs) {
  //   const arr = hobbiesInputs.map(hobby => {
  //     return new FormControl(hobby.selected || false);
  //   });
  //   return new FormArray(arr);
  // }

  // getSelectedHobbies() {
  //   const selectedList = _.map(
  //     this.personForm.controls.hobbies,
  //     // this.personForm.controls.hobbies.controls,
  //     (hobby, i) => {
  //       return hobby.value && this.field.options[i].value;
  //     }
  //   );
  //   this.getSelectedOption(selectedList);
  // }
  // getSelectedOption(arrays) {
  //   const options = _.filter(
  //     arrays,
  //     function (hobby) {
  //       if (hobby !== false) {
  //         return hobby;
  //       }
  //     }
  //   );
  //   const fields: any = this.form.controls[this.field.name];
  //   if (options.length > 0) {
  //     fields.value = options.join(',');
  //     fields.status = 'VALID';
  //   } else {
  //     fields.value = '';
  //     fields.status = 'INVALID';
  //   }
  //   this.form.value[this.field.name] = fields.value;
  // }
}
// <label >{{field.label}} {{field.required ? '*':''}}</label>
//      <div *ngFor="let hobby of field.options; let i=index;"
//             class="flex-container  align-center multi-checkbox">
//             <mat-checkbox [formControl]="hobby">{{field.options[i].name}}</mat-checkbox>
//           </div>
//           <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>





// <mat-form-field>
//     <label >{{field.label}} {{field.required ? '*':''}}</label>
//       <section *ngFor="let item of field.options; let i=index;">
//         <p><mat-checkbox>{{item.name}}</mat-checkbox></p>
//     </section>
//        <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>
//     </mat-form-field