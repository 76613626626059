import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import indiaStates from '../../../../../../../assets/js/india.state.list.json';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { StorageService } from 'src/app/Services/storage.service';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { LoaderService } from 'src/app/Services/loader.service';


export interface User {
  name: string;
}
declare const Compressor;
@Component({
  selector: 'app-editdriver',
  templateUrl: './editdriver.component.html',
  styleUrls: ['./editdriver.component.css']
})
export class EditdriverComponent implements OnInit {public editDriverForm: FormGroup;
  public orgID;
  public vendorID;
  public driverID;
  public driverData;
  public enableForm: boolean=false;
  myControl = new FormControl();
  public stateSelect = indiaStates.IND;
  options: User[] = [];
  public stateData = [];
  filteredOptions: Observable<User[]>;
  public userData: any;
  public LicenceImage: any;
  public ProfileImage:any;
  public imageField;
  public licenceImage;
  public profileImage;
  public updateValues=[];

  constructor(
    public api: ApiService,
    public storage: StorageService,
    public router: Router,
    public swal: SweetAlert,
    private loader: LoaderService,
    public route: ActivatedRoute,
    ) {
      this.route.paramMap.subscribe(paramas => {
        console.log(paramas.get('orgID'), paramas.get('vendorID'), paramas.get('driverID'));
        this.orgID = paramas.get('orgID');
        this.vendorID = paramas.get('vendorID');
        this.driverID = paramas.get('driverID');
    });
   }

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges
    .pipe(
      startWith(''),
      map(value => typeof value === 'string' ? value : value.name),
      map(name => name ? this._filter(name) : this.options.slice())
    );
    this.getDriverData();
    this.getStateOptions();
    this.addFormLoad();
  }

  /*Get Driver data*/

  getDriverData() {
    const obj = {
        orgID: this.orgID,
        vendorID: this.vendorID,
        driverID: this.driverID
    };
    this.api.transportManagerDriverList(obj).then((res: any) => {
        if (res.status == 'success') {
            this.driverData = res.data[0];
            const data = res.data[0];
            this.setValueEditDriverForm(data);
            // this.swal.swalFunction(res.status, res.status, res.message, 2000)

        }
    }).catch((err) => {
        console.log(err);
    });
}
setValueEditDriverForm(data){
  console.log(data);
  this.editDriverForm.patchValue({
    address:data.address,
    bloodGroup:data.bloodGroup,
    city:data.city,
    country:data.country,
    dob: new Date(data.dob),
    emergencyContact:data.emergencyContact,
    licenceExpiry: new Date(data.licenceExpiry),
    licenceNo:data.licenceNo,
    //licence:data.licence,
    mobile: data.mobile,
    name: data.name,
    pincode: data.pincode,
    //profilePic: data.profilePic,
    //state:{name: data.state},
  });
  this.enableForm = true;
  this.profileImage=data.profilePic;
  this.licenceImage=data.licence;
  console.log(this.editDriverForm.value.state);
}
   getStateOptions() {
    this.stateSelect.forEach(element => {
      //console.log(element)
      this.options.push({ name: element })
    });
    console.log(this.options);
  }
  addFormLoad(){
    this.editDriverForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      dob: new FormControl('', [Validators.required]),
      licenceNo:new FormControl('', [Validators.required]),
      licenceExpiry:new FormControl('', [Validators.required]),
      mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{9}')]),
      emergencyContact: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{9}')]),
      bloodGroup: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl(''),
      country: new FormControl({ value: 'India', disabled: true }),
      pincode: new FormControl('', [Validators.required, Validators.pattern('[0-9]\\d{5}')]),
      profilePic: new FormControl('', [Validators.required]),
      licence: new FormControl('', [Validators.required]),
    });
  }
  public checkError = (controlName: string, errorName: string) => {
    return this.editDriverForm.controls[controlName].hasError(errorName);
  }
  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();

    }
  }
  displayFn(user: User): string {
    this.stateData = [];
    this.stateData.push(user);
    //this.editDriverForm.value.patchValue({state:user});
    return user && user.name ? user.name : '';
  }
  /*clear image file*/
  clkFun(field) {
    this.editDriverForm.controls[field].patchValue('');
  }
  fileUpload(e:any,field:string){
    this.imageField=field;
    this.handleUpload(e);
  }
  handleUpload(e) {
  const files = e.target.files[0];
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const sizeOfFile = files.size / 1024 / 1024;
    if (sizeOfFile <= 10) {
        if (sizeOfFile <= 1) {
          const reader = new FileReader();
          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsDataURL(file);
        } else {
          this.loader.show();
          const parent = this;
          new Compressor(file, {
            quality: Number((1 / sizeOfFile).toPrecision(2).slice(0, -1)),
            success(result) {
              const reader = new FileReader();
              reader.onload = parent._handleReaderLoaded.bind(parent);
              reader.readAsDataURL(result);
              parent.loader.hide();
            },
            error(err) {
              console.log(err);
              parent.loader.hide();
            }
          }
          );
      }
    } else {
      //this.fileError = true;
      /* const f: any = this.form;
      f.errors = true; */
      //console.log(this.editDriverForm.controls[field].value);
      alert('File size should be less than 1 MB.');
    }
  }

  _handleReaderLoaded(e: any) {
    // console.log("_handleReaderLoaded");
      const reader = e.target;
      let base64=reader.result;
      if(this.imageField=='profilePic'){
        this.profileImage=base64;
        this.editDriverForm.value['profilePic'] = base64;
      }
      if(this.imageField=='licence'){
        this.licenceImage=base64;
        this.editDriverForm.value['licence'] = base64;
      }
      this.updateField(this.imageField,base64);
      //this.editDriverForm.controls[this.imageField].patchValue(reader.result);
      console.log(this.editDriverForm.value);
     /*  this.field.value = reader.result; */
  }
  /*update field*/
  fieldChange(e:any,type,field){
    if(type=='text' || type=='textarea'){
      this.updateField(field,e.target.value);
    }
    if(type=='date' || type=='dropdown'){
      this.updateField(field,e.value);
    }
    if(type=='file'){
      console.log(e);
      //this.fileUpload(e,field);
    }
    if(type=='option'){
      console.log(e.option.value.name);
    }
  }
  updateField(field,value){
    if(this.updateValues.length>0){
      let filterUpdate=this.updateValues.filter((e)=>e.field!=field);
      let obj={field:field,value:value,};
      this.updateValues=[
        ...filterUpdate,
        obj,
      ]
    }
    else{
      let obj={field:field,value:value,};
      this.updateValues.push(obj);
    }
  }
  onSubmit() {
    if (this.editDriverForm.valid) {
      if(this.updateValues.length>0){
        const obj = {
          orgID: this.orgID,
          vendorID: this.vendorID,
          driverID: this.driverID,
        };
        this.updateValues.filter((e)=>{
          obj[e.field]=e.value;
        })
        this.api.transportManagerDriverUpdate(obj).then((res: any) => {
          if (res.status == 'success') {
              this.swal.swalFunction(res.status, res.status, res.message, 2000);
              this.router.navigate(['/organization/transport-manager/information/driver/view-driver', {orgID: obj.orgID, vendorID: obj.vendorID}]);
          }
          if (res.status == 'failure') { this.swal.swalFunction(res.status, res.status, res.message, 2000); }
          }).catch((err) => {
          console.log(err);
        });
      }
      else{
        this.router.navigate(['/organization/transport-manager/information/driver/view-driver', {orgID: this.orgID, vendorID: this.vendorID}]);
      }
    }
  }
}
