import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'single-view-shift',
    template: '',
    styles: ['']
})
export class SingleViewShiftComponent implements OnInit {
    constructor() {

    }
    ngOnInit(): void {

    }
}
