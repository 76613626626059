import { FormGroup , FormControl} from '@angular/forms';
import { Component, ViewChild, ElementRef, Input  } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'dropdownNew',
    template: `
    <div [formGroup]="form">
  <mat-form-field appearance="outline">
	<mat-select (openedChange)="openedChange($event)" [placeholder]="field.label" [formControl]="selectFormControl" >
		<mat-select-trigger>
			{{selectFormControl.value ? selectFormControl.value[0] : ''}}
			<span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">

      </span>
    </mat-select-trigger>
    <div class="select-container">
    <mat-optgroup >
  <mat-form-field style="width:100%;">
    <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput [formControl]="searchTextboxControl">
    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch($event)">
    <mat-icon >close</mat-icon>
  </button>
         </mat-form-field>
    </mat-optgroup>
    <mat-optgroup *ngIf="(filteredOptions | async).length == 0">
      <div>No results found!</div>
    </mat-optgroup>
 <mat-option (onSelectionChange)="selectionChange($event)" *ngFor="let option of filteredOptions | async" [value]="option">
        {{option}}
      </mat-option>
</div>
</mat-select>
</mat-form-field>

</div>
  `

})
export class DropDownNewComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;
    // @Input() list: any[];
    // @Input() __edit: any;

    // checkedList: any[];
    // currentSelected: {};
    // selectedLists: any = [];
    // public showDropDown: boolean;
    constructor() {

    }


    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
    @ViewChild('search') searchTextBox: ElementRef;

    selectFormControl = new FormControl();
    searchTextboxControl = new FormControl();
    selectedValues = [];
    data: string[] = [
      'A1',
      'A2',
      'A3',
      'B1',
      'B2',
      'B3',
      'C1',
      'C2',
      'C3'
    ];

    filteredOptions: Observable<any[]>;

    ngOnInit() {
      /**
       * Set filter event based on value changes
       */
      this.filteredOptions = this.searchTextboxControl.valueChanges
        .pipe(
          startWith<string>(''),
          map(name => this._filter(name))
        );
    }

    /**
     * Used to filter data based on search input
     */
    private _filter(name: string): String[] {
      const filterValue = name.toLowerCase();
      // Set selected values to retain the selected checkbox state
      this.setSelectedValues();
      this.selectFormControl.patchValue(this.selectedValues);
      const filteredList = this.data.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
      return filteredList;
    }

  /**
   * Remove from selected values based on uncheck
   */
    selectionChange(event) {
      if (event.isUserInput && event.source.selected == false) {
        const index = this.selectedValues.indexOf(event.source.value);
        this.selectedValues.splice(index, 1);
      }
    }

    openedChange(e) {
      // Set search textbox value as empty while opening selectbox
      this.searchTextboxControl.patchValue('');
      // Focus to search textbox while clicking on selectbox
      if (e == true) {
        this.searchTextBox.nativeElement.focus();
      }
    }

    /**
     * Clearing search textbox value
     */
    clearSearch(event) {
      event.stopPropagation();
      this.searchTextboxControl.patchValue('');
    }

    /**
     * Set selected values to retain the state
     */
    setSelectedValues() {
      console.log('selectFormControl', this.selectFormControl.value);
      if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
        this.selectFormControl.value.forEach((e) => {
          if (this.selectedValues.indexOf(e) == -1) {
            this.selectedValues.push(e);
          }
        });
      }
    }
}

// <div [formGroup]="form">

// <searchSelect *ngIf="!field.multiselect"
// [_label]="field.label"   [_inputBG]="'#ffff'"  [_lists]="field.options" [_selected]="field.selected" (result)="action($event)"></searchSelect>

// <div *ngIf="field.multiselect">
//     <searchSelect-multi  [_label]="field.label" [_lists]="field.options"
//     (result)="actionMulti($event)"
//     [_inputBG]="'#ffff'"
//     [_hideHeaderOnSelect] = "true"
//     [_selected] = "selectedLists"
//     [_selectAllRequired]="false"
//     ></searchSelect-multi>
// </div>
// <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>
// </div>
