
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './404/notfound.component';
import { DocumentationComponent, DialogDataExampleDialog } from './documentation/documentation.component';
import { MasterAdminDeclarations } from './master/masteradmin.declaration';
import { from } from 'rxjs';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TestingComponent } from './testing/testing.component';
import { PassengersDashboardComponent } from './passengers-dashboard/passengers-dashboard.component';
import { AlertsComponent } from './alerts/alerts.component';
import { VehicleComponent } from './vehicles/vehicle.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationComponent } from './notification/notification.component';
import { HomepageComponent } from './homepage/homepage.component';
// import { TransportmangerComponent } from './transportmanger/transportmanger.component';
// import { TransportMangerAdminPage } from './transportmanger/transportmangeradminpage.declaration';
import { LiveTrackingComponent } from './live-tracking/live-tracking.component';
import { TripsComponent } from './trips/trips.component';
import { SOSalertComponent } from './sosalert/sosalert.component';
import { OrganizationDecaration, } from './organization/organization.declaration';
import { OrganizationComponent } from './organization/organization.component';
import { InformationDeclaration } from './organization/transportmanger/information/information.declaration';


export const PagesDeclaration = [
    LoginComponent,
    NotFoundComponent,
    DocumentationComponent,
    DialogDataExampleDialog,
    DashboardComponent,
    // TransportMangerAdminPage,
    MasterAdminDeclarations,
    InformationDeclaration,
    TestingComponent,
    PassengersDashboardComponent,
    AlertsComponent,
    VehicleComponent,
    SettingsComponent,
    NotificationComponent,
    HomepageComponent,
    // TransportmangerComponent   ,
    LiveTrackingComponent ,
    TripsComponent,
    SOSalertComponent,
    OrganizationDecaration,
    OrganizationComponent

];
