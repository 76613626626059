import { Component, OnInit } from '@angular/core';
declare const $;

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {


  }
  onClickEventReceived(e: any) {
    console.log(e);
  }
}
