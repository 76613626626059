import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { complaintModule } from '../../../../../modules/complaints';
@Component({
    selector: 'edit-complaint',
    template: `
  <!-- The Modal -->
<div id="openModal" class="modal" [ngStyle]="{display: modalDisplayStyle}">

  <!-- Modal content -->
  <div class="modal-content">
    <div align="right">
      <span (click)="modalClose()" class="close">&times;</span>
    </div>
    <p align="{{__align}}" *ngIf="__values" >
        Edit Complaint Form
        <mat-card>
  <g10-ms-dynamic-form-builder
    [fields]="__values" [__edit]="true"
    (values)="submitted($event)"
  ></g10-ms-dynamic-form-builder>
</mat-card>
    </p>

  </div>

</div>
  `,
    styles: [`

/* The Modal (background) */
.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 999999999; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

  `]
})
/*
 <div align="center">
      <span class="btn btn-sm btn-dark" (click)="modalClose()" *ngIf="__close">{{__close}}</span>
    </div>
 */
export class EditComplaintComponent {

    public Fields: any = complaintModule;
    public editForm: boolean;

    constructor() { }
    @Input() __values: string;
    @Input() __close: string;
    @Input() __align: string;
    @Output() emit = new EventEmitter();

    public modalDisplayStyle = 'block';
    ngOnInit() {
        this.loadModal();
    }
    submitted(e: any) {
        this.emit.emit(e);
    }
    loadModal() {
        // Get the <span> element that closes the modal
        const span: any = document.getElementsByClassName('close')[0];
        // When the user clicks on <span> (x), close the modal
        span.onclick = function() {
            // modal.style.display = 'none';
            this.modalDisplayStyle = 'none';
        };
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = (event) => {
            if (this.modalDisplayStyle === 'none') {
                this.modalDisplayStyle = 'none';
            }
        };
    }
    modalClose() {
        const modal = document.getElementById('openModal');
        // modal.style.display = 'none';

        this.modalDisplayStyle = 'none';
        this.emit.emit('0');
    }
    // onActionHandler(e: any) {
    //     let data = e.data;
    //     if (e.action.toLowerCase() == 'update') {
    //       for (let f of this.Fields) {
    //         let name = f['name'];
    //         let value = '';
    //         if (f.type == 'dropdown') {
    //           let val = data[name].split(',');
    //           let temp = [], str = [];
    //           val.map(item => { temp.push({ value: val, name: val }); str.push(item) });
    //           f.selected = temp;
    //           f['value'] = str.join(',');
    //         } else {
    //           if (name == 'email') value = 'emailID';
    //           else value = name;
    //           f['value'] = data[value];
    //         }

    //       }
    //       this.editForm = true;
    //     } else if (e.action.toLowerCase() == 'delete') {
    //       console.log('openDeleteProductModal');
    //     //   this.openDeleteProductModal();
    //     }
    //   }
}
