import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
  selector: 'app-single-view-org',
  template: `
    <title-bar [titleName]="'View Organization '" class="p-0 m-0 filterStickey"></title-bar>
<div class="container-fluid">
  <div class="row p-2">
    <div class="col-12">
      <h4><span class="float-right">

          <g10-ms-button matTooltip="Create Orgnazation" [type]="'primary'"
           [text]="'Create Organization'" [icon]="'Add'"
            [stylish]="'raised'" routerLink="/master/create-org">
          </g10-ms-button>
        </span>
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-12">

      <g10-ms-card *ngIf="orgData">
        <div card-content>
          <div class="row mt-2">
            <div class="col-md-2 col-12 text-center">
              <h6>
                <img src="{{orgData.profile}}" width="90" >

              </h6>
              <h5> {{orgData.orgName}}</h5>
              <p> {{orgData.orgEmail}}</p>
            </div>
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-2 col-6" title="Contact Name">
                  <p class="text-center"> <img src="../../../../../../../assets/images/name.png" width="20"></p>
                  <p class="text-center">P : {{orgData.primaryContactName}}</p>
                  <p class="text-center">S : {{orgData.secondaryContactName}}</p>
                </div>
                <div class="col-md-2 col-6" title="Roles">
                  <p class="text-center"> <img src="../../../../../../../assets/images/roles.png" width="20"></p>
                  <p class="text-center">P : {{orgData.primaryContactRole}}</p>
                  <p class="text-center">S : {{orgData.secondaryContactRole}}</p>
                </div>
                <div class="col-md-2 col-6" title="Mobile">
                  <p class="text-center"> <img src="../../../../../../../assets/images/phone-call.png" width="20"></p>
                  <p class="text-center">P : {{orgData.primaryContactNumber}}</p>
                  <p class="text-center">S : {{orgData.secondaryContactNumber}}</p>
                </div>
                <div class="col-md-2 col-6" title="Email">
                  <p class="text-center"> <img src="../../../../../../../assets/images/email.png" width="20"></p>
                  <p class="text-center">P : {{orgData.primaryContactEmail}}</p>
                  <p class="text-center">S : {{orgData.secondaryContactEmail}}</p>
                </div>

                <div class="col-md-2 col-6" title="pincode">
                  <p class="text-center"> <img src="../../../../../../../assets/images/mail.png" width="20"></p>
                  <p class="text-center">{{orgData.pincode}} </p>
                </div>



                <div class="col-md-2 col-6" title="Address">
                  <p class="text-center">
                    <img src="../../../../../../../assets/images/home.png" width="20">
                  </p>
                  <p class="text-center">
                    {{orgData.address}}
                  </p>
                </div>
                <div class="col-md-2 col-6" title="City">
                  <p class="text-center"> <img src="../../../../../../../assets/images/safe-zone.png" width="20"></p>
                  <p class="text-center">{{orgData.city}}
                  </p>
                </div>

                <div class="col-md-2 col-6" title="State">
                  <p class="text-center"> <img src="../../../../../../../assets/images/state.png" width="20"></p>
                  <p class="text-center">{{orgData.state}}
                  </p>
                </div>
                <div class="col-md-2 col-6" title="Country">
                  <p class="text-center"> <img src="../../../../../../../assets/images/time-zone.png" width="20"></p>
                  <p class="text-center">{{orgData.country}}
                  </p>
                </div>

                <div class="col-md-2 col-6 " title="Website">
                  <p class="text-center"> <img src="../../../../../../../assets/images/domain.png" width="20"></p>
                  <p class="text-center">{{orgData.website}}
                  </p>
                </div>
                </div>
            </div>
          </div>
        </div>
      </g10-ms-card>
      <g10-ms-card *ngIf="!orgData">
        <h2>
          No data found
        </h2>
      </g10-ms-card>
    </div>
  </div>
</div>
    `
})


export class SingleOrgViewComponent implements OnInit {
  public orgID: any;
  orgData: any;
  constructor(
    public api: ApiService,
    public router: Router,
    private route: ActivatedRoute,
    public storage: StorageService,

  ) {
    this.route.paramMap.subscribe(params => {
      console.log(params.get('id'));
      this.orgID = params.get('id');
    });
  }

  ngOnInit() {
    this.getOrgInfo();
  }

  getOrgInfo() {
    const data = {
      orgID: this.orgID
    };
    this.api.listOrg(data).then((res: any) => {
      console.log(res);
      if (res.status == 'success') {
        this.orgData = res.data[0];
      }

      console.log('slected org---> ', this.orgData);
    }).catch((err) => {
      console.log(err);
    });
  }

}
