export const ShiftModel = [
    {
        type: 'text',
        name: 'shiftName',
        label: 'Shift Name',
        value: '',
        required: true,
        col: 'col-md-4'
    },
];
