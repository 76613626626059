import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { DialogActionService } from 'src/app/Services/dialog-actions.service';
import { SharedService } from 'src/app/Services/shared.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
declare const popup: any, snackbar: any;
declare const Swal;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  username = '';
  password = '';
  confirm_password = '';
  showSpinner: boolean;
  inputType = 'password';
  adPswdSt: boolean;
  adEmail: string;
  isShowContent: any;
  isShowEyes = true;

  forgotName: string;
  isForget: any;
  isShowForgotError = false;
  noFields: boolean;

  constructor(
    private router: Router,
    public storage: StorageService,
    public api: ApiService,
    public shared: SharedService,
    public swal: SweetAlert,
    public dialogService: DialogActionService,
  ) {

  }
  ngOnInit(): void {
    this.getAdminCheckData();
    this.shared._subject.next({
      isShowNavbar: true
    });
  }


  // Ender button
  enterToLogin(e) {
    if (e.keyCode === 13) {
      this.login();
    }
  }
  //  Login function
  login(): void {
    // this.sweet.swalFunction('success', 'Deleted', 'Activity has been deleted', 2000)
    const isValidEmail = this.username.match('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
    if (isValidEmail && this.password) {
      this.api.transportManagerLogin({ email: this.username, password: this.password })
        .then((res: any) => {
          if (res.status == 'success') {
            
            this.shared._subject.next({
              type: 'navBar',
              isShowNavbar: false
            });
            
            this.storage.setData({ userType: 'transport_manager', token: res.token });
            this.router.navigate(['/organization/transport-manager/dashboard']);
          } else {
            this.openLogoutModal(res);
          }
        })
        .catch((err) => console.log(err));
    } else if (this.password) {
      if (isValidEmail == null && this.username != '') {
        snackbar(`<span class="text-warning"> Invalid username</span>`);
      } else if (isValidEmail == null && this.username == '') {
        snackbar(`<span class="text-warning"> username required</span>`);
      }
    } else if (this.username != '' && isValidEmail != null) {

      snackbar(`<span class="text-warning"> Password required </span>`);
    } else {
      snackbar(`<span class="text-warning"> Username and password required </span>`);
    }
  }
  openLogoutModal(value) {
    const userId = 'user01';
    const config = {
      name: value.message,
      title: value.status.charAt(0).toUpperCase() + value.status.slice(1).toLowerCase(),
      description: value.message,
      actionButtonText: '',
      userId
    };
    this.dialogService.popup(config);
  }

  //  Space bar disable
  keychange(event) {
    if (event.keyCode == 32) {
      this.username = '';
    }
  }

  //  Show password icon
  viewPassword() {
    this.isShowEyes = !this.isShowEyes;
    this.inputType = this.inputType == 'text' ? 'password' : 'text';
  }


  getAdminCheckData() {
    this.api.getMasterLoginData({})
      .then((res: any) => {
        if (res.status) {
          this.adPswdSt = res.adminPasswordSet;
          this.adEmail = res.email;
          if (res.adminPasswordSet) {
            this.storage.setData({ adEmail: res.email });
            // this.router.navigate(["master/active-link"]);
          }
        } else {
          alert(res.status);
        }
        console.log(res.adminPasswordSet, res.email, res.status);
      }).catch((err: any) => {
        console.log(err);
      });
  }

  forgotEmailValid() {
    this.noFields = false;
    // this.forgotEmail.match('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
    if (this.forgotName) {
      this.isForget = this.forgotName.match('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
    }
    if (this.isForget) {
      this.isShowForgotError = false;
    } else {
      this.isShowForgotError = true;
    }
  }

  sentMail() {
    if (!this.forgotName) {
      this.noFields = true;
      return;
    } else {
      this.noFields = false;
      if (this.forgotName) {
        console.log(this.forgotName);
        this.isForget = this.forgotName.match('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
        // this.close()
      } else {
        this.isShowForgotError = true;
      }
      if (this.isForget) {
        const obj = {
          email: this.forgotName
        };
        console.log(obj);
        this.api.forgotPassword(obj).then((res: any) => {
          if (res.status == 'success') {
            this.swal.swalFunction(res.status, res.status, res.message, 2000);
          }
          if (res.status == 'failure') {
            console.log('failure', res.message);
            snackbar(`<span class="text-warning">Invalid email</span>`);
          }
        }).catch((err) => {
          console.log(err);
        });
      } else { return; }
    }
  }
  close() {
    this.forgotName = '';
    this.isShowForgotError = false;
    this.noFields = false;
  }
}
