import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sos-panel',
  templateUrl: './sos-panel.component.html',
  styleUrls: ['./sos-panel.component.css']
})
export class SosPanelComponent implements OnInit {

  constructor() { }

  public sosUserData = [
    {
      userID : '1234',
      userName: 'User 1',
      imei: 1234567891011,
      vehID : 123,
      sos: 1,
      timeStamp: 1620631518,
  },
  {
      userID : '1235',
      userName: 'User 2',
      imei: 1234567891012,
      vehID : 1234,
      sos: 1,
      timeStamp: 1620631519,
  },
   {
      userID : '1236',
      userName: 'User 3',
      imei: 1234567891013,
      vehID : 1235,
      sos: 1,
      timeStamp: 1620631529,
  }
  ];

  ngOnInit(): void {
  }

}
