import { BranchModuleDeclaration } from './branch/branch.declaration';
import { DriverComponent } from './driver/driver.component';
import { DriverModuleDeclaration } from './driver/driver.declaration';
import { EmployeeBillingComponent } from './employeebilling/employeebilling.component';
import { EmpoloyeeBillingDeclatation } from './employeebilling/employeebilling.declaration';
import { EmployeedetailsComponent } from './employeedetails/employeedetails.component';
import { EmployeeDetailsDeclatation } from './employeedetails/employeedetails.declaration';
import { InformationDashboardComponent } from './information-dashboard/information-dashboard.component';
import { PaymetHistoryComponent } from './paymenthistory/paymenthistory.componet';
import { PaymentHistoryDeclaration } from './paymenthistory/paymenthistory.declaration';
import { AddShiftComponent } from './shift/add-shift/add-shift.component';
import { EditShiftComponent } from './shift/edit-shift/edit-shift.component';
import { ListShiftComponent } from './shift/list-shift/list-shift.component';
import { SingleViewShiftComponent } from './shift/list-shift/single-view-shift';
import { TestInformationComponent } from './test/test.component';
import { TestDeclaration } from './test/test.declaration';
import { VehicleInformationComponent } from './vehicle/vehicle.component';
import { VehicleDeclaration } from './vehicle/vehicle.declaration';
import { VendorDeclaration } from './vendor/vendor-declaration';
import { VendorComponent } from './vendor/vendor.component';


export const InformationDeclaration = [
    VehicleDeclaration,
    TestDeclaration,
    VendorDeclaration,
    DriverModuleDeclaration,
    EmployeeDetailsDeclatation,
    EmpoloyeeBillingDeclatation,
    PaymentHistoryDeclaration,
    TestInformationComponent,
    VendorComponent,
    DriverComponent,
    VehicleInformationComponent,
    EmployeedetailsComponent,
    EmployeeBillingComponent,
    PaymetHistoryComponent,
    InformationDashboardComponent,
    ListShiftComponent,
    AddShiftComponent,
    EditShiftComponent,
    SingleViewShiftComponent,
    BranchModuleDeclaration,
];
