import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'app-view-announcement',
  templateUrl: './view-announcement.component.html',
  styleUrls: ['./view-announcement.component.css']
})
export class ViewAnnouncementComponent implements OnInit {
isdata = new Date().toLocaleDateString();
  constructor(
    public router: Router,
    public api: ApiService,

  ) { }

  ngOnInit(): void {
  }
  viewAnnouncement() {
    this.router.navigate(['/organization/transport-manager/support-desk/announcement/single-view-announcement']);
  }

  viewDelete() {
    console.log('delete');
  }
  viewEdit() {
    console.log('edit');
  }

}
