import { Component, OnInit } from '@angular/core';
import { driverModule } from '../../../../../../modules/information/driver';

@Component({
  selector: 'app-viewpaymenthistory',
  templateUrl: './viewpaymenthistory.component.html',
  styleUrls: ['./viewpaymenthistory.component.css']
})
export class ViewpaymenthistoryComponent implements OnInit {
  constructor() { }
  public driverInfo: any = driverModule;
  public values: any = [];
  public editForm: boolean;
  public cols = [
      { key: 'name', display: 'Driver Name' },
      { key: 'facility', display: 'Facility' },
      { key: 'dateOfInduction', display: 'Date Of Induction' },
      { key: 'vendor', display: 'Vendor'},
      {
        key: 'action',
        display: 'Action',
        config: {
          isAction: true,
          actions: [
            { display: 'view', color: 'primary', icon: 'View' },
            { display: 'delete', color: 'warn', icon: 'Delete' },
            { display: 'update', color: 'primary', icon: 'EditContact' }
          ]
        }
      }
    ];
  public driverInformation = [
      {
          name: 'Raja',
          facility: 'Facility1',
          vendor: 'V1',
          dateOfInduction: '14/10/2020',
          vehicleStickerNo: 'Sticker1',
          DOB: '15/10/2020',
          address: 'asdfmsdmfsd',
          mobileNo: '34534534534',
          landlineNo: '343233',
          emergencyContactNo: '3453434',
          emergencyContact: 'afdfdasda',
          relationship: 'sdfdfdf',
          licenceNo: 'sdfdsfas',
          licenceExpiry: '22/10/2020'
      },
      {
          name: 'Raja E',
          facility: 'Facility2',
          vendor: 'V2',
          dateOfInduction: '13/10/2020',
          vehicleStickerNo: 'Sticker2',
          DOB: '15/10/2020',
          address: 'asdfmsdmfsd',
          mobileNo: '34534534534',
          landlineNo: '343233',
          emergencyContactNo: '3453434',
          emergencyContact: 'afdfdasda',
          relationship: 'sdfdfdf',
          licenceNo: 'sdfdsfas',
          licenceExpiry: '22/10/2020'
      }
  ];
  ngOnInit() {
     // console.log(this.driverInfo)
  }
  submitted(e: any) {
      console.log(e);
  }

  onActionHandler(e: any) {
      const data = e.data;
      if (e.action.display == 'update') {
        for (const f of this.driverInfo) {
          const name = f.name;
          let value = '';
          if (f.type == 'dropdown') {
            const val = data[name].split(',');
            const temp = [], str = [];
            val.map(item => { temp.push({ value: val, name: val }); str.push(item); });
            f.selected = temp;
            f.value = str.join(',');
          } else if (f.type == 'checkbox') {
            const spStr = data[name].split(',');
            for (const op of f.options) {
              if (spStr.indexOf(op.value) !== -1) { op.selected = true; }
            }
          } else {
            if (name == 'email') { value = 'emailID'; } else { value = name; }
            f.value = data[value];
          }

        }
        this.editForm = true;
      } else if (e.action.display.toLowerCase() == 'delete') {
        console.log('openDeleteProductModal');
      //   this.openDeleteProductModal();
      }
  }
  submitForm(e: any) {

    if (e == 0) { this.editForm = false; } else {
      console.log(e);
    }
}

}
