import { AutomaticRosteringComponent } from "./automatic-rostering/automatic-rostering.component";
import { ManualRosteringComponent } from "./manual-rostering/manual-rostering.component";
import { ManualRosteringRouting } from "./manual-rostering/manual-rostering.routing";
import { RosteringComponent } from "./rostering.component";
import { RouteRouting } from "./routes/routes.routing";
import { SemiAutomaticRosteringComponent } from "./semi-automatic-rostering/semi-automatic-rostering.component";

export const RosteringRouting = [
    { path: '', redirectTo: 'rostering', pathMatch: 'full' },
    { path: 'rostering', component: RosteringComponent },
    { path: 'manual', children: ManualRosteringRouting },
    { path: 'routes', children: RouteRouting },
    { path: 'semi-automatic', component: SemiAutomaticRosteringComponent },
    { path: 'automatic', component: AutomaticRosteringComponent },
];
