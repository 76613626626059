import { Injectable } from '@angular/core';

declare var nearestLatLngFromPoints: any;

@Injectable({
    providedIn: 'root'
})

export class KDTreeService {

    constructor() {

    }


    findNearestLocation(latLng: any, find: any, count= 10) {
        return new Promise((resolve, reject) => {
            try {
                let result = nearestLatLngFromPoints(latLng, find, count);
                result = result.sort((a: any, b: any) => a[1] - b[1]);
                // result = result && result[0] ? result && result[0][0] : [];
                resolve(result);
            } catch (error) {
                reject(error);
            }
        });
    }

}
