import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  imageData: string | ArrayBuffer;

  constructor() { }
  fileHandeleBase64(event) {
    return new Promise((resolve,reject)=>{
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        // console.log(reader.result);
      resolve(reader.result)
      };
    })
    // // console.log(event)
   
    // console.log(this.imageData)
    // return this.imageData
  }
 
}
