import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { VechicleComponent } from './vechicle/vechicle.component';

export const VendorRouting = [
    { path: '', component: LoginComponent},
    { path: 'login', component: LoginComponent},
    { path: 'dashboard', component: DashboardComponent},
    {path: 'vehicle', component: VechicleComponent}
];
