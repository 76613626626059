import { Injectable } from '@angular/core';
import { VehicleLiveTrackService } from '../store/vehicle/reducer';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';


declare const io;
@Injectable({
    providedIn: 'root'
})

export class SocketIOService {

    constructor(
        private storage: StorageService,
        private state: VehicleLiveTrackService,
        public api: ApiService
        ) {
            setTimeout(() => {
                this.load();
            }, 1000);
    }
    // public
    public ip: any = 'http://data-emps.g10.pw:3001';
    public socket: any;
    load() {
        const parent = this;
        const myVar = setInterval(function() {
            const orgInfo = JSON.parse(localStorage.getItem('EmployeeSolu'));
            const token = orgInfo.token;
            if (token == undefined) {
                 clearInterval(myVar);
            } else {
                // console.log('15 minuts', 'recrcive', token);
                parent.api.statusUpdate({}).then((res) => {
                    // console.log('ping', res);
                });
            }
         }, 15000);

    }

    ping() {
        console.log('pinged');

    }
    getMessages() {
         try {
        const obj: any = {
            transport: ['websocket']
        };

        this.socket = io(this.ip, obj);
        const orgInfo = this.storage.get('userInfo');
        console.log('get Messages ', orgInfo);
        this.socket.on(orgInfo.orgID, (data) => {
        //   console.log('addVehicleInfo--> ',data)
            this.state.addVehicleInfo(data);

        });
    } catch (error) {
        console.log('ERROR EEEEEEEEEE', error);
    }
    }

    // getSocketData(semdmsg) {
    //     this.socket.emit('msgdata', { mydata: semdmsg });
    // }

}
