import { Component, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {MatDatepickerInputEvent, } from '@angular/material/datepicker';

export const DD_MM_YYYY_Format = {
  parse: {
      dateInput: 'LL',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};



@Component({
  selector: 'date',
  template: `
    <!-- div [formGroup]="form">
      <input type='text' id="flatpickr"  />
      </div   -->

<div [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Choose a {{field.label}} {{field.required ? '*':''}}</mat-label>
        <input matInput [matDatepicker]="picker"  [value]="date.value"
        (dateInput)="addEvent('input', $event)"
        (dateChange)="addEvent('change', $event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker ></mat-datepicker>
        <mat-hint *ngIf="field && field.hint">{{field.hint}}</mat-hint>
        </mat-form-field>
</div>
    `, styles: [`.mat-calendar-table-header th{
      padding: 0px !important;
    }`],
    providers: [
      {provide: MAT_DATE_FORMATS, useValue: DD_MM_YYYY_Format},
  ]
})
export class DateComponent {
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }
  constructor() { }
  @Input() field: any = {};
  @Input() form: FormGroup;
  public date;
  public selectedDate: string;
  events: string[] = [];
  ngOnInit() {
    const sp = this.field.value.split('/');
    const MMDDYYYY = sp[1] + '-' + sp[0] + '-' + sp[2];
    console.log('date directives', MMDDYYYY);
    this.date = new FormControl(new Date(MMDDYYYY));
    // console.log('this.date',this.date,'this.field.value',MMDDYYYY)
    // let parent = this;
    // var fp = flatpickr(document.querySelector('#flatpickr'), {
    //   altFormat: "DD-MM-YYYY",
    //   minDate: this.field.minDate ? this.field.minDate : '',
    //   defaultDate: this.field.value ? this.field.value : '',
    //   // maxDate: this.field.maxDate,
    //   // dateFormat:this.field.dateFormat,
    //   mode: this.field.mode ? this.field.mode : 'single',// this.field.mode,
    //   // enableTime: this.field.enableTime,
    //   // time_24hr: this.field.time_24hr,
    //   // minTime: this.field.minTime,
    //   // maxTime: this.field.maxTime,
    //   onChange: function (selectedDates, dateStr, instance) {
    //     parent.assignDate(dateStr);

    //   }
    // });

  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
      const val: any = event.targetElement;
      const finalDate = val.value.split('/');
      const year = finalDate[2];
      const month = finalDate[1];
      const date = finalDate[0];
      console.log(finalDate, year + '/' + month + '/' + date);


      this.assignDate(year + '/' + month + '/' + date);
  }
  assignDate(dateStr: string) {
    const fields: any = this.form.controls[this.field.name];
    fields.status = dateStr ? 'VALID' : 'INVALID';
    fields.value = dateStr ? dateStr : '';
    this.form.value[this.field.name] = dateStr ? dateStr : '';
  }
}
