import { Injectable } from '@angular/core';
import { rejects } from 'assert';
import { resolve } from 'dns';
import { promise } from 'selenium-webdriver';
import { KDTreeService } from './kdtree.service';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { MapService } from 'src/app/Services/map.service';
import { OrgVehicleReducer } from 'src/app/store/orgVehicle/reducer';
declare const turf;
@Injectable({
  providedIn: 'root'
})

export class RouteService {
  osrmUrl = 'http://localmap.g10.pw:5000/route/v1/driving/';    // find distance
  // osrmUrl = 'http://172.16.0.77:5000/route/v1/driving/';    // find distance
  // osrmUrlloc = 'http://172.16.0.77:5000/nearest/v1/driving/';   //find nearest location
  osrmUrlloc = 'http://localmap.g10.pw:5000/nearest/v1/driving/';   //find nearest location
  shiftTime = 'Second';      // First,Second,Third
  distForclust = 500; // dist 500 in meters
  routeType = 1;    // 1-pickup, 0-drop
  route_opt = 1;       // 1-management fav , 0-driver fav(zigzag)
  officeLoc = { Point:"office", lat: 12.989705970777079, lng: 80.24943448575365 };
  route_obj: any;
  routes_t: any[] = [];
  routes = {};
  final_route: any = {};
  count_r: number = 0;
  mapData = [];
  EtaTime = 0;
  EtaDist = 0;
  //   local_arr = [];
  //   local_obj = {};
  orgveh: any;
  routeObj: any = [
    {
      routeName: 'R1',
      routeType: 'Temporary',
      branch: 'velachery',
      routeDate: '14-07-2021',
      routeShift: 'secondshift',
      cabInfo: [
        {
          Cab_Id: 0,
          cabRegNo: 'TN00AB0000',
          CarName: 'indica',
          SeatCap: 6,
          Req_Seat: 3,
          shift: 'First',
          Type: 'Hatchback',
          Class: 'Normal',
          location: {
            lat: 12.949412,
            lng: 80.239491
          }
        }, {
          Cab_Id: 0,
          cabRegNo: 'TN11AB1111',
          CarName: 'indica',
          SeatCap: 6,
          Req_Seat: 1,
          shift: 'First',
          Type: 'Hatchback',
          Class: 'Normal',
          location: {
            lat: 12.973122,
            lng: 80.245637
          }
        }
      ],
      employeeInfo: [
        {
          orgID: '60866279d774d4408635033b',
          empRefID: 'Test2',
          name: 'Test2',
          dob: 1622764800000,
          gender: 'Female',
          mobile: '9876543210',
          email: 'test2@gmail.com',
          emergencyContact: '9876542234',
          bloodGroup: 'A',
          address: 'Guindy',
          city: 'chennai',
          state: 'Tamil Nadu',
          country: 'India',
          pincode: '600042',
          location: {
            lat: 13.081479,
            lng: 80.217527,
            name: 'Sanabel Biriyani Point, Chennai, Tamil Nadu, India'
          },
          workLocation: 'chennai',
          shift: 'secondshift',
          transportType: 'company',
          profile: '/home/devuser/EMPS/API/emps-images/emp_Test2.png',
          employeeID: '60dd81e709e02e1315c9389d',
          locationName: 'Sanabel Biriyani Point, Chennai, Tamil Nadu, India'
        },
        {
          orgID: '60866279d774d4408635033b',
          empRefID: 'test123',
          name: 'test',
          dob: 1623110400000,
          gender: 'Male',
          mobile: '9123451234',
          email: 'test123@gmail.com',
          emergencyContact: '9123412342',
          bloodGroup: 'o',
          address: 'velachery',
          city: 'chennai',
          state: 'Tamil Nadu',
          country: 'India',
          pincode: '600042',
          location: {
            lat: 13.053499,
            lng: 80.273431,
            name: 'Al-Elaf Arabian Restaurant, Chennai, Tamil Nadu, India'
          },
          workLocation: 'chennai',
          shift: 'secondshift',
          transportType: 'company',
          profile: '/home/devuser/EMPS/API/emps-images/emp_test123.png',
          employeeID: '60d0639b1837c71372dd5fe7',
          locationName: 'Al-Elaf Arabian Restaurant, Chennai, Tamil Nadu, India'
        },
        {
          orgID: '60866279d774d4408635033b',
          empRefID: 'asdf123',
          name: 'asdf',
          dob: 1622764800000,
          gender: 'Male',
          mobile: '1234123412',
          email: 'asdf@g10.com',
          emergencyContact: '1234145121',
          bloodGroup: 'o',
          address: 'velachery',
          city: 'chennai',
          state: 'Tamil Nadu',
          country: 'india',
          pincode: '600042',
          location: {
            lat: 14.373596,
            lng: 80.022866,
            name: 'Kakupalle -Ii, Nellore, Sri Potti Sriramulu Nellore, Andhra Pradesh, India'
          },
          workLocation: 'chennai',
          shift: 'secondshift',
          transportType: 'company',
          profile: '/home/devuser/EMPS/API/emps-images/emp_asdf123.png',
          employeeID: '60d041e61837c71372dd5fe6',
          locationName: 'Kakupalle -Ii, Nellore, Sri Potti Sriramulu Nellore, Andhra Pradesh, India'
        },
        {
          orgID: '60866279d774d4408635033b',
          empRefID: 'asd123',
          name: 'asd',
          dob: 1622678400000,
          gender: 'Male',
          mobile: '9123412341',
          email: 'asdr@g10.com',
          emergencyContact: '9123412323',
          bloodGroup: 'o-',
          address: 'vcy',
          city: 'chennai',
          state: 'Tamil Nadu',
          country: 'india',
          pincode: '600042',
          location: {
            lat: 13.056107,
            lng: 80.231961,
            name: 'Ayappan Temple, Mahalingapuram, Chennai, Tamil Nadu, India'
          },
          workLocation: 'CHENNAI',
          shift: 'secondshift',
          transportType: 'company',
          employeeID: '60d03866df9a28d99bd59a3c',
          locationName: 'Ayappan Temple, Mahalingapuram, Chennai, Tamil Nadu, India'
        }, {
          orgID: '60866279d774d4408635033b',
          empRefID: 'Test2',
          name: 'Test2',
          dob: 1622764800000,
          gender: 'Female',
          mobile: '9876543210',
          email: 'test2@gmail.com',
          emergencyContact: '9876542234',
          bloodGroup: 'A',
          address: 'Guindy',
          city: 'chennai',
          state: 'Tamil Nadu',
          country: 'India',
          pincode: '600042',
          location: {
            lat: 13.081479,
            lng: 80.217527,
            name: 'Sanabel Biriyani Point, Chennai, Tamil Nadu, India'
          },
          workLocation: 'chennai',
          shift: 'secondshift',
          transportType: 'company',
          profile: '/home/devuser/EMPS/API/emps-images/emp_Test2.png',
          employeeID: '60dd81e709e02e1315c9389d',
          locationName: 'Sanabel Biriyani Point, Chennai, Tamil Nadu, India'
        },
        {
          orgID: '60866279d774d4408635033b',
          empRefID: 'test123',
          name: 'test',
          dob: 1623110400000,
          gender: 'Male',
          mobile: '9123451234',
          email: 'test123@gmail.com',
          emergencyContact: '9123412342',
          bloodGroup: 'o',
          address: 'velachery',
          city: 'chennai',
          state: 'Tamil Nadu',
          country: 'India',
          pincode: '600042',
          location: {
            lat: 13.053499,
            lng: 80.273431,
            name: 'Al-Elaf Arabian Restaurant, Chennai, Tamil Nadu, India'
          },
          workLocation: 'chennai',
          shift: 'secondshift',
          transportType: 'company',
          profile: '/home/devuser/EMPS/API/emps-images/emp_test123.png',
          employeeID: '60d0639b1837c71372dd5fe7',
          locationName: 'Al-Elaf Arabian Restaurant, Chennai, Tamil Nadu, India'
        },
        {
          orgID: '60866279d774d4408635033b',
          empRefID: 'asdf123',
          name: 'asdf',
          dob: 1622764800000,
          gender: 'Male',
          mobile: '1234123412',
          email: 'asdf@g10.com',
          emergencyContact: '1234145121',
          bloodGroup: 'o',
          address: 'velachery',
          city: 'chennai',
          state: 'Tamil Nadu',
          country: 'india',
          pincode: '600042',
          location: {
            lat: 14.373596,
            lng: 80.022866,
            name: 'Kakupalle -Ii, Nellore, Sri Potti Sriramulu Nellore, Andhra Pradesh, India'
          },
          workLocation: 'chennai',
          shift: 'secondshift',
          transportType: 'company',
          profile: '/home/devuser/EMPS/API/emps-images/emp_asdf123.png',
          employeeID: '60d041e61837c71372dd5fe6',
          locationName: 'Kakupalle -Ii, Nellore, Sri Potti Sriramulu Nellore, Andhra Pradesh, India'
        },
        {
          orgID: '60866279d774d4408635033b',
          empRefID: 'asd123',
          name: 'asd',
          dob: 1622678400000,
          gender: 'Male',
          mobile: '9123412341',
          email: 'asdr@g10.com',
          emergencyContact: '9123412323',
          bloodGroup: 'o-',
          address: 'vcy',
          city: 'chennai',
          state: 'Tamil Nadu',
          country: 'india',
          pincode: '600042',
          location: {
            lat: 13.056107,
            lng: 80.231961,
            name: 'Ayappan Temple, Mahalingapuram, Chennai, Tamil Nadu, India'
          },
          workLocation: 'CHENNAI',
          shift: 'secondshift',
          transportType: 'company',
          employeeID: '60d03866df9a28d99bd59a3c',
          locationName: 'Ayappan Temple, Mahalingapuram, Chennai, Tamil Nadu, India'
        }
      ]
    },
  ];
  employee = this.routeObj[0].employeeInfo;
  cab = this.routeObj[0].cabInfo;

  constructor(
    public kdtree: KDTreeService,
    public api: ApiService,
    public storage: StorageService,
    public route: RouteService,
    public map: MapService

  ) {

  }
  shortPickuppoints(cab: any, pickpts: any) {
    return new Promise(async (resolve, reject) => {
      const obj = { startLatLng: cab, pickupPoints: pickpts };
      // console.log("cab_pick",obj)
      this.api.GetNearestLocation(obj).then((res: any) => {
        // console.log("res from api",res);
        resolve(res);
      }).catch((resCatch: any) => {
        // console.log("catch status", resCatch)
      });
    });
  }

  async routeCreate(routeGenInputObj) {
    // async routeCreate() {

    const employee = routeGenInputObj[0].employeeInfo;
    const cab = routeGenInputObj[0].cabInfo;
    const cab1: any[] = [{
      orgID: '60866279d774d4408635033b',
      vendorID: '60cae868ef11c5a004e910b6',
      vehRegNo: 'TN01AA1112',
      imei: '1241923489123123',
      make: 'car',
      model: 'Hatchback',
      chassisNo: 'acsdfh9a8sd77f',
      engineNo: 'en12341',
      purchasedDate: 1625184000000,
      routePermitCost: '2000',
      startDate: 1625788800000,
      expiryDate: 1625788800000,
      roadTaxStatus: true,
      rcNo: 'RC1234',
      rcStartDate: 1625788800000,
      rcExpiryDate: 1627603200000,
      vehicleInfo: 'none',
      rcfile: 'C:\\fakepath\\profilePng.png',
      capacity: '4',
      vehicleType: 'Owned',
      boxType: 'groupten',
      createdBy: null,
      status: 1,
      createdAt: 1625746924631,
      simNo: '9234123412',
      vehicleID: '60e6edec41be498746c2cf3a',
      location: {
        lat: 13.998463,
        lng: 80.651243
      }
    }, {
      orgID: '60866279d774d4408635033b',
      vendorID: '60cae868ef11c5a004e910b6',
      vehRegNo: 'TN01AA1113',
      imei: '918234123412344',
      make: 'CAR',
      model: 'SUV',
      chassisNo: 'CH2341234ASF123',
      engineNo: 'EN51234',
      purchasedDate: 1627516800000,
      routePermitCost: '1000',
      startDate: 1626393600000,
      expiryDate: 1627689600000,
      roadTaxStatus: true,
      rcNo: 'Rc123498',
      rcStartDate: 1625788800000,
      rcExpiryDate: 1626912000000,
      vehicleInfo: 'none',
      rcfile: 'C:\\fakepath\\profilePng.png',
      capacity: '6',
      vehicleType: 'Owned',
      boxType: 'groupten',
      createdBy: null,
      status: 1,
      createdAt: 1625747069279,
      simNo: '8123412341',
      vehicleID: '60e6ee7d41be498746c2cf3b',
      location: {
        lat: 13.960201,
        lng: 80.890773
      }
    }, {
      orgID: '60866279d774d4408635033b',
      vendorID: '60cae868ef11c5a004e910b6',
      vehRegNo: 'TN01AA1114',
      imei: '8716237846102312',
      make: 'car',
      model: 'Van',
      chassisNo: 'aosudfh938r',
      engineNo: 'en213412',
      purchasedDate: 1625788800000,
      routePermitCost: '5000',
      startDate: 1625702400000,
      expiryDate: 1627689600000,
      roadTaxStatus: true,
      rcNo: 'RC98791832',
      rcStartDate: 1625184000000,
      rcExpiryDate: 1627689600000,
      vehicleInfo: 'none',
      rcfile: 'C:\\fakepath\\profilePng.png',
      capacity: '4',
      vehicleType: 'Owned',
      boxType: 'groupten',
      createdBy: null,
      status: 1,
      createdAt: 1625747177608,
      simNo: '7192384619',
      vehicleID: '60e6eee941be498746c2cf3c',
      location: {
        lat: 13.929389,
        lng: 80.689203
      }
    }];
      // console.log("employee",this.employee)
    this.route_obj = await this.makeRoute(employee, cab, this.route_opt, this.shiftTime, this.routeType);   // create route for selected emp and cab
    // this.route_obj = await this.makeRoute(this.employee,this.cab,this.route_opt,this.shiftTime,this.routeType)   //create route for selected emp and cab
    console.log('route_obj', this.route_obj);

    const empPickpts = await this.separatePickpts(this.route_obj);   // separate emp loc details for create cluspts
    // console.log("emppick",empPickpts)
    const routeCluspts = await this.createClusPts(this.route_obj, empPickpts);  // ->make routes with cluster points
    // console.log("clus",routeCluspts)
    const orderedPts = await this.orderByGender(routeCluspts);
    // console.log("orderedPts", orderedPts)
    const shortPts = await this.makeshortRoutePts(orderedPts);     // get shortest route for pickup pts
    // console.log("final pts",shortPts)

    // const routeCabEmp = await this.getCabEmpdetails(shortPts, this.cab, this.employee);    // get all about details of cab and emp
    const routeCabEmp = await this.getCabEmpdetails(shortPts, cab, employee);    // get all about details of cab and emp
    // console.log("routeCabEmp",routeCabEmp)
    const groupRoute = await this.groupRoute(routeCabEmp);
    console.log('Final Route', groupRoute);
    return groupRoute;
    // let finalRoute = await this.routeNamechange(routeCabEmp)    // replace route name with cab reg no
    // console.log("final Route", finaleRoute)
    // return finalRoute;

    // await this.plotonMap(mapRoute);     // plotting route locations on map
  }

  makeRoute(employee: any, cab: any, route_opt: any, shiftTime: any, routeType) {

    return new Promise(async (resolve, rejects) => {

      console.log('inputs', employee, cab);
      const empList = [];
      //  for (let i=0;i<employee.length;i++){
      //      if(employee[i].shift == shiftTime)
      //      empList.push(employee[i]);
      //  }
      // console.log("shift emp",empList)
      const emp_latlng = employee.map(value => value.location); // Separate emp location in array for find the shortest route
      // let cab_latlng = cab.map(value => value.Location); //Separate cab location in array for find the shortest route
      let temp_arr1: any = {}, temp_arr2: any[] = [], Req_seat_cnt = 0, i = 0, cab_len = 0, seat_cnt = 0, temp_obj = {};
      cab_len = cab.length;
      for (i = 0; i < cab.length; i++) {
        Req_seat_cnt += (cab[i].capacity);
      }
      seat_cnt = Req_seat_cnt;
      // if (route_opt && emp_latlng.length) { //0: driver route(zigjag) , 1 :management(cont location)
      //  temp_arr1 = this.arr_initial(cab,cab_len);
      for (let k = 0; emp_latlng.length != 0; k++) {
        seat_cnt = Req_seat_cnt;
        while (seat_cnt && emp_latlng.length) {
          for (let j = 0; j < cab_len && emp_latlng.length != 0; j++) {

            // if (!temp_arr1[j].Trip[k]) {
            //     temp_arr1[j].Trip[k] = [];
            //     temp_arr1[j].Trip_c = k + 1;
            // }
            if (route_opt) {
              let count_c = 0;
              // while (count_c < cab.length && emp_latlng.length != 0) {
              //  if (((temp_arr1[j].Trip[k].length) < cab[j].Req_Seat)) {
              //  while (((temp_arr2.length) < cab[j].Req_Seat)) {
              const temp_arr = await this.kdtree.findNearestLocation(emp_latlng, cab[j].location, 15);
              if (routeType) {
                // console.log("temp_arr",temp_arr)
                // temp_arr1[j].Trip[k].push(temp_arr[0][0]);
                for (let count = 0; count < cab[j].capacity && emp_latlng.length; count++) {
                  // console.log("loopppp")
                  temp_arr2.push(temp_arr[count][0]);
                  seat_cnt -= 1;
                  this.delete_arr(emp_latlng, temp_arr[count][0]);
                  // console.log("for loop",seat_cnt,emp_latlng.length,temp_arr[count][0]);
                }
              } else {
                // temp_arr1[j].Trip[k].unshift(temp_arr[0][0]);
                for (let count = 0; count < cab[j].capacity; count++) {
                  temp_arr2.unshift(temp_arr[count][0]);
                  seat_cnt -= 1;
                  this.delete_arr(emp_latlng, temp_arr[count][0]);
                  // Seat_filled += 1;
                  // seat_cnt -= 1;
                  // local_arr = {};
                }
              }
              temp_arr1[cab[j].vehRegNo + '_' + k] = temp_arr2;
              temp_arr1[cab[j].vehRegNo + '_' + k].cab_d = cab[j];
              temp_arr2 = [];
              count_c += 1;
            } else {
              if (((temp_arr1[j].Trip[k].length) < cab[j].capacity)) {
                const temp_arr = await this.kdtree.findNearestLocation(emp_latlng, cab[j].location, 3);
                // temp_arr1[j].Trip[k].push(temp_arr[0][0]);
                if (routeType) { temp_arr1[j].Trip[k].push(temp_arr[0][0]); } else { temp_arr1[j].Trip[k].unshift(temp_arr[0][0]); }
                seat_cnt -= 1;
                // local_arr = {};
                this.delete_arr(emp_latlng, temp_arr[0][0]);
              }
            }
          }
        }
      }
      //  for (let k = 0; emp_latlng.length != 0; k++) {
      //      seat_cnt = Req_seat_cnt;
      //      while (seat_cnt && emp_latlng.length) {
      //          for (let j = 0; j < cab_len && emp_latlng.length != 0; j++) {

      //              if (!temp_arr1[j].Trip[k]) {
      //                  temp_arr1[j].Trip[k] = [];
      //                  temp_arr1[j].Trip_c = k + 1;
      //              }
      //              if(route_opt){
      //                  let count_c = 0;
      //                  while (count_c < cab[j].Req_Seat && emp_latlng.length != 0) {
      //                     //  if (((temp_arr1[j].Trip[k].length) < cab[j].Req_Seat)) {
      //                       if (((temp_arr2.length) < cab[j].Req_Seat)) {
      //                          let temp_arr = await this.kdtree.findNearestLocation(emp_latlng, cab[j].Location, 3);
      //                          if(routeType){
      //                             // temp_arr1[j].Trip[k].push(temp_arr[0][0]);
      //                             temp_arr2.push(temp_arr[0][0]);

      //                          }
      //                          else temp_arr1[j].Trip[k].unshift(temp_arr[0][0]);
      //                          // Seat_filled += 1;
      //                          seat_cnt -= 1;
      //                          // local_arr = {};
      //                          this.delete_arr(emp_latlng, temp_arr[0][0]);
      //                      }
      //                      count_c += 1;
      //                  }
      //                  let tripCount = temp_arr1[j].Trip_c;
      //                  console.log("temp_arr2",temp_arr2,tripCount);

      //                  temp_arr1[cab[j].cabRegNo +"_"+ tripCount] = temp_arr2;
      //                  console.log("aftrrr",cab[j].cabRegNo + tripCount)
      //                 //  temp_arr2=[];
      //              }
      //              else{
      //                  if (((temp_arr1[j].Trip[k].length) < cab[j].Req_Seat)) {
      //                      let temp_arr = await this.kdtree.findNearestLocation(emp_latlng, cab[j].Location, 3);
      //                      // temp_arr1[j].Trip[k].push(temp_arr[0][0]);
      //                      if(routeType) temp_arr1[j].Trip[k].push(temp_arr[0][0]);
      //                      else temp_arr1[j].Trip[k].unshift(temp_arr[0][0]);
      //                      seat_cnt -= 1;
      //                      // local_arr = {};
      //                      this.delete_arr(emp_latlng, temp_arr[0][0]);
      //                  }
      //              }
      //          }
      //      }
      //  }
      //  console.log("aft route made", temp_arr1)
      //  temp_obj = await this.separateNoofRoutes(temp_arr1) //->separate noof routes using result

      resolve(temp_arr1);
    });
  }

  separateNoofRoutes(temp_arr1) {
    return new Promise(async (resolve, reject) => {
      let route_obj = [], count_r = 1, routes = {}, routes_t = [], count_s;
      route_obj = temp_arr1;
      for (let i = 0; route_obj[i] != null; i++) {   // -> create route separate from route details (cab_d,trip)--//
        console.log('trip count', route_obj[i].Trip_c);
        for (let j = 0; j < route_obj[i].Trip_c; j++) {
          if (routes[count_r] == undefined) { routes[count_r] = {}; }
          routes[count_r].cab = route_obj[i].cab_d.location;
          for (let k = 0; k < route_obj[i].Trip[j].length; k++) {
            routes_t.push(route_obj[i].Trip[j][k]);
          }
          routes[count_r].emp = routes_t;
          routes_t = [];
          count_r++;
        }
      }
      resolve(routes);
    });
  }

  separatePickpts(tobj) {
    return new Promise(async (resolve, reject) => {
      let temp = [], temp_arr = [], tempkeys1 = Object.keys(tobj);
      for (let i = 0; i < tempkeys1.length; i++) {
        let count = 0;
        const tempkeys = Object.keys(tobj[tempkeys1[i]]).filter(item => {
          if (Number(item) == 0 || Number(item)) { return item; }
        });
        for (let j = 0; j < tobj[tempkeys1[i]].length; j++) {
          temp[count++] = tobj[tempkeys1[i]][tempkeys[j]];
        }
        temp_arr[tempkeys1[i]] = temp;
        temp = [];
      }
      // console.log("separatepickpts",temp_arr)
      resolve(temp_arr);      // returning only emp location(lat: and lng:) details
    });

  }

  // sepNumericvalues(tempkeys){
  //   let tempkeys1 = Object.keys(tobj[tempkeys[i]]).filter(item=>{
  //     if(Number(item) == 0 || Number(item)) return item;});
  //     return tempkeys1
  // }

  createClusPts(routes, temp_arr) {
    return new Promise(async (resolve, reject) => {
      // console.log("temp_arr",temp_arr);
      const tempkeys: string[] = Object.keys(temp_arr);
      let count: any, j: any, k: any, res: any, res1: any, url: any, clust: boolean = false, temp_arr1: any[] = [], temp_arr2:any[] = [], local_obj: any = {}, value: any;
      for (let i = 0; i < tempkeys.length; i++) {
        j = 0, k = 1, count = 0;
        const element: string = tempkeys[i];
        // console.log("element",[tempkeys[i]])
        if (temp_arr[element].length == 1) { temp_arr2[count++] = (temp_arr[element][j]); } else {
          while (1) {
            value = 0;
            // console.log(temp_arr[i][j].lng, temp_arr[i][j].lat, temp_arr[i][k].lng, temp_arr[i][k].lat)
            url = this.osrmUrl + temp_arr[element][j].lng + ',' + temp_arr[element][j].lat + ';' + temp_arr[element][k].lng + ',' + temp_arr[element][k].lat + '?steps=true';
            res = await this.fetchUrl(url);
            url = this.osrmUrl + temp_arr[element][k].lng + ',' + temp_arr[element][k].lat + ';' + temp_arr[element][j].lng + ',' + temp_arr[element][j].lat + '?steps=true';
            res1 = await this.fetchUrl(url);
            value = Math.min(res.routes[0].distance, res1.routes[0].distance);
            // console.log(res1.routes[0].distance,temp_arr[i][k].lat,temp_arr[i][k].lng, temp_arr[i][j].lat , temp_arr[i][j].lng)

            // console.log(res.routes[0].distance, temp_arr[i][j].lat , temp_arr[i][j].lng,temp_arr[i][k].lat,temp_arr[i][k].lng)


            if (value < this.distForclust) {
              if (!temp_arr1.some(ele => ele.lat === temp_arr[element][j].lat && ele.lng === temp_arr[element][j].lng)) {
                temp_arr1.push(temp_arr[element][j]);
                temp_arr1.push(temp_arr[element][k]);
              } else {
                temp_arr1.push(temp_arr[element][k]);
              }
              clust = true;
              k++;
              if (temp_arr[element][k] == undefined) {
                const midPt = await this.findMidpt(temp_arr1);
                temp_arr2[count] = midPt;
                if (temp_arr2[count] == undefined) { temp_arr2[count] = {}; }
                temp_arr2[count++].clusPts = temp_arr1;
                clust = false;
                temp_arr1 = [];
              }

            } else {
              if (clust) {
                const midPt = await this.findMidpt(temp_arr1);
                temp_arr2[count] = midPt;
                if (temp_arr2[count] == undefined) { temp_arr2[count] = {}; }
                temp_arr2[count++].clusPts = temp_arr1;
                clust = false;
                temp_arr1 = [];
              } else {
                // console.log("arrr",temp_arr[element][j],count)
                temp_arr2[count++] = (temp_arr[element][j]);
              }
              j = k;
              k++;
              if (temp_arr[element][k] == undefined) {
                // console.log("arrr-end",temp_arr[element][j])
                temp_arr2[count++] = (temp_arr[element][j]);
              }
            }
            if (temp_arr[element].length == k) { break; }
            // console.log("before final",temp_arr2)
          }
        }
        temp_arr2['cabInfo'] = routes[tempkeys[i]].cab_d;
        // console.log("Final == ", routes[tempkeys[i]],temp_arr2)
        local_obj[tempkeys[i]] = temp_arr2;
        // console.log("localobj",local_obj)
        // local_obj["route" + (i + 1)] = temp_arr2
        temp_arr1 = [], temp_arr2 = [];
      }
      resolve(local_obj);  // returning modified group route(cluster pickuppoints)
    });
  }

  orderByGender(temp_obj) {
    return new Promise(async (resolve, reject) => {
      let tempkeys, tempkeys1, genDer;
      tempkeys = Object.keys(temp_obj);
      for (let i = 0; i < tempkeys.length; i++) {
        const element = tempkeys[i];
        tempkeys1 = Object.keys(temp_obj[tempkeys[i]]);
        // console.log("temp1", tempkeys1,tempkeys[i].length)
        for (let j = 0; j < temp_obj[element].length; j++) {
          const element1 = tempkeys1[j];
          // console.log("sec")
          for (const key in this.employee) {
            if (this.employee[key].location == temp_obj[tempkeys[i]][element1]) {
              genDer = this.employee[key].gender;
              break;
            }
          }
          // console.log("genDer",genDer,j,tempkeys1,tempkeys1[j],temp_obj[element][element1],tempkeys1.length)
          if (!temp_obj[element][tempkeys1[j]].clusPts && genDer == 'Male' && j == 0) {
            // console.log("inside if")
            break;
          } else if (!temp_obj[element][element1].clusPts && genDer == 'Male') {
            // console.log("remove ",j)
            temp_obj[element].unshift(temp_obj[element][element1]);
            temp_obj[element].splice(j + 1, 1);
            break;
          }
          genDer = null;
        }
      }
      console.log("reordered ", temp_obj)
      resolve(temp_obj);

    });
  }

  makeshortRoutePts(tobj) {
    return new Promise(async (resolve, reject) => {
      let j: any, tempkeys: any[] = [], tempkeys1:any[] = [], temp_obj1:any = {}, temp_obj:any = {}, local_obj:any = {}, k:number = 0, res:any,Etadist=0,Etatime=0;
      tempkeys = Object.keys(tobj);
      for (let i = 0; i < tempkeys.length; i++) {
        k = 0;
        tempkeys1 = Object.keys(tobj[tempkeys[i]]);
        temp_obj[k++] = tobj[tempkeys[i]].cabInfo.location;
        for (j = 0; j < (tobj[tempkeys[i]].length); j++) {
          temp_obj[k++] = tobj[tempkeys[i]][tempkeys1[j]];
        }
        if (this.routeType) { res = await this.shortPickuppoints(tobj[tempkeys[i]].cabInfo.location, temp_obj); } else { res = await this.shortPickuppoints(this.officeLoc, temp_obj); }  // get shortest route for drop pts
        j = 1;
        temp_obj1.cabInfo = tobj[tempkeys[i]].cabInfo;
        // temp_obj1.distance = Math.round(res.data.length / 1000) + 'km';
        // temp_obj1[0] = tobj[tempkeys[i]].cabInfo.location     //location will be change as per user input
        for (let m = 1; m < (res.data.path.length - 1); m++) {
          temp_obj1[j++] = temp_obj[res.data.path[m]];
        }
        if(this.routeType){
          temp_obj1[0] = tobj[tempkeys[i]].cabInfo.location; 
          temp_obj1[j]= this.officeLoc;}
        else if(!this.routeType){
          temp_obj1[j] = tobj[tempkeys[i]].cabInfo.location ;
          temp_obj1[0]= this.officeLoc;}
        var temp_keys = Object.keys(temp_obj1).filter(item => {
          if (Number(item) == 0 || Number(item)) { return item; }
        });
         for(let l=0;l<temp_keys.length-1;l++){
            let count=l+1;
            let url = this.osrmUrl + temp_obj1[l].lng + ',' + temp_obj1[l].lat + ';' + temp_obj1[count].lng + ',' + temp_obj1[count].lat + '?steps=true';
            res = await this.fetchUrl(url);
            temp_obj1[count]["ETA"] = res.routes[0].duration/60;
            temp_obj1[count]["Distance"] = res.routes[0].distance/1000;
            Etatime += res.routes[0].duration/60;
            Etadist += res.routes[0].distance/1000;
         }
         temp_obj1.TotalDuration = Math.round(Etatime);
         temp_obj1.Distance = Math.round(Etadist);
         this.EtaTime = 0;
         this.EtaDist = 0;
         Etatime = 0;
         Etadist = 0;
        console.log(temp_obj1);
        local_obj[tempkeys[i]] = temp_obj1;
        temp_obj = {};
        temp_obj1 = {};

      }
       console.log("makeshortRoutePts",local_obj)
      resolve(local_obj);      // returning only emp location(lat: and lng:) details
    });

  }

  getCabEmpdetails(tobj: unknown, cabInfo: string | any[], employeeInfo: string | any[]) {
    return new Promise(async (resolve, reject) => {
      const tempkeys = Object.keys(tobj);
      for (let i = 0; i < tempkeys.length; i++) {
        for (let j = 0; j < cabInfo.length; j++) {
          if (tobj[tempkeys[i]].cabInfo == cabInfo[j].location) {
            tobj[tempkeys[i]].cabInfo = cabInfo[j];
            // console.log("seconds if",tobj[tempkeys[i]].cabInfo,cab.cars[i].Location,cab.cars.length);
            break;
          }
        }
      }
      console.log("keys sec", tempkeys)
      for (let i = 0; i < tempkeys.length; i++) {
        const tempkeys1 = Object.keys(tobj[tempkeys[i]]).filter(item => {
          if (Number(item) == 0 || Number(item)) { return item; }
        });
        let clusPtsflag = 0, clusMale = 0, clusFemale = 0, genMale = 0, genFemale = 0, pickupPoint = 0;
        for (let j = 0; j < tempkeys1.length; j++) {
          if (tobj[tempkeys[i]][tempkeys1[j]].clusPts) {
            pickupPoint += 1;
            for (let k = 0; k < tobj[tempkeys[i]][tempkeys1[j]].clusPts.length; k++) {
              for (let m = 0; m < employeeInfo.length; m++) {
                if (tobj[tempkeys[i]][tempkeys1[j]].clusPts[k] == employeeInfo[m].location) {
                  tobj[tempkeys[i]][tempkeys1[j]].clusPts[k] = employeeInfo[m];
                  if (employeeInfo[m].gender == 'Male') { clusMale += 1; }
                  if (employeeInfo[m].gender == 'Female') { clusFemale += 1; }
                  break;
                }
              }
            }
            clusPtsflag += 1;
            // temp_obj["clusMale"] = clusMale;
            // temp_obj["clusFemale"] = clusFemale;
            // temp_obj[clusPtsflag] =
          } else {
            for (let k = 0; k < employeeInfo.length; k++) {
              if (tobj[tempkeys[i]][tempkeys1[j]].lat == employeeInfo[k].location.lat && tobj[tempkeys[i]][tempkeys1[j]].lng == employeeInfo[k].location.lng) {
                tobj[tempkeys[i]][tempkeys1[j]] = employeeInfo[k];
                if (employeeInfo[k].gender == 'Male') { genMale += 1; }
                if (employeeInfo[k].gender == 'Female') { genFemale += 1; }
                pickupPoint += 1;
                break;
              }
            }
            // console.log("ifff",tobj[tempkeys[i]][tempkeys1[j]])
          }
        }
        if (!genMale) { tobj[tempkeys[i]].participants = 'Female'; } else if (!genFemale) { tobj[tempkeys[i]].participants = 'Male'; } else { tobj[tempkeys[i]].participants = 'Mixed'; }
        tobj[tempkeys[i]].totalemp = genMale + genFemale + clusMale + clusFemale;
        tobj[tempkeys[i]].totalclus = clusPtsflag;
        tobj[tempkeys[i]].pickuppoints = pickupPoint;
        tobj[tempkeys[i]].totalmale = genMale + clusMale;
        tobj[tempkeys[i]].totalfemale = genFemale + clusFemale;
      }

      // console.log("final_route_details",tobj)
      resolve(tobj);
    });
  }

  groupRoute(tobj) {
    return new Promise(async (resolve, reject) => {
      let temp_obj: any = {}, tempkeys = Object.keys(tobj),tempobj_arr=[];
      for (let i = 0; i < tempkeys.length; i++) {
        if (tobj[tempkeys[i]].cabInfo.model == 'Hatchback') {
          if (temp_obj.Hatchback == undefined) { 
            temp_obj.Hatchback = {}; 
            temp_obj.Hatchback.routeInfo={}; }
          temp_obj.Hatchback.routeInfo[tempkeys[i]] = (tobj[tempkeys[i]]);
        } else if (tobj[tempkeys[i]].cabInfo.model == 'Sedan') {
          if (temp_obj.Sedan == undefined) { temp_obj.Sedan = {}; 
          temp_obj.Sedan.routeInfo={}; }
          temp_obj.Sedan.routeInfo[tempkeys[i]] = (tobj[tempkeys[i]]);
        } else if (tobj[tempkeys[i]].cabInfo.model == 'SUV') {
          if (temp_obj.SUV == undefined) { temp_obj.SUV = {}; 
          temp_obj.SUV.routeInfo={}; }
          temp_obj.SUV.routeInfo[tempkeys[i]] = (tobj[tempkeys[i]]);
        } else if (tobj[tempkeys[i]].cabInfo.model == 'Van') {
          if (temp_obj.Van == undefined) { temp_obj.Van = {};
          temp_obj.Van.routeInfo={}; }
          temp_obj.Van.routeInfo[tempkeys[i]] = (tobj[tempkeys[i]]);
        } else if (tobj[tempkeys[i]].cabInfo.model == 'Bus') {
          if (temp_obj.Bus == undefined) { temp_obj.Bus = {};
          temp_obj.Bus.routeInfo={}; }
          temp_obj.Bus.routeInfo[tempkeys[i]] = (tobj[tempkeys[i]]);
        }
      }
      console.log("finale obj", temp_obj)
      const temp_obj1: any = temp_obj;
      tempkeys = Object.keys(temp_obj1);
      // console.log("tempkeys",tempkeys)
      for (let j = 0; j < tempkeys.length; j++) {
        let T_pickupPoint = 0, T_totalclus = 0, T_totalmale = 0, T_totalfemale = 0, T_emp = 0, T_route = 0;
        const tempkeys1 = Object.keys(temp_obj1[tempkeys[j]].routeInfo);
        for (let k = 0; k < tempkeys1.length; k++) {
          // console.log("totalemp",temp_obj1[tempkeys[j]],tempkeys1[k],tempkeys1)
          // console.log("totalemp1",temp_obj1[tempkeys[j]].routeInfo[tempkeys1[k]])
          T_emp += temp_obj1[tempkeys[j]].routeInfo[tempkeys1[k]].totalemp;
          T_pickupPoint += temp_obj1[tempkeys[j]].routeInfo[tempkeys1[k]].pickuppoints;
          T_totalclus += temp_obj1[tempkeys[j]].routeInfo[tempkeys1[k]].totalclus;
          T_totalmale += temp_obj1[tempkeys[j]].routeInfo[tempkeys1[k]].totalmale;
          T_totalfemale += temp_obj1[tempkeys[j]].routeInfo[tempkeys1[k]].totalfemale;
          T_route += 1;
        }
        temp_obj1[tempkeys[j]].pickuppoints = T_pickupPoint;
        temp_obj1[tempkeys[j]].totalmale = T_totalmale;
        temp_obj1[tempkeys[j]].totalfemale = T_totalfemale;
        temp_obj1[tempkeys[j]].totalclus = T_totalclus;
        temp_obj1[tempkeys[j]].totalemp = T_emp;
        temp_obj1[tempkeys[j]].totalroute = T_route;
      }
      tempobj_arr = temp_obj1
      resolve(tempobj_arr);
    });
  }

  plotonMap(tobj) {
    return new Promise(async (resolve, reject) => {

      let local_arr: any[] = [], local_arr1: any, temp_obj1: any = {}, temp_obj: any = {}, temp_arr: any[] = [], count_1: number = 0, count: number = 0, tempkeys: any[] = [], colorArr: any[] = [], routeFinalMapArr: any[] = [];
      tempkeys = Object.keys(tobj);
      colorArr = this.colorGen(Object.keys(tobj).length);
      for (let i = 0; i < tempkeys.length; i++) {
        temp_arr = [], count_1 = 0, count = 0;
        const element = tempkeys[i];
        if (this.routeType) {
          temp_arr[count_1++] = tobj[element].cabInfo.location.lat;
          temp_arr[count_1++] = tobj[element].cabInfo.location.lng;
          local_arr[count++] = temp_arr;
        } else {
          temp_arr[count_1++] = this.officeLoc.lat;
          temp_arr[count_1++] = this.officeLoc.lng;
          local_arr[count++] = temp_arr;
        }
        // console.log("cab location", tobj[element].cabInfo.Location.lat)
        temp_obj.id = tobj[element].cabInfo.cabRegNo;
        // let tempkeys1 = Object.keys(tobj[element])
        const tempkeys1: any = Object.keys(tobj[tempkeys[i]]).filter(item => {
          if (Number(item) == 0 || Number(item)) { return item; }
        });
        // console.log("tempkeys1",tempkeys1)
        for (let j = 0; j < tempkeys1.length; j++) {
          const element1: any = tempkeys1[j];
          temp_arr = [], temp_obj1 = {}, count_1 = 0;
          if (tobj[element][element1].clusPts) {
            temp_arr[count_1++] = tobj[element][element1].lat;
            temp_arr[count_1++] = tobj[element][element1].lng;
            temp_obj1.title = 'cluster';
            temp_obj1.plotMarker = '<img src="assets/images/map/cluster.png" width=30px>';
            // temp_obj1['plotMarker'] = '<div class="mumb_marker_pin" style="background: black; width: 10px;"></div>'
            temp_arr[count_1++] = temp_obj1;
            local_arr[count] = temp_arr;
            if (local_arr[count] == undefined) { local_arr[count] = {}; }
            local_arr[count++].clusPts = tobj[element][element1].clusPts;
          } else {
            temp_arr[count_1++] = tobj[element][element1].location.lat;
            temp_arr[count_1++] = tobj[element][element1].location.lng;
            temp_obj1.title = tobj[element][element1].Empid;
            temp_obj1.plotMarker = '📌';
            if (tobj[element][element1].Gender == 'Male') {
              temp_obj1.plotMarker = '<img src="assets/images/map/male.png" width=30px>';
            } else {
              temp_obj1.plotMarker = '<img src="assets/images/map/female.png" width=30px>';
            }

            // temp_obj1['plotMarker'] = '<div class="mumb_marker_pulse" style="background: black; width: 10px;"></div>'
            temp_arr[count_1++] = temp_obj1;
            local_arr[count++] = temp_arr;
          }

        }
        temp_arr = [], count_1 = 0;
        if (!this.routeType) {
          temp_arr[count_1++] = tobj[element].cabInfo.location.lat;
          temp_arr[count_1++] = tobj[element].cabInfo.location.lng;
          local_arr[count++] = temp_arr;
        } else {
          temp_arr[count_1++] = this.officeLoc.lat;
          temp_arr[count_1++] = this.officeLoc.lng;
          local_arr[count++] = temp_arr;
        }
        // console.log("localarr",local_arr)
        count = 0;
        local_arr1 = await this.waypoints(local_arr);
        temp_obj.locations = local_arr1;
        temp_obj.color = colorArr[i];
        temp_obj.width = 3;
        temp_obj.lineBearing = 1;
        // temp_obj["dash"] = 2;
        // temp_obj["animation"] = true;
        temp_obj.drawArrow = { colorClass: 'secondary' };
        temp_obj.startMarker = '<img src="assets/images/map/driver.png" width=30px>';
        temp_obj.endMarker = '<img src="assets/images/map/office.png" width=40px>';
        temp_obj.bearingFocus = true;
        routeFinalMapArr.push(temp_obj);
        this.map.flatLine('TestmapRoastering', temp_obj);
        // console.log("map obj",temp_obj)
        // this.mapData.push(temp_obj);
        local_arr = [];
        temp_obj = {};
      }
      console.log('final result map', routeFinalMapArr);
      resolve(routeFinalMapArr);
    });
  }

  waypoints(tobj) {
    return new Promise(async (resolve, reject) => {
      let j, k, url, res, res1, value, temp_arr = [], temp_arr1 = [], temp_arr2 = [], temp_obj = {};
      // console.log("tobj",tobj)
      for (let i = 0; i < tobj.length; i++) {
        temp_arr1.push(tobj[i]);
        j = i + 1;
        if (tobj[j] == undefined) { break; }
        /// -> we cant compare res of two lat lng bcoz direction must be start frm first latlng////
        url = this.osrmUrl + tobj[i][1] + ',' + tobj[i][0] + ';' + tobj[j][1] + ',' + tobj[j][0] + '?steps=true&geometries=geojson';
        res = await this.fetchUrl(url);
        const lenCoor = res.routes[0].geometry.coordinates.length;
        for (let j = 0; j < lenCoor; j++) {
          temp_arr[0] = res.routes[0].geometry.coordinates[j][1];
          temp_arr[1] = res.routes[0].geometry.coordinates[j][0];
          // temp_obj['plotMarker'] =' <div class="mumb_marker_pulse"  style="background: black; width: 5px;"></div>';
          // k=j+1;
          // if(lenCoor != k){
          //   temp_arr2[0] = res.routes[0].geometry.coordinates[k][1]
          //   temp_arr2[1] = res.routes[0].geometry.coordinates[k][0]
          // let angle = await this.findAngle(temp_arr[0],temp_arr[1],temp_arr2[0],temp_arr2[1])
          // temp_obj['plotMarker'] = await this.markerRotate(angle);
          // temp_obj['bearingMarker'] = await this.markerRotate(angle);
          // temp_obj['plotMarker'] = '<div class="mumb_marker_pulse" style="background: black; width: 10px;"></div>'
          // }
          temp_arr1.push(temp_arr);
          temp_arr = [];
          temp_obj = {};
        }
      }
      // console.log("res-waypoints",temp_arr1)
      resolve(temp_arr1);
    });
  }

  findMidpt(temp_arr1) {
    return new Promise(async (resolve, reject) => {
      let features, res, findpt, url, retObj = {
        lat: null,
        lng: null
      };
      const temp_arr3 = temp_arr1.map(function (ele) {
        const tarr = [];
        tarr.push(ele.lat);
        tarr.push(ele.lng);
        return tarr;
      });
      features = turf.points(temp_arr3);
      // ([ [13.004125, 80.204540],[13.000182, 80.202950],[12.992344, 80.197290], [12.985139, 80.193051], [12.987933, 80.223452],
      //     [12.986422, 80.22453],[12.995344, 80.21453],[12.989011, 80.21453],[12.976561, 80.226789]]);
      findpt = turf.center(features);
      url = this.osrmUrlloc + findpt.geometry.coordinates[1] + ',' + findpt.geometry.coordinates[0];
      res = await this.fetchUrl(url);
      if (res.code == 'Ok') {
        retObj.lat = res.waypoints[0].location[1];
        retObj.lng = res.waypoints[0].location[0];
        resolve(retObj);
      }
    });
  }

  fetchUrl(url) {
    return new Promise(async (resolve, rejects) => {
      // console.log("in finddist")
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          // console.log("hi", myJson);
          resolve(myJson);
        });
    });

  }

  colorGen(length) {
    return Array(length).fill(0).map(x => {
      const color = Math.floor(Math.random() * 16777215).toString(16);
      return color.length >= 6 ? '#' + color : '#' + color + '2';
    });
  }

  arr_initial(cab, cab_len) {
    const temp = [];
    for (let i = 0; i < cab_len; i++) {
      const local_arr = {
        cab_d: {},
        Trip_c: 0,
        Trip: [],
      };
      local_arr.cab_d = cab[i].location;
      // local_arr.cab_d.Trip = [];
      temp[i] = local_arr;
    }
    return temp;
  }
  delete_arr(arr, elem) {
    const ind = arr.indexOf(elem);
    arr.splice(ind, 1);
  }

}
