import { DashboardComponent } from './dashboard/dashboard.component';
import { InformationRouting } from './information/information.routing';
import { LoginComponent } from './login/login.component';
import { ReportsRouting } from './reports/reports.routing';
import { RequestRouting } from './requests/request.routing';
import { RequestsComponent } from './requests/requests.component';
import { RoasteringComponent } from './roastering/roastering.component';
import { SettingsComponent } from './settings/settings.component';
import { SosPanelComponent } from './sos-panel/sos-panel.component';
import { SupportDeskRouting } from './support-desk/suportdesk.routing.component';
import { LiveVehicleTrackingComponent } from './live-tracking/live.tracking.component';
import { ListviewsComponent } from './listview/listview.component';
import { ProfileComponent } from './profile/profile.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ResetPasseordComponent } from './login/reset-passeord/reset-passeord.component';
import { RosteringComponent } from './rostering/rostering.component';
import { RosteringRouting } from './rostering/rostering.routing';


export const TransportManggerRouting = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    {path: 'reset-password', component: ResetPasseordComponent},
    { path: 'profile', component: ProfileComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: 'request-component', component: RequestsComponent },
    { path: 'reports', children: ReportsRouting },
    { path: 'sospanel', component: SosPanelComponent },
    // { path: 'rostering', component: RoasteringComponent },
    { path: 'rostering', children: RosteringRouting },
    { path: 'setting', component: SettingsComponent },
    { path: 'support-desk', children: SupportDeskRouting },
    { path: 'information', children: InformationRouting },
    { path: 'request', children: RequestRouting },
    { path: 'vehicle-track', component: LiveVehicleTrackingComponent },
    { path: 'listview', component: ListviewsComponent },
];
