import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-master-dashboard',
  templateUrl: './master-dashboard.component.html',
  styleUrls: ['./master-dashboard.component.css']
})
export class MasterDashboardComponent implements OnInit {
  constructor(public storage: StorageService,
              public api: ApiService,
              public router: Router) {
    this.userType = this.storage.get('userType');
  }
  userType: any;
  orgDetails: any;

  public barChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'April', 'May', ];
  public barChartData2: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56], label: 'Active Vehicles', },
    { data: [28, 48, 40, 19, 86], label: 'In-active Vehicles', }
  ];
  public pieChartLabels: Label[] = [[ 'Total'], ['Active'], 'In-active'];
  public pieChartData = [250, 200, 50];

  public imieBoxInformation: Label[] = [[ 'Total'], ['Active'], 'In-active'];
  public imieBoxData = [450, 300, 150];

  public employeeInfo: Label[] = ['Jan', 'Feb', 'Mar', 'April', 'May'];
  public employeetData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56], label: 'Total Employees', }
  ];

  master_org = [
    {
      name: 'org-1',
      img: 'amazon.png'
    },
    {
      name: 'org-2',
      img: 'Samsung.png'
    },
    {
      name: 'org-3',
      img: 'hp.png'
    },
    {
      name: 'org-4',
      img: 'amazon.png'
    },
    {
      name: 'org-5',
      img: 'hp.png'
    },
    {
      name: 'org-6',
      img: 'amazon.png'
    },
    {
      name: 'org-7',
      img: 'Samsung.png'
    }, {
      name: 'org-1',
      img: 'amazon.png'
    },
    // {
    //   name:'org-2',
    //   img:'Samsung.png'
    // },
    // {
    //   name:'org-3',
    //   img:'hp.png'
    // },
    // {
    //   name:'org-4',
    //   img:'amazon.png'
    // },
    // {
    //   name:'org-5',
    //   img:'hp.png'
    // },
    // {
    //   name:'org-6',
    //   img:'amazon.png'
    // },
    // {
    //   name:'org-7',
    //   img:'Samsung.png'
    // }
  ];

  ngOnInit(): void {
    if (this.userType != 'maste-admin') {
      this.router.navigate(['/master/login']);
    }
    this.getOrgList();
  }
  getOrgList() {
    this.api.listOrg({})
      .then((res: any) => {
        console.log('Success', res);
        if (res.status == 'success') {
          this.orgDetails = res.data;
        }
        console.log(this.orgDetails);
      })
      .catch((error: any) => {
        console.log('Error', error);
      });

  }

}
