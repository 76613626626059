import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { driverModule } from '../../../../../../modules/information/driver';

@Component({
    selector: 'app-edit-dreiver',
    templateUrl: './edit-driver.component.html'
})

export class EditDriverComponent {
    driverData: any;
    orgID: string;
    vendorID: string;
    driverID: string;
    driverModuleData = driverModule;

    enableForm: boolean;
    constructor(
        public api: ApiService,
        public router: Router,
        public route: ActivatedRoute,
        public swal: SweetAlert
    ) {
        this.route.paramMap.subscribe(paramas => {
            console.log(paramas.get('orgID'), paramas.get('vendorID'), paramas.get('driverID'));
            this.orgID = paramas.get('orgID');
            this.vendorID = paramas.get('vendorID');
            this.driverID = paramas.get('driverID');
        });
    }
    ngOnInit() {
        this.getDriverData();
    }
    getDriverData() {
        const obj = {
            orgID: this.orgID,
            vendorID: this.vendorID,
            driverID: this.driverID
        };
        this.api.transportManagerDriverList(obj).then((res: any) => {
            if (res.status == 'success') {
                this.driverData = res.data[0];
                for (const f of this.driverModuleData) {
                    // console.log(f)
                    const name = f.name;
                    let value = '';

                    if (f.type == 'dropdown') {
                        const val = res.data[0][name].split(',');
                        const temp = [], str = [];
                        val.map(item => { temp.push({ value: val, name: val }); str.push(item); });
                        // f.selected = temp;
                        f.value = str.join(',');
                    } else if (f.type == 'date') {
                        f.value = this.formatDate(new Date(res.data[0][name]));
                        console.log(f.value, 'date');
                    } else {
                        value = name;
                        f.value = res.data[0][name];
                    }
                }
                this.enableForm = true;
                console.log(this.driverModuleData, this.enableForm);
                // this.swal.swalFunction(res.status, res.status, res.message, 2000)

            }
        }).catch((err) => {
            console.log(err);
        });
    }

    formatDate(nowDate) {
        return (
            nowDate.getDate() +
            '/' +
            (nowDate.getMonth() + 1) +
            '/' +
            nowDate.getFullYear()
        );
    }
    submitted(e: any) {
        console.log('EE', e.value);
        const obj = {
            orgID: this.orgID,
            vendorID: this.vendorID,
            driverID: this.driverID,
            ...e.value
        };
        console.log(obj);
        this.api.transportManagerDriverUpdate(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                this.router.navigate(['/organization/transport-manager/information/driver/view-driver', {orgID: obj.orgID, vendorID: obj.vendorID}]);
            }
            if (res.status == 'failure') { this.swal.swalFunction(res.status, res.status, res.message, 2000); }
        }).catch((err) => {
            console.log(err);
        });
    }
}
