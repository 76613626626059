import { AddAnnouncementComponent } from './add-announcement/add-announcement.component';
import { AnnouncementComponent } from './announcement.component';
import { EditAnnouncementComponent } from './edit-announcement/edit-announcement.component';
import { SingleViewAnnouncementComponent } from './view-announcement/single-view-announcement/single-view-announcement.component';
import { ViewAnnouncementComponent } from './view-announcement/view-announcement.component';

export const AnnouncementDeclaration = [
    AddAnnouncementComponent,
    ViewAnnouncementComponent,
    EditAnnouncementComponent,
    SingleViewAnnouncementComponent,
    AnnouncementComponent

];
