import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-table-route',
  templateUrl: './table-route.component.html',
  styleUrls: ['./table-route.component.css']
})
export class TableRouteComponent implements OnInit{
form: FormGroup;
searchForm:FormGroup;

@Input() formGroupName: string;
@Input('tableCols') cols;
@Input('tableData') data;
@Input('tableDataType') tableType;
@Input('shiftType') EmpShift;  // mohandass create becase build isses time
@Input() selectedEmployee;
@Input() selectedCabs;
@Output() selectedData = new EventEmitter();
@Output() finalRouteList = new EventEmitter();
public searchInput: any = new Subject<string>();
public searchOptions=[];

public selection = new SelectionModel(true, []);
public dataSource;
public searchFilterList = [];
public employeeInitialSelect = true;
public cabInitialSelect = true;

constructor(
  private rootFormDirective: FormGroupDirective,
  private fb: FormBuilder
  ) { }

ngOnInit(): void {
  this.searchForm=this.fb.group({});
  this.form = this.rootFormDirective.control.get(this.formGroupName) as FormGroup;
  this.searchList();
  if (this.tableType == 'employeeTable') {
    this.form.patchValue({
      employeeInfo: this.data
    });
  }
  else if(this.tableType=='cabsTable'){
    this.form.patchValue({
      cabsInfo: this.data
    });
  }
  // this.masterToggle();
}
get keys() {
  return this.cols.map(({ key }) => key);
}
/* Table Filter */
applyFilter(event) {
  let searchValue=event.target.value;
  this.dataSource.filter = searchValue.trim().toLowerCase();
}
searchAll(){
  console.log();
}
masterToggle() {
  this.isAllSelected() ?
      this.selection.clear() :
      this.data.forEach((row) => this.selection.select(row));
  console.log(this.selection);
}
isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.data.length;
  return numSelected === numRows;
}
isNumber(o): boolean {
  return ! isNaN (o - 0) && o !== null && o !== '' && o !== false;
}

sendSelectedData(){
  let parent=this;
  let sendData=[];
    this.selection.selected.forEach((e)=>
    {
      sendData.push(e);
    })
  if(this.tableType=='employeeTable'){
  //  this.selectedEmp.emit(this.selection.selected);
  const send = {
      type: 'employeeTable',
      data: sendData,
    };
  this.selectedData.emit(send);
  } else if (this.tableType == 'cabsTable') {
   // this.selectedCab.emit(this.selection.selected);
  const send = {
      type: 'cabsTable',
      data: sendData,
    };
  this.selectedData.emit(send);
  }
  this.finalRouteList.emit(true);
}
deleteTableData(index){
  //console.log(this.selection.selected.findIndex(index));
  console.log(this.selection);
  let dataValues=[];
  this.data.filter((e)=>{
    if(this.tableType='employeeTable'){
      if(index.employeeID!=e.employeeID){
        dataValues.push(e);
    }
    }
    else if(this.tableType='cabsTable'){
      if(index.vehicleID!=e.vehicleID){
        dataValues.push(e);
    }
    }
  });
  this.data=dataValues;
  this.dataSource=new MatTableDataSource(this.data);
  this.sendSelectedData();
}

ngOnChanges(changes: SimpleChanges) {
  if (changes.tableType) {
    this.tableType = changes.tableType.currentValue;
    
  }
  if (changes.data) {
    this.dataSource = new MatTableDataSource(changes.data.currentValue);
    console.log(this.dataSource);
    this.selectedDataList();
}
  if (changes.cols) {
  this.cols = changes.cols.currentValue;
}
  this.searchList();
}
selectedDataList(){
let indexes=[];
this.data.filter((e,i)=>{
  if(this.tableType=='employeeTable'){
    if(this.selectedEmployee.length>0){
      this.selectedEmployee.filter((item)=>{
          if(item.employeeID==e.employeeID){
            if(indexes.indexOf(i)==-1){
              indexes.push(e);
            }
          }
        });
        if(indexes.length > 0) {
          this.selection.clear();
          indexes.forEach((i) => this.selection.select(i));
        }
      }
      if(indexes.length==0){
        this.selection.clear();
        this.masterToggle();
      }
  }
  else if(this.tableType=='cabsTable'){
      if(this.selectedCabs.length>0){
        this.selectedCabs.filter((item)=>{
          if(item.vehicleID==e.vehicleID){
            if(indexes.indexOf(i)==-1){
              indexes.push(e);
            }
          }
        });
        if(indexes.length > 0) {
          this.selection.clear();
          indexes.forEach((i) => this.selection.select(i));
        }
      }
      if(indexes.length==0){
        this.selection.clear();
        this.masterToggle();
      }
    }
});
}
searchList() {
this.searchFilterList = [];
this.cols.filter((e, index) => {
  if (index <= 3) {
    this.searchFilterList.push(e);
  }
});
}
/* Close modal*/
closeModal(){
  console.log('filter options');
}
filterOptions(e){
  this.searchOptions=[];
  this.searchOptions=e;
  this.searchForm=this.fb.group({});
  if(this.searchOptions && this.searchOptions.length>0){
    this.searchOptions.filter((e)=>{
      this.searchForm.addControl(e.key,this.fb.control['']);
    })
  }
  console.log(this.searchForm);
  console.log('options',this.searchOptions);
}
filterSubmit(){
  console.log(this.searchForm);
  console.log('options',this.searchOptions);
}
}
