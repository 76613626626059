import { AddDriverComponent } from './add-driver/add-driver.component';
import { CreateDriverComponent } from './create-driver/create-driver.component';
import { EditDriverComponent } from './edit-driver/edit-driver.component';
import { EditdriverComponent } from './editdriver/editdriver.component';
import { SingleViewDriverComponent } from './view-driver/single-view-driver.component';
import { ViewDriverComponent } from './view-driver/view-driver.component';

export const DriverRouting: any = [
    { path: '', redirectTo: 'view-driver', pathMatch: 'full' },
    // {path : '' , component:ViewDriverComponent},
    { path: 'create-driver', component: AddDriverComponent},
    { path: 'add-driver', component: CreateDriverComponent},
    { path: 'view-driver', component: ViewDriverComponent},
    { path: 'single-view-driver', component: SingleViewDriverComponent},
    { path: 'edit-driver', component: EditdriverComponent},
    //{ path: 'modify-driver', component: EditDriverComponent},
    
];
