import { Component, Input, HostListener } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { fromEvent, Observable } from 'rxjs';
import { LoaderService } from 'src/app/Services/loader.service';
import { filter } from 'src/assets/fontawesome/js/v4-shims';


declare const Compressor;

// text,email,tel,textarea,password,
@Component({
  selector: 'file',
  template: `
    <div [formGroup]="form">
      <label> {{field.label}} {{field.required ? '*':''}}</label>
      <mat-form-field appearance="outline"  floatLabel="always" *ngIf="!field.value">
        <input matInput [attr.type]="field.type" class="form-control"
         [id]="field.name" [name]="field.name"
         [formControlName]="field.name"
         (change)="handleInputChange($event)">
         <mat-hint class="text-danger" *ngIf="fileError">File size should be less than 1 MB.</mat-hint>
         </mat-form-field>
         <div class="pre-load-img" *ngIf="field.value">
         <img [src]="field.value" width="70"><button class="btn btn-sm-danger" (click)="clkFun()">X</button>
         </div>
      </div>
    `
})
export class FileComponent {
  @Input() field: any = {};
  onChange: Function;
  @Input() form: FormGroup;
  // private file:any = event && event.item(0);
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  public fileError = false;

  constructor(private loader: LoaderService) {

  }
  ngOnInit() {
    // console.log(this.field.value)
  }
clkFun() {
  this.field.value = '';
  // console.log(this.field.value)
}
  handleInputChange(e: any) {
    const files = e.target.files[0];
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const sizeOfFile = files.size / 1024 / 1024;
    if (sizeOfFile <= 10) {
        this.fileError = false;
        if (sizeOfFile <= 1) {
          const reader = new FileReader();
          console.log(this.form.value)
          reader.onload = this._handleReaderLoaded.bind(this);
          reader.readAsDataURL(file);
        } else {
          this.loader.show();
          const parent = this;
          new Compressor(file, {
            quality: Number((1 / sizeOfFile).toPrecision(2).slice(0, -1)),
            success(result) {
              const reader = new FileReader();
              reader.onload = parent._handleReaderLoaded.bind(parent);
              reader.readAsDataURL(result);
              parent.loader.hide();
            },
            error(err) {
              console.log(err);
              parent.loader.hide();
            }
          }
          );
      }
    } else {
      this.fileError = true;
      const f: any = this.form;
      f.errors = true;
      console.log(this.field.value);
      alert('File size should be less than 1 MB.');
    }
/*     if (files.size / 1024 / 1024 <= 1) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    } else {
      let f: any = this.form;
      f.errors = true;
      alert('File size should be less than 1 MB.');
    }  */
  }

  _handleReaderLoaded(e: any) {
    // console.log("_handleReaderLoaded");
      const reader = e.target;
      console.log(reader);
      this.form.value[this.field.name] = reader.result;
      this.field.value = reader.result;
      console.log(this.field.name,this.form.value)
  }
}
