import { FieldBuilderComponent } from './field-builder/fieldbuilderComponent';
import { DynamicFormBuilderComponent } from './dynamicFormBuilder';
import { TextBoxComponent } from './inputs/textbox';
import { DropDownComponent } from './inputs/dropDown';
import { PasswordComponent } from './inputs/password';
import { NumberComponent } from './inputs/number';
import { RadioComponent } from './inputs/radio';
import { CheckComponent } from './inputs/checkbox';
import { DateComponent } from './inputs/date';
import { SingleSelectComponent } from './inputs/singleSelect';
import { MultiSelectComponent } from './inputs/multiSelect';
import { FileComponent } from './inputs/file';
import { from } from 'rxjs';
import { ButtonComponents } from './inputs/button';
import { DropDownNewComponent } from './inputs/drop_down';
import { TextareaComponent } from './inputs/textarea';

export const formPageDeclaration = [FieldBuilderComponent, TextBoxComponent,
    DynamicFormBuilderComponent, DropDownComponent, PasswordComponent,TextareaComponent,
    NumberComponent, RadioComponent, CheckComponent, DateComponent, ButtonComponents,
    SingleSelectComponent, MultiSelectComponent, FileComponent, DropDownNewComponent];
