import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'g10-ms-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() buttonConfig: any;
  @Input() text: any;
  @Input() icon: any;
  @Input() maticon: any;
  @Input() type: any;
  @Input() shape: any;
  @Input() stylish: any;
  @Output() click: EventEmitter<string> = new EventEmitter<string>();
  @Output() imgBtnClickEmt: EventEmitter<string> = new EventEmitter<string>();



  constructor() { }

  ngOnInit() {

    // console.log(this.type, this.shape, this.stylish, this.click)
  }

  onTextBtnClick() {
    this.click.emit('You have clicked on a text button.');
  }

  onImgBtnClick() {
    this.imgBtnClickEmt.emit('You have clicked on an image button.');
  }
}
