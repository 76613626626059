import { Component , OnInit } from '@angular/core';
import { StyleUtils } from '@angular/flex-layout';

@Component ({
    selector : 'app-passenger-dashboard',
    templateUrl : './passengers-dashboard.component.html',
   styleUrls: ['./passengers-dashboard.component.css']
})

export class PassengersDashboardComponent {

    constructor() { }

    ngOnInit(): void {
    }

}
