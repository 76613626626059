import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


import { ApiService } from 'src/app/Services/api.service';
import { LoaderService } from 'src/app/Services/loader.service';

import { CommonServices } from '../../../../../Services/common.service';
import { ExcelExportService } from './../../../../../Services/excel.service';


@Component({
  selector: 'app-halt-reports',
  templateUrl: './halt-reports.component.html',
  styleUrls: ['./halt-reports.component.css']
})
export class HaltReportsComponent implements OnInit {

  public formFilters: any = FormGroup;

  public myDate = new Date();
  public maxDate: any;
  public objectKeys = Object.keys;
  public processedData: any = {};

  constructor(
    public fb: FormBuilder,
    public comService: CommonServices,
    public api: ApiService,
    public loader: LoaderService,
    public excel: ExcelExportService
  ) {
    this.maxDate = new Date(
      this.myDate.getFullYear(),
      this.myDate.getMonth(),
      this.myDate.getDate() - 1
    );
  }
  public startDateError: boolean;
  public allVehDownload: boolean;
  public showTable: boolean;
  public tableData: any = [];
  public excelData: any = {};
  public vehicles = [
    {
      vehId: '436',
      trackerId: 39347,
      name: 'GS-TN-02-AH-0392',
      vehNumber: 'TN-02-AH-0392',
      driverName: '',
      driverPhnum: '',
      model: '',
      fuelCapacity: '',
      regenc: '64049418f416bec380a00cd14e49dacd'
    },
    {
      vehId: '430',
      trackerId: 20895,
      name: 'GS-TN-20-BT-4608',
      vehNumber: 'TN-20-BT-4608',
      driverName: '',
      driverPhnum: '',
      model: '',
      fuelCapacity: '',
      regenc: 'a8353f046c86454a9b9637d5cd68dfd5'
    },
    {
      vehId: '432',
      trackerId: 39434,
      name: 'GS-TN-02-AZ-7607',
      vehNumber: 'TN-02-AZ-7607',
      driverName: '',
      driverPhnum: '',
      model: 'Tata Ace ',
      fuelCapacity: '',
      regenc: '1ed6509c12dc1489a990938142bafb27'
    },
    {
      vehId: '437',
      trackerId: 39427,
      name: 'GS-TN-02-AP-8566',
      vehNumber: 'TN-02-AP-8566',
      driverName: '',
      driverPhnum: '',
      model: '',
      fuelCapacity: '',
      regenc: 'd3d12d0a0c87113a285e964b6a151266'
    },
    {
      vehId: '61950',
      trackerId: 39462,
      name: 'GS-TN02AZ7531',
      vehNumber: 'TN02AZ7531',
      driverName: '',
      driverPhnum: '',
      model: 'Tata Ace ',
      fuelCapacity: '',
      regenc: 'abedd0694944cdbdb910c2afbd452975'
    },
    {
      vehId: '438',
      trackerId: 8293,
      name: 'GS-TN-02-AH-6736',
      vehNumber: 'TN-02-AH-6736',
      driverName: '',
      driverPhnum: '',
      model: '',
      fuelCapacity: '',
      regenc: '87912c69b2a4b8ba4e4ac8ccf22f5451'
    },
    {
      vehId: '429',
      trackerId: 13793,
      name: 'GS-TN-02-AZ-7598',
      vehNumber: 'TN-02-AZ-7598',
      driverName: '',
      driverPhnum: '',
      model: '',
      fuelCapacity: '',
      regenc: 'bfc1f4198f69290a077bdc3be727e45b'
    },
    {
      vehId: '431',
      trackerId: 39431,
      name: 'GS-TN-02-AQ-5594',
      vehNumber: 'TN-02-AQ-5594',
      driverName: '',
      driverPhnum: '',
      model: '',
      fuelCapacity: '',
      regenc: '4f301518cfe51e581373d8443404545f'
    },
    {
      vehId: '434',
      trackerId: 39346,
      name: 'GS-TN-02-AF-5674',
      vehNumber: 'TN-02-AF-5674',
      driverName: '',
      driverPhnum: '',
      model: '',
      fuelCapacity: '',
      regenc: '7e57178ed25c6073c012817e4af952e0'
    },
    {
      vehId: '435',
      trackerId: 39348,
      name: 'GS-TN-02-AC-4115',
      vehNumber: 'TN-02-AC-4115',
      driverName: '',
      driverPhnum: '',
      model: '',
      fuelCapacity: '',
      regenc: '7918d0cf6edf8a78897efd1ee2144fcd'
    }
  ];
  public cols = [
    { key: '#', display: '#' },
    { key: 'StartTime', display: 'Start Time' },
    { key: 'EndTime', display: 'End Time' },
    { key: 'Location', display: 'Location' },
    { key: 'HaltDuration', display: 'Halt Duration' },
    // { key: "Distance", display: "Total Distance (in Km)" },

  ];
  ngOnInit(): void {
    this.reactiveForm();

  }
  reactiveForm() {
    this.formFilters = this.fb.group({
      selectedVehicle: ['', Validators.required],
      sDate: ['', Validators.required],
      eDate: ['', Validators.required],
      minutes: ['', Validators.required],
      hours: ['', Validators.required]
    },
      {
        validator: [this.comService.formToDateValidation('sDate', 'eDate')]
      });
  }

  async submitForm() {
    try {
      this.startDateError = false;
      if (this.formFilters.valid) {


        this.allVehDownload = false;
        this.showTable = true;
        this.tableData = [];
        this.showTable = false;
        this.loader.show();
        const vehicle = this.formFilters.value.selectedVehicle;
        const startDate = new Date(this.formFilters.value.sDate + '');
        const endDate = new Date(this.formFilters.value.eDate + '');
        const hh = this.formFilters.value.hours;
        const mm = this.formFilters.value.minutes;
        const arr: any[] = []
        const getDaysArray = function (start, end) {
          for (const dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
            const date: Date = new Date(dt);
            arr.push(date.getFullYear() + '/' + pad(date.getMonth() + 1) + '/' + pad(date.getDate()));
          }
          return arr;
        };
        const pad = (num) => {
          return num > 9 ? num : '0' + num;
        };
        const start = startDate.getFullYear() + '/' + pad(startDate.getMonth() + 1) + '/' + pad(startDate.getDate());
        const end = endDate.getFullYear() + '/' + pad(endDate.getMonth() + 1) + '/' + pad(endDate.getDate());
        const daylist: any = getDaysArray(new Date(start), new Date(end));
        const vehicleList = await this.comService.vehicleToObject(this.vehicles, vehicle);
        const vehLen = this.objectKeys(vehicleList);
        for (let veh = 0; veh < vehLen.length; veh++) {

          if (this.processedData[vehLen[veh]] == undefined) { this.processedData[vehLen[veh]] = {}; }
          for (let day = 0; day < daylist.length; day++) {

            const splitDate = daylist[day].split('/');
            const date = splitDate[2] + splitDate[1] + splitDate[0].slice(-2);
            const url = date + '/' + vehicleList[vehLen[veh]] + '.z';
            const res = await this.api.readS3Data(url);
            if (this.processedData[vehLen[veh]][daylist[day]] == undefined) { this.processedData[vehLen[veh]][daylist[day]] = {}; }
            const pdata: any = await this.processData(res, hh, mm);
            // console.log('Pdata---> ', pdata);
            if (pdata && pdata.data.length > 0) {
              this.processedData[vehLen[veh]][daylist[day]] = pdata.data;
              const excelInfo = {
                title: {
                  title: 'Halt Report',
                  subTitleRow: 'Date : ' + startDate + ' to ' + endDate,
                  font: { name: 'Corbel', family: 4, size: 16, underline: 'double', bold: true },
                  mergeCells: 'A1:G2',
                },
                header: {
                  headers: ['Start Time', 'End Time', 'Location', 'Total Duration'],
                  fill: {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFFFFF00' },
                    bgColor: { argb: 'FF0000FF' }
                  },
                  border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
                },
                data: {
                  data: pdata.xldata,
                  columWith: [{ colNo: 2, width: 20 }]
                }
              };

              if (this.excelData[vehLen[veh]] == undefined) { this.excelData[vehLen[veh]] = {}; }
              this.excelData[vehLen[veh]] = excelInfo;
            }
          }
        }

        console.log('tableData---> ', this.tableData);
        if (vehicle != 'ALL') {
          this.tableData.sort(function (x, y) {
            return x.ts - y.ts;
          });
          this.showTable = true;
        } else { this.allVehDownload = true; }

        this.loader.hide();
      } else {
        if (this.formFilters.errors && this.formFilters.errors.dates) {
          this.formFilters.controls.sDate.errors = this.formFilters.errors.dates;
          this.formFilters.controls.sDate.pristine = true;
          this.formFilters.controls.sDate.status = 'INVALID';
        }
        console.log(this.formFilters);
      }
    } catch (error) {
      console.log(error);
    }
  }
  processData(data: any, hh: any, mm: any) {
    return new Promise((resolve, reject) => {
      // console.log('P1', data)
      console.log('hh', hh, 'mm', mm);
      try {
        const procesedData: any = [];
        const sortingData = {};
        data.map((item) => {
          item.date = this.comService.timestampToDate(item.ts, 'DMY H:M:S');
          if (item.ts != undefined) {
            sortingData[item.ts] = item;
          }
        });
        const chkObj = this.objectKeys(sortingData);
        if (chkObj && chkObj.length) {
          const ind = 1;
          const temp: any = {}, tempObj = [];
          const tempEx = [];
          let haltFlag = 0;
          let nextFlag = 0;
          let cnt = 0;
          for (let s = 0; s < chkObj.length; s++) {
            const currentKey = chkObj[s];
            const item = sortingData[currentKey];
            if (item.speed <= 5) {
              if (currentKey != undefined) {
                // var dM = ts2str(item.ts, processingDayTS);
                if (haltFlag == 0) {
                  cnt++;
                  haltFlag = 1;
                  if (temp[cnt] == undefined) { temp[cnt] = {}; }
                  temp[cnt].startTime = item.date;
                  temp[cnt].startTMS = item.ts;
                  temp[cnt].speed = item.speed;
                  temp[cnt].near = item.near;

                  if (nextFlag == 0) { nextFlag = 1; }
                }
              }
            } else {
              haltFlag = 0;
              if (nextFlag == 1) {
                // if(temp[cnt]['endTime']==undefined) temp[cnt]['endTime']=[];
                temp[cnt].endTime = item.date;
                temp[cnt].endTMS = item.ts;
                nextFlag = 0;
              }
            }
            if (chkObj.length - 1 == s) {
              const haltProcessedData = [];
              const haltExData = [];
              for (const key in temp) {
                if (temp[key].endTMS != undefined && temp[key].endTMS != null && temp[key].endTMS != '') {
                  const haltTime = this.comService.timeDiff(Number(temp[key].startTMS), Number(temp[key].endTMS));
                  const processTMS = (new Date(this.comService.timestampToDate(temp[key].startTMS, 'MDY') + ' 00:00:00')).getTime() / 1000;
                  const sTime = this.comService.ts2str(temp[key].startTMS, processTMS);
                  const eTime = this.comService.ts2str(temp[key].endTMS, processTMS);
                  if (hh != '' && mm != '') {
                    if ((parseFloat(haltTime.hh) >= parseFloat(hh)) && (parseFloat(haltTime.mm) >= (parseFloat(mm)))) {
                      const res = {
                        StartTime: this.comService.timestampToDate(temp[key].startTMS, 'DMY') + ' ' + sTime,
                        EndTime: this.comService.timestampToDate(temp[key].endTMS, 'DMY') + ' ' + eTime,
                        Location: temp[key].near,
                        HaltDuration: haltTime.timeString,
                        ts: temp[key].ts
                      };
                      haltProcessedData.push(res);
                      this.tableData.push(res);

                      haltExData.push([sTime, eTime, haltTime.timeString, temp[key].near]);
                    }
                  } else {
                    const res = {
                      StartTime: this.comService.timestampToDate(temp[key].startTMS, 'DMY') + ' ' + sTime,
                      EndTime: this.comService.timestampToDate(temp[key].endTMS, 'DMY') + ' ' + eTime,
                      Location: temp[key].near,
                      HaltDuration: haltTime.timeString,
                      ts: temp[key].ts
                    };
                    haltProcessedData.push(res);
                    this.tableData.push(res);
                    haltExData.push([sTime, eTime, haltTime.timeString, temp[key].near]);
                    this.tableData.push(res);
                  }
                }

              }
              resolve({ data: haltProcessedData, xldata: haltExData });
            }
          }

          //  resolve({data: temp,xlData:[]});
        } else {
          console.log('empty', chkObj);
          resolve({ data: [], xldata: [] });
        }

      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }

  async downloadAllVehicleHaltReport() {
    const chunk: any = await this.comService.chunkObject(this.excelData, 50);
    console.log('chunk', chunk);

    for (let i = 0; i < chunk.length; i++) {
      this.excel.generateExcel(chunk[i], 'Halt_Report_' + i + '_' + new Date().getTime() + '.xlsx');
    }

  }
  downloadHaltReport() {
    this.excel.generateExcel(this.excelData, 'Halt_Report_' + new Date().getTime() + '.xlsx');
  }

}
