import { Component, OnInit } from '@angular/core';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.css']
})
export class TripsComponent implements OnInit {
  public pieChartLabels: Label[] = [['Active'], ['Overall']];
  public pieChartData: number[] = [300, 500];
  public pieChartLabels2: Label[] = [['Active -1'], ['Overall -1']];
  public pieChartData2: number[] = [400, 200];
  public pieChartLabels3: Label[] = [['Active-2'], ['Overall-2']];
  public pieChartData3: number[] = [600, 200];
  constructor() { }

  ngOnInit(): void {
  }

}
