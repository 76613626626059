import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temporary-manual-rostering',
  templateUrl: './temporary-manual-rostering.component.html',
  styleUrls: ['./temporary-manual-rostering.component.css']
})
export class TemporaryManualRosteringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
