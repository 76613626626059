import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { OrgModule } from '../../../../modules/organization';
import { editSettingsModel } from '../../../../modules/organization/edit-settings.model';
import indiaStates from '../../../../../assets/js/india.state.list.json';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';


@Component({
    selector: 'edit-org-info',
    templateUrl: './editorganization.component.html',
    styles: [`

::ng-deep .mat-tab-list .mat-tab-labels .mat-tab-label-active {
    color:rgb(255, 255, 255) !important;
    background-color: #4950b8 !important;
    height: 30px !important;
    width: 30px;
    font-size: 18px !important;
    }
    ::ng-deep .mat-tab-list .mat-tab-labels .mat-tab-label {
        height: 40px !important;
         border-right-style: ridge;
        font-size: 18px !important;
        border-image: linear-gradient( to bottom,#ff000000, #9196d5, rgb(29 29 29 / 0%) ) 1 10%;
        /* border-right-color: rgb(160, 160, 160); */
        width: 187px !important;
        }
    .custom-theme .mat-tab-group.mat-accent .mat-ink-bar, .custom-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
      background-color: #d82929;
  }
  ::ng-deep.mat-tab-group{
      background-color: white;
  }
   ::ng-deep.mat-tab-label, ::ng-deep.mat-tab-label-active{
    min-width: 0!important;
    /* min-height: 10px !important; */
    padding: 3px!important;
    margin: 3px!important;
   }

   .mat-tab-label {

    height: 24px !important;

   }

  `]
})
/*
 <div align="center">
      <span class="btn btn-sm btn-dark" (click)="modalClose()" *ngIf="__close">{{__close}}</span>
    </div>
 */
export class EditOrgComponent {
    orgID: string;
    OrgData: any;
    enableForm: boolean;
    organation: any = OrgModule;
    settings: any = editSettingsModel;
    isUserShow: boolean;
    isServiceShow: boolean;
    isAlertShow: boolean;
    settingsInfo = {};
    selectedIndex = 0;
    editSettingsForm: FormGroup;
    submittedform = false;
    settingsList: any;

    constructor(
        public api: ApiService,
        public router: Router,
        public swal: SweetAlert,
        public route: ActivatedRoute,
        private formBuilder: FormBuilder

    ) {
        this.route.paramMap.subscribe(params => {
            console.log(params.get('id'));
            this.orgID = params.get('id');
        });

    }
    ngOnInit() {
        this.getOrgList();

        const states = {};
        const stateList = this.getStateOptions();
        for (const item of this.organation) {
            if (item.name == 'state') {
                item.options = stateList;
            }
        }

        this.settingFormLoad();


    }


    settingFormLoad() {
        this.editSettingsForm = this.formBuilder.group({
            name: ['', Validators.required],
            userEmail: ['', [Validators.required, Validators.email]],
            designation: ['', [Validators.required]],
            contactNumber: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
            call: [false],
            email: [false],
            push: [false],
            sms: [false],
            empSolution: [false],
            fleet: [false],


        });
    }
    get f() { return this.editSettingsForm.controls; }





    getStateOptions() {
        const states = [];
        for (const country in indiaStates) {
            indiaStates[country].map((item) => {
                states.push({ value: item, name: item });
            });
        }
        return states;
    }


    getOrgList() {
        const obj = {
            orgID: this.orgID
        };
        this.api.listOrg(obj).then((res: any) => {
            console.log(res.data[0]);
            console.log();
            for (const f of this.organation) {
                // console.log(f)
                const name = f.name;
                let value = '';

                if (f.type == 'dropdown') {
                    const val = res.data[0][name].split(',');
                    const temp = [], str = [];
                    val.map(item => { temp.push({ value: val, name: val }); str.push(item); });
                    f.selected = temp;
                    f.value = str.join(',');
                } else {
                    value = name;
                    f.value = res.data[0][name];
                }
            }
            console.log(this.organation);
            this.enableForm = true;
        }).catch((err) => {
            console.log(err);
        });

        //  seting list api
        this.api.org_settingsList(obj).then((res: any) => {
            this.settingsList = res.data;
            this.setDataToForm();
        }).catch((err) => {
            console.log(err);
        });
    }
    setDataToForm() {
        this.editSettingsForm.patchValue(
            {
                name: this.settingsList.userInfo[0].name,
                userEmail: this.settingsList.userInfo[0].userEmail,
                designation: this.settingsList.userInfo[0].designation,
                contactNumber: this.settingsList.userInfo[0].contactNumber,
                call: this.settingsList.alert[0].call,
                email: this.settingsList.alert[0].email,
                push: this.settingsList.alert[0].push,
                sms: this.settingsList.alert[0].sms,
                empSolution: this.settingsList.services[0].empSolution,
                fleet: this.settingsList.services[0].fleet,
            });
    }
    onSubmit() {
        this.submittedform = true;
        // stop here if form is invalid
        if (this.editSettingsForm.invalid) {
            return;
        } else {
            const obj = {
                userInfo: {
                    name: this.editSettingsForm.value.name,
                    userEmail: this.editSettingsForm.value.userEmail,
                    designation: this.editSettingsForm.value.designation,
                    contactNumber: this.editSettingsForm.value.contactNumber,
                },
                services: {
                    empSolution: this.editSettingsForm.value.empSolution,
                    fleet: this.editSettingsForm.value.fleet,
                },
                alert: {
                    email: this.editSettingsForm.value.email,
                    call: this.editSettingsForm.value.call,
                    push: this.editSettingsForm.value.push,
                    sms: this.editSettingsForm.value.sms,
                },

                orgID: this.orgID
            };
            console.log(obj);
            this.api.org_settings_update(obj).then((res: any) => {
                if (res.status == 'success') {
                    this.swal.swalFunction('success', res.status, res.message, 2000);
                    this.router.navigate(['/master/view-org']);
                }
                if (res.status == 'failure') {
                    this.swal.swalFunction('error', res.status, res.message, 2000);
                }
            }).catch((err) => {
                console.log(err);
            });
            // display form values on success
            console.log(this.editSettingsForm.value);
        }
    }

    submitted(e: any) {
        console.log(e.valid);
        console.log('Edit', e.value);
        if (e.valid) {
            const obj = {
                orgID: this.orgID,
                ...e.value
            };
            this.api.updateOrg(obj).then((res: any) => {
                if (res.status == 'success') {
                    this.swal.swalFunction(res.status, res.status, res.message, 2000);
                    this.router.navigate(['/master/view-org']);
                }
                if (res.status == 'failure') {
                    this.swal.swalFunction('error', res.status, res.message, 2000);
                }
            }).catch((err) => {
                console.log(err);
            });


        } else {
            console.log('Form field invalid');
        }
    }
}
