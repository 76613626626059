import { AutomaticRosteringComponent } from "./automatic-rostering/automatic-rostering.component";
import { ManualRosteringComponent } from "./manual-rostering/manual-rostering.component";
import { ManualRosteringDeclaration } from "./manual-rostering/manual-rostering.declaration";
import { RosteringComponent } from "./rostering.component";
import { RoutesDeclaration } from "./routes/routes.declaration";
import { SemiAutomaticRosteringComponent } from "./semi-automatic-rostering/semi-automatic-rostering.component";



export const RosteringDeclaration = [
    ManualRosteringComponent,
    ManualRosteringDeclaration,
    RoutesDeclaration,
    SemiAutomaticRosteringComponent,
    AutomaticRosteringComponent,
    RosteringComponent
];
