import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-information-dashboard',
  templateUrl: './information-dashboard.component.html',
  styleUrls: ['./information-dashboard.component.css']
})
export class InformationDashboardComponent implements OnInit {

  constructor() { }
  // lineChart Data

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
    { data: [35, 25, 40, 15, 48, 40, 28], label: 'Series C' },
    { data: [40, 19, 86, 27, 90, 27, 86], label: 'Series D' },
    { data: [15, 48, 40, 28, 48, 40, 19], label: 'Series E' },
    { data: [55, 48, 40, 19, 86, 27, 43], label: 'Series F' },
    { data: [30, 40, 19, 86, 27, 25, 46], label: 'Series G' }

    // { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  ngOnInit(): void {
  }


}
