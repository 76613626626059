import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Import HttpClientModule from @angular/common/http
import {HttpClientModule, HTTP_INTERCEPTORS, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AdminModule } from './Layouts/admin/admin.module';
import { CustomMaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomHttpInterceptor } from './Services/intercept.service';
import { PrismModule } from '@ngx-prism/core';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ServiceDeclaration } from './Services/service.declaration';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthenticationModule } from './authentication/auth.module';

// Custom Pipes


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AdminModule,
    PrismModule,
    ChartsModule,
    EditorModule,
    NgxSpinnerModule,
    AuthenticationModule

  ],
  providers: [ServiceDeclaration,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    {provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true
   },
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
