import { Component, OnInit, Input } from '@angular/core';
// import { CalendarOptions } from '@fullcalendar/angular'; // useful for typechecking
import * as moment from 'moment';



@Component({
    selector: 'calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.css']
})


export class CalendarComponent {

@Input() eventData: any;


constructor() {

   }

  //  calendarOptions: CalendarOptions = {
  //   initialView: 'dayGridMonth',
  //   // dateClick: this.handleDateClick.bind(this), // bind is important!
  //   events: [
  //     { title: 'event 1', date: '2020-07-01' },
  //     { title: 'event 2', date: '2020-07-02' }
  //   ]
  // };

  // handleDateClick(arg) {
  //   alert('date click! ' + arg.dateStr)
  // }
}
