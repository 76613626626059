export const alertSettings = [

    {
      type: 'dropdown',
      name: 'alertType',
      label: 'Alert Type',
      value: '',
      required: true,
      options: [
        { value: 'push', name: 'Push Notification' },
        { value: 'sms', name: 'SMS Alert' },
        { value: 'call', name: 'Call Alert' },
        { value: 'email', name: 'Email Alert' }
      ]
    },

    {
        type: 'checkbox',
        name: 'alertFor',
        label: 'Alert For',
        value: '',
        options: [
          { value: 'tamil', name: 'Tamil', selected: true},
          { value: 'english', name: 'English'}
        ],
        required: true,
      },

  ];
