import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { EditEmployeeComponent } from './edit-employee/edit-employee.component';
import { SingleViewEmployeeComponent } from './viewemployees/single-view-employee.component';
import { ViewemployeesComponent } from './viewemployees/viewemployees.component';

export const EmployeeDetailsRouting = [
    { path: '', redirectTo: 'view-employee', pathMatch: 'full' },
    { path: 'add-employee', component: CreateEmployeeComponent },
    { path: 'view-employee', component: ViewemployeesComponent },
    { path: 'single-view-empolyee', component: SingleViewEmployeeComponent },
    { path: 'edit-empolyee', component: EditEmployeeComponent },
];
