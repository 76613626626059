import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vechicle',
  templateUrl: './vechicle.component.html',
  styleUrls: ['./vechicle.component.css']
})
export class VechicleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
