import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-passeord',
  templateUrl: './reset-passeord.component.html',
  styleUrls: ['./reset-passeord.component.css']
})
export class ResetPasseordComponent implements OnInit {
  isShowEyesPsswrd = false;
  isShowEyesCnfrimPass = false;
  inputTypeNew = 'password';
  inputTypeConfrim = 'password';
  inputTypeOld = 'password'
  newPassword: any;
  confrimPassword: any;
  oldPassword: any
  isShowEyesOldPsswrd: any;

  constructor() { }

  ngOnInit(): void {

  }
  viewPassword(val) {
    console.log(val);
    if (val == 'new') {
      this.isShowEyesPsswrd = !this.isShowEyesPsswrd;
      if (this.isShowEyesPsswrd) { this.inputTypeNew = 'text'; } else { this.inputTypeNew = 'password'; }
    }
    if (val == 'confrim') {

      this.isShowEyesCnfrimPass = !this.isShowEyesCnfrimPass;
      if (this.isShowEyesCnfrimPass) { this.inputTypeConfrim = 'text'; } else { this.inputTypeConfrim = 'password'; }
      console.log(this.isShowEyesCnfrimPass, this.inputTypeConfrim);
    }
    if (val == 'old') {
      this.isShowEyesOldPsswrd = !this.isShowEyesOldPsswrd;
      if (this.isShowEyesOldPsswrd) { this.inputTypeNew = 'text'; } else { this.inputTypeNew = 'password'; }
    }
  }

  passwordSubmit() {
    console.log(this.newPassword, this.confrimPassword, this.oldPassword);
    if (this.newPassword && this.confrimPassword) {
    }
  }
  enterToPassWord(e) { }
}
