import { AddBranchComponent } from "./add-branch/add-branch.component";
import { EditBranchComponent } from "./edit-branch/edit-branch.component";
import { ViewBranchComponent } from "./view-branch/view-branch.component";

export const BranchRouting: any = [
    { path: '', redirectTo: 'view-branch', pathMatch: 'full' },
    { path: 'add-branch', component: AddBranchComponent},
    { path: 'view-branch', component: ViewBranchComponent},
    { path: 'edit-branch', component: EditBranchComponent},
    
];