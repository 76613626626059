import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

@Component({
    selector: 'app-single-view-employee',
    templateUrl: './singleEmployee.html'
})

export class SingleViewEmployeeComponent {
    orgID: string;
    vendorID: string;
    employeeID: string;
    employeeData: any;
    constructor(
        public api: ApiService,
        public router: Router,
        public route: ActivatedRoute,
        public swal: SweetAlert
    ) {
        this.route.paramMap.subscribe(paramas => {
            this.orgID = paramas.get('orgID');
            this.employeeID = paramas.get('employeeID');
        });
    }
    ngOnInit() {
        this.getEmployeeData();
    }
    getEmployeeData() {
        const obj = {
            orgID: this.orgID,
            // vendorID: this.vendorID,
            employeeID: this.employeeID
        };
        this.api.transportManagerEmployeeList(obj).then((res: any) => {
            if (res.status == 'success') {
                this.employeeData = res.data[0];
            }
        }).catch((err) => {
            console.log(err);
        });
    }
}
