import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Services/storage.service';
import { ApiService } from 'src/app/Services/api.service';

declare const popup: any, snackbar: any;

@Component({
  selector: 'main-app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private router: Router, public storage: StorageService, public api: ApiService) { }
  username = 'depotadmin';
  password = 'depotadmin';
  showSpinner: boolean;
  inputType = 'password';
  ngOnInit() {
    // this.storage.removeAll()
    // popup({
    //   title: 'Sample Window',
    //   body : '<p>Welcome to customized popup'
    // });

    // setInterval(()=>{ snackbar('Own snackbar.. <span class="text-warning">Great</span>') }, 5000 )
  }
  enterToLogin(e) {
    if (e.keyCode === 13) {
      this.login();
    }
  }
  login(): void {
    this.showSpinner = true;

    if (this.username && this.password) {
      this.api.masterLogin({ username: this.username, password: this.password })
        .then((res: any) => {

          if (res.status == 'success') {
            this.storage.setData({ userType: 'master', token: res.token });
            this.router.navigate(['master']);
            // if (this.username == 'master' && this.password == 'master') {
            //   this.router.navigate(["master"]);
            // }
            // else if (this.username == 'orgadmin' && this.password == 'orgadmin') {
            //   this.router.navigate(["orgadmin"]);
            // }
            // else if (this.username == 'depotadmin' && this.password == 'depotadmin') {
            //   this.router.navigate(["depotadmin"]);
            // }
            // else if (this.username == 'user' && this.password == 'user') {
            //   this.router.navigate(["map"]);
            // } else {
            //   snackbar('<span class="text-warning">Invalid credentials</span>')
            //   this.showSpinner = false;
            //   // alert("Invalid credentials");
            // }
          } else {
            snackbar(`<span class="text-warning"> ${res.message} </span>`);
            this.showSpinner = false;
          }
        })


        .catch((err) => console.log(err));
      } else {
        snackbar(`<span class="text-warning"> Username and password required </span>`);
      }
    }




  viewPassword() {
    this.inputType = this.inputType == 'text' ? 'password' : 'text';
  }
}
