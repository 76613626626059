import { HaltReportsComponent } from './halt-reports/halt-reports.component';
import { MomentReportsComponent } from './moment-reports/moment-reports.component';
import { OverspeedReportsComponent } from './over-speed-reports/over-speed-reports.component';
import { ProductivityComponent } from './productivity-reports/productivity-reports.component';
import { ReportsComponent } from './reports.component';
import { SosReportComponent } from './sos-report/sos-report.component';


export const ReportsRouting = [
    { path: '', redirectTo: 'report-dashboard', pathMatch: 'full' },
    { path: 'report-dashboard', component: ReportsComponent },
    { path: 'sos-reports', component: SosReportComponent },
    { path: 'moment-reports', component: MomentReportsComponent },
    { path: 'halt-reports', component: HaltReportsComponent },
    { path: 'overspeed-reports', component: OverspeedReportsComponent },
    { path: 'productivity-reports', component: ProductivityComponent}

];


export const ReportsRoutingBreadcrumb = [

              { name: 'Dashboard', path: '/report-dashboard' },
              { name: 'Moment', path: '/moment-reports' },
              { name: 'Halt', path: '/halt-reports' },
              { name: 'Overspeed', path: '/overspeed-reports' },

];
