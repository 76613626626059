import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  userInfo: any;

  constructor(
    public storage: StorageService,
    public api: ApiService,
    public swal: SweetAlert,
    public router: Router
  ) { }

  ngOnInit(): void {
    this. getUserData();
  }
  getUserData() {
    this.api.GetCurUserOrg({}).then((res: any) => {
      if (res.status == 'success') {
        this.userInfo = res.data[0];
        console.log(this.userInfo);
      }
      if (res.status == 'failure') {
        this.swal.swalFunction('error', res.status, res.message, 2000);
      }
    });
  }
}
