import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { CommonServices } from 'src/app/Services/common.service';
import { MapService } from 'src/app/Services/map.service';
import { SharedService } from 'src/app/Services/shared.service';
import { VehicleLiveTrackService } from 'src/app/store/vehicle/reducer';
declare const mapUtilityMapBox;

@Component({
  selector: 'app-live-tracking',
  templateUrl: './live-tracking.component.html',
  styleUrls: ['./live-tracking.component.css']
})
export class LiveTrackingComponent implements OnInit {

  public vehicleCategorys: any = {
    moving: [],
    halt: [],
    lateUpdate: [],
    all: []
  };
  @Output() vehicleStatus = new EventEmitter();

  @Input() public set selectedVeh(val: string) {
     if(val !== undefined) this.focus(val);
  }

  value: any;
  values: boolean;
  notes = [];
  folders = [];
  public addMarker: any;
  constructor(
    private share: SharedService,
    private api: ApiService,
    private map: MapService,
    private comService: CommonServices,
    private socketStore: VehicleLiveTrackService
  ) {
    // console.log('@@@@ live tracking triggered');
    this.share._subject.subscribe((data: any) => {

      if (data.type == 'socketData') { 
        data.data.map((item) => {
          // if (item.imei == '861359038057954')
          //   console.log(item.imei, item.lat, item.lng, item);

          const color = item.speed > 5 ? 'green-dot' : 'red-dot';
          const marker = {
            id: item.reg,
            content: `<div class="${color} dot--basic" ></div>`,
            lat: parseFloat(item.lat),
            lng: parseFloat(item.lng),
            // lat: 12.987315434653826,
            // lng: 80.22082852957499,
            bearing: true,
            popup: { content: item.near, open: true },
            cluster: 'dashboardMap'
          };
          // console.log('marker ', this.mapObj)
          if(this.mapObj!=undefined) this.updateMarker(marker);

          // }
        });
      }
    });
  }
  public mapObj: any;
  ngOnInit(): void {
    console.log('ong')
    this.getCurrentVehicleLocation();
  }

  initMap(markers: any, type: string) {

    try {
      const mapBoxKey = 'pk.eyJ1Ijoib3dlbmxhbWIiLCJhIjoiY2lleWljcnF4MDBiOXQ0bHR0anRvamtucSJ9.t3YnHHqvQZ8Y0MTCNy0NNw';
      const parent = this;
      if (mapBoxKey) {
        const MyMap1 = new mapUtilityMapBox();
        MyMap1.setup('markerExampleMapBox1', {
          key: mapBoxKey
        },
          function (map) {
            // if (type == 'add') {
            parent.mapObj = map;
            map.clusters = [
              {
                name: 'dashboardMap',  // unique id
                maxZoom: 10, // max zoom on cluster click event
                radius: 20,
                color: 'rgba(255, 0, 0, 0.2)', // '#be9694'  // for opacity use rgb
                textColor: 'green' // count color
              }
            ];

            map.addMarkers(markers);
            setTimeout(() => {
              map.clusterSynch();
            }, 500);

            map.onMapZoom(function (z) {
              map.clusterSynch();
              setTimeout(() => { map.marker(markers); }, 200);
            });

            map.focus({
              lat: parseFloat(markers[0].lat),
              lng: parseFloat(markers[0].lng),
              zoom: 10
            });

            map.onClick({
              type: 'marker',
            }, (e: any) => {
              map.focus({
                lat: e._lngLat.lat,
                lng: e._lngLat.lng,
                zoom: 18
              });
            });
            // } else {
            //   console.log(markers)
            //   map.marker(markers);
            //   setTimeout(() => {
            //     map.clusterSynch();
            //   }, 500);
            // }
          });
      }
    } catch (error) {
      console.log(error);
    }
  }
  updateMarker(marker) {
    console.log('marker ', this.mapObj)
   if(marker !== undefined) this.mapObj.marker(marker);

  }
  focus(e) {
    // console.log(e)
    const obj = {
      lat: e.lat,
      lng: e.lng,
      zoom: 18
    };
    console.log(obj,'this.mapObj',this.mapObj);
    this.mapObj.focus({
      lat: e.lat,
      lng: e.lng,
      zoom: 18
    });
  }
  getCurrentVehicleLocation() {
    try {
      const markers = [];

      this.api.getCurrentLocation({}).then((res: any) => {
        res.data.map((item: any, i: number) => {
          const regNo: any = Object.keys(item)[0];
          const data = typeof item[regNo] == 'string' ? JSON.parse(item[regNo]) : item[regNo];
          if (data != undefined) {
            this.socketStore.addVehicleInfo(data);
            const color = data.spd > 5 ? 'green-dot' : 'red-dot';
            const marker = {
              id: regNo,
              content: `<div class="${color} dot--basic" ></div>`,
              lat: parseFloat(data.lat),
              lng: parseFloat(data.lng),
              popup: {
                content: '<span>' + regNo + '<span>',
                open: false
              },
              cluster: 'dashboardMap',
              bearing: true,
            };
            markers.push(marker);
          }
          if (res.data.length - 1 == i) {
            this.vehicleStatus.emit(data);
            // console.log('markers ', markers);
            this.initMap(markers, 'add');
          }
        });
      });
    } catch (error) {
      console.log('Error', error);
    }
  }

}
