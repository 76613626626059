import { Component } from '@angular/core';

@Component({
    selector: 'app-driver',
    template: `
  `
    ,
    styles: []
})

export class DriverComponent {
    isShowIcon = false;
    constructor() {

    }

}
