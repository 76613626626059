export const RequestModel = [
  {
    type: 'text',
    name: 'title',
    label: 'Title',
    value: '',
    required: true,
    col: 'col-md-4',
    // multiline: true
  },
  {
    type: 'dropdown',
    name: 'requesTo',
    label: 'Request To',
    value: '',
    required: true,
    col: 'col-md-4',
    // multiselect:true,
    options: [
      { value: 'employee', name: 'Employee' },
      { value: 'driver', name: 'Driver' },
      { value: 'manager', name: 'Manager'},
    ]
  }, {
    type: 'dropdown',
    name: 'requestCategory',
    label: 'Request Category',
    value: '',
    required: true,
    col: 'col-md-4',
    // multiselect:true,
    options: [
      { value: 'special_request', name: 'Special Request' },
      { value: 'leave request', name: 'Managers Request' },
      { value: 'on_Board_request', name: 'On Board Request' },
      { value: 'location_request', name: 'Location Request' },
      { value: 'route_change', name: 'Route_Change' },
      { value: 'time_change', name: 'Time Change' },
      { value: 'shift_change', name: 'Shift Change' }
    ]
  },
  {
    type: 'text',
    name: 'message',
    label: 'Message',
    value: '',
    required: true,
    col: 'col-12',
    multiline: true,
    height: '60px'
  },
];
