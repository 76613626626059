export const OrgModule = [
  {
    type: 'text',
    name: 'orgName',
    label: 'Organization Name',
    value: '',
    required: true,
  },
  {
    type: 'text',
    name: 'orgEmail',
    label: 'Organization Email ID',
    value: '',
    required: true,
  },
  {
    type: 'text',
    name: 'website',
    label: 'Website',
    inType: 'url',
    value: '',
    required: true,
  },


  // {
  //   type: 'dropdown',
  //   name: 'country',
  //   label: 'Country',
  //   value: '',
  //   required: true,
  //   options: [
  //     { value: 'IND', name: 'IND' },
  //     // { value: 'US', name: 'US' },
  //     // { value: 'UK', name: 'UK' },
  //   ],
  //   selected:[
  //     {value: "IND", name:"IND"}
  //   ]
  // },
  {
    type: 'text',
    name: 'country',
    label: 'Country',
    value: 'India',
    required: true,
  },
  {
    type: 'dropdown',
    name: 'state',
    label: 'State',
    value: '',
    required: true,
    options: [
      { value: 'TN', name: 'TN' },
      { value: 'Kerala', name: 'Kerala' },
      { value: 'Bangalore', name: 'Bangalore' },
      { value: 'Mumbai', name: 'Mumbai' }
    ]
  },
  {
    type: 'text',
    name: 'city',
    label: 'City',
    value: '',
    required: true,
  },
  {
    type: 'text',
    name: 'address',
    label: 'Organization Address',
    value: '',
    required: true,
  },

  {
    type: 'number',
    name: 'pincode',
    label: 'Pincode',
    value: '',
    required: true,
  },

  {
    type: 'text',
    name: 'primaryContactName',
    label: 'Primary Contact Name',
    value: '',
    required: true,

  },
  {
    type: 'number',
    name: 'primaryContactNumber',
    label: 'Primary Contact Number',
    value: '',
    required: true,
    textType: 'mobile'
  },
  {
    type: 'text',
    name: 'primaryContactEmail',
    label: 'Primary Email ID',
    value: '',
    required: true,
  },
  {
    type: 'text',
    name: 'primaryContactRole',
    label: 'Primary Contact Role',
    value: '',
    required: true,
  },
  {
    type: 'text',
    name: 'secondaryContactName',
    label: 'Secondary Contact Name',
    value: '',
    required: true,
  },
  {
    type: 'number',
    name: 'secondaryContactNumber',
    label: 'Secondary Contact Number',
    value: '',
    required: true,
    textType: 'mobile'
  },
  {
    type: 'text',
    name: 'secondaryContactEmail',
    label: 'Secondary Contact Email ID',
    value: '',
    required: true,
  },
  {
    type: 'text',
    name: 'secondaryContactRole',
    label: 'Secondary Contact Role',
    value: '',
    required: true,
  },
  {
    type: 'file',
    name: 'profile',
    label: 'Organization Logo',
    value: '',
    require: true
  },
  // {
  //   type: 'button',
  //   displayText:'save',
  //   color:'primary'
  // }

];
