// import { AnnouncementComponent } from "./announcement/announcement.component";
import {  AnnouncementRouting } from './announcement/announcement.routing';
import { CreateComplaintsComponent } from './complaints/create/create-complaints.component';
import { ViewComplaintsComponent } from './complaints/view/view-complaints.component';
import { AddFeedbackComponent } from './feedback/add-feedback/add-feedback.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedBackRouting } from './feedback/feedback.routing';
import { ViewFeedbackComponent } from './feedback/view-feedback/view-feedback.component';
import { SupportDeskComponent } from './support-desk.component';
import { CreateTicketComponent } from './tickets/create/create.ticket.component';
import { ViewTicketComponent } from './tickets/view/view.ticket.component';

export const SupportDeskRouting = [
    // { path:'', component:CreateTicketComponent },
    { path: 'create-ticket', component: CreateTicketComponent },
    { path: 'view-ticket', component: ViewTicketComponent },
    { path: 'create-ticket', component: CreateTicketComponent},
    { path: 'add-feedback', component: AddFeedbackComponent},
    { path: 'feedback', component: FeedbackComponent},
    { path: 'view-feedback', component: ViewFeedbackComponent},
    { path: '', component: SupportDeskComponent},
    { path: 'create-complaint', component: CreateComplaintsComponent},
    { path: 'view-complaint', component: ViewComplaintsComponent},
    // {path:'announcement',children:AnnouncementRouting}
    { path: 'announcement', children: AnnouncementRouting},

];
