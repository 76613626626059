import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { MapService } from 'src/app/Services/map.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';

declare const Swal;

@Component({
  selector: 'app-edit-branch',
  templateUrl: './edit-branch.component.html',
  styleUrls: ['./edit-branch.component.css']
})
export class EditBranchComponent implements OnInit {
  public editBranchForm:FormGroup;
  public branchLocation:any;
  public latlong:any;
  public selectedValueClose = false;
  public locationSuggestions: any;
  public myMap: any = this.map.retMap();
  public searchInput: any = new Subject<string>();
  public destroy$: any = new Subject();
  public orgID;
  public branchID;
  public updateValues=[];
  public updateLocation:boolean=false;
  public branchInformation=[
    { "orgID": "60866279d774d4408635033b",
      "branchID":'12341234',
      "name": "velachery",
      "location": {
          "lat": 12.982389,
          "lng": 80.223183,
          "name": "Velachery, Chennai, Tamil Nadu, India"
      }
  }
  ];
  constructor(
    public api: ApiService,
    public swal: SweetAlert,
    public route: ActivatedRoute,
    public storage: StorageService,
    public router: Router,
    private map: MapService,) { 
      this.route.paramMap.subscribe(paramas => {
        this.orgID = paramas.get('orgID');
        this.branchID = paramas.get('branchID');
    });
  }

  ngOnInit(): void {
    this.editBranchLoad();
    //this.getBranchData();
    this.setValueEditBranchForm(this.branchInformation[0]);
    this.loadMap();

    this.myMap.mymap.onMapClick((e: any) => {
      this.locationSuggestions = [];
      this.getGeoCode({ key: 'mapclick', val: e.lngLat.lng + ',' + e.lngLat.lat });
    });
    this.searchInput.pipe(takeUntil(this.destroy$), debounceTime(500), distinctUntilChanged()).subscribe(async (value) => {
      if (value.val.target.value != '') {
        const val = { key: 'branchLocation', val: value.val.target.value };
        this.getGeoCode(val);
        console.log(val);
      } else if (value.val.target.value.trim() == '') {
        this.emptySuggestions();
      }
      console.log(value.val.target.value);
      console.log(value.val);
    });
  }
  editBranchLoad(){
    this.editBranchForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
    });
  }
  getBranchData(){
    const obj = {
        orgID: this.orgID,
        branchID: this.branchID
    };
    this.api.transportManagerBranchList(obj).then((res: any) => {
        if (res.status == 'success') {
            const data = res.data[0];
            this.setValueEditBranchForm(data);
        }  
    }).catch((err) => {
        console.log(err);
    });
  }
  setValueEditBranchForm(data){
    this.editBranchForm.patchValue({
      name: data.name,
      location: data.location,
    });
    this.selectedValue(data.location);
    this.close();
  }
  openLocation() {
    document.getElementById('branchLocationBtn').click();
  }
  loadMap(marker: any = []) {
    this.map.loadMap('pickuppoint', marker);
  }
  getGeoCode(value) {
    this.updateLocation=true;
    const key = value.key;
    if (key == 'branchLocation') {
      this.locationSuggestions[key] = [];
    }
    this.myMap.mymap.geocoder(value.val, (res) => {
      try {
        const result = res.features.map((i) => ({ center: i.center, name: i.place_name }));
        console.log('Loading GEO code ', key);
        if (key == 'mapclick') {
          this.locationSuggestions = {};
          //this.address = result[0];
          // this.vehicleloc = this.address.name;
          //this.vehicleLocation = this.address;
           this.selectedValueClose = true;
          this.branchLocation=result[0];
          console.log(result, result[0].name);

        } else {
          this.locationSuggestions[key] = result;
          console.log('Else Part', this.locationSuggestions);
        }
      } catch (er) { console.log(er); }
    });
  }
  selectedValue(value) {
    this.selectedValueClose = true;
    this.locationSuggestions = {};
    this.branchLocation = value;
  }
  emptySuggestions() {
    this.locationSuggestions = {};
    this.selectedValueClose = false;
    this.branchLocation = null;
    this.updateLocation=true;
  }
  close() {
    this.latlong = null;
    if (this.branchLocation != null && this.branchLocation.name) {
      this.editBranchForm.patchValue({
        location: this.branchLocation.name
      });
      this.latlong=this.branchLocation.name;
      if(this.updateLocation){
        this.fieldChange(this.branchLocation,'location','location');
      }
    } else {
      this.editBranchForm.patchValue({
        location: undefined,
      });
      this.latlong = null;
    }
  }
  /* checking error in fields*/
  public checkError = (controlName: string, errorName: string) => {
    return this.editBranchForm.controls[controlName].hasError(errorName);
  }
  /* update unique field*/
  fieldChange(e:any,type,field){
    if(type=='text' || type=='textarea'){
      this.updateField(field,e.target.value);
    }
    /* if(type=='date' || type=='dropdown'){
      this.updateField(field,e.value);
    } */
    if(type=='location'){
      this.updateField(field,e);
    }
  }
  updateField(field,value){
    if(this.updateValues.length>0){
      let filterUpdate=this.updateValues.filter((e)=>e.field!=field);
      let obj={field:field,value:value,};
      this.updateValues=[
        ...filterUpdate,
        obj,
      ]
    }
    else{
      let obj={field:field,value:value,};
      this.updateValues.push(obj);
    }
    console.log(this.updateValues);
  }
  /* Delete Data*/
  delete() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want be able to delete this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('delete click');
        this.deleteBranch();
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'success'
        );
      }
    });
  }
  deleteBranch() {
    const obj = {
      orgID: this.orgID,
      branchID: this.branchID
    };
    console.log(obj);
    this.api.transportManagerBranchDelete(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 20000);
        this.router.navigate(['/organization/transport-manager/information/branch/view-branch']);
      }
      if (res.status == 'failure') {
        this.swal.swalFunction('error', res.status, res.message, 20000);
      }
    });
  }
  /* Cancel Edit*/
  cancel(){
    this.router.navigate(['/organization/transport-manager/information/branch/view-branch']);
  }
  /* Submit Data*/
  onSubmit(){
    if(this.updateValues && this.updateValues.length>0){
      const obj = {
        orgID: this.storage.get('userInfo').orgID,
        branchID:this.branchID,
      };
      this.updateValues.filter((e)=>{
            if(e.field=='location'){
              obj[e.field] = {
                lat: e.value.center[1],
                lng: e.value.center[0],
                name: e.value.name,
              };
            }
            else{
              obj[e.field]=e.value;
            }
      });
      console.log(obj);
      this.api.transportManagerBranchUpdate(obj).then((res: any) => {
        if (res.status == 'success') {
          this.swal.swalFunction(res.status, res.status, res.message, 2000);
          this.router.navigate(['/organization/transport-manager/information/branch/view-branch'])
        }
        if (res.status == 'failure') {
          this.swal.swalFunction('error', res.status, res.message, 3000);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}