import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'g10-ms-icon',
  templateUrl: './icon.component.html',
  styles: [``]
})
export class IconComponent implements OnInit {

  @Input() icon: any;
  @Input() mat: boolean;
  @Output() click: EventEmitter<string> = new EventEmitter<string>();



  constructor() { }

  ngOnInit() {

  }

  onIconClick() {
    this.click.emit('You have clicked on a icon.');
  }

}
