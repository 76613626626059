import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { driverModule } from '../../../../../../modules/information/driver';
declare const Swal;

@Component({
  selector: 'app-viewemployees',
  templateUrl: './viewemployees.component.html',
  styleUrls: ['./viewemployees.component.css']
})
export class ViewemployeesComponent implements OnInit {
  constructor(
    public api: ApiService,
    public router: Router,
    public storage: StorageService,
    public location: Location,
    public swal: SweetAlert,
    public route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe(params => {
      this.orgID = params.get('orgID');
    });
    this.userData = this.storage.get('vendorViewData');
  }

  public driverInfo: any = driverModule;
  userData: any;
  employeeInformation: any;
  orgID: string;
  vendorID: string;
  public values: any = [];
  public editForm: boolean;
  public cols = [
    { key: 'name', display: 'Driver Name' },
    { key: 'bloodGroup', display: 'Blood-Group' },
    { key: 'mobile', display: 'Mobile' },
    {
      key: 'dob', display: 'Date Of Birth', config: {
        isDate: true,
        format: 'dd MMM YYYY'
      }
    },
    { key: 'city', display: 'City' },
    { key: 'state', display: 'State' },
    // { key: "city", display: "City" },
    {
      key: 'action',
      display: 'Action',
      config: {
        isAction: true,
        actions: [
          { display: 'view', color: 'primary', icon: 'View' },
          { display: 'delete', color: 'warn', icon: 'Delete' },
          { display: 'update', color: 'primary', icon: 'EditContact' }
        ]
      }
    }
  ];
  ngOnInit() {
    this.getEmployeeList();
  }


  submitted(e: any) {
    console.log(e);
  }
  getEmployeeList() {
    const obj = {
      orgID: this.storage.get('userInfo').orgID
    };
    this.api.transportManagerEmployeeList(obj).then((res: any) => {
      if (res.status == 'success') {
        this.employeeInformation = res.data;
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  onActionHandler(e: any) {
    const data = e.data;
    if (e.action.display == 'update') {
      this.router.navigate(['/organization/transport-manager/information/employee/edit-empolyee', { orgID: e.data.orgID, employeeID: e.data.employeeID }]);

    } else if (e.action.display.toLowerCase() == 'delete') {
      console.log(e);
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want be able to delete this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('delete click');
          this.deleteEmployeeData(e);
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          );
        }
      });
    } else if (e.action.display.toLowerCase() == 'view') {
      this.router.navigate(['/organization/transport-manager/information/employee/single-view-empolyee', { orgID: e.data.orgID, employeeID: e.data.employeeID }]);
    }
  }
  deleteEmployeeData(e?) {

    const obj = {
      orgID: e.data.orgID,
      employeeID: e.data.employeeID,
    };
    this.api.transportManagerEmployeeDelete(obj).then((res: any) => {
      if (res.status == 'success') {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
        this.getEmployeeList();
      } else {
        this.swal.swalFunction(res.status, res.status, res.message, 2000);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  searchValue(e) {
    console.log(e);
  }
  // getPage(v, v2) { }
}
