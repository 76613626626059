export const CreatePrimaryAdminModel = [
    {
        type: 'text',
        name: 'name',
        label: 'Name',
        value: '',
        required: true,
      },

      {
        type: 'text',
        name: 'userEmail',
        label: 'User Email',
        textType: 'email',
        value: '',
        required: true,
      } , {
        type: 'text',
        name: 'designation',
        label: 'Designation',
        value: '',
        required: true,
      }, {
        type: 'number',
        name: 'contactNumber',
        textType: 'mobile',
        label: 'Contact Number',
        value: '',
        required: true,
      },
      // {
      //   type: 'button',
      //   displayText:'save',
      //   color:'primary'
      // }
];
