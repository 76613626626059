import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'support-desk',
  templateUrl: './support-desk.component.html',
  styleUrls: ['./support-desk.component.css']
})
export class SupportDeskComponent implements OnInit {
  // public searchInput: any = new Subject<string>();
//  public supportDeskComponentsName = []
  constructor(
    public router: Router
  ) { }
  componentsMenu: any = [];
  searchedValue: any;

  // supportDeskComponents(data){
  // this.supportDeskComponentsName.forEach(e => {
  //   this.componentsMenu.push(e.name)
  //  });
  //  console.log(this.componentsMenu)

  //  if(this.componentsMenu.includes(data.name)){
  //    let name = data.name.toLowerCase()
  //    console.log([`/organization/transport-manager/support-desk/view-`+ `${name}`])

  //   this.router.navigate([`/organization/transport-manager/support-desk/view-`+`${name}`])
  //  }
  // }

  public supportDeskComponentsName = [
    {
      name: 'Announcement',
      img: 'annoncement.png',
      path: '/organization/transport-manager/support-desk/announcement/add-announcement'
    },
    {
      name: 'Complaint',
      img: 'compliant.png',
      path: '/organization/transport-manager/support-desk/view-complaint'
    }, {
      name: 'Feedback',
      img: 'feedback.png',
      path: '/organization/transport-manager/support-desk/view-feedback'
    }, {
      name: 'Ticket',
      img: 'ticket.png',
      path: '/organization/transport-manager/support-desk/view-ticket'
    },
  ];

  ngOnInit(): void {
  }
}
