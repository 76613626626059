// import { AnnouncementComponent } from "./announcement/announcement.component";
import { CreateComplaintsComponent } from './complaints/create/create-complaints.component';
import { EditComplaintComponent } from './complaints/edit-complaint.component';
import { ViewComplaintsComponent } from './complaints/view/view-complaints.component';
import { AddFeedbackComponent } from './feedback/add-feedback/add-feedback.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedBackDeclaration } from './feedback/feedback.declaration';
import { ViewFeedbackComponent } from './feedback/view-feedback/view-feedback.component';
import { CreateTicketComponent } from './tickets/create/create.ticket.component';
import { EditTicketComponent } from './tickets/edit.ticket.component';
import { ViewTicketComponent } from './tickets/view/view.ticket.component';

import { AnnouncementDeclaration } from './announcement/announcement.declaration';
import { SupportDeskComponent } from './support-desk.component';

export const SupportDeclarations = [
    AnnouncementDeclaration,

    CreateTicketComponent,
    ViewTicketComponent,
     EditTicketComponent,

    FeedbackComponent,
    AddFeedbackComponent,
    ViewFeedbackComponent,

    // AnnouncementComponent,
     ViewComplaintsComponent,
    CreateComplaintsComponent,
     EditComplaintComponent,

     SupportDeskComponent
    //  FeedBackDeclaration

];
