export const employeDetailsModule = [
    // {
    //         type: 'checkbox',
    //         name: 'language',
    //         label: 'Language',
    //         value: '',
    //         options: [
    //           { value:"tamil",name:"Tamil",selected:true},
    //           { value:"english",name:"English"}
    //         ],
    //         required: true,
    //       },

    //     {
    //         col: "col-md-6"
    // lable: "Lable"
    // multiselect: "true"
    // name: "Lable"
    // option: (3)[{ … }, { … }, { … }]
    // options: ""
    // required: true
    // type: "dropdown"
    //     },
    {
        type: 'text',
        name: 'name',
        label: 'Name',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'empRefID',
        label: 'Employee-ID',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'email',
        label: 'Email ID',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'date',
        name: 'dob',
        label: 'date Of Birth',
        minDate: 'today',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'dropdown',
        name: 'gender',
        label: 'Gender',
        value: '',
        required: true,
        options: [
            { value: '', name: 'Select' },
            { value: 'Male', name: 'Male' },
            { value: 'Female', name: 'Female' },
        ],
        col: 'col-md-4',

    },
    {
        type: 'number',
        name: 'mobile',
        label: 'Mobile Number',
        value: '',
        required: true,
        col: 'col-md-4',
        textType: 'mobile'
    },

    {
        type: 'number',
        name: 'emergencyContact',
        label: 'Emergency Contact Number',
        value: '',
        required: true,
        col: 'col-md-4',
        textType: 'mobile'
    },
    {
        type: 'text',
        name: 'bloodGroup',
        label: 'Blood-Group',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'text',
        name: 'address',
        label: 'Address',
        value: '',
        required: true,
        col: 'col-md-4',
        multiline: true
    },
    {
        type: 'text',
        name: 'city',
        label: 'City',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'dropdown',
        name: 'state',
        label: 'State',
        value: '',
        required: true,
        col: 'col-md-4',
        options: [
            { value: 'TN', name: 'TN' },
            { value: 'Kerala', name: 'Kerala' },
            { value: 'Bangalore', name: 'Bangalore' },
            { value: 'Mumbai', name: 'Mumbai' }
        ]
    },

    {
        type: 'text',
        name: 'country',
        label: 'Country',
        value: '',
        required: true,
        col: 'col-md-4'
    },

    // {
    //     type: 'text',
    //     name: 'Email',
    //     label: 'Email Id',
    //     value: '',
    //     required: true,
    //     col: 'col-md-4'
    // },
    {
        type: 'text',
        name: 'pincode',
        label: 'Pincode',
        value: '',
        required: true,
        col: 'col-md-4'
    },
    {
        type: 'dropdown',
        name: 'transportType',
        label: 'Transport Type',
        value: '',
        required: true,
        col: 'col-md-4',
        options: [
            { value: 'company', name: 'Company vehicle' },
            { value: 'others', name: 'Others' },

        ]
    },
    {
        type: 'text',
        name: 'workLocation',
        label: 'Work-Location',
        value: '',
        required: true,
        col: 'col-md-4',

    },
    {
        type: 'dropdown',
        name: 'branch',
        label: 'Branch',
        value: '',
        required: true,
        col: 'col-md-4',
        options: []
    },
    {
        type: 'dropdown',
        name: 'shift',
        label: 'Shift',
        value: '',
        required: true,
        col: 'col-md-4',
        options: []
    },
    {
        type: 'text',
        name: 'location',
        label: 'Location',
        value: '',
        col: 'col-md-4',
        click: (e) => {
            const x = document.getElementById('employeeLocationId');
            x.click();
        },
    },
    {
        type: 'file',
        name: 'profile',
        label: 'Profile',
        value: '',
        col: 'col-md-12',
    },

];
