import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VehicleModule } from '../../../../../../modules/information/vehicle';

@Component({
    selector: 'add-test',
    templateUrl: './add-test.component.html',
    styles: ['./add-test.component.css']
})


export class AddTestComponent implements OnInit {
    @Output() routeData = new EventEmitter();
  @Output() currentState = new EventEmitter();
  public routeForm = this.fb.group({
    routeName: ['', [Validators.required]],
    routeShift: ['', [Validators.required]]
  });

  constructor(private fb: FormBuilder) { }

  ngOnInit() {

  }
  submitForm() {
    if (this.routeForm.valid) {
      this.routeData.emit(this.routeForm.value);
      this.currentState.emit('viewRoutePage');
      this.routeForm.reset();
    }
  }
   /*  public VehicleInfo = VehicleModule
    constructor(){}
    ngOnInit(){
       console.log(this.VehicleInfo)
    }


    submitted(e:any){
         console.log(e)
    }
 */
}
