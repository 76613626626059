import { Component, OnInit } from '@angular/core';
import { StarRatingColor } from 'src/app/Directives/star-rating/star-ratting.component';
import { FeedBackModel } from 'src/app/modules/feedback.model';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
  fields: any = FeedBackModel;
  rating = 3;
  starCount = 5;
  starColor: StarRatingColor = StarRatingColor.accent;
  constructor() { }

  ngOnInit(): void {
  }
  onRatingChanged(rating) {
    console.log(rating);
    this.rating = rating;
  }
  submitted(e: any) {

  }
}
