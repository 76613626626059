import { InformationComponent } from './information/information.component';
// import { LoginComponent } from "./login/login.component";
// import { DashboardComponent } from "./dashboard/dashboard.component";
// import { ReportsComponent } from "./reports/reports.component";
// import { RequestsComponent } from "./requests/requests.component";
// import { RoasteringComponent } from "./roastering/roastering.component";
// import { RouteTripMangementComponent } from "./route-trip-mangement/route-trip-mangement.component";
// import { SettingsComponent } from "./settings/settings.component";
// import { SosPanelComponent } from "./sos-panel/sos-panel.component";
// import { TransportManagerLoginComponent } from "./transport-manager-login/transport-manager-login.component";

import { DashboardComponent } from './dashboard/dashboard.component';
import { InformationDeclaration } from './information/information.declaration';
import { LoginComponent } from './login/login.component';
// import { ReportsComponent } from "./reports/reports.component";
// import { SosReportComponent } from "./reports/sos-report/sos-report.component";
import { RequestsComponent } from './requests/requests.component';
import { RoasteringComponent } from './roastering/roastering.component';
import { RouteTripMangementComponent } from './route-trip-mangement/route-trip-mangement.component';
import { SettingsComponent } from './settings/settings.component';
import { SosPanelComponent } from './sos-panel/sos-panel.component';
import { SupportDeclarations } from './support-desk/suportdesk.page.declaration';
// import { SupportDeskComponent } from "./support-desk/support-desk.component";
import { MomentReportsComponent } from './reports/moment-reports/moment-reports.component';
import { HaltReportsComponent } from './reports/halt-reports/halt-reports.component';
import { OverspeedReportsComponent } from './reports/over-speed-reports/over-speed-reports.component';
// import { MomentReportsComponent } from "./reports/moment-reports/moment-reports.component";
// import { HaltReportsComponent } from "./reports/halt-reports/halt-reports.component";
import { RequstDeclaration } from './requests/request.declaration';
import { ReportsDeclaration } from './reports/reports.declaration';
import { LiveVehicleTrackingComponent } from './live-tracking/live.tracking.component';
import { ListviewsComponent } from './listview/listview.component';
import { RoasteringDeclaration } from './roastering/roastering.declaration';
import { ProfileComponent } from './profile/profile.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { ResetPasseordComponent } from './login/reset-passeord/reset-passeord.component';
import { RosteringDeclaration } from './rostering/rostering.declaration';
import { RosteringComponent } from './rostering/rostering.component';
import { RouteInfoComponent } from './dashboard/route-info/route-info.component';


export const TransportMangerAdminPage = [
    LoginComponent,
    ProfileComponent,
    ForgotPasswordComponent,
    ResetPasseordComponent,
    // ReportsComponent,
    ReportsDeclaration,
    RequestsComponent,
    RoasteringComponent,
    RoasteringDeclaration,
    SosPanelComponent,
    DashboardComponent,
    RouteInfoComponent,
    RouteTripMangementComponent,
    SettingsComponent,
    SupportDeclarations,
    InformationDeclaration,
    // SosReportComponent,
    MomentReportsComponent,
    HaltReportsComponent,
    // SupportDeskComponent,
    InformationComponent,
    OverspeedReportsComponent,
    RequstDeclaration,
    LiveVehicleTrackingComponent,

    ListviewsComponent,
    RosteringComponent,
    RosteringDeclaration,
];
