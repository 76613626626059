import { Component } from '@angular/core';

@Component({
    selector: 'app-no-data',
    template: `
    <mat-card>
    <h4 class="text-danger text-center ">
   <img src="../../../assets/images/cancel.png" width="70" class="img-fluid">
     No Data Found</h4>
    </mat-card>
    `
})

export class NoDataComponents {
    constructor() {

    }
}
