import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';

@Component({
    selector: 'app-single-view-driver',
    templateUrl: './singleDriver.html'

})
export class SingleViewDriverComponent {
    driverData: any;
    orgID: string;
    vendorID: string;
    driverID: string;
    constructor(
        public storage: StorageService,
        public api: ApiService,
        public router: Router,
        public route: ActivatedRoute,


    ) {
        this.route.paramMap.subscribe(paramas => {
            console.log(paramas.get('orgID'), paramas.get('vendorID'), paramas.get('driverID'));
            this.orgID = paramas.get('orgID');
            this.vendorID = paramas.get('vendorID');
            this.driverID = paramas.get('driverID');
        });

    }

    ngOnInit() {
this.getDriverData();
    }
    getDriverData() {
        const obj = {
            orgID : this.orgID,
            vendorID: this.vendorID,
            driverID: this.driverID
        };
        this.api.transportManagerDriverList(obj).then((res: any) => {
            if (res.status == 'success') {
                this.driverData = res.data[0];
            }
        }).catch((err) => {
            console.log(err);
        });
    }
}
