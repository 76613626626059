import { Component, OnInit, ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { VehicleModule } from '../../../../../../modules/information/vehicle';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { ApiService } from 'src/app/Services/api.service';
import { StorageService } from 'src/app/Services/storage.service';
import { RouteService } from 'src/app/Services/route.service';
import { MapService } from 'src/app/Services/map.service';
import { OrgVehicleReducer } from './../../../../../../store/orgVehicle/reducer';
import { FormBuilder, FormGroup, Validators, FormArray, SelectControlValueAccessor } from '@angular/forms';
import employee from './employee.json';
import cab from './cab.json';
import { templateJitUrl } from '@angular/compiler';
import { AnyCnameRecord } from 'dns';
declare const turf;
@Component({
    selector: 'view-test',
    templateUrl: './view-test.component.html',
    styleUrls: ['./view-test.component.css']
})




export class ViewTestComponent {
    public VehicleInfo = VehicleModule;
    orgID: any;
    osrmUrl = 'http://localmap.g10.pw:5000/route/v1/driving/';
    osrmUrlloc = 'http://localmap.g10.pw:5000/nearest/v1/driving/';
    shiftTime = 'Test' ;      // First,Second,Third
  distForclust = 500; // dist 500 in meters
  route_opt: any = 1;
  route_obj: any;
  routes_t = [];
  routes = {};
  final_route = {};
  count_r = 0;
  mapData = [];
  local_arr = [];
  local_obj = {};
  orgveh: any;
    constructor(
      public api: ApiService,
      public storage: StorageService,
      public route: RouteService,
      public map: MapService,
      public orgvehicles: OrgVehicleReducer

    ) {}
    ngOnInit() {
      this.getroute();

      this.loadMap();
      const orgInfo = this.storage.get('userInfo');
      this.getVehicleDetails(orgInfo.orgID);
      setTimeout(() => {
        this.orgveh = this.orgvehicles.get();
        console.log('orgveh', this.orgveh);
      }, 5000);

    }

    getVehicleDetails(orgID: any) {
        const obj = { orgID};
        this.api.transportManagerVehicleList(obj).then((res: any) => {
            const orgVeh: any = {};
            orgVeh[orgID] = res.data;
            console.log('getvehicle', orgVeh);
            this.orgvehicles.addVehicleInfo(orgVeh);
        });
    }

    shortPickuppoints(cab: any, pickpts: any) {
      return new Promise(async (resolve, reject) => {
      //   let test_pick ={},cab1={};
      //   cab1 ={"lat": 12.987001, "lng": 80.192275};
      //   test_pick =  {
      //     "0":{"lat": 12.983547, "lng": 80.200915},
      //   "1":{"lat": 12.997612,"lng": 80.200915},
      //   "2":{"lat": 12.99999,"lng": 80.21242 },
      //   "3":{lat: 12.978928, lng: 80.189763},
      //   "4":{"lat": 12.973212, "lng": 80.197633},
      //   "5":{"lat": 12.976561, "lng": 80.226789},
      // };
      //   let obj = {startLatLng:cab1, pickupPoints:test_pick};
        const obj = {startLatLng: cab, pickupPoints: pickpts};
        console.log('cab_pick', obj);
        this.api.GetNearestLocation(obj).then((res: any) => {
          console.log('res from api', res);
          resolve(res);
        }).catch((resCatch: any) => {
          // console.log("catch status", resCatch)
        });
    });
    }


    loadMap(marker: any = []) {
      this.map.loadMap('TestmapRoastering', marker);
      // setTimeout(()=>{
      //   // this.plotmarker()
      // },1000)
    }
    async getroute() {
      const obj = {
        orgID: this.orgID
      };
      console.log('cal cab-empfun', this.shiftTime);
      this.route_obj = await this.route.makeRoute(employee, cab, this.route_opt, this.shiftTime, 0);
      console.log('route_obj', this.route_obj);
      let empPickpts: any, tempKeys: any;
      empPickpts = await this.separatePickpts(this.route_obj);
      console.log('emppick', empPickpts);

      const routeCluspts = await this.createClusPts(this.route_obj, empPickpts);  // ->make routes with cluster points
    // console.log("clus",routeCluspts)
      const finalRoute = await this.makeshortRoutePts(routeCluspts);
      console.log('finalroute', finalRoute);

      const mapRoute = await this.getCabEmpdetails(finalRoute);
      console.log('map finalroute', mapRoute);

      await this.plotonMap(mapRoute);


    // -> make the route locations with employee and cab details

    /*

         */
        }

        colorGen(length) {
          return Array(length).fill(0).map(x => '#' + Math.floor(Math.random() * 16777215).toString(16));
        }

        createClusPts(routes, temp_arr) {
          return new Promise(async (resolve, reject) => {
          console.log('temp_arr', temp_arr);
          let count:any, j:any, k:any, res:any, res1:any, url:any, clust:boolean = false, temp_arr1:any[] = [], temp_arr2:any[] = [], local_obj:any = {}, value:any;
          for (let i = 0; i < temp_arr.length; i++) {
            j = 0, k = 1, count = 0;
            if (temp_arr[i].length == 1) { temp_arr2[count++] = (temp_arr[i][j]); } else {
              while (1) {
                value = 0;
                console.log(temp_arr[i][j].lng, temp_arr[i][j].lat, temp_arr[i][k].lng, temp_arr[i][k].lat);
                url = this.osrmUrl + temp_arr[i][j].lng + ',' + temp_arr[i][j].lat + ';' + temp_arr[i][k].lng + ',' + temp_arr[i][k].lat + '?steps=true';
                res = await this.fetchUrl(url);
                url = this.osrmUrl + temp_arr[i][k].lng + ',' + temp_arr[i][k].lat + ';' + temp_arr[i][j].lng + ',' + temp_arr[i][j].lat + '?steps=true';
                res1 = await this.fetchUrl(url);
                value = Math.min(res.routes[0].distance, res1.routes[0].distance);
                  // console.log(res1.routes[0].distance,temp_arr[i][k].lat,temp_arr[i][k].lng, temp_arr[i][j].lat , temp_arr[i][j].lng)

                  // console.log(res.routes[0].distance, temp_arr[i][j].lat , temp_arr[i][j].lng,temp_arr[i][k].lat,temp_arr[i][k].lng)


                if (value < this.distForclust) {
                      if (!temp_arr1.some(ele => ele.lat === temp_arr[i][j].lat && ele.lng === temp_arr[i][j].lng)) {
                          temp_arr1.push(temp_arr[i][j]);
                          temp_arr1.push(temp_arr[i][k]);
                      } else {
                          temp_arr1.push(temp_arr[i][k]);
                      }
                      clust = true;
                      k++;
                      if (temp_arr[i][k] == undefined) {
                          const midPt = await this.findMidpt(temp_arr1);
                          temp_arr2[count] = midPt;
                          if (temp_arr2[count] == undefined) { temp_arr2[count] = {}; }
                          temp_arr2[count++].clusPts = temp_arr1;
                          clust = false;
                          temp_arr1 = [];
                      }

                  } else {
                      if (clust) {
                          const midPt = await this.findMidpt(temp_arr1);
                          temp_arr2[count] = midPt;
                          if (temp_arr2[count] == undefined) { temp_arr2[count] = {}; }
                          temp_arr2[count++].clusPts = temp_arr1;
                          clust = false;
                          temp_arr1 = [];
                      } else {
                        // console.log("arrr",temp_arr[i][j],i,j)
                          temp_arr2[count++] = (temp_arr[i][j]);
                      }
                      j = k;
                      k++;
                      if (temp_arr[i][k] == undefined) {
                        // console.log("arrr-end",temp_arr[i][j] , i ,j)
                        temp_arr2[count++] = (temp_arr[i][j]);
                      }
                  }
                if (temp_arr[i].length == k) { break; }
              }
            }
            temp_arr2['cabPt'] = routes[i + 1].cab;
            local_obj['route' + (i + 1)] = temp_arr2;
                // local_obj["route" + (i + 1)] = routes[i + 1].cab
            temp_arr1 = [], temp_arr2  = [];
            // console.log("local", local_obj)
        }
          console.log('final arr', local_obj);
          resolve(local_obj);  // returning modified group route(cluster pickuppoints)
        });
        }

        makeshortRoutePts(tobj) {
          return new Promise(async (resolve, reject) => {
          let j:any, tempkeys:any[] = [], tempkeys1:any[] = [], temp_obj1:any = {}, temp_obj:any = {}, local_obj:any = {}, k:number = 0, res:any;
          tempkeys = Object.keys(tobj);
          for (let i = 0; i < tempkeys.length; i++) {
                    k = 0;
                    tempkeys1 = Object.keys(tobj[tempkeys[i]]);
                    temp_obj[k++] = tobj[tempkeys[i]].cabPt;
                    for (j = 0; j < (tobj[tempkeys[i]].length); j++) {
                        temp_obj[k++] = tobj[tempkeys[i]][tempkeys1[j]];
                    }
                    res = await this.shortPickuppoints(tobj[tempkeys[i]].cabPt, temp_obj);
                    // console.log("res and temp",res,temp_obj)
                    // console.log("resss", res.data.path.length)
                    j = 0;
                    temp_obj1.cabPt = tobj[tempkeys[i]].cabPt;
                    for (let m = 1; m < (res.data.path.length - 1); m++) {
                      temp_obj1[j++] = temp_obj[res.data.path[m]];
                    }
                    // console.log(temp_obj1);
                    local_obj['route-' + i] = temp_obj1;
                    temp_obj = {};
                    temp_obj1 = {};

               }
          console.log('makeshortRoutePts', local_obj);
          resolve(local_obj);      // returning only emp location(lat: and lng:) details
          });

        }
        separatePickpts(tobj) {
          return new Promise(async (resolve, reject) => {
          let temp = [], temp_arr = [], tempkeys = Object.keys(tobj);
          for (let i = 0; i < tempkeys.length; i++) {
              let count = 0;
              for (let j = 0; j < tobj[tempkeys[i]].emp.length; j++) {
                  temp[count++] = tobj[tempkeys[i]].emp[j];
              }
              temp_arr[i] = temp;
              temp = [];
            }
            // console.log("separatepickpts",temp_arr)
          resolve(temp_arr);      // returning only emp location(lat: and lng:) details
          });

        }
        findMidpt(temp_arr1) {
          return new Promise(async (resolve, reject) => {
              let features, res, findpt, url, retObj = {
                  lat: null,
                  lng: null
              };
              const temp_arr3 = temp_arr1.map(function(ele) {
                  const tarr = [];
                  tarr.push(ele.lat);
                  tarr.push(ele.lng);
                  return tarr;
              });
              features = turf.points(temp_arr3);
              // ([ [13.004125, 80.204540],[13.000182, 80.202950],[12.992344, 80.197290], [12.985139, 80.193051], [12.987933, 80.223452],
              //     [12.986422, 80.22453],[12.995344, 80.21453],[12.989011, 80.21453],[12.976561, 80.226789]]);
              findpt = turf.center(features);
              url = this.osrmUrlloc + findpt.geometry.coordinates[1] + ',' + findpt.geometry.coordinates[0];
              res = await this.fetchUrl(url);
              if (res.code == 'Ok') {
                  retObj.lat = res.waypoints[0].location[1];
                  retObj.lng = res.waypoints[0].location[0];
                  resolve(retObj);
              }
          });
      }


      fetchUrl(url) {
          return new Promise(async (resolve, rejects) => {
              // console.log("in finddist")
              fetch(url)
                  .then((response) => {
                      return response.json();
                  })
                  .then((myJson) => {
                      // console.log("hi", myJson);
                      resolve(myJson);
                  });
          });

      }

      getCabEmpdetails(tobj) {
        return new Promise(async (resolve, reject) => {
          const tempkeys = Object.keys(tobj);

          for (let i = 0; i < tempkeys.length; i++) {
            for (let j = 0; j < cab.cars.length; j++) {
              if (tobj[tempkeys[i]].cabPt == cab.cars[i].Location) {
                tobj[tempkeys[i]].cabPt = cab.cars[i];
                // console.log("seconds if",tobj[tempkeys[i]].cabPt,cab.cars[i].Location,cab.cars.length);
                break; }
            }
          }
          // console.log("keys sec", tempkeys)
          for (let i = 0; i < tempkeys.length; i++) {
          const tempkeys1 = Object.keys(tobj[tempkeys[i]]);
          // console.log("sec tempkeys 1", tempkeys1,tempkeys1.length)
          for (let j = 0; j < tempkeys1.length - 1 ; j++) {
                if (tobj[tempkeys[i]][tempkeys1[j]].clusPts) {
                  for (let k = 0; k < tobj[tempkeys[i]][tempkeys1[j]].clusPts.length; k++) {
                    for (let m = 0; m < employee.length; m++) {
                      if (tobj[tempkeys[i]][tempkeys1[j]].clusPts[k] == employee[m].Location) {tobj[tempkeys[i]][tempkeys1[j]].clusPts[k] = employee[m];
                                                                                               break; }
                    }
                  }
                } else {
                  for (let k = 0; k < employee.length; k++) {
                    if (tobj[tempkeys[i]][tempkeys1[j]] == employee[k].Location) {tobj[tempkeys[i]][tempkeys1[j]] = employee[k];
                                                                                  break; }
                  }
                  // console.log("ifff",tobj[tempkeys[i]][tempkeys1[j]])
                }
               }
              }
          console.log('final_route_details', tobj);
          resolve(tobj);
        });
      }

      plotonMap(tobj) {
        return new Promise(async (resolve, reject) => {

          let local_arr:any[] = [], local_arr1:any, temp_obj1:any = {}, temp_obj:any = {}, temp_arr:any[] = [], count_1:number = 0, count:number = 0, tempkeys:any[] = [], colorArr:any[] = [];
          tempkeys = Object.keys(tobj);
          colorArr = this.colorGen(Object.keys(tobj).length);
          for (let i = 0; i < tempkeys.length; i++) {
              temp_arr = [], count_1 = 0, count = 0;
              const element = tempkeys[i];
              temp_arr[count_1++] = tobj[element].cabPt.Location.lat;
              temp_arr[count_1++] = tobj[element].cabPt.Location.lng;
              local_arr[count++] = temp_arr;
              // console.log("cab obj", local_arr)
              temp_obj.id = tobj[element].cabPt.cabRegNo;
              const tempkeys1 = Object.keys(tobj[element]);
              // console.log("tempkeys1",tempkeys1)
              for (let j = 0; j < tempkeys1.length - 1; j++) {
                  const element1 = tempkeys1[j];
                  temp_arr = [], temp_obj1 = {}, count_1 = 0;
                  if (tobj[element][element1].clusPts) {
                    temp_arr[count_1++] = tobj[element][element1].lat;
                    temp_arr[count_1++] = tobj[element][element1].lng;
                    temp_obj1.title = 'cluster';
                    temp_obj1.plotMarker = '<div class="mumb_marker_pin" style="background: black; width: 10px;"></div>';
                    // test_obj.title = "cluster"
                    temp_arr[count_1++] = temp_obj1;
                    local_arr[count++] = temp_arr;
                  } else {
                    temp_arr[count_1++] = tobj[element][element1].Location.lat;
                    temp_arr[count_1++] = tobj[element][element1].Location.lng;
                    temp_obj1.title = tobj[element][element1].Empid;
                    temp_obj1.plotMarker = '📌';
                    temp_obj1.plotMarker = '<div class="mumb_marker_pulse" style="background: black; width: 10px;"></div>';
                    temp_arr[count_1++] = temp_obj1;
                    local_arr[count++] = temp_arr;
                  }

              }
              temp_arr = [], count_1 = 0;
              // temp_arr[count_1++] = tobj[element].cabPt.Location.lat
              // temp_arr[count_1++] = tobj[element].cabPt.Location.lng
              // local_arr[count++] = temp_arr;
                  // console.log("localarr",local_arr)
              count = 0;
              local_arr1 = await this.waypoints(local_arr);
              temp_obj.locations = local_arr1;

                // temp_obj["dash"] = 5;
                // temp_obj["bearingMarker"] = '<div class="mumb_marker_pulse"  style="background: black; width: 10px;"></div>';

              temp_obj.color = colorArr[i];
              temp_obj.width = 3;
              temp_obj.lineBearing = 1;
                // temp_obj["dash"] = 2;
                // temp_obj["animation"] = true;
              temp_obj.startMarker = '<div class="mumb_marker_pin" style="background: green;"></div><div class="mumb_marker_pulse" style="background: green;"></div>';
              temp_obj.bearingFocus = true;
                // temp_obj["plotMarker"] = '<div class="mumb_marker_pulse" style="background: black; width: 10px;"></div>';
              temp_obj.endMarker = '<div class="mumb_marker_pin" style="background: red;"></div><div class="mumb_marker_pulse" style="background: red;"></div>';
                // console.log("map obj",temp_obj)
              this.map.flatLine('TestmapRoastering', temp_obj);

                // this.mapData.push(temp_obj);
              local_arr = [];
              temp_obj = {};
          }

          // console.log("mapdata", this.mapData);
          resolve('ok');
        });
      }


      waypoints(tobj) {
        return new Promise(async (resolve, reject) => {
          let j, k, url, res, res1, value, temp_arr:any[] = [], temp_arr1:any[] = [], temp_arr2:any[] = [], temp_obj:any = {};
          console.log('tobj', tobj);
          for (let i = 0; i < tobj.length; i++) {
            temp_arr1.push(tobj[i]);
            j = i + 1;
            if (tobj[j] == undefined) { break; }
            /// -> we cant compare res of two lat lng bcoz direction must be start frm first latlng////
            url = this.osrmUrl + tobj[i][1] + ',' + tobj[i][0] + ';' + tobj[j][1] + ',' + tobj[j][0] + '?steps=true&geometries=geojson';
            res = await this.fetchUrl(url);
            const lenCoor = res.routes[0].geometry.coordinates.length;
            for (let j = 0; j < lenCoor; j++) {
                temp_arr[0] = res.routes[0].geometry.coordinates[j][1];
                temp_arr[1] = res.routes[0].geometry.coordinates[j][0];
              // temp_obj['plotMarker'] =' <div class="mumb_marker_pulse"  style="background: black; width: 5px;"></div>';
                k = j + 1;
                if (lenCoor != k) {
                temp_arr2[0] = res.routes[0].geometry.coordinates[k][1];
                temp_arr2[1] = res.routes[0].geometry.coordinates[k][0];
                // let angle = await this.findAngle(temp_arr[0],temp_arr[1],temp_arr2[0],temp_arr2[1])
                // temp_obj['plotMarker'] = await this.markerRotate(angle);
                // temp_obj['bearingMarker'] = await this.markerRotate(angle);
                // temp_obj['plotMarker'] = '<div class="mumb_marker_pulse" style="background: black; width: 10px;"></div>'
                temp_obj.drawArrow = {colorClass : 'secondary'};


              }
                temp_arr[2] = temp_obj;
                temp_arr1.push(temp_arr);
                temp_arr = [];
                temp_obj = {};
            }
            // temp_arr1.push(tobj[j])
          }
          console.log('res-waypoints', temp_arr1);
          resolve(temp_arr1);
        });
      }

      findAngle(φ1, λ1, φ2, λ2) {
        return new Promise(async (resolve, reject) => {

            const y = Math.sin(λ2 - λ1) * Math.cos(φ2);
            const x = Math.cos(φ1) * Math.sin(φ2) -
                Math.sin(φ1) * Math.cos(φ2) * Math.cos(λ2 - λ1);
            const θ = Math.atan2(y, x);
            const bearing = (θ * 180 / Math.PI + 360) % 360;
            // console.log("res-angle", bearing)
            resolve(bearing);
        });
      }

      markerRotate(degVal: any) {
        return new Promise(async (resolve, reject) => {
          degVal -= 90;
          const degval = Math.round(degVal);
          resolve('<div style="transform:rotate(' + degval + 'deg); transform-origin: 0% 0%;">➤</div>');
        });
      }

    // todo = [
    //   {title:'Cab clubbing issue', description:"On daily basis, we are having issues with the cab routing .. its being clubbed.....it take around 2-3 hours to reach home ... If cab routing is fine enough we can reach with in 1 hours max", status:'Urgent', assignedDate:'12/10/2020'},
    //   {title:'Driver being rude and shrewd', description:"Easy cabs is very unprofessional and unethical working with shrewd people like this driver for instance and its too expensive.", status:'Urgent', assignedDate:'12/10/2020'},
    //   {title:'Non arrival of cab', description:" Cab driver did not come . Neither he picked up phone despite several tries . Upon contacting booking Centre again they simply say sorry can't arrange cab .", status:'Urgent', assignedDate:'12/10/2020'},
    // ];

    // hold = [
    //   {title:'Poor service provided ', description:"I needed to be travelled from Khanapara Fly Over, Guwahati to Kalibari Rest Camp, Guwahati. I had stood there for about 75 minutes but the pilot did not arrived", status:'Normal', assignedDate:'12/10/2020'},
    //   {title:'Driver did not return my phone', description:"I took a ride from uber on ** June **** from Salt Lake, Kolkata at *:** p.m. and reached Birla Planetarium at *:** p.m. But unfortunately, I left my Asus Zenfone Max cell phone inside the cab. After ** minutes I realised that the phone is not w..", status:'Normal', assignedDate:'12/10/2020'},
    //   {title:'Accidental dash by the cab', description:"Today, On Hinjewadi crossing an UBER cab No. MH ** NB **** has dashed our car. The damage is on the front of the right wheel side. After giving a series of horns he doesn't stop and hit. Without any indicator, he was coming....", status:'Normal', assignedDate:'12/10/2020'},

    // ];

    // inprogress = [
    //   {title:'CAB NOT COMING ON TIME', description:"I called the cab driver that he was supposed to report at 8:45 PM and where is he? Cab driver was very adamant and answered that you can arrange your vehicle i would take another 20-25 mins to reach,", status:'Urgent', assignedDate:'12/10/2020'},
    //   {title:'No show', description:"Cab driver did not come . Neither he picked up phone despite several tries . Upon contacting booking Centre again they simply say sorry can't arrange cab", status:'Urgent', assignedDate:'12/10/2020'},
    //   {title:'Regarding abusive driver ', description:"Driver used the abusive language to the women though it was not our fault he was saying to charge ** rs extra as the road work was in progress and he was busy talking the shits with us with loud voice and verbal aggression and he said us to get      ", status:'Urgent', assignedDate:'12/10/2020'},
    // ];

    // done = [
    //   {title:'Pathetic Experience - Various Complaints', description:"horrible Cab - Exteriors of Vehicle was run down, dented and badly scarred - worse than the worse", status:'Urgent', assignedDate:'12/10/2020'},
    //   {title:'Pathetic Vehicle & Service', description:"Horrible Cab - Exteriors of Vehicle was run down, dented and badly scarred - worse than the worse", status:'Urgent', assignedDate:'12/10/2020'},

    // ];

    // drop(event: CdkDragDrop<string[]>) {
    //   if (event.previousContainer === event.container) {
    //     moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    //   } else {
    //     transferArrayItem(event.previousContainer.data,
    //                       event.container.data,
    //                       event.previousIndex,
    //                       event.currentIndex);
    //   }
    // }
}
