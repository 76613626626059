import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { SweetAlert } from 'src/app/Services/sweetalet.service';
import { VendorModule } from '../../../../../../modules/information/vendor';


@Component({
    selector: 'app-edit-vendor',
    templateUrl: './edit-vendor.component.html'
})

export class EditVendorComponent implements OnInit {
    orgID: any;
    vendorID: any;
    vendorModuleData = VendorModule;
    enableForm: boolean;

    constructor(
        public api: ApiService,
        public router: Router,
        public route: ActivatedRoute,
        public swal: SweetAlert

    ) {
        this.route.paramMap.subscribe(params => {
            console.log(params.get('orgID'), params.get('vendorID'));
            this.orgID = params.get('orgID');
            this.vendorID = params.get('vendorID');

        });
    }
    ngOnInit() {
        this.getVenderData();
    }


    getVenderData() {
        const obj = {
            orgID: this.orgID,
            vendorID: this.vendorID
        };
        this.api.transportManagerVendorList(obj).then((res: any) => {
            if (res.status == 'success') {
                for (const f of this.vendorModuleData) {
                    // console.log(f)
                    const name = f.name;
                    let value = '';

                    if (f.type == 'dropdown') {
                        const val = res.data[0][name].split(',');
                        const temp = [], str = [];
                        val.map(item => { temp.push({ value: val, name: val }); str.push(item); });
                        // f.selected = temp;
                        f.value = str.join(',');
                    } else {
                        value = name;
                        f.value = res.data[0][name];
                    }
                }
                console.log(this.vendorModuleData);
                this.enableForm = true;
                // this.swal.swalFunction(res.status, res.status, res.message, 2000)

            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }

        }).catch((err) => {
            console.log(err);
        });
    }

    submitted(e) {
        console.log(e.value);
        const obj = {
            orgID: this.orgID,
            vendorID: this.vendorID,
            ...e.value
        };
        this.api.transportManagerVendorUpdate(obj).then((res: any) => {
            if (res.status == 'success') {
                this.swal.swalFunction(res.status, res.status, res.message, 2000);
                this.router.navigate(['/organization/transport-manager/information/vendor/view-vendor']);
            }
            if (res.status == 'failure') {
                this.swal.swalFunction('error', res.status, res.message, 2000);
            }
        }).catch((err) => {
            console.log(err);
        });
    }
}
