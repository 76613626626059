import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/Services/storage.service';
import { ApiService } from 'src/app/Services/api.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
// declare const popup: any, snackbar: any;
import *  as  data from './html-code.json';
import { Cards } from '../../Interfaces/card';
import { DialogComponent } from 'src/app/Directives/dialog/dialog.component';
import { DialogActionService } from 'src/app/Services/dialog-actions.service.js';
import { SnackBarService } from 'src/app/Services/snackbar.service.js';

import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';



export interface DialogData {
  code: string; lang: string;
}

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent {
  constructor(
    public dialog: MatDialog,
    public dialogService: DialogActionService,
    public snackBar: SnackBarService) {
    this.JSON = JSON;

  }
  @ViewChild('search', {static: true}) searchTextBox: ElementRef;
  dataModel: any;
  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();
  selectedValues = [];
  data: string[] = [
    'A1',
    'A2',
    'A3',
    'B1',
    'B2',
    'B3',
    'C1',
    'C2',
    'C3'
  ];

  filteredOptions: Observable<any[]>;

  cardcode = '<g10-ms-card [card]=\'card\'></g10-ms-card>';
  logoutDialogCode = this.openLogoutModal.toString();
  deleteDialogCode = this.openDeleteProductModal.toString();

  JSON: any;
  cards = Cards;
  public barchartHtmlCode: any = ' <g10-ms-bar-chart [chartType]="\'bar\'" [labels]="barChartLabels" [barChartData]="barChartData2"></g10-ms-bar-chart>';
  public piechartHtmlCode: any = ' <g10-ms-pie-chart [pieChartData]="pieChartData" [pieChartLabels]="pieChartLabels"></g10-ms-pie-chart>';
  public linechartHtmlCode: any = ' <g10-ms-line-chart  [lineChartData]="lineChartData" [lineChartLabels]="lineChartLabels"></g10-ms-line-chart>';
  public dynamicFromCode: any = ' <g10-ms-dynamic-form-builder [fields]="fields" (values)="submitted(event)"></g10-ms-dynamic-form-builder>';

  htmlCode: any = (data as any).default[0];



  // Button Config
  message = 'Click on a button';
  textBtnConfig = {
    styles: {
      // position: 'relative',
      // width: '150px',
      // height: '60px',
      // backgroundColor: '#f92672',
      // color: '#fff',
      // fontFamily: 'sans-serif',
      // fontSize: '20px',
      // borderRadius: '10px',
      // marginTop: '30px'
    },
    color: 'primary',
    type: 'mat-raised-button',
    text: 'Raised Button Primary',
    disabled: true
  };

  textBtnConfig2 = {
    styles: {
      // position: 'relative',
      // width: '150px',
      // height: '60px',
      // backgroundColor: '#f92672',
      // color: '#fff',
      // fontFamily: 'sans-serif',
      // fontSize: '20px',
      // borderRadius: '10px',
      // marginTop: '30px'
    },
    color: 'primary',
    type: 'mat-stroked-button',
    text: 'Outline Button Primary',
    disabled: true
  };

  imgBtnConfig = {
    styles: {
      position: 'relative',
      width: '60px',
      height: '60px'
    },
    src: './assets/img/logo.png'
  };

  // ==============Table Data====================

  cols = [
    { key: '_id', display: 'User Id' },
    { key: 'username', display: 'Username' },
    { key: 'email', display: 'Email' },
    // following objects will contain a specific config parameters
    // based on this params we will change the display of each column
    {
      key: 'dob',
      display: 'Date of Birth',
      // This column will hold a date value, so we must format the
      // display to show as a date
      config: {
        isDate: true,
        format: 'dd MMM yy'
      }
    },
    {
      key: 'active',
      display: 'Active/Blocked',
      // this column holds a boolean value, we will display a value
      // in true/false cases
      config: {
        isBoolean: true,
        values: { true: 'Blocked', false: 'Active' }
      }
    },
    {
      key: 'action',
      display: 'Action',
      // in this column we have actions like activate/block account
      // so we will create a button and create it event click
      config: {
        isAction: true,
        actions: [
          { display: 'view', color: 'primary', icon: 'View' },
          { display: 'delete', color: 'warn', icon: 'Delete' },
          { display: 'update', color: 'primary' }
        ]
      }
    }
  ];

  USERS_DATA = [
    {
      _id: 1,
      username: 'Abderrahmene',
      email: 'abderrahmene@abc.xyz',
      dob: Date.now(),
      active: true
    },
    {
      _id: 2,
      username: 'Mohammed',
      email: 'mohammed@abc.xyz',
      dob: Date.now(),
      active: false
    },
    {
      _id: 3,
      username: 'Mustapha',
      email: 'mustapha@abc.xyz',
      dob: Date.now(),
      active: true
    },
    {
      _id: 4,
      username: 'Abdelaziz',
      email: 'abdelaziz@abc.xyz',
      dob: Date.now(),
      active: false
    },
    {
      _id: 5,
      username: 'Abdelhakim',
      email: 'hakim@abc.xyz',
      dob: Date.now(),
      active: true
    },
    {
      _id: 6,
      username: 'Ilyes',
      email: 'ilyes@abc.xyz',
      dob: Date.now(),
      active: true
    },
    {
      _id: 7,
      username: 'Salim',
      email: 'salim@abc.xyz',
      dob: Date.now(),
      active: false
    },
    {
      _id: 8,
      username: 'Omar',
      email: 'omar@abc.xyz',
      dob: Date.now(),
      active: true
    },
    {
      _id: 9,
      username: 'Issam',
      email: 'issam@abc.xyz',
      dob: Date.now(),
      active: false
    },
    {
      _id: 10,
      username: 'Osman',
      email: 'osman@abc.xyz',
      dob: Date.now(),
      active: false
    }
  ];




  // lineChart Data

  public lineChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' },
    { data: [35, 25, 40, 15, 48, 40, 28], label: 'Series C' },
    { data: [40, 19, 86, 27, 90, 27, 86], label: 'Series D' },
    { data: [15, 48, 40, 28, 48, 40, 19], label: 'Series E' },
    { data: [55, 48, 40, 19, 86, 27, 43], label: 'Series F' },
    { data: [30, 40, 19, 86, 27, 25, 46], label: 'Series G' }

    // { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  ];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];



  public lineChartData2: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Line A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Line B' },
    { data: [35, 25, 40, 15, 48, 40, 28], label: 'Line C' },


    // { data: [180, 480, 770, 90, 1000, 270, 400], label: 'Series C', yAxisID: 'y-axis-1' }
  ];
  public lineChartLabels2: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];



  // Bar chart data

  public myLabelsArray = [
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012'
  ];

  public barChartData = [
    {
      data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', stack: 'a', barThickness: 16, barPercentage: 0.5,
    },
    {
      data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B', stack: 'a', barThickness: 16, barPercentage: 0.5,
    }
  ];








  // bar chart2

  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData2: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B', }
  ];


  // Pie Chart

  public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartData: number[] = [300, 500, 100];

  // Pie Chart 2

  public pieChartLabels2: Label[] = [['Total', 'Available', 'Leave'], ['Sick', 'Leave'], 'Causal Leave', 'Company Holiday'];
  public pieChartData2: number[] = [10, 12, 15, 16];



  // form create
  title = 'reactiveForm';

  public fields: any[] = [
    {
      type: 'text',
      name: 'firstName',
      label: 'First Name',
      value: 'RRRRR',
      required: true,
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Last Name',
      value: 'E',
      required: true,
    },
    {
      type: 'text',
      name: 'email',
      label: 'Email',
      value: 'rajae@groupten.com',
      required: true,
    },
    {
      type: 'password',
      name: 'password',
      label: 'Password',
      value: '123',
      required: true,
    },
    {
      type: 'number',
      name: 'number',
      label: 'Contact No',
      value: '42323232',
      required: true,
    },
    {
      type: 'dropdown',
      name: 'country',
      label: 'Country',
      value: '',
      required: true,
      options: [
        { value: '', name: 'Select' },
        { value: 'in', name: 'India' },
        { value: 'us', name: 'USA' }
      ]
    },
    {
      type: 'dropdown',
      name: 'city',
      label: 'City',
      value: '',
      multiselect: true,
      required: true,
      options: [
        { value: '', name: 'Select' },
        { value: 'TN', name: 'TN' },
        { value: 'Kerala', name: 'Kerala' },
        { value: 'Bangalore', name: 'Bangalore' }
      ]

    },

    {
      type: 'radio',
      name: 'gender',
      label: 'Gender',
      value: '',
      options: [
        { value: 'Male', name: 'Male' },
        { value: 'Female', name: 'Female' }
      ],
      required: true,
    },
    {
      type: 'checkbox',
      name: 'language',
      label: 'Language',
      value: '',
      options: [
        { value: 'tamil', name: 'Tamil' },
        { value: 'english', name: 'English' }
      ],
      required: true,
    },
    {
      type: 'date',
      name: 'sDate',
      label: 'Start Date',
      minDate: 'today',
      value: '2020-09-27',
      required: true,
    },
    {
      type: 'text',
      name: 'address',
      label: 'Address',
      value: '',
      placeholder: 'type something',
      required: true,
      multiline: true
    },
  ];


  ngOnInit() {

   this.filteredOptions = this.searchTextboxControl.valueChanges
     .pipe(
       startWith<string>(''),
       map(name => this._filter(name))
     );
    // console.log(this.htmlCode);
  }

  private _filter(name: string): String[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    const filteredList = this.data.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    return filteredList;
  }

/**
 * Remove from selected values based on uncheck
 */
  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      const index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1);
    }
  }

  openedChange(e) {
    // Set search textbox value as empty while opening selectbox
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  /**
   * Clearing search textbox value
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }

  /**
   * Set selected values to retain the state
   */
  setSelectedValues() {
    console.log('selectFormControl', this.selectFormControl.value);
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  format(html) {
    const tab = '\t';
    let result = '';
    let indent = '';

    html.split(/>\s*</).forEach(function(element) {
      if (element.match(/^\/\w/)) {
        indent = indent.substring(tab.length);
      }

      result += indent + '<' + element + '>\r\n';

      if (element.match(/^<?\w[^>]*[^\/]$/)) {
        indent += tab;
      }
    });

    return result.substring(1, result.length - 3);
  }


  htmlEntities(str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }


  // Dialog box
  openDialog(id): void {
    const code = this.htmlCode[id].code;
    const format = this.format(code);
    const dialogRef = this.dialog.open(DialogDataExampleDialog, {
      data: { code: format, lang: this.htmlCode[id].lang }
    });

  }

  onClickEventReceived(event: string) {
    console.log(event);
    this.message = event;
  }


  onClickEventReceived1(event: string) {
    console.log(event);
    this.message = event;
  }

  onActionHandler(event) {
    console.log(event);
  }





  // Dialog Window Reusable Methods
  // =======================================

  openLogoutModal(e: any) {
    const userId = 'user01';


    const config = {
      name: 'logout',
      title: 'Are you sure you want to logout?',
      description: 'Pretend this is a convincing argument on why you shouldn\'t logout :)',
      actionButtonText: 'Logout',
      userId
    };
    this.dialogService.popup(config);
    // const modalDialog = this.dialog.open(DialogComponent, dialogConfig);
  }

  openDeleteProductModal(e: any) {
    const productId = 'prod01';
    let config = {};
    config = {
      name: 'deleteProduct',
      title: 'Are you sure you want to delete this product?',
      description: 'If you continue, the product with ID ' + productId + ' will be deleted.',
      actionButtonText: 'Delete',
      productId
    };
    this.dialogService.popup(config);
    // const modalDialog = this.dialog.open(DialogComponent, dialogConfig);
  }



  // =========================SnackBarService======================
  triggerSnackbar(e: any) {
    const config = {
      message: '<p class=\'p-0 m-0\'> <span > <i class=\'ms-Icon ms-Icon--InfoSolid text-primary h4 m-0 p-0 icon\' aria-hidden=\'true\'> </i> </span> Uploaded Succesfully! </p>',
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5000000
    };

    const snackBarRef: any = this.snackBar.openSnackBar(config);

  }

  submitted(obj: any) {
    // console.log('@@@', obj);
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialog {

  constructor(
    public dialogRef: MatDialogRef<DialogDataExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
