import { Component , OnInit } from '@angular/core';
declare const mapUtilityMapBox;

@Component({
    selector: 'app-alert-dashboard',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.css'],
    // templateUrl:'./alert.component.html',
})

export class AlertsComponent {

constructor() {

}

ngOnInit(): void {
}


}
