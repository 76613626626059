import { AddAnnouncementComponent } from './add-announcement/add-announcement.component';
import { SingleViewAnnouncementComponent } from './view-announcement/single-view-announcement/single-view-announcement.component';
import { ViewAnnouncementComponent } from './view-announcement/view-announcement.component';

export const AnnouncementRouting = [

    {path: 'add-announcement', component: AddAnnouncementComponent},
    {path: 'view-announcement', component: ViewAnnouncementComponent},
    {path: 'single-view-announcement', component: SingleViewAnnouncementComponent}
    // {path:'',redirectTo:'add-announcement',pathMatch:'full'},
];
