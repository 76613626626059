import { Component, OnInit } from '@angular/core';
import { employeeBillingModule } from '../../../../../../modules/information/employeebilling';

@Component({
  selector: 'app-addemployeebilling',
  templateUrl: './addemployeebilling.component.html',
  styleUrls: ['./addemployeebilling.component.css']
})
export class AddemployeebillingComponent implements OnInit {
public employeeBillingInfo = employeeBillingModule;
  constructor() { }

  ngOnInit(): void {
  }
  submitted(e: any) {
    console.log(e);
}

}
