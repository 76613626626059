export const VehicleModule: any = [
  {
        type: 'text',
        name: 'vehRegNo',
        label: 'Vehicle registration no',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'number',
        name: 'imei',
        label: 'IMEI',
        value: '',
        required: true,
        col: 'col-md-4',
        textType: 'imei'

      },
      // {
      //   type: 'text',
      //   name: 'url',
      //   label: 'URL',
      //   inType:"url",
      //   value: '',
      //   required: true,
      //   col:'col-md-4'

      // },
      {
        type: 'text',
        name: 'make',
        label: 'Make of the vehicle',
        value: '',
        required: true,
        col: 'col-md-4'

      },
      {
        type: 'text',
        name: 'model',
        label: 'Model of the vehicle',
        value: '',
        required: true,
        col: 'col-md-4'

      },
      {
        type: 'number',
        name: 'capacity',
        label: 'Seat Capacity',
        value: '',
        required: true,
        col: 'col-md-4'

      },
      {
        type: 'dropdown',
        name: 'vehicleType',
        label: 'Vehicle type',
        value: '',
        required: true,
        options: [
            { value: 'Owned', name: 'Owned'},
            { value: 'Leased Vehicle', name: 'Leased Vehicle'},
            { value: 'Vendor Owned', name: 'Vendor Owned'}
          ],
          col: 'col-md-4'
      },
      {
        type: 'text',
        name: 'chassisNo',
        label: 'Vehicle chassis no',
        value: '',
        required: true,
        col: 'col-md-4'

      },
      {
        type: 'text',
        name: 'engineNo',
        label: 'Vehicle engine no',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'date',
        name: 'purchasedDate',
        label: 'Purchased date',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'text',
        name: 'routePermitCost',
        label: 'Route permit cost',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'date',
        name: 'startDate',
        label: 'Start date',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'date',
        name: 'expiryDate',
        label: 'Expiry date',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'radio',
        name: 'roadTaxStatus',
        label: 'Road tax paid',
        value: '',
        required: true,
        options: [
            { value: true, name: 'Yes'},
            { value: false, name: 'No'}
          ],
          col: 'col-md-12'
      },
      {
        type: 'text',
        name: 'rcNo',
        label: 'RC book no',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'date',
        name: 'rcStartDate',
        label: 'Start date',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'date',
        name: 'rcExpiryDate',
        label: 'Expiry date',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'file',
        name: 'rcfile',
        label: 'Upload RC',
        value: '',
        required: true,
        col: 'col-md-12'
      },
      {
        type: 'text',
        name: 'vehicleInfo',
        label: 'Vehicle details',
        value: '',
        required: true,
        col: 'col-md-4'
      },
      {
        type: 'text',
        name: 'location',
        label: 'Location',
        value: '',
        col: 'col-md-4',
        click: (e) => {
            const x = document.getElementById('vehicleLocationId');
            x.click();
        },
    },
      {
        type: 'dropdown',
        name: 'boxType',
        label: 'Box type',
        value: '',
        required: true,
        options: [
            { value: 'groupten', name: 'Group10'},
            { value: 'others', name: 'Others'},
          ],
        col: 'col-md-4',
      },

];
