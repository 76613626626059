import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(value: any, filterKey: any, key: string): any {
    if (value != undefined) {
      console.log(value, filterKey, key, typeof (filterKey))
      return value.filter((item: any) => {
        // item[key].toLowerCase()
        // if (fiterType == 'request') {

        if (typeof filterKey == 'string' && item[key].toLowerCase().includes(filterKey.toLowerCase())) {
          return item;
        }
        else {
          console.log(filterKey)
          filterKey.forEach(element => {
            if (item[filterKey].toLowerCase().includes(element.toLowerCase())) {
              return item
            }
          });
        }
        // }
        // if (fiterType == 'vehicleList') {
        //   if (item.reg.toLowerCase().includes(filterKey.toLowerCase())) {
        //     console.log(item,'vehicleList')
        //     return item;
        //   }
        // }
        // else {
        // if (item.name.toLowerCase().includes(filterKey.toLowerCase())) {
        //   return item;
        // }
        // }
      });
    } else { return value; }

  }

}
